import styled from 'styled-components';
type Props = {
    userText: string,
    userInfo: string,
    headingImage: string,
    backgroundImage: string,
    textColor: string
}
export default styled.div<Props>`
.playerCard {
    background-image: url("${(props) => props.backgroundImage}");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 204px;
    cursor: pointer;
    width: 144px;
    text-align: center;
    padding: 10px 9px;
    position: relative;
    margin:10px 0px;
}
.medalImg{
    width: 65px;
    background-color: #212153;
    border-radius: 50%;
    padding: 4px;
    height: 65px;
    margin-top: 5px;
}
.user-name {
    /* background-color: red; */
    background-image: url("${(props) => props.headingImage}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: 10px;
    height:42px;
}

.user-name h1 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    background: ${(props) => props.userText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
   margin-bottom: 2px;


//    white-space: nowrap;
//    overflow: hidden;
//    max-width: 9ch;

}

.location,
.time {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    color: ${(props) => props.userInfo};
    margin-bottom: 0px;
}

.location svg,
.time svg {
    padding-left: 5px;
    font-size: 13px;
    color: #FDD72E;
}

.description {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: ${(props) => props.userInfo};
    text-shadow: -1px 0px 1px rgba(16, 1, 89, 0.22);
    margin-top: 10px;
    margin-bottom: 0px;
}
.descriptionOne{
    margin-top: 1px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: ${(props) => props.userInfo};
    text-shadow: -1px 0px 1px rgba(16, 1, 89, 0.22);
    margin-bottom: 0px;
}
.descriptionTwo{
    margin-top: 1px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    color: ${(props) => props.userInfo};
    text-shadow: -1px 0px 1px rgba(16, 1, 89, 0.22);
    margin-bottom: 0px;
}
// .imark{
//     position: relative;
// text-align: center;
// display: flex;
// justify-content: center;
// }
.imark img {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%,0)
   
}
.plusBtn{
    position: absolute;
right: 8px;
background-color: transparent;
border: none;
}
.plusBtn img{
    width: 20px
}
.userBtn{
    // position: absolute;
    left: 8px;
    background-color: transparent;
    border: none;
}
.userBtn img{
    width: 16px;
}
.removeCard{
    position: absolute;
    top: -16px;
    right: -19px;
    width: 15px;
    height: 15px;
    text-align: center;
    background: ${(props) => props.textColor};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}
.removeCard svg{
    color: #fff;
    font-size: 11px;
}
@media screen and (max-width: 1580px) {
    .playerCard {
        height: 166px;
    width: 120px;
        padding: 6px 5px;
         margin:10px 0px;
    }
    .medalImg{
        width: 32px;
        background-color: #212153;
        border-radius: 50%;
        padding: 2px;
        height: 32px;
        margin-top:5px;
    }
    .description {

        margin-top: 2px;
    }
    .plusBtn img{
        width: 16px
    }
    .user-name {
        height: 29px;
    }
    .user-name h1 {
        font-size: 14px;
 
    }
    
}
`;