import React from 'react'
import { Dropdown } from 'react-bootstrap';
import iButton from '../../../assets/images/icon/iButton.png'
const InfoDropdown = () => {
    return (
        <>

            <Dropdown className='iButtonImage'>
                <Dropdown.Toggle id="dropdown-basic">
                    <img className='iButton' src={iButton} alt="" style={{ width: '40px' }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='ibuttonInfo'>
                    <h1>על מנת למצוא תוצאות חיפוש רלוונטיות :</h1>
                    <h2>  <span className='serialNumber'>1.</span> בחרו תצוגה: ניתן לראות את התוצאות בתור <span className="textBold">כרטיסים</span> או על <span className="textBold">גבי מפה</span></h2>

                    <h2> <span className='serialNumber'>2.</span>בחרו אופציות סינון :</h2>
                    <h3 className='rightGap'><span className="textBold">כל התוצאות =</span> אין מסננים בחיפוש.</h3>
                    <h3 className='rightGap'><span className="textBold">מותאם אישית =</span> פתיחת אופציות סינון בחיפוש.</h3>
                    <h3 className='rightGap'>ניתן לסנן לפי מרחק, טווח גילאים, רמת משחק, ימים ושעות משחק ועוד.</h3>

                    <h2><span className='serialNumber'>3.</span> לחצו על כפתור <span className="textBold">חפש</span> כדי לקבל תוצאות חיפוש.</h2>
                    <h2> <span className='serialNumber'>4.</span> ניתן למיין את תוצאות החיפוש בכפתור <span className="textBold">מיין לפי.</span></h2>
                    <h2><span className='serialNumber'>5.</span> כדי לראות את פרטי הכרטיס המלאים, <span className="textBold"> יש ללחוץ על כרטיס המשחק.</span></h2>
                    <h2> <span className='serialNumber'>6.</span> בחלק התחתון של הכרטיס המורחב יש<span className="textBold"> אופציות ליצירת קשר. </span></h2>
                    <h2> <span className='serialNumber'>7.</span> ניתן לחזור ולשנות את המסננים בכפתור<span className="textBold"> חזרה למסננים.</span></h2>

                    <h1 className="mt-3">הגדרות חשובות:</h1>
                    <h4> כרטיסי המשחק מחולקים לצבעים לפי
                        רמות משחק:
                    </h4>

                    <h5><span className="textImageThree">ברונזה</span> רמה חובבנית</h5>
                    <h5><span className="textImageTwo">כסוף </span> רמה בינונית</h5>
                    <h5><span className="textImageOne">זהב</span>  רמה מתקדמת</h5>
                    <h5><span className="textImageFour">סגול</span>  רמה מקצועית </h5>

                    <h6 className='mt-3'><span className="textBold">רמות המשחק</span> נקבעות לפי איכות ביצועי משחק וניסיון עבר.</h6>
                    <h6><span className="textBold">דרגת כושר</span> נקבעת לפי המצב הפיזי העדכני של השחקן, ושקלול של תדירות  </h6>
                    <h4> המשחק וזמן המשחק הממוצע שלו.</h4>

                </Dropdown.Menu>
            </Dropdown>

        </>
    )
}

export default InfoDropdown