import { post, get, del, put } from './base-api';

//get user history log
export const getLogHistory = (page: number, limit: number, isFavorite: boolean = false, sortBy: string = "", payload: any | null = null) => {
    return post(`/user-log-history?sortBy=${sortBy}&limit=${limit}&page=${page}` + (isFavorite ? `&isFavorite=${isFavorite}` : ''), payload || {});
};

//toggle favourite
export const toggleFavourite = (id: number) => {
    return get(`/user-log-history/toggle-favorite/${id}`);
};

//disable log history
export const deleteLogHistory = (logId: number) => {
    return get(`/user-log-history/disable/${logId}`);
};