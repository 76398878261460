import React, { useState } from 'react'
import { Tab, Tabs, Dropdown, ButtonGroup, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import multiProfile from '../../../assets/images/icon/multiProfile.png'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './Signup.css'
import SignUpButton from '../../StyleComponent/SignUpButton/SignUpButton';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { eachTeamPlayer, surfaceType, gamePosition, sportType } from '../../../store/theme/actions';
import themeReducer, { InitialState } from '../../../store/theme/themeReducer';
import commonHebrew from '../../../assets/raw-text/common.json';

interface GameChooseProps {
  footballError: any;
  footballSurfaceError: any;
  footballEachError: any;

  basketballError: any;
  basketballEachError: any;

  tennisError: any;
  tennisEachError: any;

  volleyballError: any;
  volleyballEachError: any;
  firstLine: () => void;

}
const GameType = ({ tennisEachError, volleyballEachError, footballEachError, footballSurfaceError, basketballEachError, tennisError, footballError, basketballError, volleyballError, firstLine }: GameChooseProps) => {

  const themeState = useSelector((state: InitialState) => state);
  const dispatch = useDispatch();
  const [eachTeamPlayerValue, setEachTeamPlayer] = useState(themeState.eachTeamPlayer && themeState.eachTeamPlayer ? themeState.eachTeamPlayer : "");
  const [surfaceTypeValue, setSurfaceType] = useState(themeState.surfaceType && themeState.surfaceType ? themeState.surfaceType : "");
  const [gamePositionValue, setGamePosition] = useState("");

  const [sportsType, setSportsType] = useState(themeState.sportType && themeState.sportType ? themeState.sportType : "")

  // choose each team player
  const eachTeamHandler = (value: string) => {
    dispatch(eachTeamPlayer(value));
  }

  React.useEffect(() => {
    eachTeamHandler(eachTeamPlayerValue)
  }, [eachTeamPlayerValue]);

  // choose surface type
  const surfaceHandler = (value: string) => {
    dispatch(surfaceType(value));
  }

  React.useEffect(() => {
    surfaceHandler(surfaceTypeValue)
  }, [surfaceTypeValue]);

  // choose game position
  const gamePositionHandler = (value: string) => {
    dispatch(gamePosition(value));
  }

  React.useEffect(() => {
    gamePositionHandler(gamePositionValue)
  }, [gamePositionValue]);

  // choose sport type
  const sportsUpHandler = (value: string) => {
    dispatch(sportType(value));
  }

  React.useEffect(() => {
    sportsUpHandler(sportsType)
  }, [sportsType]);



  const playerCategoryChoose = [
    { eachTeamSize: "4-5", eachTeamName: "4-5" },
    { eachTeamSize: "6-7", eachTeamName: "6-7" },
    { eachTeamSize: "8-10", eachTeamName: "8-10" },
    { eachTeamSize: "11", eachTeamName: "11" },
    { eachTeamSize: "DOESN'T MATTER", eachTeamName: "לא משנה" },
  ];

  const leaderCategoryChoose = [
    { eachTeamSize: "4-5", eachTeamName: "4-5" },
    { eachTeamSize: "6-7", eachTeamName: "6-7" },
    { eachTeamSize: "8-10", eachTeamName: "8-10" },
    { eachTeamSize: "11", eachTeamName: "11" },
    { eachTeamSize: "NOT_CONSTANT", eachTeamName: "לא קבוע" }
  ];


  const categoryChoose = themeState.userRole === "LEADER" ? leaderCategoryChoose : playerCategoryChoose;


  const playerBasketChoose = [
    { eachTeamSize: "1-2", eachTeamName: "1-2" },
    { eachTeamSize: "3-4", eachTeamName: "3-4" },
    { eachTeamSize: "5", eachTeamName: "5" },
    { eachTeamSize: "DOESN'T MATTER", eachTeamName: "לא משנה" }
  ];

  const leaderBasketChoose = [
    { eachTeamSize: "1-2", eachTeamName: "1-2" },
    { eachTeamSize: "3-4", eachTeamName: "3-4" },
    { eachTeamSize: "5", eachTeamName: "5" },
    { eachTeamSize: "NOT_CONSTANT", eachTeamName: "לא קבוע" }
  ];

  const basketChoose = themeState.userRole === "LEADER" ? leaderBasketChoose : playerBasketChoose;


  const playerVolleyChoose = [
    { eachTeamSize: "2", eachTeamName: "2" },
    { eachTeamSize: "3", eachTeamName: "3" },
    { eachTeamSize: "4", eachTeamName: "4" },
    { eachTeamSize: "6", eachTeamName: "6" },
    { eachTeamSize: "DOESN'T MATTER", eachTeamName: "לא משנה" },
  ];

  const leaderVolleyChoose = [
    { eachTeamSize: "2", eachTeamName: "2" },
    { eachTeamSize: "3", eachTeamName: "3" },
    { eachTeamSize: "4", eachTeamName: "4" },
    { eachTeamSize: "6", eachTeamName: "6" },
    { eachTeamSize: "NOT_CONSTANT", eachTeamName: "לא קבוע" }
  ];

  const volleyChoose = themeState.userRole === "LEADER" ? leaderVolleyChoose : playerVolleyChoose;

  const playerTennisChoose = [
    { eachTeamSize: "SINGLES", eachTeamName: "יחיד" },
    { eachTeamSize: "DOUBLES", eachTeamName: "זוגות" },
    { eachTeamSize: "DOESN'T MATTER", eachTeamName: "לא משנה" },
  ];
  const leaderTennisChoose = [
    { eachTeamSize: "SINGLES", eachTeamName: "יחיד" },
    { eachTeamSize: "DOUBLES", eachTeamName: "זוגות" },
    { eachTeamSize: "NOT_CONSTANT", eachTeamName: "לא קבוע" }
  ];

  const tennisChoose = themeState.userRole === "LEADER" ? leaderTennisChoose : playerTennisChoose;

  const playerSurfaceFootballChoose = [
    { surfaceSize: "ASPHALT", surfaceName: "אספלט" },
    { surfaceSize: "SYNTHETIC_GRASS", surfaceName: "דשא סינטטי" },
    { surfaceSize: "REAL_GRASS", surfaceName: "דשא אמיתי" },
    { surfaceSize: "HALL", surfaceName: "אולם" },
    { surfaceSize: "DOESN'T MATTER", surfaceName: "לא משנה" },
  ];

  const leaderSurfaceFootballChoose = [
    { surfaceSize: "ASPHALT", surfaceName: "אספלט" },
    { surfaceSize: "SYNTHETIC_GRASS", surfaceName: "דשא סינטטי" },
    { surfaceSize: "REAL_GRASS", surfaceName: "דשא אמיתי" },
    { surfaceSize: "HALL", surfaceName: "אולם" },
    { surfaceSize: "OTHER", surfaceName: "אחר" }
  ];

  const surfaceFootballChoose = themeState.userRole === "LEADER" ? leaderSurfaceFootballChoose : playerSurfaceFootballChoose;

  const playerSurfaceBasketBallChoose = [
    { surfaceSize: "HALL", surfaceName: "אולם" },
    { surfaceSize: "OUTSIDE_PITCHES", surfaceName: "מגרשי חוץ" },
    { surfaceSize: "DOESN'T MATTER", surfaceName: "לא משנה" },
  ];
  const leaderSurfaceBasketBallChoose = [
    { surfaceSize: "HALL", surfaceName: "אולם" },
    { surfaceSize: "OUTSIDE_PITCHES", surfaceName: "מגרשי חוץ" },
    { surfaceSize: "NOT_CONSTANT", surfaceName: "לא קבוע" }
  ];

  const surfaceBasketBallChoose = themeState.userRole === "LEADER" ? leaderSurfaceBasketBallChoose : playerSurfaceBasketBallChoose;

  const playerSurfaceVolleyBallChoose = [
    { surfaceSize: "HALL", surfaceName: "אולם" },
    { surfaceSize: "BEACH", surfaceName: "חופים" },
    { surfaceSize: "DOESN'T MATTER", surfaceName: "לא משנה" },
  ];

  const leaderSurfaceVolleyBallChoose = [
    { surfaceSize: "HALL", surfaceName: "אולם" },
    { surfaceSize: "BEACH", surfaceName: "חופים" },
    { surfaceSize: "NOT_CONSTANT", surfaceName: "לא קבוע" }
  ];


  const surfaceVolleyBallChoose = themeState.userRole === "LEADER" ? leaderSurfaceVolleyBallChoose : playerSurfaceVolleyBallChoose;


  const playerSurfaceTennisChoose = [
    { surfaceSize: "ASPHALT", surfaceName: "אספלט" },
    { surfaceSize: "REAL_GRASS", surfaceName: "דשא" },
    { surfaceSize: "CLAY", surfaceName: "חימר" },
    { surfaceSize: "DOESN'T MATTER", surfaceName: "לא משנה" },
  ];

  const leaderSurfaceTennisChoose = [
    { surfaceSize: "ASPHALT", surfaceName: "אספלט" },
    { surfaceSize: "REAL_GRASS", surfaceName: "דשא" },
    { surfaceSize: "CLAY", surfaceName: "חימר" },
    { surfaceSize: "NOT_CONSTANT", surfaceName: "לא קבוע" }
  ];


  const surfaceTennisChoose = themeState.userRole === "LEADER" ? leaderSurfaceTennisChoose : playerSurfaceTennisChoose;



  //const [gamePositionChoose, setValue] = useState()


  //   const matter=[
  //     { gameSize: "GOAL_KEEPER", gameName: "goal" },
  //     { gameSize: "FIELDER", gameName: "feild שדה" },
  //     { gameSize: "DOESN'T MATTER", gameName: "לא matter" },
  //   ]
  // const constvalue=[
  //   { gameSize: "GOAL_KEEPER", gameName: "goal" },
  //   { gameSize: "FIELDER", gameName: "feild שדה" },
  //   { gameSize: "DOESN'T MATTER", gameName: "לא constant" },
  // ]
  // if(themeState.userRole === "LEADER"){
  //   setValue(constvalue)
  // }
  // else{
  //   setValue(matter)
  // }


  const gamePositionChoose = [
    { gameSize: "GOAL_KEEPER", gameName: "שוער" },
    { gameSize: "FIELDER", gameName: "שחקן שדה" },
    { gameSize: "DOESN'T MATTER", gameName: "לא משנה" },
  ];


  const [showEach, setShowEach] = useState("")
  const [showSurface, setShowSurface] = useState("")
  const [showGame, setShowGame] = useState("")

  const chooseCategory = (eachTeamSize: any, eachTeamName: any) => {
    setEachTeamPlayer(eachTeamSize);
    setShowEach(eachTeamName)
  }
  const chooseSurface = (surfaceSize: any, surfaceName: any) => {
    setSurfaceType(surfaceSize);
    setShowSurface(surfaceName)
  }
  const chooseGamePosition = (gameSize: any, gameName: any) => {
    setGamePosition(gameSize);
    setShowGame(gameName)
  }
  // surface football
  const surfaceTeamFunction = () => {
    return surfaceFootballChoose.map((data, i: number) => (
      <div key={`navbar + ${i}`}>
        {data.surfaceName === surfaceTypeValue ? null :
          <div key={`navbar + ${i}`}>
            <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => chooseSurface(data.surfaceSize, data.surfaceName)}>
              <p>{data.surfaceName}</p>
            </Dropdown.Item>
          </div>}
      </div>

    ))
  }
  // surface basketball
  // each team
  const surfaceBasketFunction = () => {
    return surfaceBasketBallChoose.map((data, i: number) => (
      <div key={`navbar + ${i}`}>
        {data.surfaceName === surfaceTypeValue ? null :
          <div key={`navbar + ${i}`}>
            <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => chooseSurface(data.surfaceSize, data.surfaceName)}>

              <p>{data.surfaceName}</p>
            </Dropdown.Item>
          </div>}
      </div>

    ))
  }
  // surface volley
  const surfaceVolleyFunction = () => {
    return surfaceVolleyBallChoose.map((data, i: number) => (
      <div key={`navbar + ${i}`}>
        {data.surfaceName === surfaceTypeValue ? null :
          <div key={`navbar + ${i}`}>
            <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => chooseSurface(data.surfaceSize, data.surfaceName)}>

              <p>{data.surfaceName}</p>
            </Dropdown.Item>
          </div>}
      </div>

    ))
  }
  const surfaceTennisFunction = () => {
    return surfaceTennisChoose.map((data, i: number) => (
      <div key={`navbar + ${i}`}>
        {data.surfaceName === surfaceTypeValue ? null :
          <div key={`navbar + ${i}`}>
            <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => chooseSurface(data.surfaceSize, data.surfaceName)}>

              <p>{data.surfaceName}</p>
            </Dropdown.Item>
          </div>}
      </div>

    ))
  }

  //   surface type
  const eachTeamFunction = () => {
    return categoryChoose.map((data, i: number) => (
      <div key={`navbar + ${i}`}>
        {data.eachTeamName === eachTeamPlayerValue ? null :
          <div key={`navbar + ${i}`}>
            <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => chooseCategory(data.eachTeamSize, data.eachTeamName)}>

              <p>{data.eachTeamName}</p>
            </Dropdown.Item>
          </div>}
      </div>

    ))
  }
  //   surface type
  const eachBasketFunction = () => {
    return basketChoose.map((data, i: number) => (
      <div key={`navbar + ${i}`}>
        {data.eachTeamName === eachTeamPlayerValue ? null :
          <div key={`navbar + ${i}`}>
            <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => chooseCategory(data.eachTeamSize, data.eachTeamName)}>

              <p>{data.eachTeamName}</p>
            </Dropdown.Item>
          </div>}
      </div>

    ))
  }

  //   surface type
  const eachVolleyFunction = () => {
    return volleyChoose.map((data, i: number) => (
      <div key={`navbar + ${i}`}>
        {data.eachTeamName === eachTeamPlayerValue ? null :
          <div key={`navbar + ${i}`}>
            <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => chooseCategory(data.eachTeamSize, data.eachTeamName)}>

              <p>{data.eachTeamName}</p>
            </Dropdown.Item>
          </div>}
      </div>

    ))
  }
  const eachTennisFunction = () => {
    return tennisChoose.map((data, i: number) => (
      <div key={`navbar + ${i}`}>
        {data.eachTeamName === eachTeamPlayerValue ? null :
          <div key={`navbar + ${i}`}>
            <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => chooseCategory(data.eachTeamSize, data.eachTeamName)}>

              <p>{data.eachTeamName}</p>
            </Dropdown.Item>
          </div>}
      </div>

    ))
  }

  //   game position
  const gamePositionFunction = () => {
    return gamePositionChoose.map((data, i: number) => (
      <div key={`navbar + ${i}`}>
        {data.gameName === gamePositionValue ? null :
          <div key={`navbar + ${i}`}>
            <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => chooseGamePosition(data.gameSize, data.gameName)}>

              <p>{data.gameName}</p>
            </Dropdown.Item>
          </div>}
      </div>

    ))
  }


  const handleSelect = (key: any) => {
    setEachTeamPlayer("")
    setSurfaceType("")
    setGamePosition("")
    setShowEach("")
    setShowSurface("")
    setShowGame("")
    setSportsType(key)

  }
  // english to hebrew
  const convertToHebrew = (key: string) => {
    let stringObj = JSON.stringify(commonHebrew)
    let mainObj = JSON.parse(stringObj)
    return mainObj[key];
  }
  return (
    <>
      <div className="gameTypeBox">
        {/* <h1>סוג המשחק</h1> */}
        <div className="tabBox">
          <Tabs
            defaultActiveKey={themeState.sportType && themeState.sportType ? themeState.sportType : themeState.sportType}
            id="fill-tab-example"
            className="tabHeadingBtn"
            fill
            onSelect={handleSelect}
          >
            <Tab eventKey="FOOTBALL" title="כדורגל">

              {themeState.userRole === "PLAYER" ? <><div className="normalSignUpWithIcon">
                {themeState.gamePosition && themeState.gamePosition.length ? <><p className='dropdownTopAnimation'>עמדת משחק</p></> :''}
                <Dropdown className='arrowDown'>
                  <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                    {showGame && showGame.length ? showGame : ' עמדת משחק '} <FontAwesomeIcon icon={faChevronDown} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors dropDownItem">
                    {gamePositionFunction()}
                  </Dropdown.Menu>
                </Dropdown>
                {footballError && footballError.length ? <p className='errorMsg'>{footballError}</p> : ''}
              </div></> : <></>}
              
              <div className="normalSignUpWithIcon">
              {themeState.surfaceType && themeState.surfaceType.length ? <>{themeState.userRole === "LEADER" ? <p className='dropdownTopAnimation'>סוג משטח</p> : <p className='dropdownTopAnimation'>סוג משטח מועדף</p>}</> :''}
                <Dropdown className='arrowDown'>
                  <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                    {themeState.surfaceType && themeState.surfaceType ? convertToHebrew(themeState.surfaceType) :
                      <>{showSurface && showSurface.length ? showSurface : themeState.userRole === "LEADER" ? "סוג משטח" : "סוג משטח מועדף"}</>}<FontAwesomeIcon icon={faChevronDown} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors dropDownItem">
                    {surfaceTeamFunction()}
                  </Dropdown.Menu>
                </Dropdown>
                {footballSurfaceError && footballSurfaceError.length ? <p className='errorMsg'>{footballSurfaceError}</p> : ''}
              </div>

              <div className="signUpWithIcon">
                {themeState.eachTeamPlayer && themeState.eachTeamPlayer.length ? <p className='dropdownTopAnimation'>כמה שחקנים בכל קבוצה</p> : ""}
                <Dropdown className='arrowDown'>
                  <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                    <img src={multiProfile} alt="" />
                    {themeState.eachTeamPlayer && themeState.eachTeamPlayer ? convertToHebrew(themeState.eachTeamPlayer) :
                      <>{showEach && showEach.length ? showEach : "כמה שחקנים בכל קבוצה"} </>}
                    <FontAwesomeIcon icon={faChevronDown} /></Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors dropDownItem">
                    {eachTeamFunction()}
                  </Dropdown.Menu>
                </Dropdown>
                {footballEachError && footballEachError.length ? <p className='errorMsg'>{footballEachError}</p> : ''}
              </div>


              <SignUpButton buttonBorder='#FB730C' buttonBackground='#FB730C;'>
                <div className="mainBtn">
                  <button type='submit' onClick={firstLine}>
                    <p>המשך</p>
                  </button>
                </div>
              </SignUpButton>
            </Tab>
            <Tab eventKey="BASKETBALL" title="כדורסל">
              <div className="normalSignUpWithIcon">
              {themeState.surfaceType && themeState.surfaceType.length ? <>{themeState.userRole === "LEADER" ? <p className='dropdownTopAnimation'>סוג משטח</p> : <p className='dropdownTopAnimation'>סוג משטח מועדף</p>}</> :''}
                <Dropdown className='arrowDown'>
                  <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                    {themeState.surfaceType && themeState.surfaceType ? convertToHebrew(themeState.surfaceType) :
                      <>{showSurface && showSurface.length ? showSurface : themeState.userRole === "LEADER" ? "סוג משטח" : "סוג משטח מועדף"}</>}<FontAwesomeIcon icon={faChevronDown} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors dropDownItem">
                    {surfaceBasketFunction()}
                  </Dropdown.Menu>
                </Dropdown>
                {basketballError && basketballError.length ? <p className='errorMsg'>{basketballError}</p> : ''}
              </div>
              <div className="signUpWithIcon">

                {themeState.eachTeamPlayer && themeState.eachTeamPlayer.length ? <p className='dropdownTopAnimation'>כמה שחקנים בכל קבוצה</p> : ""}
                <Dropdown className='arrowDown'>
                  <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                    <img src={multiProfile} alt="" />
                    {themeState.eachTeamPlayer && themeState.eachTeamPlayer ? convertToHebrew(themeState.eachTeamPlayer) :
                      <>{showEach && showEach.length ? showEach : "כמה שחקנים בכל קבוצה"} </>}
                    <FontAwesomeIcon icon={faChevronDown} /></Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors dropDownItem">
                    {eachBasketFunction()}
                  </Dropdown.Menu>
                </Dropdown>
                {basketballEachError && basketballEachError.length ? <p className='errorMsg'>{basketballEachError}</p> : ''}
              </div>
              <SignUpButton buttonBorder='#FB730C' buttonBackground='#FB730C;'>
                <div className="mainBtn">
                  <button type='submit' onClick={firstLine}>
                    <p>המשך</p>
                  </button>
                </div>
              </SignUpButton>
            </Tab>
            <Tab eventKey="VOLLEYBALL" title="כדורעף">
              <div className="normalSignUpWithIcon">
                {themeState.surfaceType && themeState.surfaceType.length ? <>{themeState.userRole === "LEADER" ? <p className='dropdownTopAnimation'>סוג משטח</p> : <p className='dropdownTopAnimation'>סוג משטח מועדף</p>}</> :''}
                <Dropdown className='arrowDown'>
                  <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                    {themeState.surfaceType && themeState.surfaceType ? convertToHebrew(themeState.surfaceType) :
                      <>{showSurface && showSurface.length ? showSurface : themeState.userRole === "LEADER" ? "סוג משטח" : "סוג משטח מועדף"}</>}<FontAwesomeIcon icon={faChevronDown} />

                  </Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors dropDownItem">
                    {surfaceVolleyFunction()}
                  </Dropdown.Menu>
                </Dropdown>
                {volleyballError && volleyballError.length ? <p className='errorMsg'>{volleyballError}</p> : ''}
              </div>
              <div className="signUpWithIcon">

                {themeState.eachTeamPlayer && themeState.eachTeamPlayer.length ? <p className='dropdownTopAnimation'>כמה שחקנים בכל קבוצה</p> : ""}
                <Dropdown className='arrowDown'>
                  <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">

                    <img src={multiProfile} alt="" />
                    {themeState.eachTeamPlayer && themeState.eachTeamPlayer ? convertToHebrew(themeState.eachTeamPlayer) :
                      <>{showEach && showEach.length ? showEach : "כמה שחקנים בכל קבוצה"} </>}
                    <FontAwesomeIcon icon={faChevronDown} /></Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors dropDownItem">
                    {eachVolleyFunction()}
                  </Dropdown.Menu>
                </Dropdown>
                {volleyballEachError && volleyballEachError.length ? <p className='errorMsg'>{volleyballEachError}</p> : ''}
              </div>
              <SignUpButton buttonBorder='#FB730C' buttonBackground='#FB730C;'>
                <div className="mainBtn">
                  <button type='submit' onClick={firstLine}>
                    <p>המשך</p>
                  </button>
                </div>
              </SignUpButton>
            </Tab>

            <Tab eventKey="TENNIS" title="טניס">

              <div className="normalSignUpWithIcon">
                {themeState.surfaceType && themeState.surfaceType.length ? <>{themeState.userRole === "LEADER" ? <p className='dropdownTopAnimation'>סוג משטח</p> : <p className='dropdownTopAnimation'>סוג משטח מועדף</p>}</> :''}
                <Dropdown className='arrowDown'>
                  <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                    {themeState.surfaceType && themeState.surfaceType ? convertToHebrew(themeState.surfaceType) :
                      <>{showSurface && showSurface.length ? showSurface : themeState.userRole === "LEADER" ? "סוג משטח" : "סוג משטח מועדף"}</>}
                    <FontAwesomeIcon icon={faChevronDown} /></Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors dropDownItem">
                    {surfaceTennisFunction()}
                  </Dropdown.Menu>
                </Dropdown>
                {tennisError && tennisError.length ? <p className='errorMsg'>{tennisError}</p> : ''}
              </div>

              <div className="signUpWithIcon">

                {themeState.eachTeamPlayer && themeState.eachTeamPlayer.length ? <p className='dropdownTopAnimation'>כמה שחקנים בכל קבוצה</p> : ""}
                <Dropdown className='arrowDown'>
                  <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                    <img src={multiProfile} alt="" />
                    {themeState.eachTeamPlayer && themeState.eachTeamPlayer ? convertToHebrew(themeState.eachTeamPlayer) :
                      <>{showEach && showEach.length ? showEach : "כמה שחקנים בכל קבוצה"} </>}
                    <FontAwesomeIcon icon={faChevronDown} /></Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors dropDownItem">
                    {eachTennisFunction()}
                  </Dropdown.Menu>
                </Dropdown>
                {tennisEachError && tennisEachError.length ? <p className='errorMsg'>{tennisEachError}</p> : ''}
              </div>



              <SignUpButton buttonBorder='#FB730C' buttonBackground='#FB730C;'>
                <div className="mainBtn">
                  <button type='submit' onClick={firstLine}>
                    <p>המשך</p>
                  </button>
                </div>
              </SignUpButton>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default GameType