import { SportType } from "../../Enums/SportType";
import { TeamSize } from "../../Enums/TeamSize";
import { UserRole } from "../../Enums/UserRole";
import { OptionType } from "../../Interfaces/OptionType";
import Localize from "../../Localization/Localize";

function teamSizeOptionsForFootball(userRole: UserRole) {
    return [
        { label: Localize[TeamSize["4-5"]], value: TeamSize["4-5"] },
        { label: Localize[TeamSize["6-7"]], value: TeamSize["6-7"] },
        { label: Localize[TeamSize["8-10"]], value: TeamSize["8-10"] },
        { label: Localize[userRole === UserRole.PLAYER ? TeamSize["DOESN'T MATTER"] : TeamSize.NOT_CONSTANT], 
            value: userRole === UserRole.PLAYER ? TeamSize["DOESN'T MATTER"] : TeamSize.NOT_CONSTANT },
    ] as OptionType[];
}

function teamSizeOptionsForBasketball(userRole: UserRole) {
    return [
        { label: Localize[TeamSize["1-2"]], value: TeamSize["1-2"] },
        { label: Localize[TeamSize["3-4"]], value: TeamSize["3-4"] },
        { label: Localize[TeamSize["T_5"]], value: TeamSize["T_5"] },
        { label: Localize[userRole === UserRole.PLAYER ? TeamSize["DOESN'T MATTER"] : TeamSize.NOT_CONSTANT], 
            value: userRole === UserRole.PLAYER ? TeamSize["DOESN'T MATTER"] : TeamSize.NOT_CONSTANT },
    ] as OptionType[];
}

function teamSizeOptionsForVolleyball(userRole: UserRole) {
    return [
        { label: Localize[TeamSize["T_2"]], value: TeamSize["T_2"] },
        { label: Localize[TeamSize["T_3"]], value: TeamSize["T_3"] },
        { label: Localize[TeamSize["T_4"]], value: TeamSize["T_4"] },
        { label: Localize[TeamSize["T_6"]], value: TeamSize["T_6"] },
        { label: Localize[userRole === UserRole.PLAYER ? TeamSize["DOESN'T MATTER"] : TeamSize.NOT_CONSTANT], 
            value: userRole === UserRole.PLAYER ? TeamSize["DOESN'T MATTER"] : TeamSize.NOT_CONSTANT },
    ] as OptionType[];
}

function teamSizeOptionsForTennis(userRole: UserRole) {
    return [
        { label: Localize[TeamSize["SINGLES"]], value: TeamSize["SINGLES"] },
        { label: Localize[TeamSize["DOUBLES"]], value: TeamSize["DOUBLES"] },
        { label: Localize[userRole === UserRole.PLAYER ? TeamSize["DOESN'T MATTER"] : TeamSize.NOT_CONSTANT], 
            value: userRole === UserRole.PLAYER ? TeamSize["DOESN'T MATTER"] : TeamSize.NOT_CONSTANT },
    ] as OptionType[];
}

function teamSizeOptionsForOthers(userRole: UserRole) {
    return [
        { label: Localize[TeamSize["1-2"]], value: TeamSize["1-2"] },
        { label: Localize[TeamSize["T_2"]], value: TeamSize["T_2"] },
        { label: Localize[TeamSize["3-4"]], value: TeamSize["3-4"] },
        { label: Localize[TeamSize["T_3"]], value: TeamSize["T_3"] },
        { label: Localize[TeamSize["4-5"]], value: TeamSize["4-5"] },
        { label: Localize[TeamSize["T_4"]], value: TeamSize["T_4"] },
        { label: Localize[TeamSize["T_5"]], value: TeamSize["T_5"] },
        { label: Localize[TeamSize["6-7"]], value: TeamSize["6-7"] },
        { label: Localize[TeamSize["T_6"]], value: TeamSize["T_6"] },
        { label: Localize[TeamSize["8-10"]], value: TeamSize["8-10"] },
        { label: Localize[TeamSize["SINGLES"]], value: TeamSize["SINGLES"] },
        { label: Localize[TeamSize["DOUBLES"]], value: TeamSize["DOUBLES"] },
        { label: Localize[userRole === UserRole.PLAYER ? TeamSize["DOESN'T MATTER"] : TeamSize.NOT_CONSTANT], 
            value: userRole === UserRole.PLAYER ? TeamSize["DOESN'T MATTER"] : TeamSize.NOT_CONSTANT },
    ] as OptionType[];
}

export function getTeamSizeOptions(userRole: UserRole, sportType: SportType | null): OptionType[] {
    switch (sportType) {
        case SportType.FOOTBALL:
            return teamSizeOptionsForFootball(userRole);
        case SportType.BASKETBALL:
            return teamSizeOptionsForBasketball(userRole);
        case SportType.VOLLEYBALL:
            return teamSizeOptionsForVolleyball(userRole)
        case SportType.TENNIS:
            return teamSizeOptionsForTennis(userRole);
        case SportType.OTHER:
            return teamSizeOptionsForOthers(userRole);
        default:
            return [
                { label: Localize[TeamSize["1-2"]], value: TeamSize["1-2"] },
                { label: Localize[TeamSize["T_2"]], value: TeamSize["T_2"] },
                { label: Localize[TeamSize["3-4"]], value: TeamSize["3-4"] },
                { label: Localize[TeamSize["T_3"]], value: TeamSize["T_3"] },
                { label: Localize[TeamSize["4-5"]], value: TeamSize["4-5"] },
                { label: Localize[TeamSize["T_4"]], value: TeamSize["T_4"] },
                { label: Localize[TeamSize["T_5"]], value: TeamSize["T_5"] },
                { label: Localize[TeamSize["6-7"]], value: TeamSize["6-7"] },
                { label: Localize[TeamSize["T_6"]], value: TeamSize["T_6"] },
                { label: Localize[TeamSize["8-10"]], value: TeamSize["8-10"] },
                { label: Localize[TeamSize["SINGLES"]], value: TeamSize["SINGLES"] },
                { label: Localize[TeamSize["DOUBLES"]], value: TeamSize["DOUBLES"] },
                { label: Localize[TeamSize.NOT_CONSTANT], value: TeamSize.NOT_CONSTANT },
                { label: Localize[TeamSize["DOESN'T MATTER"]], value: TeamSize["DOESN'T MATTER"] },
            ];
    }
}