import { StatusCodes } from "http-status-codes";
import { AgeRange } from "../../Enums/AgeRange";
import { AverageGameTime } from "../../Enums/AverageGameTime";
import { FrequencyOfPlay } from "../../Enums/FrequencyOfPlay";
import { GamePosition } from "../../Enums/GamePosition";
import { JoinGroup } from "../../Enums/JoinGroup";
import { LevelOfPlay } from "../../Enums/LevelOfPlay";
import { MaxDistance } from "../../Enums/MaxDistance";
import { PaymentType } from "../../Enums/PaymentType";
import { SportType } from "../../Enums/SportType";
import { UserRole } from "../../Enums/UserRole";
import { IProfileUpdateRequest } from "../../Interfaces/ProfileUpdateRequest";
import { checkMail, checkPhone } from "../../api/auth";
import { getMaxValueForPaymentType, getMinValueForPaymentType } from "../Generators/PaymentTypeGenerator";
import { getSurfaceOptions } from "../Generators/SurfaceTypeGenerator";
import { getTeamSizeOptions } from "../Generators/TeamSizeGenerator";
import Localize from "../../Localization/Localize";
import { ShortValidation } from "../../Enums/ShortValidation";
import { ValidateResult } from "react-hook-form";
import ValidatorResult from "../../Interfaces/ValidatorResult";

const currentDate = new Date();
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const telephonePrefixd = '972';

export function maxBirthYear(): number {
    return currentDate.getFullYear() - 13;
}

export function isBirthDateValid(birthDate: string) {

    const inputDate = new Date(birthDate);
    const currentDate = new Date();
    const thirteenYearsAgo = new Date(currentDate.getFullYear() - 13, currentDate.getMonth(), currentDate.getDate());
    const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31);

    if (inputDate <= thirteenYearsAgo && inputDate <= lastDayOfYear) {
        return true;
    } else {
        return false;
    }
}

export function validateProfile(profile: IProfileUpdateRequest, userRole: UserRole) {

    if (userRole === UserRole.PLAYER && !Object.values(MaxDistance).some(value => value === profile.maxDistance)) {
        return false;
    }
    else if (userRole === UserRole.PLAYER && !Object.values(FrequencyOfPlay).some(value => value === profile.frequencyOfPlay)) {
        return false;
    }
    else if (userRole === UserRole.PLAYER && !Object.values(AverageGameTime).some(value => value === profile.avgGameDuration)) {
        return false;
    }
    else if (!Object.values(JoinGroup).some(value => value === profile.joinedGroup)) {
        return false;
    }
    else if (userRole === UserRole.LEADER && !Object.values(PaymentType).some(value => value === profile.paymentType)) {
        return false;
    }
    else if (userRole === UserRole.LEADER && profile.paymentType !== PaymentType.FREE && 
        (profile.payment < getMinValueForPaymentType(profile.paymentType as PaymentType) ||
        profile.payment > getMaxValueForPaymentType(profile.paymentType as PaymentType))) {
        return false;
    }
    else if (!Object.values(SportType).some(value => value === profile.sportType)) {
        return false;
    }
    else if (userRole === UserRole.PLAYER && profile.sportType === SportType.FOOTBALL && !Object.values(GamePosition).some(value => value === profile.gamePosition)) {
        return false;
    }
    else if (!getSurfaceOptions(userRole, profile.sportType as SportType).map((option) => option.value).some(value => value === profile.surfaceType)) {
        return false;
    }
    else if (!getTeamSizeOptions(userRole, profile.sportType as SportType).map((option) => option.value).some(value => value === profile.eachTeamPlayer)) {
        return false;
    }
    else if (!Object.values(LevelOfPlay).some(value => value === profile.levelOfPlay)) {
        return false;
    }
    else if (userRole === UserRole.LEADER && !Object.values(AgeRange).some((ageRange) => profile.ageRange.includes(ageRange))) {
        return false;
    }
    else if (userRole === UserRole.PLAYER && !isBirthDateValid(profile.dob)) {
        return false;
    }

    return true;
}

export const isEmailValid = async (email: string, request=true) => {
    let result: ValidatorResult = {
        isSuccess: false,
        message: null
    };

    const isEmailPatternValid = emailRegex.test(email);

    if (isEmailPatternValid) {
        if (!request) return { isSuccess: true, message: null }
        return checkMail(email).then((data) => {
            if (data.statusCode === StatusCodes.OK) {
                result.isSuccess = true;
                return result;
            }
            else {
                if (data.message === "This email address is already in use!") {
                    result.message = Localize.thisEmailAddressIsAlreadyInUse;
                }
                return result;
            }
        })
    } else {
        return {
            isSuccess: false,
            message: null
        };
    }
}

export const isTelephoneNumberValid = async (telephone: string, request=true) => {
    if (telephone.startsWith(telephonePrefixd) && (telephone.length === 12 || telephone.length === 13)) {
        telephone = telephone.substring(telephonePrefixd.length);
    }

    if (telephone.length === 9 && telephone[0] === '5') {
        if (!request) return { isSuccess: true, message: null };
        return await checkIfTelephoneExists(telephone);
    }
    else if (telephone.length === 10 && telephone[0] === '0' && telephone[1] === '5') {
        if (!request) return { isSuccess: true, message: null };
        return await checkIfTelephoneExists(telephone.substring(1));
    }
    else {
        return {
            isSuccess: false,
            message: null
        };
    }
}

export const getCorrectTelephoneNumber = (telephone: string) => {
    if (telephone.startsWith(telephonePrefixd) && (telephone.length === 12 || telephone.length === 13)) {
        return telephone;
    }

    if (telephone.length === 9 && telephone[0] === '5') {
        return (telephonePrefixd + telephone);
    }
    else if (telephone.length === 10 && telephone[0] === '0' && telephone[1] === '5') {
        return (telephonePrefixd + telephone.substring(1));
    }
    else {
        return "";
    }
}

export const getTelephoneNumberDisplayedFormat = (telephone: string): string => {
    return `${telephone.slice(0, 3)}-${telephone.slice(3, 5)}-${telephone.slice(5, 8)}-${telephone.slice(8)}+`;
}

const checkIfTelephoneExists = async (telephone: string) => {
    let result: ValidatorResult = {
        isSuccess: false,
        message: null
    };
    return checkPhone(telephonePrefixd + telephone).then((data) => {
        if (data.statusCode === StatusCodes.OK) {
            result.isSuccess = true;
            return result;
        }
        else {
            if (data.message === 'This telephone number is already in use!') {
                result.message = Localize.thisPhoneNumberIsAlreadyInUse;
            }
            return result;
        }
    });
}