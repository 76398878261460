import React, { useEffect, useState, useRef } from 'react'
import './history.css';
import Header from '../Header/Header';
import Localize from '../../../Localization/Localize';
import { ILogHistoryData, LogHistory } from '../../../Interfaces/LogHistoryData';
import { IResponse } from '../../../Interfaces/Response';
import { StatusCodes } from 'http-status-codes';
import { defaultToastConfig } from '../../../Models/Configs/ToasterDefaultConfig';
import { toast } from 'react-toastify';
import { deleteLogHistory, getLogHistory, toggleFavourite } from '../../../api/LogHistoryApiService';
import { itemsPerPageList } from '../../../Constants/DefaultConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dayTimeObjectArrayToDaytimeObject, formatDate, getDateOnly, getTimeOnly24HoursFormat, shortenDays, sortDaysOfWeek } from '../../../Services/Helpers/DayTimeHelper';

import basketImg from '../../../assets/images/category/basket.png';
import footballImg from '../../../assets/images/category/football.png';
import tennisImg from '../../../assets/images/category/tennis.png';
import volleyballImg from '../../../assets/images/category/volley.png';
import userOutline from '../../../assets/images/icon/user-outline.png';
import watch from '../../../assets/images/icon/watch.png';
import userGreen from '../../../assets/images/icon/userGreen.png';
import userGroup from '../../../assets/images/icon/userGroup.png';
import footballPlusIcon from '../../../assets/images/icon/footballPlusIcon.png';
import baskeballPlusIcon from '../../../assets/images/icon/baskeballPlusIcon.png';
import volleyballPlusIcon from '../../../assets/images/icon/volleyballPlusIcon.png';
import tennislPlusIcon from '../../../assets/images/icon/tennislPlusIcon.png';

import heartOutlineBasketball from '../../../assets/images/icon/heart-outline-basketball.png';
import heartFillBasketball from '../../../assets/images/icon/heart-fill-basketball.png';
import heartOutlineFootball from '../../../assets/images/icon/heart-outline-football.png';
import heartFillFootball from '../../../assets/images/icon/heart-fill-football.png';
import heartOutlineTennis from '../../../assets/images/icon/heart-outline-tennis.png';
import heartFillTennis from '../../../assets/images/icon/heart-fill-tennis.png';
import heartOutlineVolleyball from '../../../assets/images/icon/heart-outline-volleyball.png';
import heartFillVolleyball from '../../../assets/images/icon/heart-fill-volleyball.png';

import userBlue from '../../../assets/images/icon/userBlue.png';
import groupBlue from '../../../assets/images/icon/groupBlue.png';
import rightArrow from '../../../assets/images/icon/right-arrow.png';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faInfo, faInfoCircle, faLongArrowDown, faLongArrowUp, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { SportType } from '../../../Enums/SportType';
import CustomSearch from './CustomSearch';
import CustomInfo from './CustomInfo';
import { SortOrder } from '../../../Enums/SortOrder';
import { SearchType } from '../../../Enums/SearchType';

import { useDispatch, useSelector } from 'react-redux';
import { changeChooseType, changeSearchOption, changeBackToFilterOption, changeMapCard } from '../../../store/theme/actions';
import themeReducer, { InitialState } from '../../../store/theme/themeReducer';
// google icon
import { faFacebookF, faGoogle, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faLocationDot, faClock, faArrowRight, faPhone, faEnvelopeOpenText, faCircleXmark, faChevronDown, faClose } from '@fortawesome/free-solid-svg-icons';
// hebrew language
import hebrew from '../../../assets/raw-text/english-hebrew.json'
import searchPage from '../../../assets/raw-text/search.json'
import commonHebrew from '../../../assets/raw-text/common.json';
// style component
import FilterBox from '../../StyleComponent/FilterBox/FilterBox';
import Button from '../../StyleComponent/Button/Button';
import FilterButton from '../../StyleComponent/FilterButton/FilterButton';
import PlayerButton from '../../StyleComponent/PlayerButton/PlayerButton';
import LevelPlay from '../../StyleComponent/LevelPlay/LevelPlay';
import LabelResultBox from '../../StyleComponent/LableResultBox/LabelResultBox';
import LabelResult from '../../StyleComponent/LabelResult/LabelResult';
import ResetFilter from '../../StyleComponent/ResetFilter/ResetFilter';
import { allUser, isAuthenticate, userProfile } from '../../../api/auth';
import { AgeRange, ChosePlayerType, CooseType, Days, Fitness, Hours, LevelOfPlay, MaxDistance, Tenure, UserRole } from '../Search/search.enum';
import { UserRole as UserRoleEnum } from '../../../Enums/UserRole';
import { useNavigate } from 'react-router-dom';
import Profile from '../Search/Profile';
import { Dropdown } from 'react-bootstrap';
import { convertAnyToHebrew } from '../../../Services/Helpers/ConverterService';
import { Lang } from '../../../Enums/Lang';
import { getCurrentLanguage } from '../../../Localization/Localize.Service';

function History() {

  const lang = getCurrentLanguage();
  const themeState = useSelector((state: InitialState) => state);

  const [itemsPerPage, setItemsPerPage] = useState<number>(itemsPerPageList[0]);
  const [infoModalShow, setInfoModalShow] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [logHistoryViewData, setLogHistoryViewData] = useState<LogHistory[]>([]);
  const [logHistoryOriginData, setLogHistoryOriginData] = useState<LogHistory[]>([]);
  const [showProfile, setShowProfile] = useState<boolean>(false);

  const [heartFillImageUrl, setHeartFillImageUrl] = useState<string>('');
  const [heartOutlineImageUrl, setHeartOutlineImageUrl] = useState<string>('');

  const [searchInput, setSearchInput] = useState<string>('');
  // const [startIndex, setStartIndex] = useState<number>(getStartIndex());
  // const [endIndex, setEndIndex] = useState<number>(getEndIndex());
  const [sortByDate, setSortByDate] = useState<SortOrder>(SortOrder.DESC);
  const [showFavouriteOnly, setShowFavouriteOnly] = useState<boolean>(false);

  //Child Comp props start
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = isAuthenticate();
  const [defaultVisible, setDefaultVisible] = React.useState(false);
  const [customActive, setCustomActive] = useState("");
  const [userProfileData, setUserProfileData] = React.useState<any>([]);
  const [headingLevel, setHeadingLevel] = useState("");
  const [levelActive, setLevelActive] = React.useState<string[]>([]);
  const [maxData, setMaxData] = React.useState<string[]>([]);
  const [maxDisplay, setMaxDisplay] = React.useState(false);
  const [allSearch, setAllSearch] = React.useState<string[]>([]);
  const [levelData, setLevelData] = useState("");
  const [finalArray, setFinalArray] = React.useState<string[]>([]);
  // disable button
  const [active, setActive] = useState(null);
  const [disableAdd, setDisableAdd] = React.useState<string[]>([]);
  const [disableMax, setDisableMax] = React.useState<string[]>([]);

  // api path
  const [limit, setLimit] = useState<number>(8);
  const [page, setPage] = useState(1);
  const [defaultPage, setDefaultPage] = useState(1);
  const [error, setError] = useState(false);
  // search result
  const [userAll, setUserAll] = useState<any>([]);

  // enum value
  const [customPlayerSearch, setCustomPlayerSearch] = useState({ values: Object.values(ChosePlayerType), keys: Object.keys(ChosePlayerType) });
  const [customSearch, setCustomSearch] = useState({ values: Object.values(CooseType), keys: Object.keys(CooseType) });
  const [levelOFPlays, setLevelOFPlays] = useState(Object.keys(LevelOfPlay));
  const [availableDaysEnum, setAvailabeDays] = useState(Object.keys(Days));
  const [availableHours, setAvailableHours] = useState(Object.keys(Hours));
  const [maxDistance, setMaxDistance] = useState(Object.keys(MaxDistance));
  const [userRole, setUserRole] = useState(Object.keys(UserRole));
  const [fitnessValue, setFitnessValue] = useState(Object.keys(Fitness));
  const [tenureValue, setTenureValue] = useState(Object.keys(Tenure));

  // custom search
  const [ageRanges, setAgeRanges] = React.useState<string[]>([]);
  const [hebrewLevelOfPlay, setHebLevelOfPlay] = React.useState<string[]>([]);
  const [availableDays, setAvailableDays] = React.useState<string[]>([]);
  const [availableHour, setAvailableHour] = React.useState<string[]>([]);
  const [maximumDistance, setMaximumDistance] = React.useState<string>("");
  const [userType, setUserType] = React.useState<string[]>([]);
  const [fitnessLevel, setFitnessLevel] = React.useState<string[]>([]);
  const [tenureLevel, setTenureLevel] = React.useState<string[]>([]);
  const [newLevel, setNewLevel] = React.useState<string[]>([]);
  const [backFilter, setBackFilter] = useState("");
  //   search result box
  const [showLevel, setShowLevel] = React.useState<string[]>([]);
  const [showDay, setShowDay] = React.useState<string[]>([]);
  const [showHour, setShowHour] = React.useState<string[]>([]);
  const [showDistance, setShowDistance] = React.useState<string[]>([]);
  const [showUser, setShowUser] = React.useState<string[]>([]);
  const [showFitness, setFitness] = React.useState<string[]>([]);
  const [showTenure, setTenure] = React.useState<string[]>([]);
  const [otherUserProfile, setOtherUserProfile] = React.useState<any>(null);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  // choose search option
  const gameChooseHandler = (value: string) => {
    dispatch(changeSearchOption(value));
  }

  const getOtherUserProfile = (email: string) => {
    userProfile(email, 1).then((data) => {
      if (data.statusCode === StatusCodes.OK) {
        setOtherUserProfile(data.user);
        setShowProfile(true);
      }
      else {
        toast.error(Localize.errorInAction, defaultToastConfig(3000));
      }
    });
  }

  React.useEffect(() => {
    gameChooseHandler(customActive)
  }, [customActive]);

  // custom filter button
  const customFilter = (event: any) => {
    setDefaultVisible(false)
    setCustomActive(event.target.id);
  }

  // search value
  const showSearchValue = (data: any, i: number) => {
    if (customSearch.keys[i] === 'MAXIMUM_DISTANCE') {
      setMaxDisplay(true);
    }
    else {
      setMaxDisplay(false);
    }

    setActive(data);
    setLevelData(customSearch.keys[i]);
    setHeadingLevel(customSearch.values[i]);
    setNewLevel([...newLevel, data]);

    if (customSearch.keys[i] === 'AGE') {
      setAllSearch(Object.values(AgeRange))
    }
    else if (customSearch.keys[i] === 'LEVEL_OF_PLAY') {
      setAllSearch(Object.values(LevelOfPlay))
    }
    else if (customSearch.keys[i] === 'AVAILABLE_DAYS') {
      setAllSearch(Object.values(Days))
    }
    else if (customSearch.keys[i] === 'AVIALABLES_HOURS') {
      setAllSearch(Object.values(Hours))
    }
    else if (customSearch.keys[i] === 'MAXIMUM_DISTANCE') {
      setAllSearch(Object.values(MaxDistance));
      setMaxData(Object.values(MaxDistance))
    }
    else if (customSearch.keys[i] === 'USER_TYPE') {
      setAllSearch(Object.values(UserRole))
    }
    else if (customSearch.keys[i] === 'FITNESS') {
      setAllSearch(Object.values(Fitness))
    }
    else if (customSearch.keys[i] === 'TENURE') {
      setAllSearch(Object.values(Tenure))
    }
  }

  const userProfileSave = () => {
    userProfile(user.email).then((data) => {
      if (data.statusCode !== 200) {
        navigate("/login")
      }
      else {
        setUserProfileData(data.user)
      }
    });
  }
  useEffect(() => {
    userProfileSave()
  }, []);

  // default object
  let userObject = {
    "userRole": ['PLAYER', 'LEADER'],
    "sportType": [`${themeState.gameType}`],
    "availableDays": userProfileData.availableDays,
    "availableHours": userProfileData.availableHours,
    "maxDistance": userProfileData.maxDistance,
    "levelOfPlay": [userProfileData.levelOfPlay]
  }

  // default filter function
  const defaultFilter = () => {
    let payload = {}

    setBackFilter("defaultChoose");

    initializePageData(1, null, null, '', payload, "defaultSearch");

  };

  let customObject: any = {
    "user": {},
    "age": {},
    "daysTime": {},
    "distance": {}
  }


  const gameFilterHandler = (value: string) => {
    dispatch(changeBackToFilterOption(value));
  }

  React.useEffect(() => {
    gameFilterHandler(backFilter);
  }, [backFilter]);

  // custom search
  const customSearchFunction = () => {
    customObject['age'].ageRange = ageRanges;
    customObject['user'].levelOfPlay = hebrewLevelOfPlay;
    customObject['daysTime'].availableDay = availableDays;
    customObject['daysTime'].availableHour = availableHour;
    customObject['user'].userRole = userType.length === 0 ? Object.values(UserRoleEnum) : userType;
    customObject['user'].fitness = fitnessLevel;
    customObject['user'].joinedGroup = tenureLevel;

    setBackFilter("searchChoose");

    if (maximumDistance.length > 0) {
      customObject['distance'].maxDistance = maximumDistance;
    }

    initializePageData(1, null, null, '', customObject, "backSearch");
  }

  // search result add
  const finalSearch = (data: string, i: number) => {
    setDisableAdd([...disableAdd, data]);
    setLevelActive([...levelActive, data]);

    if (levelData === 'AGE') {
      setAgeRanges([...ageRanges, data])
    }

    if (levelData === 'LEVEL_OF_PLAY') {
      setHebLevelOfPlay([...hebrewLevelOfPlay, levelOFPlays[i]])
      setShowLevel([...showLevel, data])
    }

    if (levelData === 'AVAILABLE_DAYS') {
      setAvailableDays([...availableDays, availableDaysEnum[i]])
      setShowDay([...showDay, data])
    }

    if (levelData === 'AVIALABLES_HOURS') {
      setAvailableHour([...availableHour, availableHours[i]])
      setShowHour([...showHour, data]);
    }

    if (levelData === 'MAXIMUM_DISTANCE') {
      if (maximumDistance.length === 0) {
        setShowDistance([data]);
        setDisableMax([data])
        setMaximumDistance(maxDistance[i])
      }
    }

    if (levelData === 'USER_TYPE') {
      setUserType([...userType, userRole[i]])
      setShowUser([...showUser, data])
    }

    if (levelData === 'FITNESS') {
      setFitnessLevel([...fitnessLevel, fitnessValue[i]])
      setFitness([...showFitness, data])
    }

    if (levelData === 'TENURE') {
      setTenureLevel([...tenureLevel, tenureValue[i]])
      setTenure([...showTenure, data])
    }

    setFinalArray([...finalArray, data])
  }

  // reset search filter
  const resetFilter = () => {
    setAgeRanges([]);
    setAllSearch([]);
    setShowLevel([]);
    setHebLevelOfPlay([]);
    setShowDay([]);
    setAvailableDays([]);
    setShowHour([]);
    setAvailableHour([]);
    setShowDistance([]);
    setShowUser([]);
    setTenure([]);
    setTenureLevel([]);
    setFitness([]);
    setFitnessLevel([]);
    setHeadingLevel("");
    setDisableAdd([]);
    setActive(null);
    setShowDistance([]);
    setMaxData([]);
    setDisableMax([]);
    setMaximumDistance("");
    setUserType([]);
  }

  // remove search filter
  const removeSearch = (index: number, data: any, name: string) => {
    let deleteValue = ((oldValues: any) => {
      const newArr = oldValues.filter((item: any) => item !== data);
      return newArr;
    })

    let deleteMax = ((oldValues: any) => {
      return oldValues.filter((_: any, i: number) => i !== index);
    })

    setDisableAdd(deleteValue);
    setDisableMax([]);

    if (name === 'ageRanges') {
      setAgeRanges(deleteValue);
    }
    else if (name === 'levelOf') {
      setShowLevel(deleteValue);
      setHebLevelOfPlay(deleteMax);
    }
    else if (name === 'availableDays') {
      setShowDay(deleteValue);
      setAvailableDays(deleteMax);
    }
    else if (name === 'availableHour') {
      setShowHour(deleteValue);
      setAvailableHour(deleteMax);
    }
    else if (name === 'maximumDistance') {
      setMaximumDistance("");
      setShowDistance(deleteValue);
    }
    else if (name === 'userType') {
      setShowUser(deleteValue);
      setUserType(deleteMax);
    }
    else if (name === 'fitness') {
      setFitness(deleteValue);
      setFitnessLevel(deleteMax);
    }
    else if (name === 'tenure') {
      setTenure(deleteValue);
      setTenureLevel(deleteMax);
    }
  }
  //Child Comp props end


  // useEffect(() => {
  //   initializePageData();
  // }, []);

  useEffect(() => {
    switch (themeState.gameType) {
      case 'BASKETBALL':
        setHeartFillImageUrl(heartFillBasketball);
        setHeartOutlineImageUrl(heartOutlineBasketball);
        break;
      case 'FOOTBALL':
        setHeartFillImageUrl(heartFillFootball);
        setHeartOutlineImageUrl(heartOutlineFootball);
        break;
      case 'TENNIS':
        setHeartFillImageUrl(heartFillTennis);
        setHeartOutlineImageUrl(heartOutlineTennis);
        break;
      case 'VOLLEYBALL':
        setHeartFillImageUrl(heartFillVolleyball);
        setHeartOutlineImageUrl(heartOutlineVolleyball);
        break;
      default:
        setHeartFillImageUrl(heartFillBasketball);
        setHeartOutlineImageUrl(heartOutlineBasketball);
    }
  }, [themeState.gameType]);

  const initializePageData = (currentPageForce: number | null = null, itemsPerPageForce: number | null = null,
    showFavourite: boolean | null = null, sortBy: string = "", payload: any | null = null, searchType: string | null = null) => {

    if (showFavourite === null) {
      showFavourite = showFavouriteOnly;
    }
    if (sortBy === "") {
      sortBy = sortingPath;
    }

    if (!payload) {
      customObject['user'] = {}
      customObject['age'] = {}
      customObject['daysTime'] = {}
      customObject['distance'] = {}
      customObject['age'].ageRange = ageRanges;
      customObject['user'].levelOfPlay = hebrewLevelOfPlay;
      customObject['daysTime'].availableDay = availableDays;
      customObject['daysTime'].availableHour = availableHour;
      customObject['user'].userRole = userType.length === 0 ? Object.values(UserRoleEnum) : userType;
      customObject['user'].fitness = fitnessLevel;
      customObject['user'].joinedGroup = tenureLevel;

      if (maximumDistance.length > 0) {
        customObject['distance'].maxDistance = maximumDistance;
      }

      payload = customObject;
    }

    getLogHistory(currentPageForce || currentPage, itemsPerPageForce || itemsPerPage, showFavourite, sortBy, payload).then((result: IResponse<ILogHistoryData>) => {
      if (result.statusCode === StatusCodes.OK) {
        setLogHistoryOriginData(result.data.result);
        setTotalItems(result.data.total);
        setCurrentPage(result.data.page);
        setItemsPerPage(result.data.limit);

        if (searchType) {
          setCustomActive(searchType);
        }

        if (searchInput) {
          const filteredData = getSearchFilteredData(result.data.result, searchInput);
          setLogHistoryViewData(filteredData);
        }
        else {
          setLogHistoryViewData(result.data.result);
        }
      } else {
        toast.error(Localize.errorInAction, defaultToastConfig(3000));
      }
    }).catch(err => {
      toast.error(Localize.errorInAction, defaultToastConfig(3000));
    });
  }

  const getSportTypeImage = (sportType: SportType) => {
    switch (sportType) {
      case SportType.BASKETBALL:
        return basketImg;
      case SportType.FOOTBALL:
        return footballImg;
      case SportType.TENNIS:
        return tennisImg;
      case SportType.VOLLEYBALL:
        return volleyballImg;
    }
  }

  function toggleSortByDate() {
    setSortByDate(sortByDate === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC);
  }

  function onClickSearch(searchValue: string | null = null) {
    if (searchValue === null) {
      searchValue = searchInput;
    }

    if (searchValue) {
      const filteredData = getSearchFilteredData(logHistoryOriginData, searchValue);
      setLogHistoryViewData(filteredData);
    } else {
      setLogHistoryViewData(logHistoryOriginData);
    }
  }

  const getSearchFilteredData = (originalData: LogHistory[], searchValue: string | null) => {
    const tempData = originalData.filter((data) => {
      return JSON.stringify(data).toLowerCase().includes(searchValue ? searchValue.toLocaleLowerCase() : '');
      // return data.history.playArea.toLocaleLowerCase().includes(searchValue ? searchValue.toLocaleLowerCase() : '')
      //   || (data.history.nickName ? data.history.nickName.toLocaleLowerCase().includes(searchValue ? searchValue.toLocaleLowerCase() : '') : data.history.fullName.toLocaleLowerCase().includes(searchValue ? searchValue.toLocaleLowerCase() : ''))
      //   || convertAnyToHebrew(data.history.levelOfPlay).includes(searchValue ? searchValue : '')
      //   || getTimeOnly24HoursFormat(data.created_at).toString().includes(searchValue ? searchValue : '')
      //   || formatDate(getDateOnly(data.created_at)).toString().includes(searchValue ? searchValue : '')
      //   || shortenDays(Object.keys(sortDaysOfWeek(dayTimeObjectArrayToDaytimeObject(data.history.dayTime))), lang).includes(searchValue ? searchValue : '');
    });

    return tempData;
  }

  function onClickFavourite(logId: number) {
    toggleFavourite(logId).then((result: IResponse<string>) => {
      if (result.statusCode === StatusCodes.OK) {
        toast.success(Localize.successInAction, defaultToastConfig(3000));
        initializePageData();
      } else {
        toast.error(Localize.errorInAction, defaultToastConfig(3000));
      }
    }).catch(err => {
      toast.error(Localize.errorInAction, defaultToastConfig(3000));
    });
  }

  function getStartIndex(): number {
    return getEndIndex() === 0 ? 0 : ((currentPage - 1) * itemsPerPage) + 1;
  }

  function getEndIndex(): number {
    const lastItemIndex = currentPage * itemsPerPage;
    return lastItemIndex > totalItems ? totalItems : lastItemIndex;
  }

  function gotoNextPage() {
    if (hasNextPage()) {
      initializePageData(currentPage + 1);
    }
  }

  function gotoPrevPage() {
    if (hasPrevPage()) {
      initializePageData(currentPage - 1);
    }
  }

  function hasNextPage(): boolean {
    return currentPage < Math.ceil(totalItems / itemsPerPage) ? true : false;
  }

  function hasPrevPage(): boolean {
    return currentPage > 1 ? true : false;
  }


  const backToFilter = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setLogHistoryViewData([]);
    setCustomActive("");
    setBackFilter("");
  }

  const [sortingValue, setSortingValue] = useState('מיין לפי');
  const [sortingPath, setSortingPath] = useState("");

  const sorting = [
    { sortName: "איפוס למיון", sortPath: "" },
    { sortName: "תאריך", sortPath: "" },
    { sortName: " גיל", sortPath: 'ageRange' },
    { sortName: "רמת משחק", sortPath: 'levelOfPlay' },
    { sortName: "מרחק מקסימלי בק״מ", sortPath: 'maxDistance' },
  ];

  const sortingDropdown = () => {
    return sorting.map((data, i) => (
      data.sortName === sortingValue ? <></> :
        <Dropdown.Item key={"sort" + i} onClick={() => sortingDropdownValue(data.sortName, data.sortPath)}>{data.sortName}</Dropdown.Item>
    ));
  }

  const sortingDropdownValue = (sortName: any, sortPath: any) => {
    setSortingValue(sortName);
    setSortingPath(sortPath);
  }

  useEffect(() => {
    initializePageData();
  }, [sortingPath]);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
    onClickSearch(event.target.value || '');
  }

  const onDeleteLogHistory = (logId: number) => {
    deleteLogHistory(logId).then((data) => {
      if (data.statusCode === StatusCodes.OK) {
        toast.success(Localize.successInAction, defaultToastConfig(3000));
        initializePageData();
      }
      else {
        toast.error(Localize.errorInAction, defaultToastConfig(3000));
      }
    });
  }

  return (
    <>
      <Header />

      <div className="header-back-area">

      </div>

      <div className="history-background-wrapper">
        <div className="history-page-wrapper">
          <div className="left-side" style={
            !backFilter.length ? {
              content: "",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25',
              opacity: 0.2,
              position: "relative",
              width: "100%",
              minHeight: "100%",
              pointerEvents: "none",
              padding: "20px",
              borderRadius: "32px",
            } : {}
          }>
            {!showProfile && <div className="row-wrapper">
              {/* <div className="button-wrapper">
                <button type="submit"><p>{Localize.backToFilters} <FontAwesomeIcon icon={faArrowRight} /></p></button>
              </div> */}
              <div className="button-wrapper">
                <Button
                  buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                  <div className="mainBtn">
                    <button onClick={backToFilter}>
                      <p>{Localize.backToFilters} <FontAwesomeIcon icon={faArrowRight} style={{ position: "static" }} /></p>
                    </button>
                  </div>
                </Button>
              </div>
            </div>}

            {!showProfile && <div className="header-wrapper"
              style={{
                color:
                  themeState.gameType === 'BASKETBALL' ? '#FB730C' :
                    themeState.gameType === 'FOOTBALL' ? '#00AA25' :
                      themeState.gameType === 'TENNIS' ? '#12BFEE' :
                        themeState.gameType === 'VOLLEYBALL' ? '#9069F5' : '#FB730C'
              }}>
              {Localize.connectionHistory}
            </div>}

            {!showProfile && <div className="table-option-wrapper mb-3">
              <div className="level-button-wrapper">
                {/* <div className="level-button-text"><b>{Localize.sortBy}</b></div> */}
                {/* <button type="submit"><p>{Localize.levelOfPlay} <FontAwesomeIcon icon={faAngleDown} /></p></button> */}
                {/* <Button 
                  buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                    <div className="mainBtn">
                      <button>
                        <p>{Localize.levelOfPlay} <FontAwesomeIcon icon={faAngleDown} /></p>
                      </button>
                    </div>
                </Button> */}
                <div className="sortOption">
                  <Dropdown>
                    <Dropdown.Toggle disabled={showProfile ? true : false} className='button-wrapper sortClass' id="dropdown-basic">

                      {/* <Button buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                            <div className="mainBtn">
                                <button className="white">
                                    <p>{sortingValue}</p>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </button>
                            </div>
                        </Button> */}

                      <Button
                        buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                        <div className="mainBtn">
                          <button id="history-sort-button" className={showProfile ? 'disabled-button-box-shadow' : ''}>
                            <p className={showProfile ? 'disabled-button-background' : ''}>{sortingValue} <FontAwesomeIcon icon={faAngleDown} style={{ position: "static" }} /></p>
                          </button>
                        </div>
                      </Button>

                    </Dropdown.Toggle>
                    <Dropdown.Menu className='sortingDrop'>
                      {sortingDropdown()}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

              </div>

              <div className="button-wrapper">
                <Button
                  buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                  <div className="mainBtn">
                    <button id="history-fav-button" disabled={showProfile ? true : false}
                      className={showProfile ? 'disabled-button-box-shadow' : ''}
                      onClick={() => {
                        initializePageData(1, null, !showFavouriteOnly);
                        setShowFavouriteOnly(!showFavouriteOnly);
                      }}>
                      <p className={showProfile ? 'disabled-button-background' : ''}><FontAwesomeIcon icon={faHeart} style={{ position: "static" }} /> {showFavouriteOnly ? Localize.showAll : Localize.showFavoritesOnly}</p>
                    </button>
                  </div>
                </Button>
              </div>

              <div className="search-wrapper">
                <button disabled={showProfile ? true : false} onClick={() => { onClickSearch }} className="search-button"><FontAwesomeIcon icon={faSearch} /></button>
                <input value={searchInput}
                  onChange={handleSearchInputChange}
                  disabled={showProfile ? true : false} placeholder={Localize.searchRequest}
                  className={showProfile ? 'disabled-inut-box-shadow search-input' : 'search-input'} type="text" />
              </div>
            </div>}

            <div className="table-wrapper">
              {!showProfile &&
                <table>
                  <thead
                    style={{
                      borderBottomColor:
                        themeState.gameType === 'BASKETBALL' ? '#FB730C' :
                          themeState.gameType === 'FOOTBALL' ? '#00AA25' :
                            themeState.gameType === 'TENNIS' ? '#12BFEE' :
                              themeState.gameType === 'VOLLEYBALL' ? '#9069F5' : '#FB730C'
                    }}>
                    <tr>
                      <th></th>
                      <th className="header-column-watch"><img className="header-column-image" src={watch} alt="" /></th>
                      <th>{Localize.type}<br />{Localize.game}</th>
                      <th>{Localize.nickname}</th>

                      <th>{Localize.play}<br />{Localize.levelOf}</th>

                      <th>{Localize.type}<br />{Localize.request}</th>
                      <th>{Localize.location}</th>
                      <th>ימי <br />{Localize.play}</th>
                      <th>{Localize.details}<br />{Localize.full}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      logHistoryViewData.map((logHistory: LogHistory, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                {/* <FontAwesomeIcon icon={faHeart} /> */}
                                <img className="table-heart-image cursor-pointer"
                                  onClick={() => { onClickFavourite(logHistory.logId) }}
                                  src={logHistory.history.isFavorite ? heartFillImageUrl : heartOutlineImageUrl} alt="" />
                              </td>

                              <td>
                                <div className="cell-flex-column cell-flex-column-watch">
                                  <span className="time-span">{getTimeOnly24HoursFormat(logHistory.created_at)}</span>
                                  <span className="date-span">{formatDate(getDateOnly(logHistory.created_at))}</span>
                                </div>
                              </td>

                              <td>
                                <div className="cell-image-wrapper">
                                  <img className="table-normal-image" src={getSportTypeImage(logHistory.history.sportType as SportType)} alt="" />
                                </div>
                              </td>

                              <td>{logHistory.history.nickName || logHistory.history.fullName}</td>

                              <td>{lang === Lang.HEBREW ? convertAnyToHebrew(logHistory.history.levelOfPlay) : logHistory.history.levelOfPlay}</td>

                              <td>
                                <div className="cell-flex-row history-type-col">
                                  <img className="table-normal-image"
                                    src={logHistory.history.searchType === SearchType.USER_TO_OTHER ?
                                      (logHistory.history.userRole === UserRoleEnum.PLAYER ? userBlue : groupBlue) : userGreen}
                                    alt="" />
                                  <img className="arrow-image ms-1 me-1" src={rightArrow} alt="" />
                                  <img className="table-normal-image"
                                    src={logHistory.history.searchType === SearchType.USER_TO_OTHER ? userGreen :
                                      (logHistory.history.userRole === UserRoleEnum.PLAYER ? userBlue : groupBlue)}
                                    alt="" />
                                </div>
                              </td>

                              <td>{logHistory.history.city}</td>
                              <td>{
                                shortenDays(Object.keys(sortDaysOfWeek(dayTimeObjectArrayToDaytimeObject(logHistory.history.dayTime))), lang)
                              }</td>

                              <td className="">

                                <img className="plus-button-image" src={themeState.gameType === 'BASKETBALL' ? baskeballPlusIcon :
                                  themeState.gameType === 'FOOTBALL' ? footballPlusIcon :
                                    themeState.gameType === 'TENNIS' ? tennislPlusIcon :
                                      themeState.gameType === 'VOLLEYBALL' ? volleyballPlusIcon : volleyballPlusIcon} alt="" onClick={() => { getOtherUserProfile(logHistory.history.email) }} />
                              </td>

                              <td className="table-first-data-cell">
                                <span className="cursor-pointer" onClick={() => { onDeleteLogHistory(logHistory.logId) }}>{Localize.delete}</span>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })
                    }
                  </tbody>
                </table>

              }
              {showProfile && <Profile userInfo={otherUserProfile} closeProfile={() => { setShowProfile(false) }} />}
            </div>
            {!showProfile && <div className="pagination-block-wrapper">

              <div className="items-per-page-wrapper">
                <span className="text-box">{Localize.numberOfLinesPerPage}</span>
                <div className="drop-down-wrapper">
                  <div className="dropdown">
                    <button className="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {itemsPerPage} <FontAwesomeIcon icon={faAngleDown} />
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      {
                        itemsPerPageList.map((limit) => (
                          <li><button className="dropdown-item" type="button" onClick={(event) => {
                            if (limit !== itemsPerPage) {
                              initializePageData(null, limit);
                            }
                          }}>{limit}</button></li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
              <div className="pagination-wrapper">
                <span className="pagination-info ms-4">
                  <span>{getStartIndex()}-{getEndIndex()}</span>
                  <span className="me-2 ms-2">{Localize.outOf}</span>
                  <span>{totalItems}</span>
                </span>
                <button className="pagination-button" onClick={gotoPrevPage}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
                <button className="pagination-button me-3" onClick={gotoNextPage}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              </div>
            </div>}
          </div>

          <div className="right-side">
            <div className="info-holder-wrapper" style={{ display: infoModalShow ? 'block' : 'none' }}>
              <label className="info-title mb-3">על מנת לצפות בהיסטוריית החיבורים שלך :</label>
              <ol style={{ paddingRight: "1.1rem" }}>
                <li className="info-normal-text">ניתן להציג את כל החיבורים בכפתור <b>כל התוצאות</b><br /> או לסנן את החיבורים שיצרת ע״י לחיצה על כפתור <b>הוספת מסננים.</b></li>
                <li className="info-normal-text">לחצו על כפתור <b>חפש</b> כדי לקבל את תוצאות החיבורים שיצרתם בעמוד <b>יציע.</b></li>
                <li className="info-normal-text">ניתן למיין את התוצאות בכפתור <b>מיין לפי</b>.</li>
                <li className="info-normal-text">ניתן ליצור רשימת אנשי קשר מועדפים ע״י לחיצה על אייקון לב <FontAwesomeIcon icon={faHeart} style={{ position: "static" }} /></li>
                <li className="info-normal-text">ניתן לחפש חיבורים לפי מילת חיפוש <b>בשורת החיפוש</b> שמעל הטבלה.</li>
                <li className="info-normal-text">ניתן לחזור ולשנות את המסננים בכפתור <b>חזרה למסננים.</b></li>
              </ol>
              <label className="info-normal-text mb-2"><b>מידע חשוב:</b></label>
              <ul style={{ paddingRight: "1.1rem" }}>
                <li><b>סוג בקשה</b> - האם אני <img className="table-normal-image" width={30} src={userGreen} alt="" /> לחצתי על הכרטיס משחק של משתמש אחר <img className="table-normal-image" width={30} src={userBlue} alt="" /> או להיפך.</li>
                <li><b>פרטים מלאים</b> - על מנת לצפות בפרטי המשתמש המלאים, <br /> יש ללחוץ על <b>כפתור +</b> וכרטיס המשחק המורחב ייפתח, <br />כולל אופציות ליצירת קשר.</li>
              </ul>
            </div>
            <div className="custom-info-wrapper">
              <CustomInfo onClickInfoModalButton={() => { setInfoModalShow(!infoModalShow) }}></CustomInfo>
            </div>
            <div className="custom-search-wrapper">
              <>
                <FilterBox opacityBackground={themeState.gameType === 'FOOTBALL' ? '' : ''} textBorder={themeState.gameType === 'FOOTBALL' ? '#fff' : '#000'} heading={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} background={themeState.gameType === 'FOOTBALL' ? '#ffffff' : '#ffffff'}>


                  <div className={themeState.chooseOption === 'defaultSearch' ? 'opacityAddTwo' : themeState.chooseOption === 'defaultSearchRemove' ? '' : ''}>

                    <div className={themeState.chooseFilter === 'searchChoose' ? 'opacityAddTwo' : ''}>

                      <h1 className="title"
                        style={{
                          color: themeState.gameType === 'BASKETBALL' ? '#FB730C' :
                            themeState.gameType === 'FOOTBALL' ? '#00AA25' :
                              themeState.gameType === 'TENNIS' ? '#12BFEE' :
                                themeState.gameType === 'VOLLEYBALL' ? '#9069F5' : '#FB730C'
                        }}
                      >מסננים</h1>
                      {/* chooseFilter searchChoose */}
                      <div className="filterOption">
                        {/* default search */}
                        <Button buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                          <div className="mainBtn">
                            <button className={customActive === "defaultSearch" ? "active" : ""} id={"defaultSearch"} onClick={defaultFilter}>
                              <p className={customActive === "defaultSearch" ? "active" : ""} id={"defaultSearch"}>{searchPage.All_the_results}</p>
                            </button>
                          </div>
                        </Button>
                        {/* custom search */}
                        <Button buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                          <div className="mainBtn">
                            <button className={customActive === "customSearch" ? "active" : ""} id={"customSearch"} onClick={customFilter}>
                              <p className={customActive === "customSearch" ? "active" : ""} id={"customSearch"}>הוספת מסננים</p>
                            </button>
                          </div>
                        </Button>
                      </div>
                      <div className={themeState.chooseOption === 'customSearch' ? 'searchChoose chooseRemove' : themeState.chooseOption === 'backSearch' ? 'searchChoose chooseRemove' : 'searchChoose chooseAdd'}>
                        {/* custom search list */}

                        <div className="filterBtnList">
                          <div>   {userProfileData.userRole === 'PLAYER' ?
                            <>{customPlayerSearch.values.map((data: string, i: number) =>
                              <FilterButton key={'search' + i} textColor={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} background='#E4E4E4' onClick={() => showSearchValue(data, i)} className={`${active == data && "active"}`}>{data}</FilterButton>
                            )}</> :
                            <>{customSearch.values.map((data: string, i: number) =>
                              <FilterButton key={'searchValue' + i} textColor={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} background='#E4E4E4' onClick={() => showSearchValue(data, i)} className={`${active == data && "active"}`}>{data}</FilterButton>
                            )}</>}</div>


                        </div>
                        {/* level play */}

                        <LevelPlay className='levelPlay' textColor={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} border='none' background='red'>
                          <h1 className="label-title">{headingLevel}</h1>
                          {maxDisplay ? <>
                            {maxData.map((data: any, i: number) =>
                              <PlayerButton key={'level' + i} textColor={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} background='#fff' onClick={() => finalSearch(data, i)} className={`${disableMax.indexOf(data) !== -1 && "active"}`}>{data}</PlayerButton>
                            )}
                          </> : <>
                            {allSearch.map((data: any, i: number) =>
                              <PlayerButton key={'levelData' + i} textColor={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} background='#fff' onClick={() => finalSearch(data, i)} className={`${disableAdd.indexOf(data) !== -1 && "active"}`}>{data}</PlayerButton>
                            )} </>}


                          {/* */}
                        </LevelPlay>

                        {/* all list custom search list */}

                        <LabelResultBox border='none' backgroundColor={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(180deg, #81F881 0%, #08AF2B 100%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(180deg, #11C7FF 0%, #048C9D 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(360deg, #024FD2 -40.49%, #9268F6 57.09%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(180deg, #FFC73D 0%, #FC7C11 100%);' : 'linear-gradient(180deg, #81F881 0%, #08AF2B 100%);'}>
                          {ageRanges.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'ageRanges')}>X</span></LabelResult>)}
                          {showLevel.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'levelOf')}>X</span></LabelResult>)}
                          {showDay.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'availableDays')}>X</span></LabelResult>)}
                          {showHour.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'availableHour')}>X</span></LabelResult>)}
                          {showDistance.map((data: any, index: any) =>
                            <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>
                              {data}<span className='resultClose' onClick={() => removeSearch(index, data, 'maximumDistance')}>X</span>
                            </LabelResult>)}
                          {showUser.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'userType')}>X</span></LabelResult>)}
                          {showFitness.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'fitness')}>X</span></LabelResult>)}
                          {showTenure.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'tenure')}>X</span></LabelResult>)}
                        </LabelResultBox>

                        {/* reset filter */}
                        <div className='resetBtn'>
                          <ResetFilter textColor={themeState.gameType === 'FOOTBALL' ? '#fff' : '#000'} onClick={resetFilter}>{searchPage["Reset_Filter"]}</ResetFilter>
                        </div>
                        {/* custom search */}
                        <Button buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                          <div className="mainBtn">
                            <button className={ageRanges.length + showLevel.length + showDay.length + showHour.length + showDistance.length + showUser.length + showFitness.length + showTenure.length === 0 ? 'white disActive' : 'white runActive'} onClick={customSearchFunction}>
                              <p>{searchPage["Search"]}</p>
                              <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                          </div>
                        </Button>
                      </div>

                    </div>
                  </div>
                </FilterBox>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default History