import { Lang } from '../../Enums/Lang';
import { getCurrentLanguage } from '../../Localization/Localize.Service';
import commonHebrew from '../../assets/raw-text/common.json';
import { sortDayTimes } from './DayTimeHelper';

const stringObj = JSON.stringify(commonHebrew);
const mainObj = JSON.parse(stringObj);
const lang = getCurrentLanguage();

export function convertAnyToHebrew(key: string) {
    return mainObj[key];
}

export function convertHourToHebrewAndJoin(freeHours: any, joinnerSimble: string) {
    let tempArray: string[] = [];
    let sortedHours: any = sortDayTimes(freeHours);
    for (let i = 0; i < sortedHours.length; i++) {
        const convertValue = (lang === Lang.HEBREW ? convertAnyToHebrew(sortedHours[i]) : sortedHours[i]);
        tempArray.push(convertValue);
    }
    return tempArray.join(joinnerSimble);
}

export function convertHoursToArrayAndSort(freeHours: any): string[] {
    return sortDayTimes(freeHours);
}
