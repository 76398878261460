import LocalizedStrings from 'react-localization';

const Localize = new LocalizedStrings({
    he: {
        playerLocation: "אזור מגורים",
        leaderLocationLabel: "מיקום המגרש",
        maximumDistanceInKm: "מרחק מקסימלי בק״מ",
        frequencyOfPlay: "תדירות משחק",
        playingTimeAverage: "זמן משחק (בממוצע)",
        wouldYouLikeToJoinTheGroupRegularly: "האם תרצה להצטרף לקבוצה באופן קבוע?",
        gameType: "סוג משחק",
        game: "משחק",
        levelOf: "רמת",
        gamePosition: "עמדת משחק",
        preferredSurfaceType: "סוג משטח מועדף",
        PreferredNumberOfPlayersInEachTeam: "כמה שחקנים בכל קבוצה",
        levelOfPlay: "רמת משחק",
        dateOfBirth: "תאריך לידה",
        profileDetails: "פרטי הפרופיל",
        addedAContact: "הוספת איש קשר",
        contactAdded: "איש קשר נוסף קיים",
        heyThere: "היי שם",
        hi: "היי",
        hide: "הסתר",
        return: "החזר",
        public: "פּוּמְבֵּי",
        change: "שנה",
        delete: "מחק",
        profile: "פרופיל",
        freeDaysAndHoursOfPlay: "ימים ושעות משחק פנויים",
        freeDaysAndHoursOfPlayGroup: "ימים ושעות משחק קבועים",
        updateDetails: "עדכון פרטים",
        play: "משחק",
        player: "שחקן",
        groupOwner: "בעל קבוצה",
        moreInfo: "מידע נוסף",
        updateAvailability: "עדכן זמינות",
        updateAvailabilityForLeader: "עדכן זמני משחק",
        for: "עבור",
        of: "מתוך",
        paymentToThePlayerForRentingTheField: "תשלום לשחקן עבור השכרת המגרש",
        ageRange: "טווח גילאים",
        successInAction: "הפרטים עודכנו",
        detailsHaveBeenUpdated: " הפרטים עודכנו",
        errorInAction: "שגיאה בפעולה",
        UP_TO_5KM: 'עד 5 ק״מ',
        UP_TO_10KM: 'עד 10 ק״מ',
        UP_TO_20KM: 'עד 20 ק״מ',
        OVER_20KM: 'מעל 20 ק״מ',
        ONCE_A_MONTH: 'פעם בחודש',
        "2-3_TIMES_A_MONTH": '2-3 פעמים בחודש',
        ONCE_A_WEEK: 'פעם בשבוע',
        "2-3_TIMES_A_WEEK": '2-3 פעמים בשבוע',
        ALMOST_EVERY_DAY: 'כמעט כל יום',
        TO_AN_HOUR: 'עד שעה',
        BETWEEN_ONE_AND_TWO_HOURS: 'בין שעה לשעתיים',
        OVER_TWO_HOURS: 'מעל לשעתיים',
        YES: 'כן',
        NO: 'לא',
        DOESNT_MATTER: 'לא משנה',
        PER_GAME: "לפי משחק",
        PER_MONTH: "לפי חודש",
        PER_SEASON: "לפי עונה",
        FREE: "חינם",
        FOOTBALL: 'כדורגל',
        BASKETBALL: 'כדורסל',
        VOLLEYBALL: 'כדורעף',
        TENNIS: 'טניס',
        OTHER: 'אחר',
        GOAL_KEEPER: 'שוער',
        FIELDER: 'שחקן שדה',
        ASPHALT: 'אספלט',
        SYNTHETIC_GRASS: 'דשא סינטטי',
        REAL_GRASS: 'דשא אמיתי',
        HALL: 'אולם',
        OUTSIDE_PITCHES: 'מגרשי חוץ',
        "DOESN'T MATTER": "לא משנה",
        AMATEUR: "חובבנית",
        INTERMEDIATE: "בינונית",
        ADVANCED: "מתקדמת",
        PROFESSIONAL: "מקצועית",
        "1-2": "1-2",
        "2": "2",
        "3-4": "3-4",
        "3": "3",
        "4-5": "4-5",
        "4": "4",
        "5": "5",
        "6-7": "6-7",
        "6": "6",
        "8-10": "8-10",
        "11": "11",
        "SINGLES": "סינגלס",
        "DOUBLES": "דאבל",
        '14-17': '14-17',
        '18-29': '18-29',
        '30-49': '30-49',
        '50+': '50+',
        BEACH: "חופים",
        CLAY: "חימר",
        NOT_CONSTANT: "לא קבוע",
        Submit: "בחר",
        Save: 'להציל',
        validationError: "שגיאת אימות",
        pleaseEnterAMoreAaccurateAddress: "נא לרשום כתובת יותר מדויקת בבקשה",
        userLocation: "מיקום שלי",
        leaderLocation: "מיקום המגרש",
        notAvailable: "לא זמין",
        termsOfUse: "תנאי שימוש :",
        confirm: "אשר",
        missingFields: "חסר נתונים",
        requiredMandatoryField: "אתה לא יכול למחוק את הנתון הזה",
        optionIsNotAvailable: "אפשרות זו אינה זמינה",
        forHowLongTimeYouWantToHide: "הסתרה מעמוד היציע לתקופה של :",
        areYouSure: "האם אתה בטוח?",
        "3_Days": "3 ימים",
        "1_Week": "1 " + "שבוע",
        "for_always": "לתמיד",
        close: "סגור",
        fullWeek: "כל השבוע",
        thisPhoneNumberIsAlreadyInUse: "מספר הטלפון הזה כבר בשימוש",
        thisEmailAddressIsAlreadyInUse: "כתובת האימייל הזו כבר בשימוש",
        additionalContact: "איש קשר נוסף",
        showFavorites: "הצג מועדפים",
        showFavoritesOnly: "הצג מועדפים בלבד",
        showAll: "הצג הכל",
        addAFilter: "הוסף מסנן",
        searchRequest: "חפש בקשה...",
        gameRequestsPage: "דף בקשות המשחק",
        gameRequests: "כאן תוכל לראות את היסטוריית כל בקשות המשחק.",
        userDetails: "גם פרטי משתמשים שאתה ביקשת, וגם משתמשים שביקשו את פרטיך.",
        contactInfo: "פרטי קשר",
        nickname: "שם / כינוי",
        userType: "סוג משתמש",
        request: "בקשה",
        type: "סוג",
        dateAndTime: "תאריך ושעה",
        outOf: "מתוך",
        numberOfLinesPerPage: "מספר שורות לעמוד - ",
        popoverHeader: "כותרת פופאובר",
        popoverBody: "פופאובר גוף",
        history: "היסטוריה",
        information: "מידע",
        gallery: "יציע",
        backToFilters: "חזרה למסננים",
        connectionHistory: "היסטוריית החיבורים",
        full: "מלאים",
        details: "פרטים",
        location: "מיקום",
        playType: "סוג משחק",
        sortBy: "מיין לפי",
        dummy: "דֶמֶה",
    },
    en: {
        playerLocation: "Player location",
        leaderLocationLabel: "Leader location",
        maximumDistanceInKm: "Maximum distance in km",
        frequencyOfPlay: "Frequency of play",
        playingTimeAverage: "Playing time (average)",
        wouldYouLikeToJoinTheGroupRegularly: "?Would you like to join the group regularly",
        gameType: "Game type",
        game: "Game",
        levelOf: "Level of",
        gamePosition: "Game position",
        preferredSurfaceType: "Preferred surface type",
        PreferredNumberOfPlayersInEachTeam: "Preferred number of players in each team",
        levelOfPlay: "Level of play",
        dateOfBirth: "Date of birth",
        profileDetails: "Profile details",
        addedAContact: "Added a contact",
        contactAdded: "Another contact exists",
        heyThere: "Hey there",
        hi: "Hi",
        hide: "Hide",
        return: "Return",
        public: "Public",
        change: "Change",
        delete: "Delete",
        profile: "profile",
        freeDaysAndHoursOfPlay: "Free days and hours of play",
        freeDaysAndHoursOfPlayGroup: "Regular game days and hours",
        updateDetails: "Update details",
        play: "play",
        player: "Player",
        groupOwner: "Group owner",
        moreInfo: "More info",
        updateAvailability: "Update availability",
        updateAvailabilityForLeader: "Update availability",
        for: "for",
        of: "of",
        paymentToThePlayerForRentingTheField: "Payment to the player for renting the field",
        ageRange: "Age range",
        successInAction: "Success",
        detailsHaveBeenUpdated: "The details have been updated",
        errorInAction: "Error",
        UP_TO_5KM: "Up to 5 km",
        UP_TO_10KM: "Up to 10 km",
        UP_TO_20KM: "Up to 20 km",
        OVER_20KM: "Over 20 km",
        ONCE_A_MONTH: 'Once a month',
        "2-3_TIMES_A_MONTH": '2-3 times a month',
        ONCE_A_WEEK: 'Once a week',
        "2-3_TIMES_A_WEEK": '2-3 times a week',
        ALMOST_EVERY_DAY: 'Almost every day',
        TO_AN_HOUR: 'To an hour',
        BETWEEN_ONE_AND_TWO_HOURS: 'Between one and two hours',
        OVER_TWO_HOURS: 'Over two hours',
        YES: 'YES',
        NO: 'NO',
        DOESNT_MATTER: "Doesn't matter",
        PER_GAME: "Per Game",
        PER_MONTH: "Per Month",
        PER_SEASON: "Per Season",
        FREE: "Free",
        FOOTBALL: 'Football',
        BASKETBALL: 'Basketball',
        VOLLEYBALL: 'Volleyball',
        TENNIS: 'Tennis',
        OTHER: 'Other',
        GOAL_KEEPER: 'Goal keeper',
        FIELDER: 'Fielder',
        ASPHALT: 'Asphalt',
        SYNTHETIC_GRASS: 'Synthetic grass',
        REAL_GRASS: 'Real grass',
        HALL: 'Hall',
        OUTSIDE_PITCHES: 'Outside pitches',
        "DOESN'T MATTER": "Doesn't matter",
        AMATEUR: 'Amateur',
        INTERMEDIATE: 'Intermediate',
        ADVANCED: 'Advanced',
        PROFESSIONAL: 'Professional',
        "1-2": "1-2",
        "2": "2",
        "3-4": "3-4",
        "3": "3",
        "4-5": "4-5",
        "4": "4",
        "5": "5",
        "6-7": "6-7",
        "6": "6",
        "8-10": "8-10",
        "11": "11",
        "SINGLES": "Single",
        "DOUBLES": "Double",
        '14-17': '14-17',
        '18-29': '18-29',
        '30-49': '30-49',
        '50+': '50+',
        BEACH: 'Beach',
        CLAY: 'Clay',
        NOT_CONSTANT: 'Not constant',
        Submit: 'Submit',
        Save: 'Save',
        validationError: "Validation error",
        pleaseEnterAMoreAaccurateAddress: "Please enter a more accurate address",
        userLocation: "User location",
        leaderLocation: "Leader location",
        notAvailable: "Not available",
        termsOfUse: "Terms of Use :",
        confirm: "Confirm",
        missingFields: "Missing fields",
        requiredMandatoryField: "Required mandatory field",
        optionIsNotAvailable: "Option is not available",
        forHowLongTimeYouWantToHide: "?For how long time you want to hide",
        areYouSure: "Are you sure?",
        "3_Days": "3 Days",
        "1_Week": "1 Week",
        close: "close",
        fullWeek: "Full week",
        thisPhoneNumberIsAlreadyInUse: "This phone number is already in use",
        thisEmailAddressIsAlreadyInUse: "This email address is already in use",
        showFavorites: "Show favorites",
        showFavoritesOnly: "Show favorites only",
        addAFilter: "Add a filter",
        searchRequest: "Search request...",
        gameRequestsPage: "Game requests page",
        gameRequests: "Here you can see the history of all game requests.",
        userDetails: "Both user details you requested, and users who requested your details.",
        contactInfo: "Contact info",
        nickname: "Nickname",
        userType: "User type",
        request: "Request",
        type: "type",
        dateAndTime: "Date and time",
        outOf: "Out of",
        numberOfLinesPerPage: "Number of lines per page - ",
        popoverHeader: "Popover Header",
        popoverBody: "Popover Body",
        history: "History",
        information: "Information",
        gallery: "Gallery",
        backToFilters: "Back to filters",
        connectionHistory: "Connection history",
        full: "Full",
        details: "details",
        location: "Location",
        playType: "סוג משחק",
        sortBy: "sort by",
        dummy: "Dummy",
    }
})
export default Localize;