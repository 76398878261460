import { MaxDistance } from "../../Enums/MaxDistance";
import { OptionType } from "../../Interfaces/OptionType";
import Localize from "../../Localization/Localize";

export function getMaxDistanceOptions(): OptionType[] {
    return [
        { label: Localize[MaxDistance.UP_TO_5KM], value: MaxDistance.UP_TO_5KM },
        { label: Localize[MaxDistance.UP_TO_10KM], value: MaxDistance.UP_TO_10KM },
        { label: Localize[MaxDistance.UP_TO_20KM], value: MaxDistance.UP_TO_20KM },
        { label: Localize[MaxDistance.OVER_20KM], value: MaxDistance.OVER_20KM },
    ];
}