import React, { useEffect, useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { UserRole } from '../../../Enums/UserRole';
import { ILocation } from '../../../Interfaces/Location';
import Localize from '../../../Localization/Localize';

export interface locationSearchProps {
    userRole: string,
    currentLocation: string,
    playAreaError: any,
    locationError: any,
    onChangeSearch: (value: ILocation) => void
}

function LocationSearch({ userRole, currentLocation, locationError, playAreaError, onChangeSearch }: locationSearchProps) {

    const [locationMark, setLocationMark] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [cityName, setCityName] = useState<string>('');
    const [countryName, setCountryName] = useState<string>('');
    const [playAreaValue, setPlayAreaValue] = useState<string>('');
    const [locationLatLong, setLocationLatLong] = useState<google.maps.LatLngLiteral>({ lat: 0, lng: 0 });
    const [conditionMap, setConditionMap] = useState<string>('');

    useEffect(() => {
        setLocationMark(currentLocation);
    }, [currentLocation]);

    useEffect(() => {
        if (address !== "") {
            const newLocation: ILocation = {
                city: cityName,
                country: countryName,
                location: address,
                latitude: locationLatLong.lat.toString(),
                longitude: locationLatLong.lng.toString(),
                playArea: playAreaValue
            }
            onChangeSearch(newLocation);
        }

    }, [address]);

    const handleChange = (address: any) => {
        setLocationMark(address);
    };

    const handleSelect = async (address: any) => {
        setLocationMark(address);

        geocodeByAddress(address)
            .then(results => {
                const full_addresses_key = ['establishment', 'point_of_interest', 'tourist_attraction', 'route', "street_number"];
                const addresses_key = ['route', "street_number"];
                const addressComponents = results[0].address_components;

                // Formating the address string <specific> (park, establishment, ...),  <street_number> <street>
                const address_part = (addressComponents.filter(i => i['types'].some(r => full_addresses_key.includes(r)))).map(j => (j['types'].some(r => addresses_key.includes(r))) ? j['short_name'] : `${j['short_name']},`)
                const address = address_part.join(' ');

                // Formatting area, city, country
                const area = addressComponents.find(i => i['types'].includes('administrative_area_level_1') || i['types'].includes('administrative_area_level_2'))
                const city = addressComponents.find(i => i['types'].includes('locality'))
                const country = addressComponents.find(i => i['types'].includes('country'))

                if (!address.length && !city) {
                    setConditionMap(Localize.pleaseEnterAMoreAaccurateAddress)
                } else if (!address.length && city && country) {
                    setAddress(city['long_name'])
                    setCityName(city['long_name'])
                    setCountryName(country['long_name'])
                } else if (address.length && city && country) {
                    setAddress(address)
                    setCityName(city['long_name'])
                    setCountryName(country['long_name'])
                }
                if (area) {
                    setPlayAreaValue(area['long_name']);
                }
            })

        geocodeByAddress(address)
            .then((results: google.maps.GeocoderResult[]) => getLatLng(results[0]))
            .then((latLng: google.maps.LatLngLiteral) => setLocationLatLong(latLng))
            .catch(error => console.error('Error', error));

    };

    return (
        <div className="location-search-wrapper locationMap">

            <PlacesAutocomplete value={locationMark} onChange={handleChange} onSelect={handleSelect} >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <>

                        <input
                            {...getInputProps({
                                placeholder: userRole === UserRole.PLAYER ? Localize.userLocation : Localize.leaderLocation,
                                className: 'mapInput mt-1',
                            })}
                            required
                        />

                        {conditionMap && conditionMap.length ? <p className='errorMsg'>{conditionMap}</p> : ""}

                        {suggestions && suggestions.length ? <div className="autocomplete-dropdown-container" style={{ border: "1px solid #100159", borderRadius: "10px", padding: "2%", marginTop: "2%" }}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion: any) => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <p>{suggestion.description}</p>
                                    </div>
                                );
                            })}
                        </div> : null
                        }
                    </>
                )}
            </PlacesAutocomplete>
            {/* {locationError && locationError.length ? <p className='errorMsg'>{locationError}</p> : ''} */}
        </div>
    )
}

export default LocationSearch