import React, { useState, useEffect } from "react";
import plus from "../../../assets/svgs/plus.svg";
import minus from "../../../assets/svgs/minus.svg";
import { Container, Row, Col, Button, Accordion, Stack } from "react-bootstrap";
import "./index.css";
import CustomContainer from "./customContainer";
import DOMPurify from 'dompurify';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';
import smoothscroll from 'smoothscroll-polyfill';

//this is question data where button text is "text"
//and details array are actually question and answer

const cssBronzaText: React.CSSProperties = {
	backgroundImage: "linear-gradient(270.31deg, #AB7537 0.5%, #DDAC6F 18.08%, #D69A51 38.05%, #9E692F 56.43%, #B88C5B 72.81%)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent"
}
const cssSilverText: React.CSSProperties = {
	backgroundImage: "linear-gradient(271.79deg, #A0A0A0 5.97%, #AFAFAF 20.4%, #CFCFCF 33.14%, #EEEEEE 43.54%, #CECECE 54.27%, #959595 66.01%)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent"
}
const cssGoldText: React.CSSProperties = {
	backgroundImage: "linear-gradient(269.67deg, #E9D58B 0.36%, #D1A855 15.08%, #C28D31 30.55%, #D1A85B 41.22%, #DFBF7C 53.37%, #C3913A 71.04%)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent"
}
const cssPurpleText: React.CSSProperties = {
	backgroundImage: "linear-gradient(270.58deg, #3F114F 0.13%, #6E107E 28.44%, #50105E 56.75%, #6D117F 73.23%)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent"
}

const accordainData = [
	{

		id: 1,
		text: "עלינו",
		details: [
			{
				question: "מי אנחנו",
				answer: `האתר tapnscore.com (לינק לאתר להעתקה) פועל תחת מיזם עצמאי בשם <b>טאפ אנ סקור (Tap N Score)</b>,
<br/>				והוקם במטרה להוות בית לשחקנים וקבוצות של משחקי כדור חובבניים, כמו, כדורגל, כדורסל, כדורעף וכיו״ב.
<br/>				אנו מסייעים לשחקנים וקבוצות משחקי כדור לארגן ולנהל ימי משחק, בקלות ובנגישות.
<br/>				<b>טאפ אנ סקור</b> יצרה עד כה מאות חיבורים מוצלחים באמצעות האתר, קהילות הווטסאפ והמדיה החברתית שלנו.
				`
			},
			{
				question: "מה אנחנו עושים ?",
				answer: `אתר tapnscore.com מסייע לשחקנים וקבוצות משחקי כדור חובבניים להתחבר אחד עם השני למשחקים משותפים.<br/>
				האתר מציע מנועי חיפוש חדשניים, שעושים שימוש בפרמטרים ומסננים ייחודיים למשחקי הכדור,<br/>
				על מנת לאפשר חיבורים מוצלחים למשחקים משותפים.<br/>
				לחצו על <b>איך מתחילים</b> כדי לקבל מידע על השימוש באתר.`
			},
			{
				question: "משימה",
				answer: `<b>המשימה</b> שלנו היא להפוך את הספורט החובבני לנחלת הכלל, באמצעות שירות חיבורים נוח ונגיש,
				<br/>				לשימוש כלל השחקנים והקבוצות.
<br/>				אנו שואפים לכך שכל אדם, שמעוניין לשחק משחקי כדור, יוכל למצוא שותפים רלוונטיים באתר וללכת לשחק,
<br/>				ולמנוע מצב שבו הוא נאלץ לוותר על התחביב האהוב עליו, בגלל שלא מצא מספיק שחקנים או קבוצת משחק לשחק איתם.`
			},
			{
				question: "חזון",
				answer: `<b>החזון</b> שלנו הוא לעודד עשיית פעילות ספורטיבית חברתית ומהנה בקרב קהילת שחקני הכדור בישראל.
<br/>				אנו מאמינים כי אין פעילות או סיטואציה אחרת בחיי היום-יום שלנו בו אנחנו מתחברים, בזמן קצר מאוד ועם אנשים רנדומליים,
<br/>				למשחק כדור מהנה, שיוצר הנאה מטורפת לכלל המשתתפים ויוצר קשרים חברתיים ארוכי טווח.`
			},
			{
				question: "קהילת ווטסאפ",
				answer: `הצטרפו לקהילת הווטסאפ השקטה שלנו בקישור 🔗 <a href="https://chat.whatsapp.com/DhPzWnadoGE30nPWEMeVxT" target="_blank" rel="noreferrer">כאן</a>.`
			},
			{
				question: "תנאי שימוש ומדיניות פרטיות",
				answer: `על מנת לקרוא את תנאי השימוש ומדיניות הפרטיות, יש ללחוץ <a href="https://tapnscore.com/terms" target="_blank" rel="noreferrer">כאן</a>.`
			},
		],
	},

	{
		id: 2,
		text: "איך מתחילים",
		details: [
			{
				question: "על התהליך",
				answer: `נרשמים לאתר באמצעות שאלון הרשמה ייחודי לשחקנים ולקבוצות משחקי כדור.
<br/>				השאלון כולל מתן פרטים אישיים, פרטי משחק, זמינות למשחקים והעדפות נוספות.
				<br/>
				<br/>
				נכנסים למסך <b>היציע</b>, בו מוצאים שותפים רלוונטיים בשימוש של מנועי חיפוש ייחודיים.
<br/>				בוחרים את המסננים או הפרמטרים שחשובים לכם בשותף האידיאלי, ומקבלים את כל התוצאות הרלוונטיות במאגר שלנו.
				<br/>
				<br/>
				לאחר שמצאתם את השותף האידיאלי, לחצו על כרטיס המשחק שלו על מנת לצפות בפרטים המלאים,
<br/>				וליצור קשר דרך אייקונים ליצירת קשר (ווטסאפ, טלפון, אימייל, פייסבוק).
<br/>				<b>ופשוט מתחילים לשחק !</b>
				<br/>
				<br/>
				לשאלות נוספות מוזמנים ליצור קשר דרך הפנייה למטה, בווטסאפ שלנו, או דרך עמודי המדיה החברתית שלנו.
				`
			},
			{
				question: "על עמודי האתר",
				answer: `מנועי החיפוש שבמסך <b>היציע</b>, מורכבים מתצוגת כרטיסי משחק ומתצוגת מפה אינטראקטיבית.<br/>
				לחיצה על כרטיסי המשחק פותחת את הפרטים המלאים של השחקן, או הקבוצה, וכמו כן גם אופציות ליצירת קשר.<br/><br/>
				בעמוד <b>הפרופיל</b> תוכלו לעדכן את פרטי המשחק והעדפות שלכם, וגם את זמני המשחק.<br/><br/>
				בעמוד <b>היסטוריה</b> תוכלו לצפות בכל כרטיסי המשחק אשר חיפשתם בעבר במסך <b>יציע</b>, וכמו כן גם במשתמשים שחיפשו אתכם.`
			},
			{
				question: "סרטון הדרכה",
				answer: `לחצו כדי לצפות בסרטון ההדרכה לשימוש באתר 🔗 <a href="https://youtu.be/WWCK8vjpRDo" target="_blank" rel="noreferrer">כאן</a>.`
			},
		],
	},
	{
		id: 3,
		text: "שאלות & תשובות",
		details: [
			{
				question: "האם השימוש באתר הוא בחינם?",
				answer: `<b>כן</b>, השימוש באתר, ובכל הפיצ'רים שהוא מכיל, הם חינמיים לחלוטין!`
			},
			{
				question: "האם המידע שלי חשוף למשתמשים אחרים באתר?",
				answer: `<b>כן</b> כל משתמש שנרשם לאתר, יכול לקבל את הפרטים (מידע אישי, פרטי משחק והעדפות משחק)<br/>
		של כל יתר המשתמשים, על מנת להתחבר למשחק משותף.
<br/>למרות זאת, <b>לא</b> נחשוף באתר את פרטי יצירת הקשר שלך בצורה 
<b>גלויה.</b>`
			},
			{
				question: "איך אני יוצר קשר עם שחקן / קבוצה במסך היציע?",
				answer: `לחיצה על <b>כרטיס המשחק</b> במנועי החיפוש ייפתח עבורך את כל הפרטים וגם אייקונים ליצירת קשר (ווטסאפ,  
					טלפון, אימייל, פייסבוק).`
			},
			{
				question: "מה המשמעות של הצבעים בכרטיסי המשחק במסך היציע?",
				answer: (<><p>כל השחקנים והקבוצות מחולקים לפי <b>רמות משחק</b> (מחובבני עד מקצועי) ומוצגים לפי צבעים,
					<br />					עפ״י החלוקה:</p>
					<ul style={{ listStyleType: "none", paddingInlineStart: 0 }}>
						<li><span style={cssBronzaText}>ברונזה</span> - רמה חובבנית</li>
						<li><span style={cssSilverText}>כסוף</span> - רמה בינונית</li>
						<li><span style={cssGoldText}>זהב</span> - רמה מתקדמת</li>
						<li><span style={cssPurpleText}>סגול</span> - רמה מקצועית</li>
					</ul></>)
			},
			{
				question: "מה הכוונה בדרגת כושר ?",
				answer: `הדרגת הכושר של שחקן מחושבת ע״י שקלול של תדירות משחק ומשך זמן משחק (בממוצע), 
<br/>				ומעידה על <b>מצבו הפיזי</b> של השחקן, או השחקנים בקבוצה.`
			},
			{
				question: "אם נרשמתי כשחקן, האם אני יכול להירשם גם כקבוצה?",
				answer: `<b>כן</b>, כל משתמש יכול להירשם גם כשחקן וגם כבעל קבוצה, ע״י לחיצה על <b>שנה פרופיל</b> בעמוד הפרופיל. 
<br/>				לאחר ההרשמה הנוספת, יהיו למשתמש שני פרופילים שונים, וגם <b>שני כרטיסי משחק</b> שונים.`
			},
			{
				question: "האם ניתן לשמור פרטים של משתמשים רלוונטיים לשימוש עתידי?",
				answer: `<b>כן</b>, במסך ה<b>היסטוריה</b> תוכלו למצוא את כל המשתמשים שחיפשתם בעבר,
<br/>				וללחוץ על אייקון <b>לב</b> על מנת לשמור את המשתמש כמועדף עבורכם, על מנת ליצור איתו קשר בעתיד בקלות.`
			},
			{
				question: "האם אתם מתחייבים שההצעות לשותפים למשחק יהיו רלוונטיים עבורי?",
				answer: `<b>לא</b>, באמצעות פרטי המשתמשים באתר, אשר הזינו בתהליך ההרשמה,
<br/>			אנו יודעים לאפשר לשחקנים וקבוצות לחפש שותפים אידיאליים בעמוד היציע לפי פרמטרים ומסננים ייחודים.
<br/>				אנו דורשים שכל פרטי ההרשמה יהיו מדויקים על מנת ליצור חיבורים אמינים ורלוונטיים.
<br/>				עם זאת, <b>אין אנו מתחייבים</b> שהחיבור למשחקים יהיה מתאים במאת האחוזים,
<br/>			<b>ולא ניקח אחריות</b> על כל מידע שגוי, או על כל נזק אשר ייגרם כתוצאה מהשענות על המידע שמופיע באתר.`
			},
			{
				question: "האם תעשו שימוש במידע שלי מחוץ לאתר?",
				answer: `<b>לא</b>, אנחנו לא נעשה כל שימוש במידע האישי שלך.
<br/>	המטרה של  הכנסת המידע למערכת האתר, היא בכדי <b>לסייע לך למצוא שותפים</b> רלוונטיים למשחק משותף,
<br/>				ולוודא שאתה משחק את המשחק האהוב עלייך מבלי להתעסק במציאת שותפים כל היום.`
			},
			{

				question: "איך אני יכול לשלוח את האתר לחברים שלי?",
				answer: [` באמצעות לחיצה על אייקון<b> השיתוף </b>`, ` בצד שמאל למעלה של האתר,<br/> יועתק ללוח שלכם קישור ייחודי לאתר, אשר ניתן להדבקה בכל מדיה חברתית שהיא (ווטסאפ, פייסבוק וכד׳).`]
			},
		],

	},
];
export default function Questions() {
	const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the breakpoint for mobile devices
	const [selectedItem, setSelectedItem] = useState<
		| {
			id: number;
			text: string;
			details: {
				question: string;
				answer: string | string[] | any;
			}[];
		}
		| undefined
	>();

	const [selectedAccordion, setSelectedAccordion] = useState<any>();

	const scrollToAccordion = () => {
		smoothscroll.polyfill();

		const activeAccordionElement = document.getElementById(`accordion-container`);
		const headerElement = document.getElementById(`header-element`);

		if (activeAccordionElement && headerElement) {
			const headerHeight = headerElement.offsetHeight;
			const elementTop = activeAccordionElement.offsetTop;
			const scrollToY = elementTop - headerHeight;
			window.scrollTo({ top: scrollToY, behavior: "smooth" });
		}
	};

	const handleClickItem = (item: any) => {
		if ((selectedItem && item.id !== selectedItem.id) || !selectedItem) {
			setSelectedItem(item);
			setSelectedAccordion(null);
			scrollToAccordion();
		} else setSelectedItem(undefined);
	};

	return (
		<div style={{ marginTop: isMobile ? "30px" : "50px" }}>
			<CustomContainer>
				{/* buttons */}
				<Row style={{ gap: "10px" }} className='justify-content-center'>
					{accordainData.map((item) => {
						let selected = selectedItem && selectedItem.id === item.id;
						return (
							<Col md sm={12} key={item.id}>
								<Button
									size='sm'
									className='btn-block w-100 border-0 question-button'
									style={{
										//fontFamily: "Rubik One",
										borderRadius: "53px",
										background: selected
											? "linear-gradient(90deg, #FB730C -6.97%, #FC9818 42.82%, #FDD72E 106.39%)"
											: "linear-gradient(180deg, #FDFDFD -7.2%, #E7E7E7 53.19%, #F2F2F2 104.24%)",
										boxShadow: "1px 2px 10px 4px rgba(0, 0, 0, 0.25)",
										color: selected ? "#fff" : "var(--orange)",
										fontWeight: 700,
										textShadow: selected ? "0px 2px 4px rgba(0, 0, 0, 0.57)" : ""
									}}
									onClick={() => handleClickItem(item)}
								>
									{item.text}
								</Button>
							</Col>
						);
					})}
				</Row>
			</CustomContainer>
			{/* accordain */}
			<div id="accordion-container">
				{
					selectedItem && (
						<Accordion
							style={{ marginTop: "70px" }}
							className='custom-accordion'
							onSelect={(selectedIndex) => setSelectedAccordion(selectedIndex)}
							activeKey={selectedAccordion}

						>
							{selectedItem.details.map((item, i) => (
								<Accordion.Item
									eventKey={`${i}`}
									key={i}
									className='rounded-0 border-left-0 border-right-0'
									style={{
										borderTop: "4px solid var(--blue)",
										borderBottom:
											selectedItem.details.length === i + 1
												? "4px solid var(--blue)"
												: 0,
									}}
									id={`accordion-${i}`}
								>
									<Accordion.Header>
										<Stack
											direction='horizontal'
											gap={3}
											style={{
												backgroundColor:
													"#FB730C" /* Replace with your desired gradient colors */,
												backgroundClip: "text",
												WebkitBackgroundClip: "text" /* For Safari support */,
												color: "#FB730C",
												fontSize: isMobile ? "20px" : "31px",
												fontWeight: 700,
												textAlign: "right"
											}}
										>
											<img
												src={selectedAccordion == i ? minus : plus}
												style={{ width: "20px", height: "20px" }}
											/>
											{item.question}
										</Stack>
									</Accordion.Header>
									<Accordion.Body
										style={{
											borderTop:
												selectedAccordion == i ? "4px solid var(--blue)" : 0,
											//whiteSpace: "pre-wrap",
											color: "var(--blue)",
										}}
									>
										<Stack
											direction='horizontal'
											gap={3}
											style={{
												color: "var(--blue)",
												fontWeight: 500,
												fontSize: isMobile ? "15px" : "21px",
											}}
										>
											<img
												src={selectedAccordion == i ? minus : plus}
												style={{
													width: "20px",
													height: "20px",
													visibility: "hidden",
												}}
											/>
											{Array.isArray(item.answer) ?
												<p>
													<span dangerouslySetInnerHTML={{ __html: `${DOMPurify.sanitize(item.answer[0])}` }} />
													<FontAwesomeIcon icon={faShareNodes} />
													<span dangerouslySetInnerHTML={{ __html: `${DOMPurify.sanitize(item.answer[1])}` }} />
												</p>
												:
												typeof item.answer === "string" ?
													<div dangerouslySetInnerHTML={{
														__html: DOMPurify.sanitize(item.answer, {
															ALLOWED_ATTR: ['href', 'target', 'rel']
														})
													}} /> :
													<div>{item.answer}</div>}
										</Stack>
									</Accordion.Body>

								</Accordion.Item>
							))}
						</Accordion>
					)
				}
			</div>
		</div >
	);
}
