import React from 'react';
import Routing from './Routing';
import { Provider } from 'react-redux';
import store,{persistor} from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
//  let persistor = persistStore(store)
function App() {

  return (
    <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}> 
      <Routing />
      </PersistGate>
      <ToastContainer />
    </Provider>
  );
}

export default App;
