import React, { useState } from "react";
import Layout from "../Layout/Layout";
import {
  Container,
  Navbar,
  Stack,
  Row,
  Col,
  Button,
  Accordion,
} from "react-bootstrap";
import AboutUsPage from "../../StyleComponent/AboutUs/AboutUsPage";
import Header from "../Header/Header";
import pattern from "../../../assets/images/bg-image/pattern.png";

import Questions from "./questions";
import Form from "./form";
import Footer from "./footer";
import { isAuthenticate } from '../../../api/auth';

export default function Index() {
  return (
    <>
      <AboutUsPage backgroundImage={pattern}>
        <Header aboutUs={true} />
        <div className={`${isAuthenticate() ? 'bg-header-about-us' : 'bg-header-about-us-not-logged'}`}>
        </div>
        <Questions />
        <Form />
        <Footer />
      </AboutUsPage>
    </>
  );
}
