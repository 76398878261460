import React, { useEffect, useRef, useState } from 'react';
import Localize from '../../../Localization/Localize';

import phoneIcon from '../../../assets/images/icon/phone-round.png';
import emailIcon from '../../../assets/images/icon/email-round.png';
import facebookIcon from '../../../assets/images/icon/facebook.png';
import addIcon from '../../../assets/images/icon/add-round.png';
import hideProfileIcon from '../../../assets/images/icon/user-hide.png';
import profileDeleteIcon from '../../../assets/images/icon/profile-delete.png';
import profileTypeIcon from '../../../assets/images/icon/group-profile.png';
import calenderIcon from '../../../assets/images/icon/calender.png';

import IProfile, { IDayTime } from '../../../Interfaces/Profile';
import ITemplateProps from '../../../Interfaces/TemplateProps';
import { addMissingDaysOfWeek, dayTimeObjectArrayToDaytimeObject, sortDaysOfWeek } from '../../../Services/Helpers/DayTimeHelper';
import { convertAnyToHebrew, convertHourToHebrewAndJoin, convertHoursToArrayAndSort } from '../../../Services/Helpers/ConverterService';
import { changeUserRole, removeProfile, toggleProfileStatus, updateAdditionalContact, updateDateTIme as updateDateTime, updateProfile } from '../../../api/profile';
import { IResponse } from '../../../Interfaces/Response';
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';
import { defaultToastConfig } from '../../../Models/Configs/ToasterDefaultConfig';
import { ModalStatus, ProfileStatus } from '../../../Enums/ProfileStatus';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Dropdown, Form, Modal } from 'react-bootstrap';
import { TimeOfDay } from '../../../Enums/TimeOfDay';
import { IDayIndexTimeArray } from '../../../Interfaces/DayIndexTimeArray';
import { getCurrentLanguage } from '../../../Localization/Localize.Service';
import { Lang } from '../../../Enums/Lang';
import { UserRole } from '../../../Enums/UserRole';

import { useDispatch, useSelector } from 'react-redux';
import { iconSet, clearData, publicEmail, nickName, fbName, changeUserIcon, fullName, telephone, email, password, userRole, sportType, loginCheck, ageRange, dob, levelOfPlay, maxDistance, findUs, playArea, location, gamePosition, surfaceType, eachTeamPlayer, jointGroup, latitude, longitude, fitness, city, country, gameTime, profileUpdate, changeGameType } from '../../../store/theme/actions';
import themeReducer, { InitialState } from '../../../store/theme/themeReducer';
import ModalDialog from './ModalDialog';
import { HideProfileOption } from '../../../Enums/HideProfileOption';
import { getDayFromHideProfileOption } from '../../../Services/Generators/ProfileHideDayGenerator';
import { sunday } from '../../../store/theme/actions';
import { monday } from '../../../store/theme/actions';
import { tuesday } from '../../../store/theme/actions';
import { wednesday } from '../../../store/theme/actions';
import { thursday } from '../../../store/theme/actions';
import { friday } from '../../../store/theme/actions';
import { saturday } from '../../../store/theme/actions';
import { DaysOfWeek } from '../../../Enums/DaysOfWeek';
import { useNavigate } from 'react-router-dom';
import { getCorrectTelephoneNumber, getTelephoneNumberDisplayedFormat, isEmailValid, isTelephoneNumberValid } from '../../../Services/Validators/UserProfileValidator';
import { IUserAdditionalInfo } from '../../../Interfaces/UserAdditionalInfo';
import ModalConfirmation from './ModalConfirmation';

function ProfileDetails({ data, settingsMode, dateTimeSettingsMode, addContactEnableMode, userContactInfo,
    onUserContactInfoChange, onDateTimeSettings, onSaveDateTimeSettings, onContactEnableSettings, onAdditionalContactToSave, loadUserData }:
    ITemplateProps<IProfile, boolean, boolean, boolean>) {

    const lang = getCurrentLanguage();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleUserProfileStatus = () => {
        profileStatus === ProfileStatus.PUBLIC ? setShow(true) : setShowConfirmModal({ status: ModalStatus.HIDE, state: true });
    }
    const handleUserProfileRemove = () => {
        setShowConfirmModal({ status: ModalStatus.REMOVE, state: true });
    }

    //Confirmation modal...
    const [showConfirmModal, setShowConfirmModal] = useState({ status: ModalStatus.HIDE, state: false });
    const handleConfirmModalClose = (status: ModalStatus) => setShowConfirmModal({ status: status, state: false });
    const handleConfirmModalSubmit = (status: ModalStatus) => {
        if (status === ModalStatus.HIDE) {
            invokeToggleProfileStatus();
        } else {
            invokeToggleProfileRemove();
        }
        handleConfirmModalClose(status);
    }

    //Additional Contact Modal...
    const [additionalContact, setAdditionalContact] = useState<IUserAdditionalInfo>({
        phone: "",
        email: "",
        fullname: "",
    });
    const [additionalContactModalShow, setAdditionalContactModalShow] = useState(false);
    const additionalContactModalClose = (reloadData: boolean = false) => {
        reloadData ? loadUserData() : initializeAdditionalContactChange();
        setAdditionalContactModalShow(false);
    }


    const dispatch = useDispatch();
    const setUserDataToReduxState = () => {
        if (data && data.user) {
            dispatch(userRole(data.user.userRole === UserRole.PLAYER ? UserRole.LEADER : UserRole.PLAYER));
            dispatch(iconSet(data.user.userImage));
            dispatch(fullName(data.user.fullName));
            dispatch(nickName(data.user.nickName));
            dispatch(telephone(data.user.telephone.substring(3)));
            dispatch(email(data.user.email));
            dispatch(password('Asdf1234'));
            dispatch(fbName(data.user.fbName));
            dispatch(ageRange([]));
            dispatch(dob(''));
            dispatch(levelOfPlay(data.user.levelOfPlay));
            dispatch(maxDistance(''));
            dispatch(findUs(''));
            dispatch(playArea(data.user.playArea));
            dispatch(location(data.user.location));
            dispatch(sportType(data.user.sportType));
            dispatch(gamePosition(''));
            //   dispatch(surfaceType(data.user.surfaceType));
            dispatch(eachTeamPlayer(data.user.eachTeamPlayer));
            dispatch(jointGroup(data.user.joinedGroup));
            dispatch(gameTime(''));
            dispatch(latitude(data.user.latitude));
            dispatch(longitude(data.user.longitude));
            dispatch(publicEmail(data.user.publicEmail));
            dispatch(city(data.user.city));
            dispatch(country(data.user.country));

            for (const key in sortedDayTimes) {
                if (Object.prototype.hasOwnProperty.call(sortedDayTimes, key)) {
                    const value = sortedDayTimes[key];
                    if (key === DaysOfWeek.SUNDAY) {
                        dispatch(sunday(value));
                    }
                    else if (key === DaysOfWeek.MONDAY) {
                        dispatch(monday(value));
                    }
                    else if (key === DaysOfWeek.TUESDAY) {
                        dispatch(tuesday(value));
                    }
                    else if (key === DaysOfWeek.WEDNESDAY) {
                        dispatch(wednesday(value));
                    }
                    else if (key === DaysOfWeek.THURSDAY) {
                        dispatch(thursday(value));
                    }
                    else if (key === DaysOfWeek.FRIDAY) {
                        dispatch(friday(value));
                    }
                    else if (key === DaysOfWeek.SATURDAY) {
                        dispatch(saturday(value));
                    }
                }
            }
        }
    }

    const [dayTimes, setDayTimes] = useState<IDayTime[]>([]);
    const [sortedDayTimes, setSortedDayTimes] = useState<IDayIndexTimeArray[]>([]);

    const [editDayTimes, setEditDayTimes] = useState<IDayTime[]>([]);
    const [sortedEditDayTimes, setSortedEditDayTimes] = useState<IDayIndexTimeArray[]>([]);

    const [fullWeekTimes, setFullWeekTimes] = useState<string[]>([]);

    const [profileStatus, setProfileStatus] = useState<string | null>(null);

    useEffect(() => {
        if (data && data.user) {
            setDayTimes(data.user.dayTime);
            setEditDayTimes(data.user.dayTime);
            setFullWeekTimes([]);
            setProfileStatus(data.user.disable ? ProfileStatus.PRIVATE : ProfileStatus.PUBLIC);
            initializeAdditionalContactChange();
            dispatch(changeGameType(data.user.sportType));
        }
    }, [data]);

    const initializeAdditionalContactChange = () => {
        if (data && data.user && data.user.anotherContactDetails) {
            const userAdditionalInfo: IUserAdditionalInfo = JSON.parse(data.user.anotherContactDetails);
            setAdditionalContact(userAdditionalInfo);
        }
    }

    useEffect(() => {
        if (dayTimes) {
            const convertedDayTimes = dayTimeObjectArrayToDaytimeObject(dayTimes);
            const tempSortedDayTimes = sortDaysOfWeek(convertedDayTimes);
            setSortedDayTimes(tempSortedDayTimes);
        }
    }, [dayTimes]);

    useEffect(() => {
        if (editDayTimes) {
            const convertedEditDayTimes = dayTimeObjectArrayToDaytimeObject(editDayTimes);
            setSortedEditDayTimes(sortDaysOfWeek(addMissingDaysOfWeek(convertedEditDayTimes)));
        }
    }, [editDayTimes]);

    const onDateTimeSettingsModeChange = (mode: boolean) => {
        if (settingsMode || addContactEnableMode) {
            return;
        }

        if (!mode) {
            setEditDayTimes(dayTimes);
        }
        onDateTimeSettings(mode);
        setDaytimePopupShow(false);
    }

    const onContactEnableModeChange = (mode: boolean) => {

        if (settingsMode || dateTimeSettingsMode) {
            return;
        }

        if (!mode) {
            onUserContactInfoChange({ ...userContactInfo, anotherContactDetails: "" });
        }

        onContactEnableSettings(mode);
    }

    const updateDateTimes = () => {

        setDaytimePopupShow(false);

        if (editDayTimes.length === 0 && fullWeekTimes.length === 0) {
            toast.error(Localize.missingFields, defaultToastConfig(3000));
            return;
        }

        const refinedList = JSON.parse(JSON.stringify(sortedEditDayTimes));

        for (const day in refinedList) {
            for (const hour of fullWeekTimes) {
                if (!refinedList[day].includes(hour)) {
                    refinedList[day].push(hour);
                }
            }
        }

        updateDateTime(refinedList).then((result: IResponse<null>) => {
            if (result.statusCode === StatusCodes.OK) {
                toast.success(Localize.detailsHaveBeenUpdated, defaultToastConfig(3000));
                onSaveDateTimeSettings();
            } else {
                toast.error(Localize.errorInAction, defaultToastConfig(3000));
            }
        }).catch(err => {
            toast.error(Localize.errorInAction, defaultToastConfig(3000));
        });
    }

    const invokeToggleProfileStatus = (hideProfileOption: HideProfileOption | null = null) => {

        toggleProfileStatus(hideProfileOption ? getDayFromHideProfileOption(hideProfileOption) : null)
            .then((response: IResponse<{ status: string }>) => {

                if (response.statusCode === StatusCodes.OK) {

                    if (response.data.status === ProfileStatus.PRIVATE) {
                        setProfileStatus(ProfileStatus.PRIVATE);
                    } else {
                        setProfileStatus(ProfileStatus.PUBLIC);
                    }

                    toast.success(Localize.detailsHaveBeenUpdated, defaultToastConfig(3000));
                } else {
                    toast.error(Localize.errorInAction, defaultToastConfig(3000));
                }
            }).catch(err => {
                toast.error(Localize.errorInAction, defaultToastConfig(3000));
            }).finally(() => {
                handleClose();
            });
    }

    // TODO Add functionnality after refactor user / profile
    const invokeToggleProfileRemove = () => {
        removeProfile()
            .then((response: IResponse<{ status: string }>) => {

                if (response.statusCode === StatusCodes.OK) {
                    toast.success(Localize.detailsHaveBeenUpdated, defaultToastConfig(3000));
                } else {
                    toast.error(Localize.errorInAction, defaultToastConfig(3000));
                }
            }).catch(err => {
                toast.error(Localize.errorInAction, defaultToastConfig(3000));
            }).finally(() => {
                // handleClose();
                console.log('success')
            });
    }

    const onClickTimesOfDaysDropDown = (day: string, timeOfDay: string) => {
        const newTimeOfDay: IDayTime = {
            availableDay: day,
            availableHour: timeOfDay
        };

        const isDuplicate = editDayTimes.some((dayTime) =>
            dayTime.availableDay === newTimeOfDay.availableDay && dayTime.availableHour === newTimeOfDay.availableHour
        );

        if (!isDuplicate && ![...fullWeekTimes].includes(timeOfDay)) {
            setEditDayTimes(editDayTimes ? [...editDayTimes, newTimeOfDay] : [newTimeOfDay]);
        }
    }

    const onClickTimesOfAllDaysDropDown = (timeOfDay: string) => {
        if (![...fullWeekTimes].includes(timeOfDay)) {
            setFullWeekTimes([...fullWeekTimes, timeOfDay]);
        }
        setEditDayTimes(editDayTimes.filter(obj => obj.availableHour !== timeOfDay));
    }

    const removeTimeFromDaysList = (day: string, timeOfDay: string) => {
        setEditDayTimes(prevDayTimes => prevDayTimes.filter(dt => dt.availableDay !== day || dt.availableHour !== timeOfDay));
    }

    const removeTimeFromAllDaysList = (timeOfDay: string) => {
        setFullWeekTimes(prevDayTimes => prevDayTimes.filter(availableHour => availableHour !== timeOfDay));
    }

    const onUserRoleChange = () => {

        if (data && data.user.email && data.user.userRole) {

            changeUserRole(data.user.email, data.user.userRole as UserRole)
                .then((response: IResponse<{ existence: boolean }>) => {
                    if (response.statusCode === StatusCodes.OK) {
                        if (response.data.existence) {
                            toast.success(Localize.detailsHaveBeenUpdated, defaultToastConfig(3000));
                            loadUserData();
                        } else {
                            dispatch(profileUpdate('profileUpdate'))
                            setUserDataToReduxState();
                            navigate("/signup");
                        }
                    } else {
                        toast.error(Localize.errorInAction, defaultToastConfig(3000));
                    }
                }
                ).catch(err => {

                    toast.error(Localize.errorInAction, defaultToastConfig(3000));
                });

            return;
        }
        toast.error(Localize.errorInAction, defaultToastConfig(3000));
    }

    const saveAdditionalContact = async () => {
        //Check additional contact...
        let userAdditionalInfoOrigin: IUserAdditionalInfo = {
            phone: '',
            email: '',
            fullname: ''
        }


        if (data && data.user && data.user.anotherContactDetails) {
            userAdditionalInfoOrigin = JSON.parse(data.user.anotherContactDetails);
        }

        let dataToSaved;
        if ((additionalContact.phone.length || additionalContact.email.length) && additionalContact.fullname.length) {

            // Checking phone format and if exist
            if (additionalContact.phone.length) {
                const result = await isTelephoneNumberValid(additionalContact.phone, false);
                if (!result.isSuccess) {
                    result.message ? toast.error(Localize.thisPhoneNumberIsAlreadyInUse, defaultToastConfig(3000)) :
                        toast.error(Localize.missingFields, defaultToastConfig(3000));
                    return;
                }
            }

            // Checking email format and if exist
            if (additionalContact.email.length) {
                const result = await isEmailValid(additionalContact.email, false);
                if (!result.isSuccess) {
                    result.message ? toast.error(Localize.thisEmailAddressIsAlreadyInUse, defaultToastConfig(3000)) :
                        toast.error(Localize.missingFields, defaultToastConfig(3000));
                    return;
                }
            }
        } else if (!additionalContact.fullname.length) {
            toast.error(Localize.missingFields, defaultToastConfig(3000));
            return
        }

        if (!additionalContact.fullname.length && !additionalContact.email.length && !additionalContact.phone.length) {
            dataToSaved = ''
        } else {
            dataToSaved = JSON.stringify({ phone: getCorrectTelephoneNumber(additionalContact.phone), email: additionalContact.email, fullname: additionalContact.fullname })
        }

        updateAdditionalContact(dataToSaved)
            .then((response: IResponse<{ status: string }>) => {
                if (response.statusCode === StatusCodes.OK) {
                    toast.success(Localize.detailsHaveBeenUpdated, defaultToastConfig(3000));
                    additionalContactModalClose(true);
                    return;
                } else {
                    toast.error(Localize.errorInAction, defaultToastConfig(3000));
                }
            }).catch(err => {
                toast.error(Localize.errorInAction, defaultToastConfig(3000));
            });

        additionalContactModalClose();
    }

    // Custom popup ...
    const [daytimePopupShow, setDaytimePopupShow] = useState<boolean>(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [paddingY, setPaddingY] = useState(65);
    const [selectedDayForPopup, setSelectedDayForPopup] = useState<string>('');
    const parentElement = useRef<HTMLDivElement>(null);
    const startElementForPopup = useRef<HTMLDivElement>(null);
    const endElementForPopup = useRef<HTMLDivElement>(null);
    const daytimePopup = useRef<HTMLDivElement>(null);

    useEffect(() => {
        getCorrectPadding();

        const handleResize = () => {
            getCorrectPadding();
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getCorrectPadding = () => {
        if (576 >= window.innerWidth) {
            setPaddingY(380);
        }
        else if (576 < window.innerWidth && window.innerWidth <= 1580) {
            setPaddingY(65);
        }
        else if (window.innerWidth > 1580) {
            setPaddingY(95);
        }
    }

    function handleDayTimePopupTrigger(event: React.MouseEvent<HTMLButtonElement>, day: string) {
        if (daytimePopupShow && day === selectedDayForPopup) {
            setDaytimePopupShow(false);
            return;
        }  

        const paddingX = 0;

        if (
            
            ((data!.user.userRole === UserRole.PLAYER && startElementForPopup && startElementForPopup.current) || data!.user.userRole !== UserRole.PLAYER)
            && endElementForPopup && endElementForPopup.current
            && daytimePopup && daytimePopup.current) {

            const rect1 = event.currentTarget.getBoundingClientRect();
            const rect2 = endElementForPopup.current.getBoundingClientRect();
            const rect3 = daytimePopup.current.getBoundingClientRect();

            const x1 = rect1.x + rect1.width / 2;
            const y1 = rect1.y + rect1.height / 2;
            const x2 = rect2.x + rect2.width / 2;
            const y2 = rect2.y + rect2.height / 2;
            const popUpHeight = 202;

            const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
            const rect = event.currentTarget.getBoundingClientRect();

            if (576 < window.innerWidth) {

                if (distance - rect2.height > popUpHeight) {
                    setPosition({ x: rect.x + paddingX, y: rect.y - paddingY });
                } else {
                    setPosition({ x: rect.x + paddingX, y: rect.y - paddingY - popUpHeight });
                }
            } else {
                setPosition({ x: rect.x + paddingX, y: rect.y - paddingY });
            }
            setDaytimePopupShow(true);
            setSelectedDayForPopup(day);
        }
    }

    const handleDaytimePopupOptionClick = (timeOfDay: string) => {
        if (selectedDayForPopup) {
            onClickTimesOfDaysDropDown(selectedDayForPopup, timeOfDay);
        } else {
            onClickTimesOfAllDaysDropDown(timeOfDay);
        }
        setDaytimePopupShow(false);
    }

    return (
        <>
            <div className="profile-details-wrapper" ref={parentElement}>
                <div className="profile-details-wrapper-child">
                    <div className="profile-social-wrapper">
                        <div className="property-block mb-4">
                            <span className="property-label">{Localize.profileDetails}</span>
                        </div>
                        <div className="profile-info mb-3" title={getTelephoneNumberDisplayedFormat(userContactInfo.telephone) || ""}>
                            <span className="profile-info-icon ms-2">
                                <img src={phoneIcon} alt="" />
                            </span>
                            {!settingsMode && <span className="profile-info-text">{data && data.user.telephone ? getTelephoneNumberDisplayedFormat(data.user.telephone) : ""}</span>}

                            {settingsMode && <input type="text" className="profile-input-decorate"
                                onChange={(event) => { onUserContactInfoChange({ ...userContactInfo, telephone: event.target.value }) }}
                                value={userContactInfo.telephone || ""} />
                            }
                        </div>
                        <div className="profile-info mb-3" title={data && data.user.email ? data.user.email : ""}>
                            <span className="profile-info-icon ms-2">
                                <img src={emailIcon} alt="" />
                            </span>
                            {!settingsMode && <span className="profile-info-text">{data && data.user.email ? data.user.email : ""}</span>}
                            {settingsMode && <input type="email" className="profile-input-decorate"
                                onChange={(event) => { onUserContactInfoChange({ ...userContactInfo, email: event.target.value }) }}
                                value={userContactInfo.email || ""} />
                            }
                        </div>
                        {((data && data.user.fbName) || settingsMode) && <div className="profile-info mb-3" title={data && data.user.fbName ? data.user.fbName : ""}>
                            <span className="profile-info-icon ms-2">
                                <img src={facebookIcon} alt="" />
                            </span>
                            {!settingsMode && <span className="profile-info-text">{data && data.user.fbName ? data.user.fbName : ""}</span>}
                            {settingsMode && <input type="text" className="profile-input-decorate"
                                onChange={(event) => { onUserContactInfoChange({ ...userContactInfo, fbName: event.target.value }) }}
                                value={userContactInfo.fbName || ""} />
                            }
                        </div>}
                        {data && data.user.userRole === UserRole.LEADER && <div className="profile-info mb-1">
                            <span className="profile-info-icon ms-2">
                                <img src={addIcon} className="cursor-pointer" onClick={() => { setAdditionalContactModalShow(!additionalContactModalShow) }} alt="" />
                            </span>
                            {data.user.anotherContactDetails ?
                                <span className="profile-info-text added-contact">{Localize.contactAdded}</span> :
                                <span className="profile-info-text added-contact">{Localize.addedAContact}</span>
                            }

                            {/* Addtional Contact Modal Start */}
                            <Modal show={additionalContactModalShow} onHide={additionalContactModalClose} centered>
                                <Modal.Header>
                                    <Modal.Title className="modal-title">{Localize.additionalContact}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="profile-hide-modal-body additional-contact-modal">
                                    <input type="text" value={additionalContact.phone}
                                        onChange={(event) => { setAdditionalContact({ ...additionalContact, phone: event.target.value }) }}
                                        className='profile-input-decorate' placeholder='מספר טלפון' />
                                    <input type="text" value={additionalContact.email}
                                        onChange={(event) => { setAdditionalContact({ ...additionalContact, email: event.target.value }) }}
                                        className='profile-input-decorate' placeholder='מייל' />
                                    <input type="text" value={additionalContact.fullname}
                                        onChange={(event) => { setAdditionalContact({ ...additionalContact, fullname: event.target.value }) }}
                                        className='profile-input-decorate' placeholder='שם מלא' />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => { additionalContactModalClose(false) }}>
                                        {Localize.close}
                                    </Button>
                                    <Button variant="primary" onClick={saveAdditionalContact}>
                                        {Localize.confirm}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            {/* Modal End */}


                        </div>}

                        {/* {((data && data.user.anotherContactDetails)) && data && data.user.userRole === UserRole.LEADER && <div className="profile-info mb-3" title={data && data.user.anotherContactDetails ? getTelephoneNumberDisplayedFormat(data.user.anotherContactDetails) : ""}>
                            <span className="profile-info-icon ms-2">
                                <img src={phoneIcon} alt="" />
                            </span>
                            {!settingsMode && <span className="profile-info-text">{data && data.user.anotherContactDetails ? getTelephoneNumberDisplayedFormat(data.user.anotherContactDetails) : ""}</span>}
                            {settingsMode && <input type="text" className="profile-input-decorate"
                                onChange={(event) => { onUserContactInfoChange({ ...userContactInfo, anotherContactDetails: event.target.value }) }}
                                value={userContactInfo.anotherContactDetails || ""} />
                            }
                        </div>} */}
                        {/* <div className="profile-info mb-3" title={data && data.user.location ? data.user.location : ""}>
                            <span className="profile-info-icon ms-2">
                                <img src={locationIcon} alt="" />
                            </span>
                            {!settingsMode && <span className="profile-info-text">{data && data.user.location ? data.user.location : ""}</span>}
                            {settingsMode && <input type="text" 
                                onChange={(event) => {setUserProfileInfo({...userProfileInfo, address: event.target.value})} } 
                                value={userProfileInfo.address || ""} />
                            }
                        </div> OLD...*/ }
                        {/* {!(data && data.user.anotherContactDetails) && data && data.user.userRole === UserRole.LEADER && <div className="profile-info mb-1">
                            <span className="profile-info-icon ms-2">
                                <img src={addIcon} className="cursor-pointer" onClick={() => { onContactEnableModeChange(!addContactEnableMode) }} alt="" />
                            </span>
                            {!addContactEnableMode && <span className="profile-info-text added-contact">{Localize.addedAContact}</span>}
                            {addContactEnableMode && <input type="text" className="profile-input-decorate"
                                onChange={(event) => { onUserContactInfoChange({ ...userContactInfo, anotherContactDetails: event.target.value }) }}
                                value={userContactInfo.anotherContactDetails || ""} />
                            }
                        </div>} */}
                        {/* {addContactEnableMode && <div className="profile-info mb-1 cursor-pointer">
                            <div className="button-box profile-additional-contact-button-box">
                                <button type="submit" onClick={() => { onAdditionalContactToSave(true) }}><p>{Localize.confirm}</p></button>
                            </div>
                        </div>} */}
                    </div>
                    <div className="profile-option-wrapper mb-2">
                        <div className="profile-type">
                            <img className="cursor-pointer" src={profileTypeIcon} onClick={onUserRoleChange} alt="" />
                            <div className="text fw-semibold">{Localize.change} <br /> {Localize.profile}</div>
                        </div>
                        <div className="profile-hide">
                            <img className="cursor-pointer" style={profileStatus === ProfileStatus.PRIVATE ? { "opacity": "0.5" } : { "opacity": "1" }}
                                src={hideProfileIcon} onClick={handleUserProfileStatus} alt="" />
                            <div className="text fw-semibold">{profileStatus === ProfileStatus.PRIVATE ? Localize.return : Localize.hide}<br /> {Localize.profile}</div>
                        </div>
                        {/* TODO Add functionnality after refactor user / profile */}
                        {/* <div className="profile-delete">
                            <img className="cursor-pointer" src={profileDeleteIcon} alt="" onClick={handleUserProfileRemove} />
                            <div className="text fw-semibold">{Localize.delete} <br /> {Localize.profile}</div>
                        </div> */}
                    </div>
                    <div className="property-block mb-0 ms-2 me-2 pb-2">
                        <span className="property-label">{ data && data.user.userRole === UserRole.PLAYER ? Localize.freeDaysAndHoursOfPlay : Localize.freeDaysAndHoursOfPlayGroup }</span>
                    </div>
                    <div className="play-time-wrapper">
                        {!dateTimeSettingsMode && sortedDayTimes ?
                            <>
                                <div className="date-time-scroller">
                                    {Object.entries(sortedDayTimes).map(([day, times]) => (
                                        <div className="play-time-value-block">
                                            <div className="play-time-text" key={day}>
                                                <span className="day-span"> {lang === Lang.HEBREW ? convertAnyToHebrew(day) : day}:&nbsp;</span>
                                                <span className="time-join-span"> {convertHourToHebrewAndJoin(times, "-")} </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                            : <></>
                        }
                        {dateTimeSettingsMode && sortedEditDayTimes ?
                            <>
                                <div className="date-time-scroller">
                                    {Object.entries(sortedEditDayTimes).map(([day, times]) => (
                                        <div className="play-time-value-block">
                                            <div className="play-time-text-edit" key={day}>
                                                <span className="day-span">{lang === Lang.HEBREW ? convertAnyToHebrew(day) : day}:</span>
                                                {convertHoursToArrayAndSort(times).map((time: string) => (
                                                    <span className="time-span">{lang === Lang.HEBREW ? convertAnyToHebrew(time) : time}
                                                        <span onClick={() => { removeTimeFromDaysList(day, time) }} className="remove-time-span cursor-pointer"> x </span>
                                                    </span>
                                                ))}
                                            </div>
                                            <Container className="timeOfDays-button not-for-moblie">
                                                <Dropdown>
                                                    <Dropdown.Toggle onClick={(event) => { handleDayTimePopupTrigger(event, day) }} variant="" id="dropdown-basic">
                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {Object.values(TimeOfDay).map((timeOfDay, index) => (
                                                            <Dropdown.Item key={index} onClick={() => { onClickTimesOfDaysDropDown(day, timeOfDay) }}>
                                                                {lang === Lang.HEBREW ? convertAnyToHebrew(timeOfDay) : timeOfDay}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Container>
                                            <Container className="timeOfDays-button only-for-moblie">
                                                <Dropdown className='only-for-moblie'>
                                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {Object.values(TimeOfDay).map((timeOfDay, index) => (
                                                            <Dropdown.Item key={index} onClick={() => { onClickTimesOfDaysDropDown(day, timeOfDay) }}>
                                                                {lang === Lang.HEBREW ? convertAnyToHebrew(timeOfDay) : timeOfDay}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Container>
                                        </div>
                                    ))}
                                    {data && data.user.userRole === UserRole.PLAYER && <div className="play-time-value-block" ref={startElementForPopup}>
                                        <div className="play-time-text-edit">
                                            <span className="day-span">{Localize.fullWeek}:</span>
                                            {convertHoursToArrayAndSort(fullWeekTimes).map((time: string) => (
                                                <span className="time-span">{lang === Lang.HEBREW ? convertAnyToHebrew(time) : time}
                                                    <span onClick={() => { removeTimeFromAllDaysList(time) }} className="remove-time-span cursor-pointer"> x </span>
                                                </span>
                                            ))}
                                        </div>
                                        <Container className="timeOfDays-button not-for-moblie">
                                            <Dropdown>
                                                <Dropdown.Toggle onClick={(event) => { handleDayTimePopupTrigger(event, '') }} variant="" id="dropdown-basic">
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='only-for-moblie'>
                                                    {Object.values(TimeOfDay).map((timeOfDay, index) => (
                                                        <Dropdown.Item key={index} onClick={() => { onClickTimesOfAllDaysDropDown(timeOfDay) }}>
                                                            {lang === Lang.HEBREW ? convertAnyToHebrew(timeOfDay) : timeOfDay}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Container>
                                        <Container className="timeOfDays-button only-for-moblie">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {Object.values(TimeOfDay).map((timeOfDay, index) => (
                                                        <Dropdown.Item key={index} onClick={() => { onClickTimesOfAllDaysDropDown(timeOfDay) }}>
                                                            {lang === Lang.HEBREW ? convertAnyToHebrew(timeOfDay) : timeOfDay}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Container>
                                    </div>}
                                </div>
                            </>
                            : <></>
                        }
                    </div>
                    <div ref={endElementForPopup} className={dateTimeSettingsMode ? "calender-wrapper-edit" : "calender-wrapper"}>
                        <img className="cursor-pointer" onClick={() => { onDateTimeSettingsModeChange(!dateTimeSettingsMode) }} src={calenderIcon} alt="" />
                        <span>{data && data.user.userRole === UserRole.PLAYER ? Localize.updateAvailability : Localize.updateAvailabilityForLeader}</span>
                        {dateTimeSettingsMode && <div className="button-box">
                            <button type="submit" onClick={updateDateTimes}><p>{Localize.confirm}</p></button>
                        </div>}
                    </div>
                </div>
            </div>

            <div className="daytime-modal-wrapper not-for-moblie" ref={daytimePopup}
                style={{ display: daytimePopupShow && 576 < window.innerWidth ? 'block' : 'none', position: 'absolute', top: position.y, left: position.x }}>
                <div className="daytime-modal">
                    {Object.values(TimeOfDay).map((timeOfDay, index) => (
                        <div className="daytime-block" key={index} onClick={() => { handleDaytimePopupOptionClick(timeOfDay) }}>
                            {lang === Lang.HEBREW ? convertAnyToHebrew(timeOfDay) : timeOfDay}
                        </div>
                    ))}
                </div>
            </div>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title className="modal-title">{Localize.forHowLongTimeYouWantToHide}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="profile-hide-modal-body">
                    {Object.values(HideProfileOption).map((option) => (
                        <Button key={option} className="modal-button" onClick={() => invokeToggleProfileStatus(option)}>
                            {Localize[option]}
                        </Button>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {Localize.close}
                    </Button>
                </Modal.Footer>
            </Modal>


            {showConfirmModal.status === ModalStatus.HIDE && <ModalConfirmation isOpen={showConfirmModal} handleClose={handleConfirmModalClose} handleSubmit={handleConfirmModalSubmit} />}
            {/* TODO Add functionnality after refactor user / profile */}
            {/* {showConfirmModal.status === ModalStatus.REMOVE && <ModalConfirmation isOpen={showConfirmModal} handleClose={handleConfirmModalClose} handleSubmit={handleConfirmModalSubmit} />} */}
        </>
    )
}

export default ProfileDetails