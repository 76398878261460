import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Localize from '../../../Localization/Localize';
import { ModalStatus } from '../../../Enums/ProfileStatus';

interface Props {
    isOpen: {status : ModalStatus, state: boolean};
    handleClose: any;
    handleSubmit: any;
  }

const ModalConfirmation: React.FC<Props> = ({isOpen, handleClose, handleSubmit}) => {

    return (
        <>
            <Modal show={isOpen.state} onHide={() => handleClose(isOpen.status)} centered>
                <Modal.Header>
                    <Modal.Title className="modal-title">{Localize.areYouSure}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="profile-hide-modal-body">
                    
                    <Button className="modal-button" onClick={() => handleSubmit(isOpen.status)}>
                        {Localize.YES}
                    </Button>

                    <Button className="modal-button" onClick={() => handleClose(isOpen.status)}>
                        {Localize.NO}
                    </Button>
                    
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalConfirmation