import React, { useState, useEffect } from 'react';
// icon
// google icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGoogle, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faLocationDot, faClock, faArrowRight, faPhone, faEnvelopeOpenText, faCircleXmark, faChevronDown, faClose } from '@fortawesome/free-solid-svg-icons';

// hebrew language
import hebrew from '../../../assets/raw-text/english-hebrew.json'
import searchPage from '../../../assets/raw-text/search.json'
import commonHebrew from '../../../assets/raw-text/common.json';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { changeChooseType, changeSearchOption, changeBackToFilterOption } from '../../../store/theme/actions';
import { InitialState } from '../../../store/theme/themeReducer';
import UserProfile from '../../StyleComponent/UserProfile/UserProfile';
// profile background image
import blackImage from '../../../assets/images/bg-image/blackImage.jpg'
import whiteImage from '../../../assets/images/bg-image/whiteProfile.png'
import { UserRole } from '../../../Enums/UserRole';

import { TimeOfDay } from '../../../Enums/TimeOfDay';

interface ProfileProps {
    userInfo: any;
    closeProfile: () => void;
}
const Profile = ({ userInfo, closeProfile }: ProfileProps) => {

    const [availableDays, setAvailableDays] = React.useState<string[]>([]);
    const [availableHours, setAvailableHours] = React.useState<string[]>([]);

    // redux
    const themeState = useSelector((state: InitialState) => state);
    // english to hebrew
    const convertToHebrew = (key: string) => {
        let stringObj = JSON.stringify(commonHebrew)
        let mainObj = JSON.parse(stringObj)
        return mainObj[key];
    }


    function sortDaysOfWeek(obj: any) {
        const orderedDays = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
        const sortedObj: any = {};

        // Sort the keys of the object by the index of the day in the orderedDays array
        Object.keys(obj).sort((a, b) => orderedDays.indexOf(a) - orderedDays.indexOf(b))
            .forEach((key: any) => {
                sortedObj[key] = obj[key].sort(); // Sort the time slots for each day
            });

        return sortedObj;
    }


    const [daysHourShow, setDaysHour] = useState<any>({})



    useEffect(() => {
        const result: any = {};
        if (userInfo && userInfo.dayTime && userInfo.dayTime.length) {

            userInfo.dayTime.forEach((item: any) => {
                if (!result[item.availableDay]) {
                    result[item.availableDay] = [];
                }
                result[item.availableDay].push(item.availableHour);
            });
        }

        for (let key in result) {
            if (result[key].includes(TimeOfDay.FLEXIBLE))
                result[key] = [TimeOfDay.FLEXIBLE]
        }
        setDaysHour(result)
    }, [userInfo])

    const sortedObj = sortDaysOfWeek(daysHourShow);

    const [hoursTime, setHoursTime] = useState<any>([]);


    function customSort(arr: string[]) {
        const order = [
            'MORNING',
            'NOON',
            'AFTERNOON',
            'EVENING',
            'NIGHT',
            'FLEXIBLE',
            'NOT_CONSTANT'
        ];
        return arr.sort((a: string, b: string) => {
            const indexA = order.indexOf(a);
            const indexB = order.indexOf(b);
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;
            return indexA - indexB;
        });
    }

    const convertHourToHebrew = (freeHours: any) => {
        let tempArray: string[] = [];
        let sortedHours: any = customSort(freeHours)
        for (let i = 0; i < sortedHours.length; i++) {
            const convertValue = convertToHebrew(sortedHours[i])
            tempArray.push(convertValue)
        }
        return tempArray.join(", ")
    }


    return (
        <>
            <div className="userPadding">
                <UserProfile backgroundImage={themeState.gameType === 'FOOTBALL' ? blackImage : whiteImage} textBackground={themeState.gameType === 'FOOTBALL' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)'} textColor={themeState.gameType === 'FOOTBALL' ? "#fff" : "#000"} closeButton={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'}>
                    <div className="profileClose">
                        <button className='btn' onClick={() => closeProfile()}><FontAwesomeIcon icon={faCircleXmark} /></button>
                    </div>
                    <div className="userHeading">
                        {userInfo.nickName && userInfo.nickName.length ?
                            <p>כינוי : <span>{userInfo.nickName}</span></p> :
                            <>{userInfo.fullName && userInfo.fullName.length ? <p>שם מלא : <span>{userInfo.fullName}</span></p> : ""}</>
                        }

                        {userInfo.userRole && userInfo.userRole.length ? <p>סוג משתמש : <span>{convertToHebrew(userInfo.userRole)} </span></p> : ""}
                        {userInfo.sportType && userInfo.sportType.length ? <p>סוג המשחק : <span>{convertToHebrew(userInfo.sportType)}</span></p> : ""}

                    </div>
                    <div className="userDivided">
                        <div className="userDetails">
                            {userInfo.gamePosition && userInfo.gamePosition.length ? <p>{searchPage["PlayOfPosition"]} : <span> {convertToHebrew(userInfo.gamePosition)}</span></p> : ''}
                            {userInfo.userRole && userInfo.userRole === "PLAYER" ? <>{userInfo.surfaceType && userInfo.surfaceType ? <p>{searchPage["SurfaceTYpe"]} :  <span> {convertToHebrew(userInfo.surfaceType)}</span></p> : ''}</> : <>{userInfo.surfaceType && userInfo.surfaceType ? <p>סוג משטח :  <span> {convertToHebrew(userInfo.surfaceType)}</span></p> : ''}</>}

                            {userInfo.ageRange && userInfo.ageRange ? <p>{searchPage["ageRange"]} : <span>{userInfo.ageRange.join(", ")}</span></p> : ''}

                            {/* {userInfo.ageRange.map((data: any) => <span>{data}</span>)} */}

                            {userInfo.age && userInfo.age ? <p>{searchPage["age"]} : <span>{userInfo.age}</span></p> : ''}
                            {userInfo.levelOfPlay && userInfo.levelOfPlay.length ? <p>{searchPage["levelOfPlay"]} : <span>{convertToHebrew(userInfo.levelOfPlay)}</span></p> : ""}



                            {userInfo.userRole && userInfo.userRole === "PLAYER" ?
                                <>{userInfo.eachTeamPlayer && userInfo.eachTeamPlayer.length ? <p>{searchPage["eachTeam"]} : <span>{convertToHebrew(userInfo.eachTeamPlayer)}</span></p> : ""}</> : <></>}


                            {userInfo.userRole && userInfo.userRole === "LEADER" ?
                                <>{userInfo.eachTeamPlayer && userInfo.eachTeamPlayer.length ? <p>כמות שחקנים : <span>{convertToHebrew(userInfo.eachTeamPlayer)}</span></p> : ""}</> : <></>}


                            {userInfo.location &&
                                userInfo.location.length &&
                                userInfo.location.toLowerCase() === userInfo.city.toLowerCase() ?
                                <p>כתובת מדוייקת : <span>{userInfo.city}</span></p> :
                                userInfo.location &&
                                    userInfo.location.length ?
                                    <p>כתובת מדוייקת : <span>{userInfo.location}</span>, &nbsp;
                                        {userInfo.city && userInfo.city.length ? <span>{userInfo.city}</span> : <></>
                                        } </p> : <></>
                            }

                            {/* {userInfo.city && userInfo.city.length ? <span>{userInfo.city}</span> : <></> } */}


                            {userInfo.userRole && userInfo.userRole === "LEADER" && userInfo.playArea && userInfo.playArea.length ? <p>אזור משחק : <span>{userInfo.playArea}</span></p> : <></>}
                            {userInfo.joinedGroup && userInfo.joinedGroup.length ? <p>{searchPage["jointGroup"]} : <span>{convertToHebrew(userInfo.joinedGroup)}</span></p> : ""}
                            {userInfo.fitness && userInfo.fitness.length ? <p> {searchPage["fitness"]} : <span>{convertToHebrew(userInfo.fitness)}</span></p> : ""}

                            {userInfo.payment && userInfo.paymentType ? <p> {searchPage["payment"]} : <span>{userInfo.payment} ₪  {convertToHebrew(userInfo.paymentType)}</span></p> : ""}

                        </div>
                        <div className="daysHour">

                            {userInfo.userRole === "LEADER" ? <h1>זמני משחק</h1> : <h1>זמינות</h1>}



                            {Object.keys(sortedObj).map((day: any) => (
                                <>
                                    <p> {convertToHebrew(day)}:&nbsp; <span> {convertHourToHebrew(daysHourShow[day])} </span> </p>
                                </>

                            ))}

                            {/* {availableDays && availableDays.map((day: string, i: number) => <p> {convertToHebrew(day)}: <span>{convertToHebrew(availableHours[i])}</span> </p>)} */}


                        </div>

                    </div>
                    <div className="socialLinks">
                        <span>פרטי יצירת קשר</span>
                        <div className="socialIcon">
                            <ul>

                                {userInfo.email && userInfo.publicEmail && userInfo.email.length ? <li><a target="_blank" href={`mailto:${userInfo.email && userInfo.email.length ? userInfo.email : ''}`} className='email'><FontAwesomeIcon icon={faEnvelopeOpenText} /></a></li> : ''}
                                {userInfo.telephone && userInfo.telephone.length ? <li><a target="_blank" href={`tel:${userInfo.telephone && userInfo.telephone.length ? userInfo.telephone : ''}`} className='phone'><FontAwesomeIcon icon={faPhone} /></a></li> : ''}
                                {userInfo.fbName && userInfo.fbName.length ? <li><a target="_blank" href="https://www.facebook.com/" className='facebook'><FontAwesomeIcon icon={faFacebookF} /></a></li> : ''}
                                {userInfo.telephone && userInfo.telephone.length ? <li><a target="_blank" href={`https://api.whatsapp.com/send?phone=${userInfo.telephone && userInfo.telephone.length ? userInfo.telephone : ''}&text=${encodeURIComponent('היי מה קורה, הגעתי דרך אתר TapNScore')}`} className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} /></a></li> : ''}

                            </ul>
                        </div>
                    </div>
                    {
                        (userInfo.userRole === UserRole.LEADER && userInfo.anotherContactDetails && userInfo.anotherContactDetails.length) ?
                            <div className="socialLinksAnotherContactDetails">
                                <span>איש קשר נוסף</span>
                                <div className="socialIcon">
                                    <ul>

                                        {JSON.parse(userInfo.anotherContactDetails).email && JSON.parse(userInfo.anotherContactDetails).email.length ? <li><a target="_blank" href={`mailto:${JSON.parse(userInfo.anotherContactDetails).email}`} className='email'><FontAwesomeIcon icon={faEnvelopeOpenText} /></a></li> : ''}
                                        {JSON.parse(userInfo.anotherContactDetails).phone && JSON.parse(userInfo.anotherContactDetails).phone.length ? <li><a target="_blank" href={`tel:${JSON.parse(userInfo.anotherContactDetails).phone}`} className='phone'><FontAwesomeIcon icon={faPhone} /></a></li> : ''}
                                        {JSON.parse(userInfo.anotherContactDetails).phone && JSON.parse(userInfo.anotherContactDetails).phone.length ? <li><a target="_blank" href={`https://api.whatsapp.com/send?phone=${JSON.parse(userInfo.anotherContactDetails).phone}`} className='whatsapp'><FontAwesomeIcon icon={faWhatsapp} /></a></li> : ''}

                                    </ul>
                                </div>
                            </div> : null
                    }

                </UserProfile>
            </div>
        </>
    )
}

export default Profile