import { SportType } from "../../Enums/SportType";
import { OptionType } from "../../Interfaces/OptionType";
import Localize from "../../Localization/Localize";

export function getSportTypeOptions(): OptionType[] {
    return [
        { label: Localize[SportType.FOOTBALL], value: SportType.FOOTBALL },
        { label: Localize[SportType.BASKETBALL], value: SportType.BASKETBALL },
        { label: Localize[SportType.VOLLEYBALL], value: SportType.VOLLEYBALL },
        { label: Localize[SportType.TENNIS], value: SportType.TENNIS },
    ];
}