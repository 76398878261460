import styled from 'styled-components';
type Props = {
    textColor: string,
    background: string
}
export default styled.button<Props>`
color: ${(props) => props.textColor};
border:none;
border-radius: 6px;
background: ${(props) => props.background};
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-align: center;
padding:4px 8px;
margin:1px 4px;
@media screen and (max-width: 1580px) {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    padding: 1px 8px;
    height:20px;
    margin:5px;
}
@media screen and (max-width: 780px) {
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    padding: 2px 8px;
    margin:5px;
}
@media screen and (min-height: 1080px) {
    font-size: 16px;
line-height: 19px;
text-align: center;
padding: 8px 8px;
}
`;