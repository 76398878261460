export enum TeamSize {
    "1-2" = "1-2",
    "T_2" ="2",
    "3-4" = "3-4",
    "T_3" = "3",
    "4-5" = "4-5",
    "T_4" = "4",
    "T_5" = "5",
    "6-7" = "6-7",
    "T_6" = "6",
    "8-10" = "8-10",
    "T_11" = "11",
    SINGLES = "SINGLES",
    DOUBLES = "DOUBLES",
    "DOESN'T MATTER" = "DOESN'T MATTER",
    NOT_CONSTANT = "NOT_CONSTANT",
}