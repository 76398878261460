import React, { useEffect, useState } from 'react';
import Localize from '../../../Localization/Localize';
import infoIcon from '../../../assets/images/icon/info.png';
import IProfile from '../../../Interfaces/Profile';
import { convertToHebrew } from '../Search/Card';
import { SportType } from '../../../Enums/SportType';
import { UserRole } from '../../../Enums/UserRole';
import ITemplateProps from '../../../Interfaces/TemplateProps';
import { MaxDistance } from '../../../Enums/MaxDistance';
import { FrequencyOfPlay } from '../../../Enums/FrequencyOfPlay';
import CustomRadioGroup, { RadioGroup } from '../../StyleComponent/CustomRadioGroup/CustomRadioGroup';
import { AverageGameTime } from '../../../Enums/AverageGameTime';
import { JoinGroup } from '../../../Enums/JoinGroup';
import { PaymentType } from '../../../Enums/PaymentType';
import { GamePosition } from '../../../Enums/GamePosition';
import { SurfaceType } from '../../../Enums/SurfaceType';
import { LevelOfPlay } from '../../../Enums/LevelOfPlay';
import { TeamSize } from '../../../Enums/TeamSize';
import { AgeRange } from '../../../Enums/AgeRange';
import { OptionType } from '../../../Interfaces/OptionType';
import CustomCheckbox from '../../StyleComponent/CustomCheckbox/CustomCheckbox';
import { getSurfaceOptions } from '../../../Services/Generators/SurfaceTypeGenerator';
import { getTeamSizeOptions } from '../../../Services/Generators/TeamSizeGenerator';
import { getMaxDistanceOptions } from '../../../Services/Generators/MaxDistanceGenerator';
import { getFrequencyOfPlayOptions } from '../../../Services/Generators/FrequencyOfPlayGenerator';
import { getAvgPlayingTimeOptions } from '../../../Services/Generators/AvgPlayingTimeGenerator';
import { getJoinGroupOptions } from '../../../Services/Generators/JoinGroupGenerator';
import { getMaxValueForPaymentType, getMinValueForPaymentType, getPaymentTypeOptions, getPaymentTypeOptionsOnlyFree, getPaymentTypeOptionsOnlyPaid } from '../../../Services/Generators/PaymentTypeGenerator';
import { getSportTypeOptions } from '../../../Services/Generators/SportTypeGenerator';
import { getGamePositionOptions } from '../../../Services/Generators/GamePositionGenerator';
import { getLevelOfPlayOptions } from '../../../Services/Generators/LevelOfPlayGenerator';
import { getAgeRangeOptions } from '../../../Services/Generators/AgeRangeOptionsGenerator';
import { getCorrectTelephoneNumber, isEmailValid, isTelephoneNumberValid, maxBirthYear, validateProfile } from '../../../Services/Validators/UserProfileValidator';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LocationSearch from '../../StyleComponent/LocationSearch/LocationSearch';
import { toast } from 'react-toastify';
import { defaultToastConfig } from '../../../Models/Configs/ToasterDefaultConfig';
import { updateProfile } from '../../../api/profile';
import { IResponse } from '../../../Interfaces/Response';
import { StatusCodes } from 'http-status-codes';
import { ILocation } from '../../../Interfaces/Location';
import { IProfileUpdateRequest } from '../../../Interfaces/ProfileUpdateRequest';
import { getDaysInMonth, getISODateTimeFromDayMonthYear } from '../../../Services/Helpers/DayTimeHelper';
import { getCurrentLanguage } from '../../../Localization/Localize.Service';
import { Lang } from '../../../Enums/Lang';
import { ShortValidation } from '../../../Enums/ShortValidation';
import { Popover } from 'react-bootstrap';

function GameDetails({data, settingsMode, dateTimeSettingsMode, addContactEnableMode, userContactInfo, 
    requiredAdditionalContactToSave, onUserContactInfoChange, onSaveSettings, onAdditionalContactToSave, onShowInfoModal}: 
    ITemplateProps<IProfile, boolean, boolean, boolean>) {

    const lang = getCurrentLanguage();

    const [selectedLocation, setSelectedLocation] = useState<ILocation>({
        city: '',
        country: '',
        latitude: '',
        location: '',
        longitude: '',
        playArea: ''
    });
    
    const maxDistanceOptions = getMaxDistanceOptions();
    const [selectedMaxDistanceValues, setSelectedMaxDistanceValues] = useState<string>('');
    const handleMaxDistanceChange = (values: string) => {
        setSelectedMaxDistanceValues(values || '');
    };
    
    const frequencyOfPlayOptions = getFrequencyOfPlayOptions();
    const [selectedFrequencyOfPlayValues, setSelectedFrequencyOfPlayValues] = useState<string>('');
    const handleFrequencyOfPlayChange = (value: string) => {
        setSelectedFrequencyOfPlayValues(value || '');
    };

    const avgPlayingTimeOptions = getAvgPlayingTimeOptions();
    const [selectedAvgPlayingTimeValues, setSelectedAvgPlayingTimeValues] = useState<string>('');
    const handleSelectedAvgPlayingTimeValuesChange = (value: string) => {
        setSelectedAvgPlayingTimeValues(value || '');
    };

    const joinGroupOptions = getJoinGroupOptions();
    const [selectedJoinGroupValues, setSelectedJoinGroupValues] = useState<string>('');
    const handleSelectedJoinGroupValuesChange = (value: string) => {
        setSelectedJoinGroupValues(value || '');
    };

    const [selectedPaymentTypeValues, setSelectedPaymentTypeValues] = useState<string>('');
    const [selectedPaymentValue, setSelectedPaymentValue] = useState<number>(0);

    const handlePaymentTypeValuesChange = (value: string) => {
        setSelectedPaymentTypeValues(value);
        const paymentMinValue = getMinValueForPaymentType(value as PaymentType);
        const paymentMaxValue = getMaxValueForPaymentType(value as PaymentType);
        if (selectedPaymentValue < paymentMinValue) {
            setSelectedPaymentValue(paymentMinValue);
        }
        else if (selectedPaymentValue > paymentMaxValue) {
            setSelectedPaymentValue(paymentMaxValue);
        }
    };

    const sportTypeOptions = getSportTypeOptions();
    const [selectedSportTypeValues, setSelectedSportTypeValues] = useState<string>('');
    const handleSelectedSportTypeValuesChange = (value: string) => {
        setSelectedSportTypeValues(value || '');
        if (data) {
            setSurfaceTypeOptions(getSurfaceOptions(data.user.userRole as UserRole, value as SportType));
            setTeamSizeOptions(getTeamSizeOptions(data.user.userRole as UserRole, value as SportType));
        }
    };

    const gamePositionOptions = getGamePositionOptions();
    const [selectedGamePositionValues, setSelectedGamePositionValues] = useState<string>('');
    const handleSelectedGamePositionValuesChange = (value: string) => {
        setSelectedGamePositionValues(value || '');
    };
    
    //Surface Type.......................................................................................
    const [surfaceTypeOptions, setSurfaceTypeOptions] = useState<OptionType[]>([]);
    const [selectedSurfaceTypeValues, setSelectedSurfaceTypeValues] = useState<string>('');
    const handleSelectedSurfaceTypeValuesChange = (value: string) => {
        setSelectedSurfaceTypeValues(value || '');
    };
    
    const levelOfPlayOptions = getLevelOfPlayOptions();
    const [selectedLevelOfPlayValues, setSelectedLevelOfPlayValues] = useState<string>('');
    const handleSelectedLevelOfPlayValuesChange = (value: string) => {
        setSelectedLevelOfPlayValues(value || '');
    };

    //TeamSize..........................................................................................
    const [teamSizeOptions, setTeamSizeOptions] = useState<OptionType[]>([]);
    const [selectedTeamSizeValues, setSelectedTeamSizeValues] = useState<string>('');
    const handleSelectedTeamSizeValuesChange = (value: string) => {
        setSelectedTeamSizeValues(value || '');
    };

    const ageRangeOptions = getAgeRangeOptions();
    const [selectedAgeRangeValues, setSelectedAgeRangeValues] = useState<string[]>([]);
    const handleSelectedAgeRangeValuesChange = (value: string[]) => {
        setSelectedAgeRangeValues(value);
    };

    const [birthYear, setBirthYear] = useState<number>(0);
    const [birthMonth, setBirthMonth] = useState<number>(1);
    const [birthDate, setBirthDate] = useState<number>(1);

    //Initializing for settings 
    useEffect(() => {
        updateUserSettingsFromOrigialData();
    }, [data]);
    
    const updateUserSettingsFromOrigialData = () => {
        if (data && data.user) {

            setSelectedLocation({
                city: data.user.city,
                country: data.user.country,
                location: data.user.location,
                latitude: data.user.latitude,
                longitude: data.user.longitude,
                playArea: data.user.playArea
            });

            onUserContactInfoChange({
                telephone: data.user.telephone,
                email: data.user.email,
                fbName: data.user.fbName,
                anotherContactDetails: data.user.anotherContactDetails,
            });
            
            if (data.user.maxDistance)
                setSelectedMaxDistanceValues(data.user.maxDistance);
            
            if (data.user.frequencyOfPlay) 
                setSelectedFrequencyOfPlayValues(data.user.frequencyOfPlay);
            
            if (data.user.avgGameDuration) 
                setSelectedAvgPlayingTimeValues(data.user.avgGameDuration);
            
            if (data.user.joinedGroup) 
                setSelectedJoinGroupValues(data.user.joinedGroup);
            
            if (data.user.sportType) {
                setSelectedSportTypeValues(data.user.sportType);
                setSurfaceTypeOptions(getSurfaceOptions(data.user.userRole as UserRole, data.user.sportType as SportType));
                setTeamSizeOptions(getTeamSizeOptions(data.user.userRole as UserRole, data.user.sportType as SportType));
            }
            
            if (data.user.gamePosition) 
                setSelectedGamePositionValues(data.user.gamePosition);
            
            if (data.user.surfaceType) 
                setSelectedSurfaceTypeValues(data.user.surfaceType);

            if (data.user.eachTeamPlayer) 
                setSelectedTeamSizeValues(data.user.eachTeamPlayer);

            if (data.user.levelOfPlay) 
                setSelectedLevelOfPlayValues(data.user.levelOfPlay);
            
            if (data.user.paymentType) 
                setSelectedPaymentTypeValues(data.user.paymentType);

            if (data.user.payment) 
                setSelectedPaymentValue(data.user.payment ? +data.user.payment : 0);

            if (data.user.ageRange) 
                setSelectedAgeRangeValues(data.user.ageRange);

            if (data.user.dob) {
                updateBirthYear();
                updateBirthMonth();
                updateBirthDate();
            }
        } 
    }

    useEffect(() => {
        updateBirthDate(birthDate);
    }, [birthMonth, birthYear]);

    const updateBirthYear = (birthYear: number | null = null) => {
        if (birthYear !== null) {
            if (birthYear < 1) {
                setBirthYear(1);
            }
            else if (maxBirthYear() >= birthYear) {
                setBirthYear(birthYear);
            }
        } else if (birthYear === null && data && data.user && data.user.dob) {
            setBirthYear(+data.user.dob.split("-")[0]);
        }
    }

    const updateBirthMonth = (birthMonthValue: number | null = null) => {

        if (birthMonthValue !== null) {
            if (birthMonthValue < 1) {
                setBirthMonth(1);
            }
            else if (12 < birthMonthValue) {
                setBirthMonth(12);
            } else {
                setBirthMonth(birthMonthValue);
            }
        } else if (birthMonthValue === null && data && data.user && data.user.dob) {
            setBirthMonth(new Date(data.user.dob).getUTCMonth() + 1);
        }
    }

    const updateBirthDate = (birthDate: number | null = null) => {

        const maxDay = getDaysInMonth(birthMonth, birthYear);

        if (birthDate !== null) {
            if (birthDate < 1) {
                setBirthDate(1);
            }
            else if (maxDay < birthDate) {
                setBirthDate(maxDay);
            } else {
                setBirthDate(birthDate);
            }
        } else if (birthDate === null && data && data.user && data.user.dob) {
            setBirthDate(new Date(data.user.dob).getUTCDate());
        }
    }

    useEffect(() => {
        if (!settingsMode) {
            updateUserSettingsFromOrigialData();
        }
    }, [settingsMode]);

    useEffect(() => {
        if (requiredAdditionalContactToSave) {
            onAdditionalContactToSave(false);
            onGameDetaillsAndProfileSave();
        }
    }, [requiredAdditionalContactToSave]);

    const onGameDetaillsAndProfileSave = async () => {

        if (data && data.user.userRole) {

            const refinedTelephone = userContactInfo.telephone.trim();
            const isTelephoneChanged = refinedTelephone !== data.user.telephone;
            let isTelephoneValid = true;
            if (isTelephoneChanged) {
                let result = await isTelephoneNumberValid(refinedTelephone);
                isTelephoneValid = result.isSuccess;
                if (result.message) {
                    toast.error(Localize.thisPhoneNumberIsAlreadyInUse, defaultToastConfig(3000));
                    return;
                }
            }

            const refinedEmail = userContactInfo.email.trim();
            const isEmailChanged = refinedEmail !== data.user.email;
            let isCurrentEmailValid = true;
            if (isEmailChanged) {
                let result = await isEmailValid(refinedEmail);
                isCurrentEmailValid = result.isSuccess;
                if (result.message) {
                    toast.error(Localize.thisEmailAddressIsAlreadyInUse, defaultToastConfig(3000));
                    return;
                }
            }

            const refinedAdditionalContact = userContactInfo.anotherContactDetails ? userContactInfo.anotherContactDetails.trim() : "";
            const isAdditionalContactChanged = refinedAdditionalContact !== data.user.anotherContactDetails;
            let isAdditionalContactValid = true;
            if (isAdditionalContactChanged && refinedAdditionalContact.length > 0) {
                let result = await isTelephoneNumberValid(refinedAdditionalContact);
                isAdditionalContactValid = result.isSuccess;
                if (result.message) {
                    toast.error(Localize.thisPhoneNumberIsAlreadyInUse, defaultToastConfig(3000));
                    return;
                }
            }

            if (refinedTelephone === '' || refinedEmail === '') {
                toast.error(Localize.requiredMandatoryField, defaultToastConfig(3000));
                return;
            }
            const updatedProfile: IProfileUpdateRequest = {
                city: selectedLocation.city,
                country: selectedLocation.country,
                location: selectedLocation.location,
                latitude: selectedLocation.latitude,
                longitude: selectedLocation.longitude,
                playArea: selectedLocation.playArea,
                joinedGroup: selectedJoinGroupValues,
                sportType: selectedSportTypeValues,
                gamePosition: (selectedSportTypeValues === SportType.FOOTBALL && data && data.user.userRole === UserRole.PLAYER) ? selectedGamePositionValues : '',
                surfaceType: selectedSurfaceTypeValues,
                eachTeamPlayer: selectedTeamSizeValues,
                levelOfPlay: selectedLevelOfPlayValues,
                ageRange: selectedAgeRangeValues,
                payment: selectedPaymentTypeValues !== PaymentType.FREE ? selectedPaymentValue : 0,
                paymentType: selectedPaymentTypeValues,
                maxDistance: selectedMaxDistanceValues,
                frequencyOfPlay: selectedFrequencyOfPlayValues,
                avgGameDuration: selectedAvgPlayingTimeValues,
                dob: getISODateTimeFromDayMonthYear(birthDate, birthMonth, birthYear),
                ...(isTelephoneChanged && isTelephoneValid ? { telephone: getCorrectTelephoneNumber(refinedTelephone) } : {}),
                ...(isEmailChanged && isCurrentEmailValid ? { email: refinedEmail } : {}),
                ...(isAdditionalContactChanged && isAdditionalContactValid ? { anotherContactDetails: getCorrectTelephoneNumber(refinedAdditionalContact) } : {}),
                fbName: userContactInfo.fbName || '',
            };

            const isValid = validateProfile(updatedProfile, data.user.userRole as UserRole);

            if (!isValid) {
                toast.error(Localize.missingFields, defaultToastConfig(3000));
                return;
            }

            if (isTelephoneValid && isCurrentEmailValid && isAdditionalContactValid) {

                updateProfile(updatedProfile).then((response: IResponse<null>) => {
                    if (response.statusCode === StatusCodes.OK) {
                        toast.success(Localize.detailsHaveBeenUpdated, defaultToastConfig(3000));
                        onSaveSettings(isEmailChanged && isCurrentEmailValid);
                    } else {
                        toast.error(Localize.errorInAction, defaultToastConfig(3000));
                    }
                }).catch(err => {
                    toast.error(Localize.errorInAction, defaultToastConfig(3000));
                });
            } else {
                toast.error(Localize.missingFields, defaultToastConfig(3000));
            }
        }
    }

    const onChangeLocation = (value: ILocation) => {
        setSelectedLocation({
            city: value.city,
            country: value.country,
            location: value.location,
            latitude: value.latitude,
            longitude: value.longitude,
            playArea: value.playArea
        });
    };
    
    return (
        <>
            <div className="game-details-wrapper-parent" style={{ background: settingsMode === true ? "white" : "" }}>
                <div className="game-details-wrapper">
                    <div className="right-side">
                        <div className="property-block mb-4">
                            <span className="property-label">{Localize.gameType}</span>
                            {!settingsMode && <span className="property-value">{data && data.user.sportType ? 
                                (lang === Lang.HEBREW ? convertToHebrew(data.user.sportType) : data.user.sportType) : ""}</span>}
                            {settingsMode && <div className="mt-2 edit-wrapper">
                                <CustomRadioGroup options={sportTypeOptions} selectedValue={selectedSportTypeValues} 
                                    maxWidth={240} onChange={handleSelectedSportTypeValuesChange} />
                            </div>}
                        </div>
                        {(data && data.user.userRole === UserRole.PLAYER && selectedSportTypeValues === SportType.FOOTBALL) &&
                        <div className="property-block mb-4">
                            <span className="property-label">{Localize.gamePosition}</span>
                            {!settingsMode && <span className="property-value">{data && data.user.gamePosition ? 
                                (lang === Lang.HEBREW ? convertToHebrew(data.user.gamePosition) : data.user.gamePosition) : ""}</span>}
                            {settingsMode && <div className="mt-2 edit-wrapper">
                                <CustomRadioGroup options={gamePositionOptions} selectedValue={selectedGamePositionValues} 
                                    maxWidth={150} onChange={handleSelectedGamePositionValuesChange} />
                            </div>}
                        </div>}
                        <div className="property-block mb-4">
                            <span className="property-label">{Localize.preferredSurfaceType}</span>
                            {!settingsMode && <span className="property-value">{data && data.user.surfaceType ? 
                                (lang === Lang.HEBREW ? convertToHebrew(data.user.surfaceType) : data.user.surfaceType) : ""}</span>}
                            {settingsMode && <div className="mt-2 edit-wrapper">
                                <CustomRadioGroup options={surfaceTypeOptions} selectedValue={selectedSurfaceTypeValues} 
                                    maxWidth={410} onChange={handleSelectedSurfaceTypeValuesChange} />
                            </div>}
                        </div>
                        <div className="property-block mb-4">
                            <span className="property-label">{Localize.PreferredNumberOfPlayersInEachTeam}</span>
                            {!settingsMode && <span className="property-value">{data && data.user.eachTeamPlayer ? 
                                (lang === Lang.HEBREW ? convertToHebrew(data.user.eachTeamPlayer) : data.user.eachTeamPlayer) : ""}</span>}
                            {settingsMode && <div className="mt-2 edit-wrapper">
                                <CustomRadioGroup options={teamSizeOptions} selectedValue={selectedTeamSizeValues} 
                                    maxWidth={0} onChange={handleSelectedTeamSizeValuesChange} />
                            </div>}
                        </div>
                        <div className="property-block mb-4">
                            <span className="property-label">{Localize.levelOfPlay}</span>
                            {!settingsMode && <span className="property-value">{data && data.user.levelOfPlay ? 
                                (lang === Lang.HEBREW ? convertToHebrew(data.user.levelOfPlay) : data.user.levelOfPlay) : ""}</span>}
                            {settingsMode && <div className="mt-2 edit-wrapper">
                                <CustomRadioGroup options={levelOfPlayOptions} selectedValue={selectedLevelOfPlayValues} 
                                    maxWidth={270} onChange={handleSelectedLevelOfPlayValuesChange} />
                            </div>}
                        </div>
                        {(data && data.user.userRole === UserRole.PLAYER) && <div className="property-block">
                            <span className="property-label">{Localize.dateOfBirth}</span>
                            {!settingsMode && <span className="property-value">{birthDate}/{birthMonth}/{birthYear}</span>}
                            {settingsMode && <div className="birthdate-wrapper prevent-select">
                                <div className="birthdate-box">
                                    <div className="sort-button">
                                        <FontAwesomeIcon onClick={() => {updateBirthYear(birthYear + 1)}} icon={faSortUp} />
                                    </div>
                                    <input value={birthYear} min={0} max={maxBirthYear()} 
                                        onChange={(event) => updateBirthYear(+event.target.value)} type="number" />
                                    <div className="sort-button">
                                        <FontAwesomeIcon onClick={() => {updateBirthYear(birthYear - 1)}} icon={faSortDown} />
                                    </div>
                                </div>
                                <div className="birthdate-box">
                                    <div className="sort-button">
                                        <FontAwesomeIcon onClick={() => {updateBirthMonth(birthMonth + 1)}} icon={faSortUp} />
                                    </div>
                                    <input value={birthMonth} min={1} max={12} 
                                        onChange={(event) => updateBirthMonth(+event.target.value)} type="number" />
                                    <div className="sort-button">
                                        <FontAwesomeIcon onClick={() => {updateBirthMonth(birthMonth - 1)}} icon={faSortDown} />
                                    </div>
                                </div>
                                <div className="birthdate-box">
                                    <div className="sort-button">
                                        <FontAwesomeIcon onClick={() => {updateBirthDate(birthDate + 1)}} icon={faSortUp} />
                                    </div>
                                    <input value={birthDate} min={0} max={getDaysInMonth(birthMonth, birthYear)} 
                                        onChange={(event) => updateBirthDate(+event.target.value)} type="number" />
                                    <div className="sort-button">
                                        <FontAwesomeIcon onClick={() => {updateBirthDate(birthDate - 1)}} icon={faSortDown} />
                                    </div>
                                </div>
                            </div>}
                        </div>}
                        {(data && data.user.userRole === UserRole.LEADER) && <div className="property-block mb-4">
                            <span className="property-label">{Localize.ageRange}</span>
                            {!settingsMode && <span className="property-value">{(data && data.user.ageRange.length > 0) ? 
                                data.user.ageRange.join(", ") : ""}</span>}
                            {settingsMode && <CustomCheckbox options={ageRangeOptions} selectedValues={selectedAgeRangeValues} 
                                maxWidth={0} onChange={handleSelectedAgeRangeValuesChange} ></CustomCheckbox>}
                        </div>}
                    </div>
                    <div className="left-side">
                        <div className="property-block mb-4">
                            <span className="property-label">{data && data.user.userRole === UserRole.PLAYER ? Localize.playerLocation : Localize.leaderLocationLabel}</span>
                            {!settingsMode && <span className="property-value">{data && data.user.location ? data.user.location : ""}</span>}
                            {settingsMode && <LocationSearch userRole={data ? data.user.userRole : ""} 
                                currentLocation={data && data.user.location ? data.user.location : ""}
                                playAreaError={undefined} locationError={undefined} 
                                onChangeSearch={onChangeLocation}                           
                            />}
                        </div>
                        {(data && data.user.userRole === UserRole.PLAYER) && <div className="property-block mb-4">
                            <span className="property-label">{Localize.maximumDistanceInKm}</span>
                            {!settingsMode && <span className="property-value">{data && data.user.maxDistance ? 
                                (lang === Lang.HEBREW ? convertToHebrew(data.user.maxDistance) : data.user.maxDistance) : ""}</span>}
                            {settingsMode && <div className="mt-2 edit-wrapper">
                                <CustomRadioGroup options={maxDistanceOptions} selectedValue={selectedMaxDistanceValues} 
                                    maxWidth={290} onChange={handleMaxDistanceChange} />
                            </div>}
                        </div>}
                        {(data && data.user.userRole === UserRole.PLAYER) && <div className="property-block mb-4">
                            <span className="property-label">{Localize.frequencyOfPlay}</span>
                            {!settingsMode && <span className="property-value">{data && data.user.frequencyOfPlay ? 
                                (lang === Lang.HEBREW ? convertToHebrew(data.user.frequencyOfPlay) : data.user.frequencyOfPlay) : ""}</span>}
                            {settingsMode && <div className="mt-2 edit-wrapper">
                                <CustomRadioGroup options={frequencyOfPlayOptions} selectedValue={selectedFrequencyOfPlayValues}
                                    maxWidth={0} onChange={handleFrequencyOfPlayChange}></CustomRadioGroup>
                            </div>}
                        </div>}
                        {(data && data.user.userRole === UserRole.PLAYER) && <div className="property-block mb-4">
                            <span className="property-label">{Localize.playingTimeAverage}</span>
                            {!settingsMode && <span className="property-value">{data && data.user.avgGameDuration ? 
                                (lang === Lang.HEBREW ? convertToHebrew(data.user.avgGameDuration) : data.user.avgGameDuration) : ""}</span>}
                            {settingsMode && <div className="mt-2 edit-wrapper">
                                <CustomRadioGroup options={avgPlayingTimeOptions} selectedValue={selectedAvgPlayingTimeValues}
                                    maxWidth={0} onChange={handleSelectedAvgPlayingTimeValuesChange}></CustomRadioGroup>
                            </div>}
                        </div>}
                        <div className="property-block mb-4">
                            <span className="property-label">{Localize.wouldYouLikeToJoinTheGroupRegularly}</span>
                            {!settingsMode && <span className="property-value">{data && data.user.joinedGroup ? 
                                (lang === Lang.HEBREW ? convertToHebrew(data.user.joinedGroup) : data.user.joinedGroup) : ""}</span>}
                            {settingsMode && <div className="mt-2 edit-wrapper">
                                <CustomRadioGroup options={joinGroupOptions} selectedValue={selectedJoinGroupValues}
                                    maxWidth={0} onChange={handleSelectedJoinGroupValuesChange}></CustomRadioGroup>
                            </div>}
                        </div>
                        {(data && data.user.userRole === UserRole.LEADER) && <div className="property-block mb-4">
                            <span className="property-label">{Localize.for}</span>
                            {!settingsMode && <span className="property-value">{data && data.user.paymentType ? 
                                (lang === Lang.HEBREW ? convertToHebrew(data.user.paymentType) : data.user.paymentType) : ""}</span>}
                            {settingsMode && <div className="mt-2 edit-wrapper">
                                {/* <CustomCheckbox options={paymentTypeOptionsOnlyFree} selectedValues={[selectedPaymentTypeValues]}
                                    onChange={handleSelectedPaymentTypeValuesChange}></CustomCheckbox> */}

                                <div className="custom-payment-type-wrapper-free">
                                    <div className="custom-chcekbox-wrapper">
                                        <label className="checkbox-container" key={PaymentType.FREE}>
                                            <input type="checkbox" checked={selectedPaymentTypeValues === PaymentType.FREE} 
                                                onChange={() => {
                                                    handlePaymentTypeValuesChange(selectedPaymentTypeValues === PaymentType.FREE ? PaymentType.PER_SEASON : PaymentType.FREE);
                                                }} />
                                            {Localize.FREE}
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                {(selectedPaymentTypeValues !== PaymentType.FREE) && <div className="custom-payment-type-wrapper-paid">
                                    <div className="custom-chcekbox-wrapper">
                                        <div className="button-box-square">
                                            <button className={selectedPaymentTypeValues === PaymentType.PER_GAME ? "active-square" : ""}
                                            onClick={() => {handlePaymentTypeValuesChange(PaymentType.PER_GAME)}}
                                            type="submit">{Localize[PaymentType.PER_GAME]}</button>
                                        </div>
                                        <div className="button-box-square">
                                            <button className={selectedPaymentTypeValues === PaymentType.PER_MONTH ? "active-square" : ""}
                                            onClick={() => {handlePaymentTypeValuesChange(PaymentType.PER_MONTH)}}
                                            type="submit">{Localize[PaymentType.PER_MONTH]}</button>
                                        </div>
                                        <div className="button-box-square">
                                            <button className={selectedPaymentTypeValues === PaymentType.PER_SEASON ? "active-square" : ""}
                                            onClick={() => {handlePaymentTypeValuesChange(PaymentType.PER_SEASON)}}
                                            type="submit">{Localize[PaymentType.PER_SEASON]}</button>
                                        </div>
                                    </div>
                                </div>}
                            </div>}
                        </div>}
                        {(data && data.user.userRole === UserRole.LEADER && selectedPaymentTypeValues !== PaymentType.FREE) && 
                            <div className="property-block mb-4">
                            <span className="property-label">{Localize.paymentToThePlayerForRentingTheField}</span>
                            {!settingsMode && <span className="property-value">{selectedPaymentValue || 0}₪</span>}
                            {settingsMode && <div className="edit-wrapper">
                                <div className="priceRange-box">
                                    <div className="range">
                                        <input type="range" onChange={(event) => {setSelectedPaymentValue(+event.target.value)}} 
                                            min={getMinValueForPaymentType(selectedPaymentTypeValues as PaymentType)} 
                                            max={getMaxValueForPaymentType(selectedPaymentTypeValues as PaymentType)} 
                                            step={5} value={selectedPaymentValue} 
                                            className="custom-slider"/>
                                        <div style={{ width: `${(selectedPaymentValue - getMinValueForPaymentType(selectedPaymentTypeValues as PaymentType)) * 
                                            (100 / (getMaxValueForPaymentType(selectedPaymentTypeValues as PaymentType) - getMinValueForPaymentType(selectedPaymentTypeValues as PaymentType)))}%`, height: "20px" }} 
                                            className="sliderLine"></div>
                                    </div>
                                </div>
                                <span className="property-value payment-property-value">{selectedPaymentValue || 0}₪</span>
                            </div>}
                        </div>}
                    </div>
                </div>
                <div className="info-wrapper">
                    {!settingsMode && <div className="info-box">
                        <img className="cursor-pointer" onClick={onShowInfoModal} src={infoIcon} alt="" />
                        <span>{Localize.moreInfo}</span>
                    </div>}
                    {settingsMode && <div className="button-box">
                        <button onClick={onGameDetaillsAndProfileSave} type="submit"><p>{Localize.confirm}</p></button>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default GameDetails