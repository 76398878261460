import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-slideshow-image/dist/styles.css';
import './assets/font/Dina_TalBal.ttf';
import env from "ts-react-dotenv";
// import ReactGA from "react-ga4";
import { initializeLocalization } from './Localization/Localize.Service';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
//Initialize GA4
// ReactGA.initialize("G-3P041R628B");
initializeLocalization();

//testing
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// useEffect(()=>{
//   ReactGA.pageview({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// },[])

// useEffect(() => {
//   ReactGA.pageview(window.location.pathname + window.location.search);
// }, []);


// ReactGA.send({
//   hitType: "pageview",
//   page: window.location.pathname,
// });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
