import styled from 'styled-components';
type Props = {
    textColor: string,
    disableTextColor: string
}

export default styled.div<Props>`
position: absolute;
bottom: 15px;
padding: 0px 44px;
left: 0px;
width: 100%;


.paginationLine{
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
    align-items: center;
}
.paginationLine p {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: ${(props) => props.textColor};
    margin-bottom: 0px;
}

.paginationLine button {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 95.8%;
    text-align: center;
    color: ${(props) => props.textColor};
    text-decoration: underline;
}
.paginationLine button:hover{
    color: ${(props) => props.textColor};
}

.paginationLine .prevDisable {
    pointer-events: none;
    color:  ${(props) => props.disableTextColor};;
    
}
@media (max-width:778px){
    bottom:8px;
}
`;