
import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk'
const persistConfig = {
  key: 'persistvalue',
  version: 1,
  storage,
}
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import themeReducer from './theme/themeReducer';


const persistedReducer = persistReducer(persistConfig, themeReducer)


const store=createStore(persistedReducer,applyMiddleware(ReduxThunk));
const persistor=persistStore(store);
export default store;
export {persistor}