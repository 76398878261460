import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Component/Common/Login/Login";
import Search from "./Component/Common/Search/Search";
import PrivateRoute from "./api/PrivateRoute";
import NotAvailable from "./Component/Common/NotAvailable/NotAvailable";
import Signup from "./Component/Common/Signup/Signup";
import TermsAndConditions from "./Component/Common/TermsAndConditions/TermsAndConditions";
import Profile from "./Component/Common/Profile/Profile";
import History from "./Component/Common/History/History";
import AboutUs from "./Component/Common/AboutUs/";

const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path='/search' element={<Search />}></Route>
          </Route>
          <Route path='/' element={<Login />}></Route>
          <Route path='/signup' element={<Signup />}></Route>
          <Route path='/login' element={<Login />}></Route>
          <Route element={<PrivateRoute />}>
            <Route path='/profile' element={<Profile />}></Route>
          </Route>
          <Route path='/log_history' element={<History />}></Route>
          {/* <Route path="/log_history" element={<NotAvailable />}></Route> */}
          <Route path='/terms' element={<TermsAndConditions />}></Route>
          <Route path='/about_us' element={<AboutUs />}></Route>
          <Route path='*' element={<NotAvailable />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
