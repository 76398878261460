import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Dropdown } from 'react-bootstrap';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { payment, paymentType, findUs } from '../../../store/theme/actions';
import themeReducer, { InitialState } from '../../../store/theme/themeReducer';
import SignUpButton from '../../StyleComponent/SignUpButton/SignUpButton';
// import 'toolcool-range-slider';
// import 'toolcool-range-slider/dist/plugins/tcrs-storage.min.js';
// import { RangeSlider } from 'toolcool-range-slider';
// import 'toolcool-range-slider';
import RangeSlider from './RangeSlider';


const ThumbComp = () => {
    return (
        <div
            style={{

                width: 30,
                height: 30,
                background: "#FFFFFF",
                borderRadius: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <div
                style={{
                    width: 16,
                    height: 16,
                    backgroundColor: "#100159",
                    borderRadius: "100%"
                }}
            ></div>
        </div>
    );
};
const ProgressComp = () => {
    return (
        <div
            style={{
                width: '100%',
                height: 20,
                borderRadius: 25,
                background:
                    "linear-gradient(90deg, #100159  0%, #0080E8, #100159 100%)"
            }}
        ></div>
    );
};




interface PaymentProps {
    setPaymentCollapse: any;
    paymentCollapse: any;
    paymentLine: () => void;
    paymentCollapseSet: () => void;
}


const Payment = ({ paymentCollapseSet, setPaymentCollapse, paymentCollapse, paymentLine }: PaymentProps) => {

    const themeState = useSelector((state: InitialState) => state);
    const dispatch = useDispatch();

    const [findUsValue, setFindUs] = useState("");


    const [paymentValue, setPaymentValue] = useState("");
    const [paymentTypeValue, setPaymentTypeValue] = useState("");

    const findUsHandler = (value: string) => {
        dispatch(findUs(value));
    }
    React.useEffect(() => {
        findUsHandler(findUsValue)
    }, [findUsValue]);


    const paymentTypeHandler = (value: string) => {
        dispatch(payment(value));
    }
    React.useEffect(() => {
        paymentTypeHandler(paymentValue)
    }, [paymentValue]);

    const paymentHandler = (value: string) => {
        dispatch(paymentType(value));
    }
    React.useEffect(() => {
        paymentHandler(paymentTypeValue)
    }, [paymentTypeValue]);


    const [minValue, setMinValue] = useState<number>()
    const [maxValue, setMaxValue] = useState<number>()

    const [sliderValue, setSliderValue] = useState<number>(10);


    const [activeButton, setActiveButton] = useState('monthly');

    const [daysPrice, setPrice] = useState(false)
    const [monPrice, setMonPrice] = useState(true)
    const [seasonPrice, setSeasonPrice] = useState(false)



    const [dayRange, setDayRange] = useState(10);
    const [monthlyRange, setMonthlyRange] = useState(100);
    const [seasonRange, setSeasonRange] = useState(100);



    const [dayError, setDayError] = useState("");
    const [monthlyError, setMonthlyError] = useState("");
    const [yearlyError, setYearlyError] = useState("");



    const playClick = (priceRange: string) => {
        if (priceRange === "play") {
            setDayRange(10)
            setPaymentTypeValue("PER_GAME")
            setActiveButton('play')
            setPrice(true)
            setMonPrice(false)
            setSeasonPrice(false)
            setPaymentValue("10")
        }
        if (priceRange === "monthly") {
            setMonthlyRange(100)
            setPaymentTypeValue("PER_MONTH")
            setActiveButton('monthly')
            setPrice(false)
            setMonPrice(true)
            setSeasonPrice(false)
            setPaymentValue("100")
        }
        if (priceRange === "answer") {
            setSeasonRange(100)
            setPaymentTypeValue("PER_SEASON")
            setActiveButton('answer')
            setPrice(false)
            setMonPrice(false)
            setSeasonPrice(true)
            setPaymentValue("100")
        }
    }

    useEffect(() => {
        setPaymentTypeValue("PER_MONTH");
        setPaymentValue("100")
        setMaxValue(400);
        setMinValue(100)
    }, [])



    const [freeMoney, setFreeMoney] = useState(true)
    const freeMoneyFunction = () => {
        setFreeMoney(!freeMoney)
        setPaymentTypeValue("FREE")
        setPaymentValue("0")
    }


    const newSliderValue = (e: any) => {
        setSliderValue(e.target.value)
    }

    useEffect(() => {

    }, [sliderValue])


    // days price

    const changeDayRange = (event: any) => {
        setPaymentTypeValue("PER_GAME")
        setDayRange(parseInt(event.target.value));
        setPaymentValue(`${parseInt(event.target.value)}`)
    };

    const dayInputHandle = (event: any) => {

        if (event.target.value < 10 || isNaN(event.target.value)) {
            setDayRange(10)
            setDayError("הערך הוא 10 - 10")
        } else if (parseInt(event.target.value) > 100) {
            setDayRange(100)
            event.target.value=100
            setDayError("הערך הוא 100 - 100")
        }

        setDayRange(parseInt(event.target.value));
    };



    const changeMonthlyRange = (event: any) => {
        setPaymentTypeValue("PER_MONTH")
        setMonthlyRange(parseInt(event.target.value));
        setPaymentValue(`${parseInt(event.target.value)}`)
    };
    const [fixMonth, setFixMonth] = useState(0)
    const monthlyInputHandle = (event: any) => {



        if (event.target.value < 100 || isNaN(event.target.value)) {
            setMonthlyRange(parseInt(event.target.value));
            setMonthlyError("הערך הוא 100 - 400")
        } else if (parseInt(event.target.value) >= 400) {
            event.target.value=400
            setMonthlyError("הערך הוא 100 - 400")
        }
        else {
            setMonthlyError("")
            setMonthlyRange(parseInt(event.target.value));
        }

      



    };



    // season price

    const changeSeasonRange = (event: any) => {
        setPaymentTypeValue("PER_SEASON")
        setSeasonRange(parseInt(event.target.value));
        setPaymentValue(`${parseInt(event.target.value)}`)
    };

    const seasonHandle = (event: any) => {
        if (event.target.value < 100 || isNaN(event.target.value)) {
            setSeasonRange(100)
            setYearlyError("הערך הוא 100 - 1000")
        } else if (parseInt(event.target.value) > 1000) {
            setSeasonRange(1000)
            event.target.value=1000
            setYearlyError("הערך הוא 100 - 1000")
        }
        setSeasonRange(parseInt(event.target.value));
    };



    return (
        <>
            <div className="gameDetails">
                <button className='collapseHeading'
                    onClick={() => paymentCollapseSet()}
                    aria-controls="example-collapse-text"
                    aria-expanded={paymentCollapse}
                >
                    <span>תשלום</span> <FontAwesomeIcon icon={faAnglesDown} />
                </button>
                <Collapse className='collapseBody' in={paymentCollapse}>
                    <div className="paymentDetailsBox">
                        <div className="freePayment">
                            <div className="checkBox">
                                <div className="check">
                                    <div className="roundCircle">
                                        <input type="checkbox" id="PaymentCheckbox" onClick={freeMoneyFunction} />
                                        <label htmlFor="PaymentCheckbox">  <span> עלות חינמית   </span></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {freeMoney ? <>
                            <div className="paymentType">
                                <h1>עבור</h1>
                            </div>
                            <div className='paymentTypeButton'>
                                <button onClick={() => playClick("play")} className={activeButton === "play" ? 'activeShow' : ''}>משחק</button>
                                <button onClick={() => playClick("monthly")} className={activeButton === "monthly" ? 'activeShow' : ''}>חודש</button>
                                <button onClick={() => playClick("answer")} className={activeButton === "answer" ? 'activeShow' : ''}>עונה</button>
                            </div>
                            <div className="paymentTypeTile">
                                <h1>תשלום לשחקן עבור השכרת המגרש</h1>
                            </div>
                            <div className="priceRange">

                                {daysPrice ? <>
                                    <div className="range">
                                        <input
                                            type="range"
                                            onChange={changeDayRange}
                                            min={10}
                                            max={100}
                                            step={5}
                                            value={dayRange}
                                            className="custom-slider"
                                        ></input>
                                        <div
                                            style={{ width: `${(dayRange / 1) - 6}%`, height: "20px" }}
                                            className="sliderLine"
                                        ></div>
                                    </div>
                                    <div className="rangePrice">
                                        <p>₪<input min={10} type="number" value={dayRange} onChange={dayInputHandle} /></p>
                                        {dayError && dayError.length ? dayError : ''}
                                    </div>
                                </> : <></>}
                                {monPrice ? <>
                                    <div className="range">
                                        <input
                                            type="range"
                                            onChange={changeMonthlyRange}
                                            min={100}
                                            max={400}
                                            step={5}
                                            value={monthlyRange}
                                            className="custom-slider"
                                        ></input>
                                        <div
                                            style={{ width: `${(monthlyRange - 100) * 0.328}%`, height: "20px" }}
                                            className="sliderLine"
                                        ></div>
                                    </div>
                                    <div className="rangePrice">
                                        <p>₪<input min={10} max={400} type="number" value={monthlyRange} onChange={monthlyInputHandle} /></p>
                                        {monthlyError && monthlyError.length ? monthlyError : ''}
                                    </div>
                                </> : <></>}
                                {seasonPrice ? <>
                                    <div className="range">
                                        <input
                                            type="range"
                                            onChange={changeSeasonRange}
                                            min={100}
                                            max={1000}
                                            step={5}
                                            value={seasonRange}
                                            className="custom-slider"
                                        ></input>
                                        <div
                                            style={{ width: `${(seasonRange / 10) - 6}%`, height: "20px" }}
                                            className="sliderLine"
                                        ></div>
                                    </div>
                                    <div className="rangePrice">
                                        <p>₪<input min={0} type="number" value={seasonRange} onChange={seasonHandle} /></p>
                                        {yearlyError && yearlyError.length ? yearlyError : ''}
                                    </div>
                                </> : <></>}



                            </div>

                        </> : <></>}


                        <SignUpButton buttonBorder='#FB730C' buttonBackground='#FB730C;'>
                            <div className="mainBtn">
                                <button type='submit' onClick={paymentLine}>
                                    <p>המשך</p>
                                </button>
                            </div>
                        </SignUpButton>

                    </div>
                </Collapse>
            </div>
        </>
    )
}

export default Payment