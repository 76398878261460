import './popupWithCheckbox.css';
import React, { useState } from 'react';
import Button from '../../StyleComponent/Button/Button';
import { InitialState } from '../../../store/theme/themeReducer';
import { useSelector } from 'react-redux';

interface ITemplateProps {
    bodyText: JSX.Element;
    checkboxText: JSX.Element | null;
    buttonText: JSX.Element;
    isChecked: boolean | null;
    closePopup: () => void;
    onCheckboxClick: ((isChecked: boolean) => void) | null;
}

function PopupWithCheckbox({ bodyText, checkboxText, buttonText, isChecked, closePopup, onCheckboxClick }: ITemplateProps) {
    const themeState = useSelector((state: InitialState) => state);
    return (
        <div className="checkbox-popup-wrapper disable-select">
            {/* <div className="close-button">
                <div className="close-icon cursor-pointer" onClick={closePopup}>
                    x
                </div>
            </div> */}
            <div className="text-wrapper">
                {bodyText}
            </div>
            {checkboxText && onCheckboxClick && isChecked !== null ?
                (<div className="checkbox-wrapper">
                    <input type="checkbox"
                        onChange={(event) => onCheckboxClick(!isChecked)}
                        name="customCheckbox" value="checkboxValue" checked={isChecked} /> <small>{checkboxText}</small>
                </div>) : null
            }
            <div className="submit-button">
                {/* <button onClick={closePopup}>אשר</button> */}

                <Button buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                    <div className="mainBtn">
                        <button id={"customSearch"} onClick={closePopup}>
                            <p id={"customSearch"}>{buttonText}</p>
                        </button>
                    </div>
                </Button>
            </div>
        </div>
    )
}

export default PopupWithCheckbox