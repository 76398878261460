

import styled from 'styled-components';

type Props = {
  backgroundColor: string
}

export default styled.div<Props>`
.removeMulti{
    position: absolute;
    left: 0px;
    top: 0px;
    cursor: pointer;
    width: 15px;
    height: 15px;
    text-align: center;
    background: ${(props) => props.backgroundColor};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}
.removeMulti svg{
    color: #fff;
    font-size: 11px;
}
`;