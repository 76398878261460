import { DaysOfWeek } from "../../Enums/DaysOfWeek";
import { Lang } from "../../Enums/Lang";
import { TimeOfDay } from "../../Enums/TimeOfDay";
import { IDayTime } from "../../Interfaces/Profile";
import { convertAnyToHebrew } from "./ConverterService";

export function dayTimeObjectArrayToDaytimeObject(dayTimes: IDayTime[]) {
    return dayTimes.reduce((dayTime: any, index: any) => {
        dayTime[index.availableDay] = dayTime[index.availableDay] || [];
        dayTime[index.availableDay].push(index.availableHour);
        return dayTime;
    }, {});
}

export function sortDaysOfWeek(daysOfWeek: any) {
    const orderedDays = Object.values(DaysOfWeek) as string[];
    const sortedObj: any = {};

    Object.keys(daysOfWeek).sort((a, b) => orderedDays.indexOf(a) - orderedDays.indexOf(b))
        .forEach((key: string) => {
            sortedObj[key] = daysOfWeek[key].sort();
        });

    return sortedObj;
}

export function sortDayTimes(arr: string[]) {
    const order = Object.values(TimeOfDay) as string[];
    
    return arr.sort((a: string, b: string) => {
        const indexA = order.indexOf(a);
        const indexB = order.indexOf(b);
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
    });
}

export const addMissingDaysOfWeek = (daysOfWeek: Record<string, string[]>) => {
    const daysOfWeekValues = Object.values(DaysOfWeek);
  
    for (const dayOfWeek of daysOfWeekValues) {
      if (!(dayOfWeek in daysOfWeek)) {
        daysOfWeek[dayOfWeek] = [];
      }
    }
  
    return daysOfWeek;
}

export const getDaysInMonth = (monthIndex: number, birthYear: number): number => {
    const date = new Date();
    date.setFullYear(birthYear);
    date.setMonth(monthIndex); 
    date.setDate(1);
    date.setHours(-1);
    return date.getDate();
}

export const getISODateTimeFromDayMonthYear = (day: number, month: number, year: number): string => {
    const date = new Date(Date.UTC(year, month - 1, day)); // Create a new Date object in UTC
    date.setUTCHours(18); // Set the hours to 18 (UTC time)
    return date.toISOString();
}

export const getTimeOnly12HoursFormat = (dateTime: string) =>  //dateTime:'2023-04-15T17:59:38.240Z' -> output: 12 hours format
    new Date(dateTime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

export const getTimeOnly24HoursFormat = (dateTime: string) => { //dateTime:'2023-04-15T17:59:38.240Z' -> output: 24 hours format
    const dateObj = new Date(dateTime);
    return dateObj.getHours().toString().padStart(2, '0') + ':' + dateObj.getMinutes().toString().padStart(2, '0');
}

export const getDateOnly = (dateTime: string) =>  
    new Date(dateTime).toISOString().substring(0, 10);

export const formatDate = (dateString: string): string => {
    const dateParts = dateString.split("-");
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    return `${day}/${month}/${year}`;
}

export const shortenDays = (days: string[], lang: string): string => {
    if (days.length === 0) {
        return "";
    }
    if (days.length === 7) {
        return lang === Lang.HEBREW ? "זמינות מלאה" : "Full Week";
    }
    if (lang === Lang.HEBREW) {
        return (days.map(day => {
            const shortenSubstring = convertAnyToHebrew(day)[convertAnyToHebrew(day).length-1]
            if (shortenSubstring === 'ת') {
                return 'שבת'
            } else return convertAnyToHebrew(day)[convertAnyToHebrew(day).length-1]
        }).join(','));
    } else {
        return (days.map(day => day[0]).join(','));
    }
}