import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Dropdown } from 'react-bootstrap';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { ageRange, fitness, city, country, location, dob, latitude, longitude, levelOfPlay, frequencyOfPlay, maxDistance, gameTime, playArea, jointGroup } from '../../../store/theme/actions';
import themeReducer, { InitialState } from '../../../store/theme/themeReducer';
import SignUpButton from '../../StyleComponent/SignUpButton/SignUpButton';

import DatePicker from 'react-date-picker';
import PlacesAutocomplete from 'react-places-autocomplete';

import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';
import commonHebrew from '../../../assets/raw-text/common.json';
interface gameDetailsProps {
    setSecondCollapse: any;
    secondCollapse: any;
    secondLine: () => void;
    secondCollapseSet: () => void;
    playAreaError: any;
    levelOfPlayError: any;
    frequencyOfPlayError: any;
    maxDistanceError: any;
    gameTimeError: any;
    jointGroupError: any;
    ageRangeError: any;
    locationError: any;
    playDobError: any;
    fitnessError: any;
    dobValidation: any;
    setDobValidation: any;
    dobError: any;
    setConditionMap: any;
    conditionMap: any
}
const GameDetails = ({ conditionMap, setConditionMap, dobError, dobValidation, setDobValidation, fitnessError, playDobError, locationError, ageRangeError, jointGroupError, gameTimeError, maxDistanceError, frequencyOfPlayError, levelOfPlayError, playAreaError, secondCollapseSet, setSecondCollapse, secondCollapse, secondLine }: gameDetailsProps) => {

    const themeState = useSelector((state: InitialState) => state);
    const dispatch = useDispatch();



    const [monthState, setMonthlyState] = useState("")
    const [dayState, setDayState] = useState("")
    const [yearState, setYearState] = useState("")






    const [levelPlay, setLevelPlay] = useState(themeState.levelOfPlay && themeState.levelOfPlay ? themeState.levelOfPlay : "");
    const [frequencyValue, setFrequencyValue] = useState(themeState.frequencyOfPlay && themeState.frequencyOfPlay ? themeState.frequencyOfPlay : "");
    const [maxDistanceValue, setMaxDistanceValue] = useState(themeState.maxDistance && themeState.maxDistance ? themeState.maxDistance : "");
    const [gameTimeValue, setGameTimeValue] = useState(themeState.gameTime && themeState.gameTime ? themeState.gameTime : "");
    const [ageRangeValue, setAgeRangeValue] = useState<any>(themeState.ageRange && themeState.ageRange ? themeState.ageRange : []);
    const [playAreaValue, setPlayAreaValue] = useState(themeState.playArea && themeState.playArea ? themeState.playArea : "");
    const [jointGroupValue, setJointGroup] = useState(themeState.jointGroup && themeState.jointGroup ? themeState.jointGroup : "");
    const [fitnessChooseValue, setFitnessChoose] = useState(themeState.fitness && themeState.fitness.length ? themeState.fitness : "");
    const [playerDistrict, setPlayerDistrict] = useState({})

    const [cityName, setCityName] = useState(themeState.city && themeState.city ? themeState.city : "");
    const [countryName, setCountryName] = useState(themeState.country && themeState.country ? themeState.country : "");
    // location address lat lng
    const [locationMark, setLocationMark] = useState(themeState.location && themeState.location ? themeState.location : '')

    const [locationMain, setLocationMain] = useState<any>([]);

    const [latitudeValue, setLatitudeValue] = useState<any>(themeState.latitude && themeState.latitude ? themeState.latitude : "")
    const [longitudeValue, setLongitudeValue] = useState<any>(themeState.longitude && themeState.longitude ? themeState.longitude : "")



    const [address, setAddress] = useState(themeState.location && themeState.location ? themeState.location : '')

    const [jointActive, setJointActive] = useState("")
    const moment = require('moment');
    const [dobValue, dateChange] = useState(new Date());
    const dobDate = `${yearState}-${monthState}-${dayState}`

    const [showLevel, setShowLevel] = useState("")
    const [showFrequency, setShowFrequency] = useState("")
    const [showMaxDistance, setShowMaxDistance] = useState("")
    const [showGameTime, setShowGameTime] = useState("")
    const [showAgeRange, setShowAgeRange] = useState<any>(themeState.ageRange && themeState.ageRange.length ? themeState.ageRange : [])
    const [showPlayArea, setShowPlayArea] = useState("")
    const [showFitness, setShowFitness] = useState(themeState.fitness && themeState.fitness.length ? themeState.fitness : "")
    const [showJointGroup, setShowJointGroup] = useState("")



    useEffect(() => {

    }, [yearState, monthState, dayState])


    function validateDate(dateString: any) {
        const parts = dateString.split('-');
        if (parts[1].length < 2 || parts[2].length < 2) {
            dateString = `${parts[0]}-${String(parts[1]).padStart(2, '0')}-${String(parts[2]).padStart(2, '0')}`;
        }
        const inputDate = new Date(dateString);
        const currentDate = new Date();
        const thirteenYearsAgo = new Date(currentDate.getFullYear() - 13, currentDate.getMonth(), currentDate.getDate());
        const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31);

        if (inputDate <= thirteenYearsAgo && inputDate <= lastDayOfYear) {
            return true;
        } else {
            return false;
        }
    }
    setDobValidation(validateDate(dobDate))



    // level Of Play
    const levelOfPlayHandler = (value: string) => {
        dispatch(levelOfPlay(value));
    }
    React.useEffect(() => {
        levelOfPlayHandler(levelPlay)
    }, [levelPlay]);

    // frequency of play
    const frequencyHandler = (value: string) => {
        dispatch(frequencyOfPlay(value));
    }
    React.useEffect(() => {
        frequencyHandler(frequencyValue)
    }, [frequencyValue]);

    // max distance
    const maxDistanceHandler = (value: string) => {
        dispatch(maxDistance(value));
    }
    React.useEffect(() => {
        maxDistanceHandler(maxDistanceValue)
    }, [maxDistanceValue]);

    // game time
    const gameTimeHandler = (value: string) => {
        dispatch(gameTime(value));
    }
    React.useEffect(() => {
        gameTimeHandler(gameTimeValue)
    }, [gameTimeValue]);

    // age Range
    const ageRangeHandler = (value: string) => {
        dispatch(ageRange(value));
    }
    React.useEffect(() => {
        ageRangeHandler(ageRangeValue)
    }, [ageRangeValue]);

    // game time
    const playAreaHandler = (value: string) => {
        dispatch(playArea(value));
    }
    React.useEffect(() => {
        playAreaHandler(playAreaValue)
    }, [playAreaValue]);

    // joint group
    const jointHandler = (value: string) => {
        dispatch(jointGroup(value));
    }
    React.useEffect(() => {
        jointHandler(jointGroupValue)
    }, [jointGroupValue]);

    // location
    const locationHandler = (value: string) => {
        dispatch(location(value));
    }
    React.useEffect(() => {
        locationHandler(address)
    }, [address]);

    // latitude
    const latitudeHandler = (value: string) => {
        dispatch(latitude(value));
    }
    React.useEffect(() => {
        latitudeHandler(latitudeValue)
    }, [latitudeValue]);

    // longitude
    const longitudeHandler = (value: string) => {
        dispatch(longitude(value));
    }
    React.useEffect(() => {
        longitudeHandler(longitudeValue)
    }, [longitudeValue]);


    // dob
    const dobHandler = (value: string) => {
        dispatch(dob(value));
    }
    React.useEffect(() => {
        dobHandler(dobDate)
    }, [dobDate]);

    // city
    const cityHandler = (value: string) => {
        dispatch(city(value));
    }
    React.useEffect(() => {
        cityHandler(cityName)
    }, [cityName]);

    // country
    const countryHandler = (value: string) => {
        dispatch(country(value));
    }
    React.useEffect(() => {
        countryHandler(countryName)
    }, [countryName]);

    // fitness
    const fitnessHandler = (value: string) => {
        dispatch(fitness(value));
    }
    React.useEffect(() => {
        fitnessHandler(fitnessChooseValue)
    }, [fitnessChooseValue]);





    const levelPlayChoose = [
        { levelOfPlaySize: "AMATEUR", levelOfPlayName: "חובבנית" },
        { levelOfPlaySize: "INTERMEDIATE", levelOfPlayName: "בינונית" },
        { levelOfPlaySize: "ADVANCED", levelOfPlayName: "מתקדמת" },
        { levelOfPlaySize: "PROFESSIONAL", levelOfPlayName: "מקצועית" },
    ];
    const frequencyChoose = [
        { frequencySize: "ONCE_A_MONTH", frequencyName: "פעם בחודש" },
        { frequencySize: "ONCE_A_WEEK", frequencyName: "פעם בשבוע" },
        { frequencySize: "2-3_TIMES_A_MONTH", frequencyName: "2-3 פעמים בחודש" },
        { frequencySize: "2-3_TIMES_A_WEEK", frequencyName: "2-3 פעמים בשבוע" },
        { frequencySize: "ALMOST_EVERY_DAY", frequencyName: "כמעט כל יום" },

    ];
    const maxDistanceChoose = [
        { maxDistanceSize: "UP_TO_5KM", maxDistanceName: "עד 5 ק״מ" },
        { maxDistanceSize: "UP_TO_10KM", maxDistanceName: "עד 10 ק״מ" },
        { maxDistanceSize: "UP_TO_20KM", maxDistanceName: "עד 20 ק״מ" },
        { maxDistanceSize: "OVER_20KM", maxDistanceName: "מעל 20 ק״מ" },
    ];
    const gameTimeChoose = [
        { gameTimeSize: "TO_AN_HOUR", gameTimeName: "עד שעה" },
        { gameTimeSize: "BETWEEN_ONE_AND_TWO_HOURS", gameTimeName: "בין שעה לשעתיים" },
        { gameTimeSize: "OVER_TWO_HOURS", gameTimeName: "מעל לשעתיים" }
    ];
    const ageRangeChoose = [
        { ageSizeSize: "14-17", ageName: "14-17" },
        { ageSizeSize: "18-29", ageName: "18-29" },
        { ageSizeSize: "30-49", ageName: "30-49" },
        { ageSizeSize: "50+", ageName: "50+" }
    ];
    const playAreaChoose = [
        { playAreaSize: "CENTRAL_ZONE", playAreaName: "אזור המרכז" },
        { playAreaSize: "TEL_AVIV_AND_THE_SURROUNDING_AREA", playAreaName: "תל אביב והסביבה" },
        { playAreaSize: "NORTH", playAreaName: "צפון" },
        { playAreaSize: "SOUTH", playAreaName: "דרום" },
        { playAreaSize: "JERUSALEM", playAreaName: "ירושלים" },
        { playAreaSize: "JUDEA_AND_SAMARIA", playAreaName: "יהודה ושומרון" }
    ];
    const fitnessChoose = [
        { fitnessValue: "LESS", fitnessName: "פחותה" },
        { fitnessValue: "OK", fitnessName: "סבירה" },
        { fitnessValue: "GOOD", fitnessName: "טובה" },
        { fitnessValue: "VERY_GOOD", fitnessName: "טובה מאוד" },
        { fitnessValue: "EXCELLENT", fitnessName: "מצוינת" }
    ];

    const jointGroupChoose = [
        { jointGroupEnum: "YES", jointGroupName: "כן" },
        { jointGroupEnum: "NO", jointGroupName: "לא" },
        { jointGroupEnum: "DOESN'T MATTER", jointGroupName: "לא משנה" },

    ];


    const levelOfPlayCategory = (levelOfPlaySize: any, levelOfPlayName: any) => {
        setLevelPlay(levelOfPlaySize);
        setShowLevel(levelOfPlayName)
    }
    const frequencyCategory = (frequencySize: any, frequencyName: any) => {
        setFrequencyValue(frequencySize);
        setShowFrequency(frequencyName)
    }
    const maxDistanceCategory = (maxDistanceSize: any, maxDistanceName: any) => {
        setMaxDistanceValue(maxDistanceSize);
        setShowMaxDistance(maxDistanceName)
    }
    const gameTimeCategory = (gameTimeSize: any, gameTimeName: any) => {
        setGameTimeValue(gameTimeSize);
        setShowGameTime(gameTimeName)
    }


    const fn = (newAge: string) => {
        let reduxData = themeState.ageRange
        const temp1 = new Set([...reduxData, newAge]);
        let arr = Array.from(temp1);
        return arr;
    }

    const ageRangeCategory = (ageSizeSize: any, ageName: any) => {
        setAgeRangeValue([...ageRangeValue, ageSizeSize]);
        setShowAgeRange([...showAgeRange, ageName])

        let newData = fn(ageSizeSize)
        setAgeRangeValue(newData)
        setShowAgeRange(newData)
    }


    const playAreaCategory = (playAreaSize: any, playAreaName: any) => {
        setPlayAreaValue(playAreaSize);
        setShowPlayArea(playAreaName)
    }
    const fitnessCategory = (fitnessValue: any, playAreaName: any) => {
        setFitnessChoose(fitnessValue);
        setShowFitness(playAreaName)
    }
    // joint group
    const jointGroupCategory = (jointGroupEnum: any, jointGroupName: any) => {
        setJointGroup(jointGroupEnum);
        setShowJointGroup(jointGroupName)
    }

    // joint group
    const jointGroupFunction = () => {
        return jointGroupChoose.map((data, i: number) => (
            <div key={`navbar + ${i}`}>
                {data.jointGroupName === jointGroupValue ? null :
                    <div key={`navbar + ${i}`}>
                        <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => jointGroupCategory(data.jointGroupEnum, data.jointGroupName)}>

                            <p>{data.jointGroupName}</p>
                        </Dropdown.Item>
                    </div>}
            </div>

        ))
    }

    // level of play
    const levelOfPlayFunction = () => {
        return levelPlayChoose.map((data, i: number) => (
            <div key={`navbar + ${i}`}>
                {data.levelOfPlayName === levelPlay ? null :
                    <div key={`navbar + ${i}`}>
                        <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => levelOfPlayCategory(data.levelOfPlaySize, data.levelOfPlayName)}>

                            <p>{data.levelOfPlayName}</p>
                        </Dropdown.Item>
                    </div>}
            </div>

        ))
    }
    // frequency of play
    const frequencyOfPlayFunction = () => {
        return frequencyChoose.map((data, i: number) => (
            <div key={`navbar + ${i}`}>
                {data.frequencyName === frequencyValue ? null :
                    <div key={`navbar + ${i}`}>
                        <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => frequencyCategory(data.frequencySize, data.frequencyName)}>

                            <p>{data.frequencyName}</p>
                        </Dropdown.Item>
                    </div>}
            </div>

        ))
    }
    // max Distance
    const maxDistanceFunction = () => {
        return maxDistanceChoose.map((data, i: number) => (
            <div key={`navbar + ${i}`}>
                {data.maxDistanceName === maxDistanceValue ? null :
                    <div key={`navbar + ${i}`}>
                        <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => maxDistanceCategory(data.maxDistanceSize, data.maxDistanceName)}>

                            <p>{data.maxDistanceName}</p>
                        </Dropdown.Item>
                    </div>}
            </div>

        ))
    }

    // game time
    const gameTimeFunction = () => {
        return gameTimeChoose.map((data, i: number) => (
            <div key={`navbar + ${i}`}>
                {data.gameTimeName === gameTimeValue ? null :
                    <div key={`navbar + ${i}`}>
                        <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => gameTimeCategory(data.gameTimeSize, data.gameTimeName)}>

                            <p>{data.gameTimeName}</p>
                        </Dropdown.Item>
                    </div>}
            </div>

        ))
    }

    // play area
    // game time
    const playAreaFunction = () => {
        return playAreaChoose.map((data, i: number) => (
            <div key={`navbar + ${i}`}>
                {data.playAreaName === playAreaValue ? null :
                    <div key={`navbar + ${i}`}>
                        <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => playAreaCategory(data.playAreaSize, data.playAreaName)}>

                            <p>{data.playAreaName}</p>
                        </Dropdown.Item>
                    </div>}
            </div>

        ))
    }






    // age range
    const ageRangeFunction = () => {

        return ageRangeChoose.map((data, i: number) => (
            <div key={`navbar + ${i}`}>
                {data.ageName === gameTimeValue ? null :
                    <div key={`navbar + ${i}`}>
                        <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => ageRangeCategory(data.ageSizeSize, data.ageName)}>
                            <p>{data.ageName}</p>
                        </Dropdown.Item>
                    </div>}
            </div>

        ))
    }


    // fitness
    const fitnessFunction = () => {
        return fitnessChoose.map((data, i: number) => (
            <div key={`navbar + ${i}`}>
                {data.fitnessName === fitnessChooseValue ? null :
                    <div key={`navbar + ${i}`}>
                        <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => fitnessCategory(data.fitnessValue, data.fitnessName)}>

                            <p>{data.fitnessName}</p>
                        </Dropdown.Item>
                    </div>}
            </div>

        ))
    }





    const handleChange = (address: any) => {
        setLocationMark(address);
    };





    const handleSelect = async (address: any) => {

        // console.log('address :', address)
        setLocationMark(address);

        geocodeByAddress(address)
            .then(results => {
              
                getLatLng(results[0])
                    .then((result) => {
                        const { lat, lng } = result;
                        setLatitudeValue(lat)
                        setLongitudeValue(lng)
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                // console.log('results :', results)


                const full_addresses_key = ['establishment', 'point_of_interest', 'tourist_attraction', 'route', "street_number"];
                const addresses_key = ['route', "street_number"];
                const addressComponents = results[0].address_components;

                // Formating the address string <specific> (park, establishment, ...),  <street_number> <street>
                const address_part = (addressComponents.filter(i => i['types'].some(r => full_addresses_key.includes(r)))).map(j => (j['types'].some(r => addresses_key.includes(r))) ? j['short_name'] : `${j['short_name']},`)
                const address = address_part.join(' ');

                // Formatting area, city, country
                const area = addressComponents.find(i => i['types'].includes('administrative_area_level_1') || i['types'].includes('administrative_area_level_2'))
                const city = addressComponents.find(i => i['types'].includes('locality'))
                const country = addressComponents.find(i => i['types'].includes('country'))

                // console.log('area :', area)
                // console.log('city :', city)
                // console.log('country :', country)
                // console.log('address :', address)

                if (!address.length && !city) {
                    setConditionMap("נא לרשום כתובת יותר מדוייקת בבקשה")
                } else if (!address.length && city && country) {
                    setAddress(city['long_name'])
                    setCityName(city['long_name'])
                    setCountryName(country['long_name'])
                } else if (address.length && city && country) {
                    setAddress(address)
                    setCityName(city['long_name'])
                    setCountryName(country['long_name'])
                }
                if (area) {
                    setPlayAreaValue(area['long_name']);
                } else setPlayAreaValue('לא מוגדר');
            })

        // geocodeByAddress(address)
        //     .then(results => getLatLng(results[0]))
        //     .then(latLng => { setLatitudeValue(latLng.lat); setLongitudeValue(latLng.lng) })
        //     .catch(error => console.error('Error', error));

      

    };


    // date



    const months = ['ינואר', 'פברואר', 'מרץ', 'אפריל',
        'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר',
        'נובמבר', 'דצמבר'];
    const [allYear, setAllYear] = useState<any>([])
    const [allDay, setAllDay] = useState<any>([])
    const [selectedYear, setSelectedYear] = useState<any>(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState<any>(new Date().getMonth() + 1);
    const [selectedDay, setSelectedDay] = useState<any>(new Date().getDate())



    const populateYears = () => {
        //Get the current year as a number
        let year = new Date().getFullYear();

        let tempArr = []
        //Make the previous 100 years be an option
        for (let i = 0; i < 101; i++) {
            tempArr.push(year - i);
        }
        setAllYear(tempArr);
        setShowRangeYearArray(tempArr.slice((1 - 1) * 30, 1 * 30));
    }

    const populateDays = (month: string) => {

        let dayNum;

        if (month === 'ינואר' || month === 'מרץ' ||
            month === 'מאי' || month === 'יולי' || month === 'אוגוסט'
            || month === 'אוקטובר' || month === 'דצמבר') {
            dayNum = 31;
        } else if (month === 'אפריל' || month === 'יוני'
            || month === 'ספטמבר' || month === 'נובמבר') {
            dayNum = 30;
        } else {
            //Check for a leap year
            if (new Date(selectedYear, 1, 29).getMonth() === 1) {
                dayNum = 29;
            } else {
                dayNum = 28;
            }
        }
        //Insert the correct days into the day <select>
        let tempArr = []
        for (let i = 1; i <= dayNum; i++) {
            tempArr.push(i)
        }
        setAllDay(tempArr)

    }


    useEffect(() => {

        if (selectedMonth) {
            populateDays(months[selectedMonth - 1])
        }
    }, [selectedMonth])






    const changeDate = (value: string) => {
        let str = String(value)

        if (str.length === 1) {
            setDayState("0" + (value))
        }
        else {
            setDayState(String(value))
        }
        setSelectedDay(value)
    }


    const changeMonth = (value: number) => {
        let str = String(value)

        if (str === '9') {
            setMonthlyState("10")
        }

        else if (str.length === 1) {

            if (value <= 8) {
                setMonthlyState("0" + (value + 1))
            }
            // else {
            //     setMonthlyState(String(value + 1))
            // }


        }
        else {
            if (value <= 8) {
                setMonthlyState("0" + (value + 1))
            }
            else {
                setMonthlyState(String(value + 1))
            }

        }
        setSelectedMonth(value + 1)
    }




    const changeYear = (value: number) => {
        setSelectedYear(value)
        setYearState(String(value))
    }

    const [showRangeYearArray, setShowRangeYearArray] = useState<any>([])
    const [pageNumber, setPageNumber] = useState<any>(1);

    const [disablePag, setDisablePag] = useState(false)

    const paginate = (page_number: number) => {


        if (page_number > 0 && page_number <= 4) {
            let tempArray = allYear;
            setShowRangeYearArray(tempArray.slice((page_number - 1) * 30, page_number * 30));
        }



    }

    const dateMin = 0;
    const dateMax = 31;

    const monthMin = 0;
    const monthMax = 12;

    const dayInput = (e: any) => {
        const value = Math.max(dateMin, Math.min(dateMax, Number(e.target.value)));

        setDayState(`${value}`)

        // if(e.target.value.length === 1){
        //     setDayState(`${value}`)
        // }
        // else{
        //     setDayState(`${value}`)
        // }

    }
    const monthInput = (e: any) => {
        const value = Math.max(monthMin, Math.min(monthMax, Number(e.target.value)));
        setMonthlyState(`${value}`)
    }
    const yearInput = (e: any) => {
        setYearState(e.target.value)
    }

    useEffect(() => {
        paginate(pageNumber)
    }, [pageNumber])

    useEffect(() => {
    }, [showRangeYearArray])


    useEffect(() => {
        paginate(pageNumber)
    }, [allYear])

    useEffect(() => {
        populateYears()


        let str = String(new Date().getMonth() + 1)
        if (str.length === 1) {
            setMonthlyState("0" + str)
        }
        else {
            setMonthlyState(String(str))
        }


        str = String(new Date().getDate())
        if (str.length === 1) {
            setDayState("0" + str)
        }
        else {
            setDayState(String(str))
        }

        str = String(new Date().getFullYear())

        setYearState(str)





    }, [])

    const handelPageNumber = (page: number) => {
        if (page > 0 && page <= 4) {
            setPageNumber(page)
        }
        // if(page === 0){
        //     setDisablePag(true)
        // }
        // else{

        // }
    }

    const removeAge = (data: any, index: number) => {


        let deleteValue = ((oldValues: any) => {
            const newArr = oldValues.filter((item: any) => item !== data)
            return newArr
        })
        setShowAgeRange(deleteValue)
        setAgeRangeValue(deleteValue)

    }

    // english to hebrew
    const convertToHebrew = (key: string) => {
        let stringObj = JSON.stringify(commonHebrew)
        let mainObj = JSON.parse(stringObj)
        return mainObj[key];
    }

    return (
        <>

            <div className="gameDetails">



                <button className='collapseHeading'
                    onClick={() => secondCollapseSet()}
                    aria-controls="example-collapse-text"
                    aria-expanded={secondCollapse}
                >
                    <span>פרטי המשחק</span> <FontAwesomeIcon icon={faAnglesDown} />
                </button>
                <Collapse className='collapseBody' in={secondCollapse}>
                    <div className="gameDetailsBox">
                        {themeState.userRole === "LEADER" ? <><div className="normalSignUpWithIcon">
                            {themeState.ageRange && themeState.ageRange.length ? <p className='dropdownTopAnimation'>טווח גילאים</p> : ""}
                            <Dropdown className='arrowDown mt-3'>
                                <Dropdown.Toggle className={showAgeRange && showAgeRange.length ? 'arrowBtn ageRangeDrop' : 'arrowBtn'} split id="dropdown-custom-2">
                                    {themeState.ageRange && themeState.ageRange.length > 0 ? <>
                                        <p className='ageRangeItem'>
                                            {showAgeRange.map((data: any, index: number) => <span className='ageItem'>
                                                {data} <span className='removeAgeRange' onClick={() => removeAge(data, index)}>X</span> </span>)} </p>
                                    </> : <>טווח גילאים</>}

                                    <FontAwesomeIcon icon={faChevronDown} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="super-colors dropDownItem">
                                    {ageRangeFunction()}
                                </Dropdown.Menu>
                            </Dropdown>
                            {ageRangeError && ageRangeError.length ? <p className='errorMsg'>{ageRangeError}</p> : ""}
                        </div></> : <>
                            <div className="datePicker">
                                <div className="dateFormatheading">
                                    <h1>תאריך לידה</h1>
                                </div>
                                <div className="dateFormat">
                                    <Dropdown>
                                        <Dropdown.Toggle className='yearlySelect' id="dropdown-basic">
                                            <input type="text" value={yearState} onChange={yearInput} />
                                            <span><FontAwesomeIcon icon={faChevronDown} /></span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className='yearDropdown'> {showRangeYearArray && showRangeYearArray.length && showRangeYearArray.map((year: any,) => {
                                                return <Dropdown.Item href="#" onClick={() => changeYear(year)}>{year}</Dropdown.Item>
                                            })}
                                                <div className="paginateButton">

                                                    <button onClick={() => handelPageNumber(pageNumber - 1)}>הַבָּא</button>
                                                    <button onClick={() => handelPageNumber(pageNumber + 1)}>הקודם</button>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown>
                                        <Dropdown.Toggle className='monthSelect' id="dropdown-autoclose-true">

                                            <input type="text" value={monthState} onChange={monthInput} min={1} max={12} />
                                            <span><FontAwesomeIcon icon={faChevronDown} /></span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className='monthDropdown'> {months && months.length && months.map((m: any, i: any) => {
                                                return <Dropdown.Item href="#" onClick={() => changeMonth(i)}>{m}</Dropdown.Item>
                                            })}</div>
                                        </Dropdown.Menu>

                                    </Dropdown>
                                    <Dropdown>
                                        <Dropdown.Toggle className='daySelect' id="dropdown-autoclose-true">
                                            <input type="text" value={dayState} onChange={dayInput} min={1} max={31} />
                                            <span><FontAwesomeIcon icon={faChevronDown} /></span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu >
                                            <div className='dayDropdown'>
                                                {allDay && allDay.length && allDay.map((d: any, i: any) => {
                                                    return <Dropdown.Item href="#" onClick={() => changeDate(d)}>{d}</Dropdown.Item>
                                                })}
                                            </div>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            {dobError && dobError.length ? <p className='errorMsg'>{dobError}</p> : ""}
                            {playAreaError && playDobError.length ? <p className='errorMsg'>{playDobError}</p> : ""}
                        </>}


                        {/* level of play */}
                        <div className="normalSignUpWithIcon mobileLevelOfPlay mt-2">
                            {themeState.levelOfPlay && themeState.levelOfPlay.length ? <p className='dropdownTopAnimation'>רמת משחק</p> : ""}
                            <Dropdown className='arrowDown'>
                                <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                                    {themeState.levelOfPlay && themeState.levelOfPlay ? convertToHebrew(themeState.levelOfPlay) :
                                        <>{showLevel && showLevel.length ? showLevel : "רמת משחק"}</>}
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="super-colors dropDownItem">
                                    {levelOfPlayFunction()}
                                </Dropdown.Menu>
                            </Dropdown>
                            {levelOfPlayError && levelOfPlayError.length ? <p className='errorMsg'>{levelOfPlayError}</p> : ""}
                        </div>

                        {/* frequency of play */}
                        {themeState.userRole === "PLAYER" ? <>
                            <div className="normalSignUpWithIcon">
                                {themeState.frequencyOfPlay && themeState.frequencyOfPlay.length ? <p className='dropdownTopAnimation'>רמת משחק</p> : ""}
                                <Dropdown className='arrowDown'>
                                    <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                                        {themeState.frequencyOfPlay && themeState.frequencyOfPlay ? convertToHebrew(themeState.frequencyOfPlay) :
                                            <> {showFrequency && showFrequency.length ? showFrequency : "תדירות משחק"}</>}
                                        <FontAwesomeIcon icon={faChevronDown} /></Dropdown.Toggle>
                                    <Dropdown.Menu className="super-colors dropDownItem">
                                        {frequencyOfPlayFunction()}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {frequencyOfPlayError && frequencyOfPlayError.length ? <p className='errorMsg'>{frequencyOfPlayError}</p> : ""}
                            </div>
                        </> : <></>}



                        {/* location */}

                        <div className="normalSignUpWithIcon locationMap mb-2">

                            {locationMark && locationMark.length ? <p className='dropdownTopAnimation'>מיקום המגרש</p> : ""}
                            <PlacesAutocomplete
                                value={locationMark}
                                onChange={handleChange}
                                onSelect={handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <>

                                        {themeState.userRole === "PLAYER" ?
                                            <input
                                                {...getInputProps({
                                                    placeholder: "מיקום שלי",
                                                    className: 'mapInput mt-1',
                                                })}
                                                required
                                            />
                                            :
                                            <input
                                                {...getInputProps({
                                                    placeholder: "מיקום המגרש",
                                                    className: 'mapInput mt-1',
                                                })}
                                                required
                                            />



                                        }

                                        {conditionMap && conditionMap.length ? <p className='errorMsg'>{conditionMap}</p> : ""}

                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion: any) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <p>{suggestion.description}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>


                                )}
                            </PlacesAutocomplete>
                            {locationError && locationError.length ? <p className='errorMsg'>{locationError}</p> : ''}
                        </div>

                        {/* joint group */}
                        {themeState.userRole === "LEADER" ? <div className="normalSignUpWithIcon">
                            {themeState.jointGroup && themeState.jointGroup.length ? <p className='dropdownTopAnimation'>האם תרצה לצרף שחקנים באופן קבוע?</p> : ""}
                            <Dropdown className='arrowDown'>
                                <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                                    {themeState.jointGroup && themeState.jointGroup ? convertToHebrew(themeState.jointGroup) :
                                        <>{showJointGroup && showJointGroup.length ? showJointGroup : "האם תרצה לצרף שחקנים באופן קבוע?"} </>}

                                    <FontAwesomeIcon icon={faChevronDown} /></Dropdown.Toggle>
                                <Dropdown.Menu className="super-colors dropDownItem">
                                    {jointGroupFunction()}
                                </Dropdown.Menu>
                            </Dropdown>
                            {jointGroupError && jointGroupError.length ? <p className='errorMsg'>{jointGroupError}</p> : ""}
                        </div> : <></>}

                        {/* max distance */}
                        {themeState.userRole === "PLAYER" ? <div className="normalSignUpWithIcon">
                            {themeState.maxDistance && themeState.maxDistance.length ? <p className='dropdownTopAnimation'>מרחק מקסימלי בק״מ</p> : ""}
                            <Dropdown className='arrowDown'>
                                <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                                    {themeState.maxDistance && themeState.maxDistance ? convertToHebrew(themeState.maxDistance) :
                                        <> {showMaxDistance && showMaxDistance.length ? showMaxDistance : "מרחק מקסימלי בק״מ"}</>}
                                    <FontAwesomeIcon icon={faChevronDown} /></Dropdown.Toggle>
                                <Dropdown.Menu className="super-colors dropDownItem">
                                    {maxDistanceFunction()}
                                </Dropdown.Menu>
                            </Dropdown>
                            {maxDistanceError && maxDistanceError.length ? <p className='errorMsg'>{maxDistanceError}</p> : ""}
                        </div> : <></>}

                        {/* playing time */}
                        {themeState.userRole === "LEADER" ? <></> : <>
                            <div className="normalSignUpWithIcon">
                                {themeState.gameTime && themeState.gameTime.length ? <p className='dropdownTopAnimation'>זמן משחק (בממוצע)</p> : ""}
                                <Dropdown className='arrowDown'>
                                    <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                                        {themeState.gameTime && themeState.gameTime ? convertToHebrew(themeState.gameTime) :
                                            <>{showGameTime && showGameTime.length ? showGameTime : "זמן משחק (בממוצע)"}</>}
                                        <FontAwesomeIcon icon={faChevronDown} /></Dropdown.Toggle>
                                    <Dropdown.Menu className="super-colors dropDownItem">
                                        {gameTimeFunction()}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {gameTimeError && gameTimeError.length ? <p className='errorMsg'>{gameTimeError}</p> : ""}
                            </div>
                        </>}
                        {/* fitness */}
                        {themeState.userRole === "LEADER" ? <>
                            <div className="normalSignUpWithIcon">
                                {themeState.fitness && themeState.fitness.length ? <p className='dropdownTopAnimation'>רמת כושר השחקנים</p> : ""}
                                {/* {showFitness && showFitness.length ? <p className='dropdownTopAnimation'>רמת כושר השחקנים</p> : ""} */}
                                <Dropdown className='arrowDown'>
                                    <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                                        {themeState.fitness && themeState.fitness ? convertToHebrew(themeState.fitness) :
                                            <>{showFitness && showFitness.length ? showFitness : "רמת כושר השחקנים"}</>}
                                        <FontAwesomeIcon icon={faChevronDown} /></Dropdown.Toggle>
                                    <Dropdown.Menu className="super-colors dropDownItem">
                                        {fitnessFunction()}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {fitnessError && fitnessError.length ? <p className='errorMsg'>{fitnessError}</p> : ""}
                            </div>
                        </> : <></>}

                        {/* joint group */}

                        {themeState.userRole === "PLAYER" ? <>
                            <div className="normalSignUpWithIcon">
                                {themeState.jointGroup && themeState.jointGroup.length ? <p className='dropdownTopAnimation'>קביעות</p> : ""}
                                <Dropdown className='arrowDown'>
                                    <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                                        {themeState.jointGroup && themeState.jointGroup ? convertToHebrew(themeState.jointGroup) :
                                            <>{showJointGroup && showJointGroup.length ? showJointGroup : "קביעות"} </>}

                                        <FontAwesomeIcon icon={faChevronDown} /></Dropdown.Toggle>
                                    <Dropdown.Menu className="super-colors dropDownItem">
                                        {jointGroupFunction()}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {jointGroupError && jointGroupError.length ? <p className='errorMsg'>{jointGroupError}</p> : ""}
                            </div>


                        </> : <></>}






                        <SignUpButton buttonBorder='#FB730C' buttonBackground='#FB730C;'>
                            <div className="mainBtn">
                                <button type='submit' onClick={secondLine}>
                                    <p>המשך</p>
                                </button>
                            </div>
                        </SignUpButton>
                    </div>
                </Collapse>
            </div>

        </>
    )
}

export default GameDetails