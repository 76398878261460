import { PaymentType } from "../../Enums/PaymentType";
import { OptionType } from "../../Interfaces/OptionType";
import Localize from "../../Localization/Localize";

export function getMinValueForPaymentType(paymentType: PaymentType): number {
    switch (paymentType) {
        case PaymentType.PER_GAME:
            return 10;
        case PaymentType.PER_MONTH:
            return 100;
        case PaymentType.PER_SEASON:
            return 100;
        default:
            return 0;
    }
}

export function getMaxValueForPaymentType(paymentType: PaymentType): number {
    switch (paymentType) {
        case PaymentType.PER_GAME:
            return 100;
        case PaymentType.PER_MONTH:
            return 400;
        case PaymentType.PER_SEASON:
            return 1000;
        default:
            return 0;
    }
}

export function getSliderLineWidth(paymentType: PaymentType, paymentValue: number): number {

    if (paymentValue < getMinValueForPaymentType(paymentType)) {
        paymentValue = getMinValueForPaymentType(paymentType) + 1;
    }
    
    const diff = (getMaxValueForPaymentType(paymentType) - getMinValueForPaymentType(paymentType));
    return (paymentValue - getMinValueForPaymentType(paymentType)) * (diff === 0 ? 0 : 100 / diff);
}

export function getPaymentTypeOptionsOnlyFree(): OptionType[] {
    return [
        { label: Localize[PaymentType.FREE], value: PaymentType.FREE },
    ];
}

export function getPaymentTypeOptionsOnlyPaid(): OptionType[] {
    return [
        { label: Localize[PaymentType.PER_SEASON], value: PaymentType.PER_SEASON },
        { label: Localize[PaymentType.PER_MONTH], value: PaymentType.PER_MONTH },
        { label: Localize[PaymentType.PER_GAME], value: PaymentType.PER_GAME },
    ];
}

export function getPaymentTypeOptions(): OptionType[] {
    return [
        { label: Localize[PaymentType.FREE], value: PaymentType.FREE },
        { label: Localize[PaymentType.PER_SEASON], value: PaymentType.PER_SEASON },
        { label: Localize[PaymentType.PER_MONTH], value: PaymentType.PER_MONTH },
        { label: Localize[PaymentType.PER_GAME], value: PaymentType.PER_GAME },
    ];
}