import styled from 'styled-components';


type Props = {
    buttonBackground: string,
    buttonBorder: string
}

export default styled.div<Props>`

.mainBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.mainBtn button {
    padding: 6px;
    font-family: 'Fredoka';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 18px;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 0 0 2px inset ${(props) => props.buttonBorder};
    position: relative;
    margin: 5px 0;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    width: 143px;
}
.mainBtn button:hover{
    box-shadow: 0 0 0 2px inset #bbb;
}
.mainBtn button.white:hover>p {
    color: #ece8e1;
}

.mainBtn button.transparent:hover>p {
    color: #ece8e1;
}

.mainBtn button.transparent:hover>p span.text {
    box-shadow: 0 0 0 1px #ece8e1;
}

.mainBtn button.transparent>p {
    background: #0f1923;
    color: #ece8e1;
}

.backToFilter .white{
width:185px;
}

.mainBtn button p {
    margin: 0;
    height: 32px;
    line-height: 32px;
    box-sizing: border-box;
    z-index: 1;
    left: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    background: ${(props) => props.buttonBackground};
    color: #fff;
    cursor
}
.mainBtn button p:hover{
    background: #bbb;
}
.mainBtn button p.active{
    background: #bbb;
}

`;