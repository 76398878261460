export enum SurfaceType {
    ASPHALT = 'ASPHALT',
    SYNTHETIC_GRASS = 'SYNTHETIC_GRASS',
    REAL_GRASS = 'REAL_GRASS',
    HALL = 'HALL',
    BEACH = 'BEACH',
    CLAY = 'CLAY',
    OUTSIDE_PITCHES = 'OUTSIDE_PITCHES',
    NOT_CONSTANT = 'NOT_CONSTANT',
    "DOESN'T MATTER" = "DOESN'T MATTER"
}