import React, { useEffect, useState } from 'react';
import Localize from '../../../Localization/Localize';
import userIcon from '../../../assets/images/icon/user.png';
import settingsIcon from '../../../assets/images/icon/settings.png';
import pencilIcon from '../../../assets/images/icon/pencil.png';
import groupOwnerIcon from '../../../assets/images/icon/group-profile.png';

import PlayerIconModal from '../../StyleComponent/PlayerIconModal/PlayerIconModal';
import { IResponse } from '../../../Interfaces/Response';
import { changeProfilePicture } from '../../../api/profile';
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';
import { defaultToastConfig } from '../../../Models/Configs/ToasterDefaultConfig';
import IProfile from '../../../Interfaces/Profile';
import ITemplateProps from '../../../Interfaces/TemplateProps';
import { IPlayerIcon, PlayerIcons } from '../../../Models/Configs/PredefinedModels';
import { UserRole } from '../../../Enums/UserRole';

function SettingDetails({data, settingsMode, dateTimeSettingsMode, addContactEnableMode, userContactInfo, 
    onChangeSettings, loadUserData}: 
    ITemplateProps<IProfile, boolean, boolean, boolean>) {

    const [playerIcon, setPlayerIcon] = useState<IPlayerIcon>({ "Name": "", "icon": null });
    const [popupState, setPopupState] = useState<boolean>(false);
    const [settingsModeIsActive, setSettingsModeIsActive] = useState<boolean>(false);

    function getPlayerIconByName(iconName: string): IPlayerIcon | undefined {
        return PlayerIcons.find(player => player.Name === iconName);
    }

    useEffect(() => {
        if (data && data.user && data.user.userImage) {
            setPlayerIcon(getPlayerIconByName(data.user.userImage) || { "Name": "", "icon": null });
        }
    }, [data]);

    useEffect(() => {
        if (!settingsMode) {
            setPopupState(false);
        }
        setSettingsModeIsActive(settingsMode);
    }, [settingsMode]);
    
    const onSettingsMode = (mode: boolean) => {
        if (dateTimeSettingsMode || addContactEnableMode) {
            return;
        }

        setSettingsModeIsActive(mode);
        onChangeSettings(mode);
    }

    const onChangeProfileIcon = (profileIcon: string) => {

        setPopupState(false);
        if (!profileIcon) {
            return;
        }
        
        changeProfilePicture(profileIcon).then((result: IResponse<string>) => {
            if (result.statusCode === StatusCodes.OK) {
                toast.success(Localize.detailsHaveBeenUpdated, defaultToastConfig(3000));
                setPlayerIcon(getPlayerIconByName(profileIcon) || { "Name": "", "icon": null });
                loadUserData();
            } else {
                toast.error(Localize.errorInAction, defaultToastConfig(3000));
            }
        }).catch(err => {
            toast.error(Localize.errorInAction, defaultToastConfig(3000));
        });
    };

    return (
        <>
            <div className="property-block mb-2 profile-top-block">
                <span className="property-label text-center username-text">{Localize.hi}<br />
                    {data && data.user.nickName ? ((data.user.fullName.split(" "))[0] || "") + ", " + data.user.nickName : data && data.user.fullName}
                </span>
            </div>
            <div className="settings-body">
                <div className="settings-block setting-profile-block-icon-holder mb-3">
                    <img className="uesr-icon" src={(data && data.user.userRole === UserRole.PLAYER) ? userIcon : groupOwnerIcon} alt="" />
                    <div className="text">{(data && data.user.userRole === UserRole.PLAYER) ? Localize.player : Localize.groupOwner }</div>
                </div>
                <div className="settings-block settings-profile mb-3">
                    <img className="trophy-icon" src={playerIcon.icon} alt="" />
                    {settingsMode && <div className="settings-pencil ">
                        <img className="cursor-pointer" onClick={() => {setPopupState(!popupState)}} src={pencilIcon} alt="" />
                        <div className="modal-pivot"></div>
                        <PlayerIconModal onData={onChangeProfileIcon} isPopupActive={popupState}></PlayerIconModal>
                    </div>}
                </div>
                <div className="settings-block">
                    <img className="settings-icon cursor-pointer mb-2" onClick={() => {onSettingsMode(!settingsModeIsActive)}}
                        src={settingsIcon} alt="" />
                    <div className="text settings-text">{Localize.updateDetails}</div>
                </div>
            </div>
        </>
    )
}

export default SettingDetails