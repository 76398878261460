
import { post, get, del, put } from './base-api';


//get user signup info
export const createUser = (data: any) => {
    return post(`/users/register`, data);
};

// user login
export const login = (user: any) => {
    return post(`/users/login`, user);
};
// forget password
export const forgetPasswordApi = (userEmail: any) => {
    return get(`/users/forget-password/${userEmail}`);
};
// verify forget password
export const verifyPassword = (verifyBody: any) => {
    return post(`/users/code-verify/forget-password`, verifyBody);
};

// update forget password
export const updatePassword = (newPassword: any) => {
    return put(`/users/update/password`, newPassword);
};


// validation
// user login
export const jwtValidation = () => {
    return get(`/users/validate-token`);
};


//save jwt to local storage
export const authenticate = (data: any, next: any) => {
    if (typeof window !== undefined) {
        localStorage.setItem("jwt", JSON.stringify(data));
        next();
    }
};

//is authenticate
export const isAuthenticate = () => {
    if (typeof window == undefined) {
        return false;
    }
    if (localStorage.getItem("jwt")) {
        return JSON.parse(localStorage.getItem("jwt") || '{}');
    } else {
        return false;
    }
};

// type user select
export const typeUser = () => {
    if (typeof window == undefined) {
        return false;
    }
    if (localStorage.getItem("typeuser")) {
        return JSON.parse(localStorage.getItem("typeuser") || '{}');
    } else {
        return false;
    }
};

// single profile
export const userProfile = (useremail: any, history = 0) => {
    return get(`/users/${useremail}?history=${history}`);
};


// single profile for search
export const userProfileById = (userId: any, history = 0) => {
    return get(`/users/get-user/${userId}?history=${history}`);
};

// all user
export const allUser = (paramsValue: string, customSearch: any, limit: number, page: number) => {
    return post(`/users?${paramsValue.length > 0 ? `sortBy=${paramsValue}&` : ""}limit=${limit}&page=${page}`, customSearch);
};




// change role
export const changeRole = (userEmail: any) => {
    return get(`/users/role/${userEmail}`);
};



// disbale/active profile
export const disableProfile = (userEmail: any) => {
    return get(`/users/disble/${userEmail}`);
};

// Sports show
export const sportsShow = () => {
    return post(`/sports/search`);
};

// multiple user
export const multiUserApi = (data: any) => {
    return post(`/users/multiple`, data);
};

// single profile
export const checkMail = (email: string) => {
    return get(`/users/check/mail/${email}`);
};

// single profile
export const checkInstagramUsername = (username: string) => {
    return get(`/users/check/instagram/${username}`);
};

// single profile
export const verifyMail = (token: string) => {
    return get(`/users/confirm-email?token=${token}`);
};


// single profile
export const checkPhone = (phone: string) => {
    return get(`/users/check/phone/${phone}`);
};

export const getProfile = () => {
    return get(`/users/profile`);
};

export const updateSearchAndprofileCheckBox = (property: string, value: boolean) => {
    return put(`/users/update-profile`, { [property]: value });
};

export const sendContactUsForm = (data: any) => {
    return post(`/users/contact-us`, data);
};