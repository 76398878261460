import styled from 'styled-components';
type Props = {
    textColor: string,
    background: string
}
export default styled.button<Props>`
color: ${(props) => props.textColor};

border:none;
border-radius: 10px;
background: ${(props) => props.background};
font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 15px;
text-align: center;
width:24%;
height:40px;
margin-top:3px;
margin-bottom:3px;

@media screen and (max-width: 1580px) {
    font-size: 10px;
    line-height: 11px;
    width:24%;
    height: 41px;
    margin-top:3px;
    margin-bottom:3px
}
@media screen and (max-width: 780px) {
    font-size: 10px;
    line-height: 11px;
    width:48%;
    font-size: 10px;
    height: 41px;
    margin-top:3px;
    margin-bottom:3px
}
@media screen and (min-height: 1080px) {
    height:60px;
}
`;