import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import { getProfile } from '../../../api/profile';
import IProfile from '../../../Interfaces/Profile';
import { IResponse } from '../../../Interfaces/Response';
import Header from '../Header/Header';
import settingsBlock from "./../../../assets/images/icon/settings-block.png";
import calenderBlock from "./../../../assets/images/icon/calender-block.png";
import groupBlock from "./../../../assets/images/icon/group-block.png";
import profileHideBlock from "./../../../assets/images/icon/profile-hide-block.png";
import profileDeleteBlock from "./../../../assets/images/icon/profile-delete-block.png";
import GameDetails from './GameDetails';
import './profile.css';
import ProfileDetails from './ProfileDetails';
import SettingDetails from './SettingDetails';
import { IUserContactInfo } from '../../../Interfaces/ProfileShortInfo';
import { useNavigate } from 'react-router-dom';
import { defaultToastConfig } from '../../../Models/Configs/ToasterDefaultConfig';
import { toast } from 'react-toastify';
import Localize from '../../../Localization/Localize';

function Profile() {

    const navigate = useNavigate();
    const [profile, setProfile] = useState<IProfile | null>(null);
    const [userContactInfo, setUserContactInfo] = useState<IUserContactInfo>({
        telephone: '',
        email: '',
        fbName: '',
        anotherContactDetails: ''
    });
    const [settingsMode, setSettingsMode] = useState<boolean>(false);
    const [dateTimeSettingsMode, setDateTimeSettingsMode] = useState<boolean>(false);
    const [contactSettingsMode, setContactSettingsMode] = useState<boolean>(false);
    const [additionalContactToSave, setAdditionalContactToSave] = useState<boolean>(false);
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

    useEffect(() => {
        invokeProfile();
    }, []);

    const invokeProfile = () => {
        getProfile().then((result: IResponse<IProfile>) => {
            if (result.statusCode === StatusCodes.OK) {
                setProfile(result.data);
            }
            else{
                navigate("/login");
            }
        }).catch(err => {
            toast.error(Localize.errorInAction, defaultToastConfig(3000));
        });
    }

    const handleChangeSettings = (value: boolean) => {
        setSettingsMode(value);
    };

    const handleSaveSettings = (requiredLogout: boolean) => {
        setSettingsMode(false);
        setContactSettingsMode(false);
        setAdditionalContactToSave(false);
        
        if (requiredLogout) {
            localStorage.removeItem("jwt");
            navigate("/login");
        } else {
            invokeProfile();
        }
    }

    const handleDateTimeChangeSettings = (value: boolean) => {
        setDateTimeSettingsMode(value);
    };

    const handleSaveDateTimeSettings = () => {
        setDateTimeSettingsMode(false);
        invokeProfile();
    }
    
    const handleContactSettingsChange = (value: boolean) => {
        setContactSettingsMode(value);
    };
    
    const hangleAdditionalContactToSave = (value: boolean) => {
        setAdditionalContactToSave(value);
    };
    
    const handleShowInfoModal = () => {
        setShowInfoModal(!showInfoModal);
    };

    return (
        <>
            <Header></Header>
            <div className="profile-container">
                <div className="profile-row">
                    <div className="settings-wrapper">
                        <SettingDetails data={profile} settingsMode={settingsMode} dateTimeSettingsMode={dateTimeSettingsMode}
                            addContactEnableMode={contactSettingsMode} userContactInfo={userContactInfo}
                            onUserContactInfoChange={setUserContactInfo} requiredAdditionalContactToSave={additionalContactToSave}
                            onChangeSettings={handleChangeSettings} onSaveSettings={handleSaveSettings}
                            onDateTimeSettings={handleDateTimeChangeSettings} onSaveDateTimeSettings={handleSaveDateTimeSettings}
                            onContactEnableSettings={handleContactSettingsChange} onAdditionalContactToSave={hangleAdditionalContactToSave}
                            onShowInfoModal={handleShowInfoModal} loadUserData={invokeProfile}
                        ></SettingDetails>
                    </div>
                    <div className="profile-game-wrapper">
                        <ProfileDetails data={profile} settingsMode={settingsMode} dateTimeSettingsMode={dateTimeSettingsMode}
                            addContactEnableMode={contactSettingsMode} userContactInfo={userContactInfo}
                            onUserContactInfoChange={setUserContactInfo} requiredAdditionalContactToSave={additionalContactToSave}
                            onChangeSettings={handleChangeSettings} onDateTimeSettings={handleDateTimeChangeSettings}
                            onSaveSettings={handleSaveSettings} onSaveDateTimeSettings={handleSaveDateTimeSettings}
                            onContactEnableSettings={handleContactSettingsChange} onAdditionalContactToSave={hangleAdditionalContactToSave}
                            onShowInfoModal={handleShowInfoModal} loadUserData={invokeProfile}
                        ></ProfileDetails>

                        <GameDetails data={profile} settingsMode={settingsMode} dateTimeSettingsMode={dateTimeSettingsMode}
                            addContactEnableMode={contactSettingsMode} userContactInfo={userContactInfo}
                            onUserContactInfoChange={setUserContactInfo} requiredAdditionalContactToSave={additionalContactToSave}
                            onChangeSettings={handleChangeSettings} onDateTimeSettings={handleDateTimeChangeSettings}
                            onSaveSettings={handleSaveSettings} onSaveDateTimeSettings={handleSaveDateTimeSettings}
                            onContactEnableSettings={handleContactSettingsChange} onAdditionalContactToSave={hangleAdditionalContactToSave}
                            onShowInfoModal={handleShowInfoModal} loadUserData={invokeProfile}
                        ></GameDetails>

                        <div className="info-popup-wrapper" style={{display: showInfoModal ? "block" : "none"}}>
                            <div className="cross-button-wrapper">
                                <div className="cross-button cursor-pointer" onClick={handleShowInfoModal}>x</div>
                                <div className="orange-text remove-line-height">מסך הפרופיל</div>
                            </div>
                            <div className="navy-text mt-4">
                                <b>
                                    כאן תמצאו את פרטי הקשר,פרטי המשחק והעדפות המשחק, <br />
                                    אשר הזנתם בעת ההרשמה למערכת האתר.
                                </b>
                            </div>
                            
                            <div className="navy-text mt-4">
                                ע״י לחיצה על כפתור 
                                &nbsp;&nbsp;<img src={settingsBlock} alt="" />&nbsp;&nbsp;
                                <b><span className="orange-text">תוכלו לעדכן את כל פרטי הפרופיל שלכם.</span></b>
                            </div>
                            <div className="navy-text mt-4">
                                ע״י לחיצה על כפתור  
                                &nbsp;&nbsp;<img src={calenderBlock} alt="" />&nbsp;&nbsp;
                                <b><span className="orange-text">תוכלו לעדכן את הזמינות / זמני המשחק שלכם.</span></b>
                            </div>
                            <div className="navy-text mt-4">
                                ע״י לחיצה על כפתור 
                                &nbsp;&nbsp;<img src={groupBlock} alt="" />&nbsp;&nbsp;
                                <b><span className="orange-text">תוכלו להירשם כסוג המשתמש הנוסף.</span></b>
                            </div>

                            <div className="navy-text mt-4">
                                אם נרשמתם כקבוצה, תוכלו להירשם גם כשחקן, ולהיפך. <br /> 
                                בלחיצה <b>ראשונית</b> על כפתור זה, תעברו ישירות <b>לטופס ההרשמה</b>. <br />
                                בעת לחיצה על כפתור זה (שנה פרופיל), תשנו את סוג המשתמש שיופיע בפרופיל. <br />
                                וכעת, במסך <b>היציע</b>, החיפוש שלכם ישתנה עפ״י סוג המשתמש העדכני בפרופיל. <br /> 
                                אם נרשמתם כשני סוגי המשתמשים, אתם תופיעו <b>בשני כרטיסי משחק</b> שונים במסך <b>היציע</b> (שחקן + קבוצה).
                            </div>

                            <div className="navy-text mt-4">
                                ע״י לחיצה על כפתור 
                                &nbsp;&nbsp;<img src={profileHideBlock} alt="" />&nbsp;&nbsp;
                                <b><span className="orange-text">תוכלו להסתיר את כרטיס המשחק שלכם ממסך היציע, לפי פרק זמן לבחירתכם.</span></b>
                            </div>
                            {/* TODO Add functionnality after refactor user / profile */}
                            {/* <div className="navy-text mt-2">
                                ע״י לחיצה על כפתור 
                                &nbsp;&nbsp;<img src={profileDeleteBlock} alt="" />&nbsp;&nbsp;
                                <b><span className="orange-text">תוכלו להסיר לאלתר את המשתמש שלכם ממערכת האתר.</span></b>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile