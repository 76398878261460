import styled from 'styled-components';
type Props = {
    textColor: string
}
export default styled.button<Props>`
font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 13px;
margin-top: 5px;
display: flex;
align-items: center;
text-decoration-line: underline;
background-color: transparent;
color: ${(props) => props.textColor};
border: none;
@media (max-width: 780px){
    font-size: 17px;
}
`;