import styled from 'styled-components';


type Props = {
  heading: string,
  paragraphText: string,
  textBorder: string,
  background: string
}
export default styled.div<Props>`
border: ${(props) => props.heading};
background: ${(props) => props.background};
opacity: 1;
border-radius: 32px 32px 0px 0px;
padding: 10px 20px;
width:100%;
min-height: calc(32vh - 60px);
position:relative;
 .title {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-size: 41px;
    line-height: 105%;
    text-align: center;
    color: ${(props) => props.heading};
    -webkit-text-stroke: 1px ${(props) => props.textBorder};
}

 .dob {
    font-family: 'Rubik';
    font-style: normal;
    // font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.03em;
    color: ${(props) => props.paragraphText};
    margin-bottom: 2px;
  }
  .viewBtn{
    font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;
text-align: center;
letter-spacing: 0.03em;
color: ${(props) => props.paragraphText};
  }

 .typeBtn {
    background: linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);
    font-family: 'Fredoka';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
text-align: center;
color: #FFFFFF;
position: relative;
border-radius: 25px;
border: 3px solid transparent;
// border: 5px solid;
//   border-image: linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%) 1;
  }
  .typeBtn::after {
    content: "";
    background-color: #000;
    border: 3px solid red;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0px;
    border-radius: 25px;
  } 
  .iButton{
    position: absolute;
top: 24px;
right: 10px;
width: 30px;
  }
  @media screen and (max-width: 1580px) {
    padding: 5px;
    min-height: calc(32vh - 50px);
    display: grid;
align-content: center;
    .title {
      font-size: 28px;
  }
  .dob {
    font-size: 12px;
margin-bottom:2px;
  }
  .viewBtn{

font-size: 12px;
margin-bottom:2px;
  }
  }
`;