export const CHANGE_GAME_TYPE = 'theme/changeGameType';
export const CHOOSE_TYPE = 'theme/changeChooseType';
export const CHANGE_SEARCH_OPTION = 'theme/changeSearchOption';
export const CHANGE_BACKTOFILTER_OPTION = 'theme/changeBackToFilterOption';
export const CHANGE_LOGIN_MODE = 'theme/changeLoginMode';
export const CHANGE_MAP_CARD = 'theme/changeMapCard';  
export const CHANGE_USER_ICON = 'theme/changeUserIcon';
// signup info
export const FULL_NAME = 'theme/fullName'; 
export const TELEPHONE = 'theme/telephone';
export const EMAIL = 'theme/email'; 
export const PASSWORD = 'theme/password';  
export const USER_ROLE = 'theme/userRole';
export const GAME_POSITION = 'theme/gamePosition';    
export const EACH_TEAM = 'theme/eachTeamPlayer';
export const SURFACE_TYPE = 'theme/surfaceType';

export const LEVEL_OF_PLAY = 'theme/levelOfPlay';
export const MAX_DISTANCE = 'theme/maxDistance';
export const GAME_TIME = 'theme/gameTime';
export const FREQUENCY_OF_PLAY = 'theme/frequencyOfPlay'; 
export const SPORT_TYPE = 'theme/sportType';  
export const NICK_NAME = 'theme/nickName';
export const FIND_US = 'theme/findUs';
export const AGE_RANGE = 'theme/ageRange';
export const PLAY_AREA = 'theme/playArea';
export const JOINT_GROUP = 'theme/jointGroup';
export const FB_NAME = 'theme/fbName';

export const AVAILABLE_DAYS = 'theme/availableDays';
export const AVAILABLE_HOURS = 'theme/availableHours';  
export const LOCATION = 'theme/location';
export const LATITUDE = 'theme/latitude';
export const LONGITUDE = 'theme/longitude';
export const DOB = 'theme/dob';
export const CITY = 'theme/city';
export const COUNTRY = 'theme/country';
export const FITNESS = 'theme/fitness';

export const PAYMENT = 'theme/payment';
export const PAYMENT_TYPE = 'theme/paymentType';  
export const CLEAR_DATA = 'theme/clearData'; 
export const PUBLIC_EMAIL = 'theme/publicEmail'; 
export const ICON = 'theme/iconSet'; 


export const SUNDAY = 'theme/sunday'; 
export const MONDAY = 'theme/monday'; 
export const TUESDAY = 'theme/tuesday'; 
export const WEDNESDAY = 'theme/wednesday'; 
export const THURSDAY = 'theme/thursday'; 
export const FRIDAY = 'theme/friday'; 
export const SATURDAY = 'theme/saturday'; 
export const ALL_DATE = 'theme/allDate'; 

export const PROVIDER = 'theme/provider';    
export const ACCESSTOKEN = 'theme/accessToken'; 
export const LOGIN_CHECK = 'theme/loginCheck'; 
export const PROFILE_UPDATE = 'theme/profileUpdate'; 