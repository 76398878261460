import React from 'react';
import './customRadioGroup.css';
import { OptionType } from '../../../Interfaces/OptionType';

export interface RadioGroup {
    options: OptionType[];
    selectedValue: string;
    maxWidth: number;
    onChange: (value: string) => void;
};

const CustomRadioGroup: React.FC<RadioGroup> = ({options, selectedValue, maxWidth, onChange}) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    };
  
    return (
        <div className="custom-radio-wrapper" style={{ maxWidth: maxWidth === 0 ? "" : `${maxWidth}px` }}>
            {options.map((option) => (
                <label className="radio-container" key={option.value}>
                    {option.label}
                    
                    <input
                        type="radio"
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={handleChange}
                    />
                    <span className="checkmark"></span>
                </label>
            ))}
        </div>
    );
  };
  
  export default CustomRadioGroup;