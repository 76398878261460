import styled from 'styled-components';
type Props = {
    backgroundImage: string
}

export default styled.div<Props>`
background-image: url("${(props) => props.backgroundImage}");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100px;
    padding-bottom:10px;
    min-height:100vh;
    padding-left: 30px; 
    padding-right: 30px; 

}
@media screen and (max-width: 1580px) {
    padding-top: 70px;
}
@media screen and (max-width:780px) {
    padding-left: 0px; 
    padding-right: 0px; 
}
`;