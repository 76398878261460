import React, { useContext, useState, useRef, useEffect } from 'react'
import './Signup.css';

import Layout from '../Layout/Layout';
import { Col, Collapse, Container, Dropdown, Row, Modal } from 'react-bootstrap';
import FacebookLogin from '@greatsumini/react-facebook-login';
import signUpGroup from '../../../assets/images/icon/signupGroup.png'
import SignUpPage from '../../StyleComponent/SignUpPage/SignUpPage';
import loginman from '../../../assets/images/bg-image/loginman.png'
import lock from '../../../assets/images/icon/lock.png';
import messageIcon from '../../../assets/images/icon/message.png'
import phone from '../../../assets/images/icon/phone.png'
import user from '../../../assets/images/icon/user.png';
import Button from '../../StyleComponent/Button/Button';
import SignUpButton from '../../StyleComponent/SignUpButton/SignUpButton';
import SignUpIcon from './SignUpIcon';
import SelectUserType from './SelectUserType';
import GameType from './GameType';
import buttonImage from '../../../assets/images/button/button.png'
import singlePlayer from '../../../assets/images/button/character_set.png'
import groupPlayer from '../../../assets/images/button/group.png';
import facebook from '../../../assets/images/icon/facebook.png';
// icon

// default 
import default00 from '../../../assets/images/signUpIcon/DEFAULT-00.png';

// basket
import basket01 from '../../../assets/images/signUpIcon/BASKET-01.png';
import basket02 from '../../../assets/images/signUpIcon/BASKET-02.png';
import basket03 from '../../../assets/images/signUpIcon/BASKET-03.png';
import basket04 from '../../../assets/images/signUpIcon/BASKET-04.png';
import basket05 from '../../../assets/images/signUpIcon/BASKET-05.png';
import basket06 from '../../../assets/images/signUpIcon/BASKET-06.png';
import basket07 from '../../../assets/images/signUpIcon/BASKET-07.png';
import basket08 from '../../../assets/images/signUpIcon/BASKET-08.png';
import basket09 from '../../../assets/images/signUpIcon/BASKET-09.png';
import basket10 from '../../../assets/images/signUpIcon/BASKET-10.png';
// football
import footBall01 from '../../../assets/images/signUpIcon/FOOTBALL-01.png';
import footBall02 from '../../../assets/images/signUpIcon/FOOTBALL-02.png';
import footBall03 from '../../../assets/images/signUpIcon/FOOTBALL-03.png';
import footBall04 from '../../../assets/images/signUpIcon/FOOTBALL-04.png';
import footBall05 from '../../../assets/images/signUpIcon/FOOTBALL-05.png';
import footBall06 from '../../../assets/images/signUpIcon/FOOTBALL-06.png';
import footBall07 from '../../../assets/images/signUpIcon/FOOTBALL-07.png';
import footBall08 from '../../../assets/images/signUpIcon/FOOTBALL-08.png';
import footBall09 from '../../../assets/images/signUpIcon/FOOTBALL-09.png';
import footBall10 from '../../../assets/images/signUpIcon/FOOTBALL-10.png';
// tennis
import tennis01 from '../../../assets/images/signUpIcon/TENNIS-01.png';
import tennis02 from '../../../assets/images/signUpIcon/TENNIS-02.png';
import tennis03 from '../../../assets/images/signUpIcon/TENNIS-03.png';
import tennis04 from '../../../assets/images/signUpIcon/TENNIS-04.png';
import tennis05 from '../../../assets/images/signUpIcon/TENNIS-05.png';
import tennis06 from '../../../assets/images/signUpIcon/TENNIS-06.png';
import tennis07 from '../../../assets/images/signUpIcon/TENNIS-07.png';
import tennis08 from '../../../assets/images/signUpIcon/TENNIS-08.png';
import tennis09 from '../../../assets/images/signUpIcon/TENNIS-09.png';
import tennis10 from '../../../assets/images/signUpIcon/TENNIS-10.png';
// volley
import volley01 from '../../../assets/images/signUpIcon/VOLLEY-01.png';
import volley02 from '../../../assets/images/signUpIcon/VOLLEY-02.png';
import volley03 from '../../../assets/images/signUpIcon/VOLLEY-03.png';
import volley04 from '../../../assets/images/signUpIcon/VOLLEY-04.png';
import volley05 from '../../../assets/images/signUpIcon/VOLLEY-05.png';
import volley06 from '../../../assets/images/signUpIcon/VOLLEY-06.png';
import volley07 from '../../../assets/images/signUpIcon/VOLLEY-07.png';
import volley08 from '../../../assets/images/signUpIcon/VOLLEY-08.png';
import volley09 from '../../../assets/images/signUpIcon/VOLLEY-09.png';
import volley10 from '../../../assets/images/signUpIcon/VOLLEY-10.png';



import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import errorImage from '../../../assets/images/icon/error.png';
import successImage from '../../../assets/images/icon/successImage.png';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { iconSet, clearData, publicEmail, nickName, fbName, changeUserIcon, fullName, telephone, email, password, userRole, sportType, loginCheck } from '../../../store/theme/actions';
import themeReducer, { InitialState } from '../../../store/theme/themeReducer';

import SignUpProfile from './SignUpProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle, faInstagram } from '@fortawesome/free-brands-svg-icons';
import multiProfile from '../../../assets/images/icon/multiProfile.png'
import { faAnglesDown, faChevronDown, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import GameDetails from './GameDetails';
import FindUs from './FindUs';
import DaysHours from './DaysHours';
import { MAP } from '../../../config';

import Autocomplete from "react-google-autocomplete";
import { Link, useNavigate } from 'react-router-dom';
import Datetime from 'react-datetime';

import { createUser, checkMail, checkPhone, authenticate } from './../../../api/auth';
import Payment from './Payment';
import { ThirdPartyApp } from '../../../Interfaces/LoginRequeset';
import { PlayerIcons } from '../../../Models/Configs/PredefinedModels';
import { UserRole } from '../../../Enums/UserRole';
import PopupWithCheckbox from '../../StyleComponent/PopupWithCheckbox/PopupWithCheckbox';

import WhatsAppButton from '../WhatsappWidget/index'

interface UserImages {
  [key: string]: string;
}


const Signup = () => {
  const navigate = useNavigate();
  const themeState = useSelector((state: InitialState) => state);
  const dispatch = useDispatch();

  const [iconBox, setIconBox] = useState(false)
  const [activeIcon, setActiveIcon] = React.useState<string[]>([]);
  const [iconShow, setIconShow] = React.useState(null);
  const [userIcon, setUserIcon] = useState("");
  const [saveIcon, setSaveIcon] = useState("");
  const [chooseOption, setChooseOption] = useState(false)
  const [signUpUser, setSignUpUser] = useState(true);
  const [errorPhone, setErrorPhone] = useState("")
  const [errorPhoneInUse, setErrorPhoneInUse] = useState(false)
  const [errorEmail, setErrorEmail] = useState("")
  const [errorPassword, setErrorPassword] = useState("")
  const [iconName, seticonName] = useState('')
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleType, setToggleType] = useState("password");
  // user info
  const [userName, setUserName] = useState(themeState.fullName);
  const [phoneNumber, setPhoneNumber] = useState(themeState.telephone);
  const [message, setMessage] = useState(themeState.email);
  const [userPassword, setPassword] = useState(themeState.password);
  const [chooseRole, setChooseRole] = useState(themeState.userRole && themeState.userRole ? themeState.userRole : '')
  const [sportsType, setSportsType] = useState(themeState.sportType && themeState.sportType ? themeState.sportType : "")

  const [nickNameValue, setNickName] = useState("");
  const [publicEmailValue, setPublicEmailValue] = useState(themeState.publicEmail && themeState.publicEmail === 'false' ? false : true);
  const [fbNameValue, setFbNameValue] = useState(themeState.fbName && themeState.fbName ? themeState.fbName : '');
  const [profileInfoInput, setProfileInfoInput] = useState(false)
  const [iconSetValue, setIconSetValue] = useState(themeState.iconSet && themeState.iconSet ? themeState.iconSet : '');

  const [saturdayValue, setSaturdayValue] = useState<any>([])
  const [sundayValue, setSundayValue] = useState<any>([])
  const [mondayValue, setMondayValue] = useState<any>([])
  const [tuesdayValue, setTuesdayValue] = useState<any>([])
  const [wednesdayValue, setWednesdayValue] = useState<any>([])
  const [thursdayValue, setThursdayValue] = useState<any>([])
  const [fridayValue, setFridayValue] = useState<any>([])
  const [allDateValue, setAllDateValue] = useState<any>([])

  const [imagePopup, setImagePopup] = useState<boolean>(true);


  const [conditionMap, setConditionMap] = useState("")
  const UserHandleChange = (event: any) => {
    setUserName(event.target.value);
  };

  const [zeroPhone, setZeroPhone] = useState('')

  const PhoneHandleChange = (event: any) => {
    const telephoneNumber = event.target.value;
    //  const limit = 10;


    // if (telephoneNumber.charAt(0) === "0") {

    // //  setPhoneNumber(finalValue.slice(0, limit));

    //   const finalValue = telephoneNumber.substring(1);
    //   setZeroPhone(finalValue);
    //   setPhoneNumber(zeroPhone);
    // //  setPhoneNumber(finalValue.slice(0, limit));

    // }
    // else {
    //   setPhoneNumber(event.target.value.slice(0, limit));
    // }


    setPhoneNumber(event.target.value)

  };



  // password show
  const showPass = () => {
    setTogglePassword(!togglePassword)
    setToggleType("password")
  }

  const hidePass = () => {
    setTogglePassword(!togglePassword)
    setToggleType("")
  }


  const EmailHandleChange = (event: any) => {

    setMessage(event.target.value);
  };
  const PasswordHandleChange = (event: any) => {
    setPassword(event.target.value);
  };


  const fullNameHandle = (event: any) => {
    setUserName(event.target.value);
  }
  const telephoneHandle = (event: any) => {
    setPhoneNumber(event.target.value);
  }
  const emailHandle = (event: any) => {
    setMessage(event.target.value);
  }
  const nickHandleChange = (event: any) => {
    setNickName(event.target.value);
  };

  const fbHandleChange = (event: any) => {
    setFbNameValue(event.target.value)
  }
  // nick name
  const nickNameHandler = (value: string) => {
    dispatch(nickName(value));
  }
  React.useEffect(() => {
    nickNameHandler(nickNameValue)
  }, [nickNameValue]);

  // fbName
  const fbNameHandler = (value: string) => {
    dispatch(fbName(value));
  }
  React.useEffect(() => {
    fbNameHandler(fbNameValue)
  }, [fbNameValue]);

  // icon set
  const iconSetHandler = (value: string) => {
    dispatch(iconSet(value));
  }

  React.useEffect(() => {
    iconSetHandler(iconSetValue)
  }, [iconSetValue]);

  // icon image redux
  const userIconHandler = (value: string) => {
    dispatch(changeUserIcon(value));
  }

  React.useEffect(() => {
    userIconHandler(userIcon)
  }, [userIcon]);


  // player full name
  const fullNameUpHandler = (value: string) => {
    dispatch(fullName(value));
  }

  React.useEffect(() => {
    fullNameUpHandler(userName)
  }, [userName]);

  // player phone number
  const phoneUpHandler = (value: string) => {
    dispatch(telephone(value));
  }

  React.useEffect(() => {
    phoneUpHandler(phoneNumber)
  }, [phoneNumber]);
  // player email
  const emailUpHandler = (value: string) => {
    dispatch(email(value));
  }

  React.useEffect(() => {
    emailUpHandler(message)
  }, [message]);
  // player password
  const passwordUpHandler = (value: string) => {
    dispatch(password(value));
  }

  React.useEffect(() => {
    passwordUpHandler(userPassword)
  }, [userPassword]);

  // choose user role
  const sportTypeUpHandler = (value: string) => {
    dispatch(userRole(value));
  }

  React.useEffect(() => {
    sportTypeUpHandler(chooseRole)
  }, [chooseRole]);

  // choose sport type
  const sportsUpHandler = (value: string) => {
    dispatch(sportType(value));
  }

  React.useEffect(() => {
    sportsUpHandler(sportsType)
  }, [sportsType]);

  // choose email public
  const publicEmailHandler = (value: string) => {
    dispatch(publicEmail(value));
  }

  React.useEffect(() => {
    publicEmailHandler(`${publicEmailValue}`)
  }, [`${publicEmailValue}`]);


  const iconList = [
    { "Name": "DEFAULT-00", "icon": default00 },

    { "Name": "BASKET-01", "icon": basket01 },
    { "Name": "BASKET-02", "icon": basket02 },
    { "Name": "BASKET-03", "icon": basket03 },
    { "Name": "BASKET-04", "icon": basket04 },
    { "Name": "BASKET-05", "icon": basket05 },
    { "Name": "BASKET-06", "icon": basket06 },
    { "Name": "BASKET-07", "icon": basket07 },
    { "Name": "BASKET-08", "icon": basket08 },
    { "Name": "BASKET-09", "icon": basket09 },
    { "Name": "BASKET-10", "icon": basket10 },

    { "Name": "FOOTBALL-01", "icon": footBall01 },
    { "Name": "FOOTBALL-02", "icon": footBall02 },
    { "Name": "FOOTBALL-03", "icon": footBall03 },
    { "Name": "FOOTBALL-04", "icon": footBall04 },
    { "Name": "FOOTBALL-05", "icon": footBall05 },
    { "Name": "FOOTBALL-06", "icon": footBall06 },
    { "Name": "FOOTBALL-07", "icon": footBall07 },
    { "Name": "FOOTBALL-08", "icon": footBall08 },
    { "Name": "FOOTBALL-09", "icon": footBall09 },
    { "Name": "FOOTBALL-10", "icon": footBall10 },


    { "Name": "TENNIS-01", "icon": tennis01 },
    { "Name": "TENNIS-02", "icon": tennis02 },
    { "Name": "TENNIS-03", "icon": tennis03 },
    { "Name": "TENNIS-04", "icon": tennis04 },
    { "Name": "TENNIS-05", "icon": tennis05 },
    { "Name": "TENNIS-06", "icon": tennis06 },
    { "Name": "TENNIS-07", "icon": tennis07 },
    { "Name": "TENNIS-08", "icon": tennis08 },
    { "Name": "TENNIS-09", "icon": tennis09 },
    { "Name": "TENNIS-10", "icon": tennis10 },


    { "Name": "VOLLEY-01", "icon": volley01 },
    { "Name": "VOLLEY-02", "icon": volley02 },
    { "Name": "VOLLEY-03", "icon": volley03 },
    { "Name": "VOLLEY-04", "icon": volley04 },
    { "Name": "VOLLEY-05", "icon": volley05 },
    { "Name": "VOLLEY-06", "icon": volley06 },
    { "Name": "VOLLEY-07", "icon": volley07 },
    { "Name": "VOLLEY-08", "icon": volley08 },
    { "Name": "VOLLEY-09", "icon": volley09 },
    { "Name": "VOLLEY-10", "icon": volley10 },
  ]

  const iconPath: UserImages = {
    "DEFAULT-00": default00,

    "BASKET-01": basket01,
    "BASKET-02": basket02,
    "BASKET-03": basket03,
    "BASKET-04": basket04,
    "BASKET-05": basket05,
    "BASKET-06": basket06,
    "BASKET-07": basket07,
    "BASKET-08": basket08,
    "BASKET-09": basket09,
    "BASKET-10": basket10,

    "FOOTBALL-01": footBall01,
    "FOOTBALL-02": footBall02,
    "FOOTBALL-03": footBall03,
    "FOOTBALL-04": footBall04,
    "FOOTBALL-05": footBall05,
    "FOOTBALL-06": footBall06,
    "FOOTBALL-07": footBall07,
    "FOOTBALL-08": footBall08,
    "FOOTBALL-09": footBall09,
    "FOOTBALL-10": footBall10,


    "TENNIS-01": tennis01,
    "TENNIS-02": tennis02,
    "TENNIS-03": tennis03,
    "TENNIS-04": tennis04,
    "TENNIS-05": tennis05,
    "TENNIS-06": tennis06,
    "TENNIS-07": tennis07,
    "TENNIS-08": tennis08,
    "TENNIS-09": tennis09,
    "TENNIS-10": tennis10,


    "VOLLEY-01": volley01,
    "VOLLEY-02": volley02,
    "VOLLEY-03": volley03,
    "VOLLEY-04": volley04,
    "VOLLEY-05": volley05,
    "VOLLEY-06": volley06,
    "VOLLEY-07": volley07,
    "VOLLEY-08": volley08,
    "VOLLEY-09": volley09,
    "VOLLEY-10": volley10
  }



  const [loginSuccess, setLoginSuccess] = useState(false)
  const [loginFail, setLoginFail] = useState(false)
  //error tooltip
  const showError = () => {
    return (
      <>
        <div className='toastPosition'>
          <ToastContainer position="bottom-center" className="mt-1 errorToast">
            <Toast onClose={() => setLoginFail(false)} show={loginFail} delay={5000} autohide>
              <div className="errorNotificaton">
                <Toast.Body><img src={errorImage} /> שגיאה בפעולה </Toast.Body>
              </div>
            </Toast>
          </ToastContainer>
        </div>

      </>
    );
  };
  // success login
  const successLogin = () => {
    return (
      <>
        <div className="toastPosition">
          <ToastContainer position="bottom-end" className="mt-1 errorToast">
            <Toast onClose={() => setCreatingSuccess(false)} show={creatingSuccess} autohide>
              <div className="successNotificaton signUpNotify">
                <Toast.Body>
                  <img src={successImage} />
                  <div>
                    <p>מייל לאישור החשבון נשלח למייל שהזנת,</p>
                    <p>אנא אשר את הרשמתך דרך המייל, תודה.</p>
                  </div>
                </Toast.Body>
              </div>
            </Toast>
          </ToastContainer>
        </div>
      </>
    );
  };


  // user creating
  const creatingLogin = () => {
    return (
      <>
        <div className='toastPosition'>
          <ToastContainer position="bottom-end" className="p-3">
            <Toast onClose={() => setLoginSuccess(false)} show={loginSuccess} delay={5000} autohide>
              <div className="bg-success text-white">
                <Toast.Body>{loginSuccess}</Toast.Body>
              </div>
            </Toast>
          </ToastContainer>
        </div>

      </>
    );
  };




  const [dateSave, setDateSave] = useState<any[]>(themeState.availableDays)
  const [hourSave, setHourSave] = useState<any[]>(themeState.availableHours)

  var strValue = `${themeState.publicEmail}`
  var regexPattern = new RegExp("true");
  var boolValue1 = regexPattern.test(strValue);

  const fn = (dayName: string) => {
    let reduxData: any = [];

    let newAllDay = themeState.allDate

    switch (dayName) {
      case "SUNDAY":
        reduxData = themeState.sunday
        break;
      case "MONDAY":
        reduxData = themeState.monday
        break;
      case "TUESDAY":
        reduxData = themeState.tuesday
        break;
      case "WEDNESDAY":
        reduxData = themeState.wednesday
        break;
      case "THURSDAY":
        reduxData = themeState.thursday
        break;
      case "FRIDAY":
        reduxData = themeState.friday
        break;
      case "SATURDAY":
        reduxData = themeState.saturday
        break;
      case "ALL_DATE":
        reduxData = themeState.allDate
        break;

    }
    const temp1 = new Set([...reduxData, ...newAllDay]);
    let arr = Array.from(temp1);
    return arr;
  }

  const scroll = useRef(null);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const iconChoose = () => {

    if (windowSize.current[0] < 800) {
      window.scrollTo({
        top: 1050,
        behavior: 'smooth',
      });
      setTimeout(() => {
        window.scrollTo({
          top: 1050,
          behavior: 'smooth',
        });
      }, 100);
    }


    setIconBox(true);
  }






  const iconData = (data: any, icon: any, i: number) => {

    if (i < 10) {
      setSportsType("BASKETBALL")
    }
    else if (i < 20) {
      setSportsType("FOOTBALL")
    }
    else if (i < 30) {
      setSportsType("TENNIS")
    }
    else {
      setSportsType("VOLLEYBALL")
    }
    seticonName(data)
    setIconShow(icon);
    setSaveIcon(icon);
    setIconSetValue(data)

  }

  const saveUserIcon = () => {
    setUserIcon(saveIcon)
    setIconBox(false)
  }

  // user info
  const [imageError, setImageError] = useState("")
  const [userNameError, setUserNameError] = useState("")
  const [userPasswordError, setUserPasswordError] = useState("")
  const [userEmailError, setUserEmailError] = useState("")
  const [userPhoneError, setUserPhoneError] = useState("")



  const userInfoSubmit = async () => {

    if (userIcon.length === 0) {
      // setImageError("אנא בחר תמונת פרופיל")
      seticonName('DEFAULT-00')
      setIconShow(default00 as any);
      setSaveIcon(default00 as any);
      setIconSetValue('DEFAULT-00')
    }
    if (userName.length === 0) {
      setUserNameError("אנא מלא את שמך")
    }

    if (themeState.email.length === 0) {
      setErrorEmail("אנא הכנס את המייל שלך")
    }

    if (themeState.telephone.length === 0) {
      setErrorPhone("אנא הכנס את מספר הטלפון שלך")
    }

    if (themeState.password.length === 0) {
      setErrorPassword("אנא הכנס את הסיסמה שלך")
    }

    // if ((phoneNumber.length !== 9 && phoneNumber[0] !== '5') || (phoneNumber.length !== 10 && phoneNumber[0] !== '0' && phoneNumber[1] !== '5')) {
    //   setErrorPhone("מספר הטלפון שגוי")
    // }

    if (!errorPhoneInUse && !(phoneNumber.length === 9 && phoneNumber[0] === '5') && !(phoneNumber.length === 10 && phoneNumber[0] === '0' && phoneNumber[1] === '5')) {
      setErrorPhone('מספר הטלפון שגוי')
    }

    if (userPassword.length < 8) {
      setErrorPassword("not_strong")
    }


    // אנא ספק סיסמה חזקה

    const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+={}\[\]:;\"'<>,.?\/\\|-]{8,}$/);
    const check = regex.test(userPassword);

    if (check) {
      setErrorPassword("")
    } else {
      setErrorPassword('not_strong')
    }

    if (match === true && check === true && userName.length > 0 && (phoneNumber.length === 9 || phoneNumber.length === 10) && errorEmail.length === 0 && errorPassword.length === 0 && errorPhone.length === 0 && message.length > 1 && userPassword.length > 1) {
      setChooseOption(true)
      setSignUpUser(false)
    }
    else if (themeState.profileUpdate === "profileUpdate") {
      setChooseOption(true)
      setSignUpUser(false)
    }
    else {
      console.log('fail');
    }
  }


  const checkPhoneValidation = async () => {
    if (phoneNumber.length === 9 && phoneNumber[0] === '5') {
      checkPhone('972' + phoneNumber).then((data) => {
        if (data.statusCode !== 200) {
          setErrorPhone('מספר הטלפון הזה כבר בשימוש')
          setErrorPhoneInUse(true)
        }
        else {
          setErrorPhone('')
          setErrorPhoneInUse(false)

        }
      })
    }
    else if (phoneNumber.length === 10 && phoneNumber[0] === '0' && phoneNumber[1] === '5') {
      let tempPhone = phoneNumber.substring(1)
      checkPhone('972' + tempPhone).then((data) => {
        if (data.statusCode !== 200) {
          setErrorPhone('מספר הטלפון הזה כבר בשימוש')
          setErrorPhoneInUse(true);
        }
        else {
          setErrorPhone('')
          setErrorPhoneInUse(false)

        }
      })
    }
    else {
      setErrorPhone('מספר הטלפון שגוי')
      setErrorPhoneInUse(false)
    }



  }


  const checkMailValidation = async () => {
    const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const check = regex.test(message);
    if (check) {
      checkMail(message).then((data) => {
        if (data.statusCode !== 200) {
          setErrorEmail('כתובת האימייל הזו כבר בשימוש')
        }
        else {
          setErrorEmail('')

        }

      })
    } else {
      setErrorEmail('נא לספק כתובת דוא"ל חוקית')
    }
  }


  // signup Profile

  const userSignProfile = (sportType: any) => {
    setChooseRole(sportType)
    setProfileInfoInput(true)
    setChooseOption(false)
  }
  // 4 types sport type tab
  const [sportTab, setSportTab] = useState(false)
  const sportTypeChoose = () => {
    setSportTab(true)

  }

  const chooseUser = () => {
    setProfileInfoInput(false)
    setChooseOption(true)
    setSportTab(false)

    setGameLine(false)
    setFindUs(false)
    setFreeDays(false)
    setPaymentOption(false)
  }


  // collapse state
  const [firstCollapse, setFirstCollapse] = useState(true);
  const [secondCollapse, setSecondCollapse] = useState(false);
  const [thirdCollapse, setThirdCollapse] = useState(false);
  const [fourthCollapse, setFourthCollapse] = useState(false);
  const [paymentCollapse, setPaymentCollapse] = useState(false);
  const [paymentOption, setPaymentOption] = useState(false)
  const [gameLine, setGameLine] = useState(false);
  const [findUs, setFindUs] = useState(false);
  const [freeDays, setFreeDays] = useState(false);

  // first line

  const [footballError, setFootballError] = useState("")
  const [footballSurfaceError, setFootballSurfaceError] = useState("")
  const [footballEachError, setFootballEachError] = useState("")
  const [basketballError, setBasketballError] = useState("")
  const [basketballEachError, setBasketballEachError] = useState("")
  const [tennisError, setTennisError] = useState("")
  const [tennisEachError, setTennisEachError] = useState("")
  const [volleyballError, setVolleyballError] = useState("")
  const [volleyballEachError, setVolleyballEachError] = useState("")


  const [confirmEmail, setConfirmEmail] = useState("")
  const [match, setMatch] = useState(false);

  const handleConfirmEmailChange = (event: any) => {
    setConfirmEmail(event.target.value)
  }
  const handlePaste = (event: any) => {
    event.preventDefault();
  }


  useEffect(() => {
    if (message === confirmEmail) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  }, [message, confirmEmail]);

  const firstLine = () => {

    if (themeState.sportType === "FOOTBALL") {

      if (themeState.gamePosition.length === 0) {
        setFootballError("זהו שדה חובה")
      }
      else {
        setFootballError("")
      }
      if (themeState.surfaceType.length === 0) {
        setFootballSurfaceError("זהו שדה חובה")
      }
      else {
        setFootballSurfaceError("")
      }
      if (themeState.eachTeamPlayer.length === 0) {
        setFootballEachError("זהו שדה חובה")
      }
      else {
        setFootballEachError("")
      }

      if (themeState.surfaceType.length > 0 && themeState.eachTeamPlayer.length > 0 && ((themeState.userRole === UserRole.PLAYER && themeState.gamePosition.length > 0) || themeState.userRole === UserRole.LEADER)) {
        setFirstCollapse(!firstCollapse)
        setGameLine(true)
        setFootballError("")
        setFootballSurfaceError("")
        setFootballEachError("")
        setSecondCollapse(true)

      }
    }

    if (themeState.sportType === "BASKETBALL") {

      if (themeState.surfaceType.length === 0) {
        setBasketballError("זהו שדה חובה")
      }
      else {
        setBasketballError("")
      }
      if (themeState.eachTeamPlayer.length === 0) {
        setBasketballEachError("זהו שדה חובה")
      }
      else {
        setBasketballEachError("")
      }
      if (themeState.eachTeamPlayer.length > 0 && themeState.surfaceType.length > 0) {
        setFirstCollapse(!firstCollapse)
        setGameLine(true)
        setBasketballError("")
        setBasketballEachError("")
        setSecondCollapse(true)
      }
    }

    if (themeState.sportType === "VOLLEYBALL") {

      if (themeState.surfaceType.length === 0) {
        setVolleyballError("זהו שדה חובה")
      }
      else {
        setVolleyballError("")
      }
      if (themeState.eachTeamPlayer.length === 0) {
        setVolleyballEachError("זהו שדה חובה")
      }
      else {
        setVolleyballEachError("")
      }
      if (themeState.eachTeamPlayer.length > 0 && themeState.surfaceType.length > 0) {
        setFirstCollapse(!firstCollapse)
        setGameLine(true)
        setVolleyballError("")
        setVolleyballEachError("")
        setSecondCollapse(true)
      }
    }


    if (themeState.sportType === "TENNIS") {

      if (themeState.surfaceType.length === 0) {
        setTennisError("זהו שדה חובה")
      }
      else {
        setTennisError("")
      }
      if (themeState.eachTeamPlayer.length === 0) {
        setTennisEachError("זהו שדה חובה")
      }
      else {
        setTennisEachError("")
      }
      if (themeState.eachTeamPlayer.length > 0 && themeState.surfaceType.length > 0) {
        setFirstCollapse(!firstCollapse)
        setGameLine(true)
        setTennisError("")
        setTennisEachError("")
        setSecondCollapse(true)
      }
    }


  }

  const [playDobError, setPlayDobError] = useState("")

  const [playAreaError, setPlayAreaError] = useState("")
  const [levelOfPlayError, setLevelOfPlayError] = useState("")
  const [frequencyOfPlayError, setFrequencyOfPlayError] = useState("")
  const [maxDistanceError, setMaxDistanceError] = useState("")
  const [gameTimeError, setGameTimeError] = useState("")
  const [jointGroupError, setJointGroupError] = useState("")
  const [ageRangeError, setAgeRangeError] = useState("")
  const [locationError, setLocationError] = useState("")
  const [fitnessError, setFitnessError] = useState("")

  const [dobValidation, setDobValidation] = useState<boolean>()

  const [dobError, setDobError] = useState("")
  const secondLine = () => {


    if (themeState.dob.length === 0) {
      setPlayDobError("זהו שדה חובה")
    }
    else {
      setPlayDobError("")
    }

    if (themeState.playArea.length === 0) {
      setPlayAreaError("זהו שדה חובה")
    }
    else {
      setPlayAreaError("")
    }

    if (themeState.levelOfPlay.length === 0) {
      setLevelOfPlayError("זהו שדה חובה")
    }
    else {
      setLevelOfPlayError("")
    }

    if (themeState.frequencyOfPlay.length === 0) {
      setFrequencyOfPlayError("זהו שדה חובה")
    }
    else {
      setFrequencyOfPlayError("")
    }
    if (themeState.maxDistance.length === 0) {
      setMaxDistanceError("זהו שדה חובה")
    }
    else {
      setMaxDistanceError("")
    }

    if (themeState.gameTime.length === 0) {
      setGameTimeError("זהו שדה חובה")
    }
    else {
      setGameTimeError("")
    }
    if (themeState.jointGroup.length === 0) {
      setJointGroupError("זהו שדה חובה")
    }
    else {
      setJointGroupError("")
    }

    if (themeState.ageRange.length === 0) {
      setAgeRangeError("זהו שדה חובה")
    }
    else {
      setAgeRangeError("")
    }

    if (themeState.location.length === 0) {
      setLocationError("זהו שדה חובה")
    }
    else {
      setLocationError("")
    }
    if (themeState.fitness.length === 0) {
      setFitnessError("זהו שדה חובה")
    }
    else {
      setFitnessError("")
    }

    if (dobValidation === false) {
      setDobError("חייב להיות בן 13 לפחות")
    }
    else {
      setDobError("")
    }


    // console.log('dobValidation :', dobValidation)
    // console.log('themeState.playArea :', themeState.playArea)
    // console.log('themeState.levelOfPlay :', themeState.levelOfPlay)
    // console.log('themeState.frequencyOfPlay :', themeState.frequencyOfPlay)
    // console.log('themeState.maxDistance :', themeState.maxDistance)
    // console.log('themeState.gameTime :', themeState.gameTime)
    // console.log('themeState.jointGroup :', themeState.jointGroup)
    // console.log('themeState.userRole :', themeState.userRole)

    if (themeState.userRole === "PLAYER") {
      if (dobValidation === true && themeState.playArea.length > 0 && themeState.levelOfPlay.length > 0 && themeState.frequencyOfPlay.length > 0 && themeState.maxDistance.length > 0 && themeState.gameTime.length > 0 && themeState.jointGroup.length > 0) {
        setSecondCollapse(!secondCollapse)
        setFreeDays(true);
        setThirdCollapse(true)
        setConditionMap("")
      }
    }

    if (themeState.userRole === "LEADER") {
      if (themeState.fitness.length > 0 && themeState.ageRange.length > 0 && themeState.playArea.length > 0 && themeState.levelOfPlay.length > 0 && themeState.jointGroup.length > 0) {
        setSecondCollapse(!secondCollapse)
        setFreeDays(true);
        setThirdCollapse(true)
        setConditionMap("")
      }
    }

  }


  const [errorTime, setErrorTime] = useState("")

  const thirdLine = () => {



    if (themeState.allDate.length === 0 && themeState.saturday.length === 0 && themeState.sunday.length === 0 && themeState.monday.length === 0 && themeState.tuesday.length === 0 && themeState.wednesday.length === 0 && themeState.thursday.length === 0 && themeState.friday.length === 0) {
      setErrorTime("זהו שדה חובה")
    }
    else {
      setThirdCollapse(!thirdCollapse)
      setErrorTime("")
      if (themeState.userRole === "PLAYER") {
        setFindUs(true)
        setFourthCollapse(true)
      }
      if (themeState.userRole === "LEADER") {
        setPaymentOption(true)
        setPaymentCollapse(true)
      }
    }




    // }

  }

  const [creatingSuccess, setCreatingSuccess] = useState(false)
  const [termsAndCondition, setTermsAndCondition] = useState<boolean | undefined>(undefined);

  const fourthLine = () => {
    setFourthCollapse(!fourthCollapse)

    let signUp: any = {
      "publicEmail": boolValue1,
      "userRole": `${themeState.userRole}`,
      "userImage": `${themeState.iconSet}`,
      "fullName": `${themeState.fullName}`,
      "nickName": `${themeState.nickName}`,
      "telephone": `972${themeState.telephone}`,
      "email": `${themeState.email}`,
      "password": `${themeState.password}`,
      "fbName": `${themeState.fbName}`,
      "ageRange": themeState.ageRange,
      "dob": `${themeState.dob}`,
      "levelOfPlay": `${themeState.levelOfPlay}`,
      "frequencyOfPlay": `${themeState.frequencyOfPlay}`,
      "maxDistance": `${themeState.maxDistance}`,
      "howDidYouFindUs": `${themeState.findUs}`,
      "playArea": `${themeState.playArea}`,
      "location": `${themeState.location}`,
      "sportType": `${themeState.sportType}`,
      "surfaceType": `${themeState.surfaceType}`,
      "eachTeamPlayer": `${themeState.eachTeamPlayer}`,
      "gamePosition": `${themeState.gamePosition}`,
      "fitness": `${themeState.fitness}`,
      "joinedGroup": `${themeState.jointGroup}`,
      "avgGameDuration": `${themeState.gameTime}`,
      "latitude": `${themeState.latitude}`,
      "longitude": `${themeState.longitude}`,
      "city": `${themeState.city}`,
      "thirdPartyAppName": `${themeState.provider}`,
      "thirdPartyToken": `${themeState.accessToken}`,
      "country": `${themeState.country}`,
      "payment": parseInt(themeState.payment),
      "paymentType": `${themeState.paymentType}`,
      "dayTime": {
        "SUNDAY": fn("SUNDAY"),
        "MONDAY": fn("SUNDAY"),
        "TUESDAY": fn("TUESDAY"),
        "WEDNESDAY": fn("WEDNESDAY"),
        "THURSDAY": fn("THURSDAY"),
        "FRIDAY": fn("FRIDAY"),
        "SATURDAY": fn("SATURDAY")
      }
    }

    if (themeState.sportType !== "FOOTBALL") {
      delete signUp["gamePosition"];
    }

    if (themeState.telephone.length === 10) {
      signUp.telephone = `972${themeState.telephone.substring(1)}`
    }

    if (themeState.userRole === "LEADER") {
      delete signUp["maxDistance"];
      delete signUp["frequencyOfPlay"];
      delete signUp["gamePosition"];
      delete signUp["avgGameDuration"]
    }
    if (themeState.userRole === "PLAYER") {
      delete signUp["ageRange"];
      delete signUp["payment"];
      delete signUp["paymentType"];
      delete signUp["fitness"];
    }

    // setCreatingSuccess(true)

    if (termsAndCondition) {
      createUser(signUp).then((data) => {

        if (data.statusCode !== 200) {
          setLoginFail(data.message);
        }
        else {

          //  localStorage.removeItem("jwt");
          if (themeState.profileUpdate === "profileUpdate") {
            dispatch(clearData());
            dispatch(loginCheck("true"));
            authenticate(data, () => {
              return navigate("/profile");
            })
          }
          else {
            dispatch(clearData());
            dispatch(loginCheck("true"));
            return navigate('/login')
          }

        }
      })
    }

  }



  const paymentLine = () => {
    setFindUs(true)
    setPaymentCollapse(!paymentCollapse)
    setFourthCollapse(true)
  }


  const firstCollapseSet = () => {
    setFirstCollapse(!firstCollapse)
    setSecondCollapse(false)
    setThirdCollapse(false)
    setFourthCollapse(false)
    setPaymentCollapse(false)
  }
  const secondCollapseSet = () => {
    setSecondCollapse(!secondCollapse)
    setFirstCollapse(false)
    setThirdCollapse(false)
    setFourthCollapse(false)
    setPaymentCollapse(false)
  }
  const thirdCollapseSet = () => {
    setThirdCollapse(!thirdCollapse)
    setFirstCollapse(false)
    setSecondCollapse(false)
    setFourthCollapse(false)
    setPaymentCollapse(false)
  }
  const fourthCollapseSet = () => {
    setFourthCollapse(!fourthCollapse)
    setFirstCollapse(false)
    setSecondCollapse(false)
    setThirdCollapse(false)
    setPaymentCollapse(false)
  }

  const paymentCollapseSet = () => {
    setPaymentCollapse(!paymentCollapse)
    setFourthCollapse(false)
    setFirstCollapse(false)
    setSecondCollapse(false)
    setThirdCollapse(false)
  }


  const backToImage = () => {
    setSignUpUser(true)
    setProfileInfoInput(false)
    setSportTab(false)
    setGameLine(false)
    setFindUs(false)
    setFreeDays(false)
    setPaymentOption(false)
  }


  //password validation 
  const passwordValidation = () => {
    const containsUppercase = /[A-Z]/.test(userPassword);
    const containsLowercase = /[a-z]/.test(userPassword);
    const containsNumber = /\d/.test(userPassword);
    const minimumHeightChar = userPassword.length >= 8;

    if (containsUppercase && containsLowercase && containsNumber && minimumHeightChar) {
      setErrorPassword("")
    } else {
      setErrorPassword("not_strong")
    }
  }

  function publicEmailSend() {
    setPublicEmailValue(!publicEmailValue)

  }


  useEffect(() => {
    if (message.length) {
      checkMailValidation();
    }
    if (phoneNumber.length) {
      checkPhoneValidation();
    }

    // profile update
    if (themeState.profileUpdate === "profileUpdate") {
      setSignUpUser(false)
      setIconBox(false)
      setChooseOption(false)
      setProfileInfoInput(true)
    }

  }, [])


  // facebook
  const [height, setHeight] = useState(100);
  const changeHeight = (event: any) => {
    setHeight(event.target.value);
  };



  return (
    <>
      {successLogin()}
      {showError()}
      <Layout>
        <SignUpPage backgroundImage={loginman}>
          <Container>
            <Row className='d-flex align-items-center'>




              <Col md={12}>

                <div className="choosePlayingItem">
                  {/* <input
        type='range'
        onChange={changeHeight}
        min={1}
        max={400}
        step={1}
        value={height}
        className='custom-slider'
      ></input> */}

                  {signUpUser ? <><div className="rightBoxSignUp">
                    <div className="signUpBox">
                      <h1>ברוך הבא לטאפ אן סקור</h1>
                      {userIcon && userIcon.length ? <div className="userIcon" >
                        <img onClick={iconChoose} src={userIcon} />
                      </div> : <>
                        <div className="userIcon" >
                          <img onClick={iconChoose} src={signUpGroup} />
                          <p onClick={iconChoose}>בחר אייקון</p>
                        </div><p className='errorMsg'>{imageError}</p>
                      </>}

                      {imagePopup && <div className="custom-popover">
                        <PopupWithCheckbox
                          bodyText={<>בחרו <b>אייקון</b>  לפרופיל, <br /> ולאחר מכן לחצו על <b> בחר </b></>}
                          checkboxText={null}
                          buttonText={<>אשר</>}
                          isChecked={null}
                          closePopup={() => {
                            setImagePopup(false)
                          }}
                          onCheckboxClick={null}
                        ></PopupWithCheckbox>
                      </div>}

                      {/* user name */}
                      <div className={themeState.provider && themeState.provider === ThirdPartyApp.INSTAGRAM ? "input-field emailLogin" : "input-field"}>
                        <input required onChange={UserHandleChange} type="text" id="userName" name="userName" value={userName} />
                        <label>שם מלא</label>
                        <img src={user} alt="" />
                      </div>
                      {userNameError && userNameError.length ? <p className='errorMsg'>{userNameError}</p> : ""}


                      {/* user phone number */}
                      <div className={themeState.profileUpdate === "profileUpdate" ? "input-field emailLogin" : "input-field"}>
                        <input required onBlur={checkPhoneValidation} onChange={PhoneHandleChange} maxLength={12} type="number" pattern="[0-9]" value={phoneNumber} id="phoneNumber" name="phoneNumber" />
                        <label>מספר טלפון</label>
                        <span className='phoneDigit'>972+</span>
                        <img src={phone} alt="" />
                      </div>
                      {themeState.profileUpdate === "profileUpdate" ? <></> :
                        <> {errorPhone && errorPhone.length ? <p className='errorMsg'>{errorPhone}</p> : ""}</>}



                      {/* readOnly={themeState.provider ? true : false} */}

                      {/* user email */}
                      <div className={themeState.provider && (themeState.provider === ThirdPartyApp.FACEBOOK || themeState.provider === ThirdPartyApp.GOOGLE) || themeState.profileUpdate === "profileUpdate" ?
                        "input-field emailLogin" : "input-field"} >
                        <input required onBlur={checkMailValidation} onChange={EmailHandleChange} id='message' name="message" value={message} type="email" />
                        <label>מייל</label>
                        <img src={messageIcon} alt="" />
                      </div>
                      {themeState.profileUpdate === "profileUpdate" ? <></> :
                        <>{errorEmail && errorEmail.length ? <p className='errorMsg'>{errorEmail}</p> : ""}</>}


                      <div className="checkBox mt-2">
                        <div className="check">
                          <div className="roundCircle emailRoundCircle">
                            <input type="checkbox" id="publicCheckbox" onClick={publicEmailSend} />
                            <label htmlFor="publicCheckbox"><span>אל תציג את הדוא״ל שלי לאחרים</span></label>
                          </div>
                        </div>
                      </div>

                      <div className="input-field">
                        <input required id='confirmEmailMsg' name="confirmEmailMsg" onPaste={handlePaste} value={confirmEmail} onChange={handleConfirmEmailChange} type="email" />
                        <label>הזן מייל בשנית</label>
                        <img src={messageIcon} alt="" />
                      </div>
                      {confirmEmail && confirmEmail.length > 0 ? match === true ? <></> : <p className='errorMsg'>האימייל הזה לא תואם</p> : <></>}

                      {/* user password */}

                      <div className="input-field passwordShow">
                        <input required onBlur={passwordValidation} onChange={PasswordHandleChange} type={toggleType} minLength={8} value={userPassword} id="password" name="password" />
                        <label>סיסמה</label>
                        <img src={lock} alt="" />
                        {togglePassword ? <span onClick={() => showPass()}><FontAwesomeIcon icon={faEye} /> </span> : <span onClick={() => hidePass()}><FontAwesomeIcon icon={faEyeSlash} /></span>}
                      </div>
                      {errorPassword && errorPassword.length ? errorPassword === 'not_strong' ?
                        <div className='multiError'><span>סיסמה לא חזקה מידי</span><span>סיסמה חזקה היא:</span><span>לפחות אות גדולה באנגלית, אות קטנה באנגלית, לפחות מספר אחד, ומינימום של 8 תווים</span></div> : <p className='errorMsg'>{errorPassword}</p> : ""}




                      <div className="checkBox mt-2">
                        <div className="check">
                          <div className="roundCircle">
                            <input type="checkbox" id="checkbox" />
                            <label htmlFor="checkbox"><span>זכור אותי</span></label>
                          </div>
                        </div>
                      </div>

                      <div className="signUpNext">
                        <SignUpButton buttonBorder='#FB730C' buttonBackground='#FB730C;'>
                          <div className="mainBtn">
                            <button type='submit' onClick={userInfoSubmit}>
                              <p>המשך</p>
                            </button>
                          </div>
                        </SignUpButton>
                      </div>
                    </div>
                  </div></> : <></>}


                  {iconBox ?
                    <>
                      <div ref={scroll} className="iconListBox">
                        <div className="iconImg">
                          {iconList.map((data, i) => (
                            i > 0 && <div className="imgBox" ><img onClick={() => iconData(data.Name, data.icon, i)} className={`${iconShow === data.icon && "active"}`} src={data.icon} alt="" /></div>
                          ))}
                        </div>

                        <SignUpButton buttonBorder='#FB730C' buttonBackground='#FB730C;'>
                          <div className="mainBtn">
                            <button type='submit' onClick={saveUserIcon}>
                              <p>בחר</p>
                            </button>
                          </div>
                        </SignUpButton>

                      </div>

                    </> : <></>}
                  {chooseOption ? <>
                    <SelectUserType iconPath={iconPath} userSignProfile={userSignProfile} />
                  </> : <></>}

                  {/* */}
                  {profileInfoInput ? <>
                    <div className="rightBoxSignUp">
                      <div className='signUpProfileBox'>
                        <div className=''>
                          <div className="signUpTypeChoose">
                            {themeState.userRole === "PLAYER" ?
                              <div className="playerProfile" onClick={chooseUser}>
                                <img className='characterBackground' src={buttonImage} alt="" />
                                <img className='singleCharacter' src={singlePlayer} alt="" />
                                <p className='playerName'>שחקן</p>
                              </div> : <div className="playerProfile" onClick={chooseUser}>
                                <img className='characterBackground' src={buttonImage} alt="" />
                                <img className='groupCharacter' src={groupPlayer} alt="" />
                                <p className='groupName'>בעל קבוצה</p>
                              </div>}


                            <div className="iconChoose" onClick={backToImage}>
                              <img src={iconPath[themeState.iconSet]} alt="" />


                              <p> חזרה  <br />
                                לבחירת אייקון </p>
                            </div>

                          </div>

                          <h1>פרופיל</h1>

                          {/* user name */}
                          <div className="input-field">
                            <input required type="text" id="userName" name="fullName" value={userName} />
                            <label>שם מלא</label>
                            <img src={user} alt="" />
                          </div>

                          {/* user nick name */}


                          <div className="input-field">
                            <input required type="text" id="nickName" onChange={nickHandleChange} />
                            <label>{themeState.userRole === "LEADER" ? "כינוי קבוצה" : "כינוי שחקן"}</label>
                            <img src={user} alt="" />
                          </div>




                          <h1 className='mt-3'>יצירת קשר</h1>

                          {/* user phone number */}
                          <div className="input-field">
                            <input required type="number" id="userName" disabled={true} value={phoneNumber.length === 9 ? `0${phoneNumber}` : phoneNumber} />
                            {/* { themeState.profileUpdate !== "profileUpdate" && <label>מספר טלפון</label>} */}
                            <img src={phone} alt="" />
                          </div>



                          <div className="input-field">
                            <input required type="email" id="userName" disabled={true} value={message} />
                            {/* <label>מייל</label> */}
                            <img src={messageIcon} alt="" />
                          </div>

                          <div className="input-field">
                            <input required type="text" id="fbName" onChange={fbHandleChange} />
                            <label>שם בפייסבוק</label>
                            <img src={facebook} alt="" />
                          </div>

                          <label className='comment'>ניתן להוסיף כינוי + שם בפייסבוק</label>

                          <div className="signUpNext">
                            <SignUpButton buttonBorder='#FB730C' buttonBackground='#FB730C;'>
                              <div className="mainBtn">
                                <button type='submit' onClick={sportTypeChoose}>
                                  <p>המשך</p>
                                </button>
                              </div>
                            </SignUpButton>
                          </div>
                        </div>

                      </div>
                    </div>
                  </> : <></>}
                  {/*  */}

                  {/*  */}
                  {sportTab ? <>
                    {/* type of player */}
                    <div className="longCollapseLine">
                      <button className='collapseHeading'
                        onClick={() => firstCollapseSet()}
                        aria-controls="example-collapse-text"
                        aria-expanded={firstCollapse}
                      >
                        <span>סוג המשחק</span> <FontAwesomeIcon icon={faAnglesDown} />
                      </button>
                      <Collapse className='collapseBody' in={firstCollapse}>
                        <div className="">
                          <GameType footballError={footballError} footballSurfaceError={footballSurfaceError} footballEachError={footballEachError} volleyballEachError={volleyballEachError} tennisEachError={tennisEachError} basketballEachError={basketballEachError} basketballError={basketballError} volleyballError={volleyballError} tennisError={tennisError} firstLine={firstLine} />
                        </div>
                      </Collapse>


                      {gameLine ? <GameDetails setConditionMap={setConditionMap} conditionMap={conditionMap} dobError={dobError} setDobValidation={setDobValidation} dobValidation={dobValidation} fitnessError={fitnessError} playDobError={playDobError} locationError={locationError} ageRangeError={ageRangeError} jointGroupError={jointGroupError} gameTimeError={gameTimeError} maxDistanceError={maxDistanceError} frequencyOfPlayError={frequencyOfPlayError} levelOfPlayError={levelOfPlayError} playAreaError={playAreaError} secondCollapseSet={secondCollapseSet} setSecondCollapse={setSecondCollapse} secondLine={secondLine} secondCollapse={secondCollapse} /> : <></>}




                      {freeDays ? <DaysHours errorTime={errorTime} thirdCollapseSet={thirdCollapseSet} thirdLine={thirdLine} setThirdCollapse={setThirdCollapse} thirdCollapse={thirdCollapse} /> : <></>}
                      {/* {paymentCollape ?  :<></> } */}
                      {themeState.userRole === "LEADER" ?
                        <> {paymentOption ? <Payment paymentCollapseSet={paymentCollapseSet} paymentCollapse={paymentCollapse} setPaymentCollapse={setPaymentCollapse} paymentLine={paymentLine} /> : <></>}</>
                        : <></>}
                      {findUs ? <FindUs termsAndCondition={termsAndCondition} setTermsAndCondition={setTermsAndCondition} fourthCollapseSet={fourthCollapseSet} setFourthCollapse={setFourthCollapse} fourthCollapse={fourthCollapse} fourthLine={fourthLine} /> : <></>}
                    </div>
                    {/* game details */}


                  </> : <></>}


                </div>

              </Col>

            </Row>
          </Container>
        </SignUpPage>
        <WhatsAppButton />
      </Layout>
    </>
  )
}

export default Signup