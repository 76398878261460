
import styled from 'styled-components';
type Props = {
    backgroundImage: string;
    textColor:string;
    closeButton:string;
    textBackground:string;
}
export default styled.div<Props>`
    background-image: url("${(props) => props.backgroundImage}");
    background-color: #000;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 15px;
    margin-top: 20px;
    position: relative;
    height: 70vh;
    .userHeading p,
.userDetails p {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: right;
    color: ${(props) => props.textColor};
    margin-bottom: 3px;
}
.daysHour h1{
    color: ${(props) => props.textColor};
}
.daysHour p{
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: right;
    color: ${(props) => props.textColor};
    margin-bottom: 3px;
}
.daysHour p span{

}
.profileClose button {
    color: ${(props) => props.closeButton};
    font-size: 16px;
}
.profileClose button svg:hover {
    color:${(props) => props.closeButton} !important;
    font-size: 16px;
}
.socialLinks span, .socialLinksAnotherContactDetails span {
    background: ${(props) => props.textBackground};
    border-radius: 5px;
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    text-align: center; 
    color: ${(props) => props.textColor};
    padding: 2px 20px;
}
.userDetails h1 {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: right;
    color: ${(props) => props.textColor};
    margin-top: 15px;
    margin-bottom: 5px;
}
@media (max-width:778px){
    height: auto;
    padding-bottom: 220px;
    .daysHour p{
        text-align: center;
    }
}
`;