import React, { ReactNode } from "react";
import { Container } from "react-bootstrap";

interface Props {
  children?: ReactNode;
}
export default function CustomContainer({ children }: Props) {
  return (
    <Container className='px-lg-5 px-md-3 px-sm-2 px-xs-1' style={{ marginTop: "4px", marginBottom: "4px" }}>
      {children}
    </Container>
  );
}
