import 'react-toastify/dist/ReactToastify.css';
import { ToastConfig } from '../../Interfaces/ToastConfig';

export const defaultToastConfig = (
    autoClose?: number,
    position?: "top-right" | "top-left" | "bottom-right" | "bottom-left",
    hideProgressBar?: boolean,
    newestOnTop?: boolean,
    closeOnClick?: boolean,
    rtl?: boolean,
    pauseOnFocusLoss?: boolean,
    draggable?: boolean,
    pauseOnHover?: boolean,
    theme?: "light" | "dark",
): ToastConfig => ({
    autoClose: autoClose || 5000,
    position: position || 'top-right',
    hideProgressBar: hideProgressBar || true,
    newestOnTop: newestOnTop || true,
    closeOnClick: closeOnClick || true,
    rtl: rtl || true,
    pauseOnFocusLoss: pauseOnFocusLoss || false,
    draggable: draggable || true,
    pauseOnHover: pauseOnHover || false,
    theme: theme || 'light',
});

export const CustomToastIcons = {
    INFO: 'ℹ️',
    SUCCESS: "✅",
    WARNING: "⚠️",
    ERROR: "❌",
};