export enum ThirdPartyApp {
    FACEBOOK = 'FACEBOOK',
    INSTAGRAM = 'INSTAGRAM',
    GOOGLE = 'GOOGLE'
}

export interface ILoginRequeset {
    email: string,
    password?: string,
    thirdPartyAppName?: ThirdPartyApp,
    thirdPartyToken?: string
}