import styled from 'styled-components';


type Props = {
  border: string,
  heading: string,
  background: string,
  textBorder: string,
  opacityBackground: string
}
// export default styled.div`
// display: flex;
// flex-direction: ${(props) => props.direction};
// flex: ${(props) => props.flex};
// `;
export default styled.div<Props>`

  border: ${(props) => props.border};
  background: ${(props) => props.background};
  // opacity: 0.9;
  border-radius: 32px;
  
  position: relative;
  width:100%;
  height: 100%;


 .result-title {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-size: 41px;
  line-height: 105%;
  text-align: center;
  color: ${(props) => props.heading};
  -webkit-text-stroke: 1px  ${(props) => props.textBorder};
}

 .dob {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
  }
.viewBtn{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
  }

 .typeBtn {
    background: linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);
    font-family: 'Fredoka';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    border-radius: 25px;
    border: 3px solid transparent;
// border: 5px solid;
//   border-image: linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%) 1;
  }
  .typeBtn::after {
    content: "";
    background-color: #000;
    border: 3px solid red;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0px;
    border-radius: 25px;
  } 
 
  .blurAdd{
  content: '';
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.opacityBackground};
  opacity: 0.2;
  position: relative;
  width:100%;
  min-height: 100%;
  pointer-events: none;
  padding:20px;
  border-radius: 32px;
  }

  .disabled{
    opacity: 0.2;
    pointer-events: none;
    }

  .blurRemove{
    opacity: 0.9;
    padding:20px;
    // position:relative;
  }
  @media(max-width: 1580px){
    width:100%;
    height: 100%;
  }
 
  @media(max-width: 778px){
    height: 100%;
    padding:20px 5px;
    margin-top:0px;
    .resultTopLine{
      display:block;
    }
  }
  @media(max-width: 780px){
    width:100%;
    min-height: auto;
    .blurAdd{
      min-height: auto;
    }
  }
`;