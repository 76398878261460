import { ThirdPartyApp } from "../../Interfaces/LoginRequeset"
import {
    ACCESSTOKEN, PROVIDER, CHANGE_SEARCH_OPTION, CHANGE_GAME_TYPE, CHOOSE_TYPE, CHANGE_BACKTOFILTER_OPTION,
    CHANGE_LOGIN_MODE, CHANGE_MAP_CARD, CHANGE_USER_ICON,
    FULL_NAME, TELEPHONE, EMAIL, PASSWORD, USER_ROLE, GAME_POSITION, EACH_TEAM, SURFACE_TYPE,
    LEVEL_OF_PLAY, MAX_DISTANCE, GAME_TIME, FREQUENCY_OF_PLAY, SPORT_TYPE, NICK_NAME, FIND_US, AGE_RANGE,
    PLAY_AREA, JOINT_GROUP, FB_NAME, AVAILABLE_DAYS, AVAILABLE_HOURS, LOCATION, LATITUDE, LONGITUDE, DOB, CITY, COUNTRY, FITNESS,
    PAYMENT, PAYMENT_TYPE, CLEAR_DATA, PUBLIC_EMAIL, ICON, SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, ALL_DATE, LOGIN_CHECK, PROFILE_UPDATE
} from "./actionTypes"

export interface InitialState {
    provider: ThirdPartyApp | null,
    accessToken: string,
    gameType: string,
    chooseType: string,
    chooseOption: string,
    chooseFilter: string,
    loginMode: string,
    mapCard: string,
    userIcon: string,
    fullName: string,
    telephone: string,
    email: string,
    password: string,
    userRole: string,
    gamePosition: string,
    eachTeamPlayer: string,
    surfaceType: string,
    levelOfPlay: string,
    maxDistance: string,
    gameTime: string,
    frequencyOfPlay: string,
    sportType: string,
    nickName: string,
    findUs: string,
    ageRange: any,
    playArea: string,
    jointGroup: string,
    fbName: string,
    availableDays: any,
    availableHours: any,
    location: string,
    latitude: string,
    longitude: string,
    dob: string,
    city: string,
    country: string,
    fitness: string,
    payment: string
    paymentType: string,
    publicEmail: string,
    iconSet: string,

    sunday: any,
    monday: any,
    tuesday: any,
    wednesday: any,
    thursday: any,
    friday: any,
    saturday: any,
    allDate: any,
    loginCheck: string,
    profileUpdate: string
}

const initialState: InitialState | undefined = {
    provider: null,
    accessToken: "",
    gameType: "",
    chooseType: "",
    chooseOption: "url",
    chooseFilter: "",
    loginMode: "",
    mapCard: "",
    userIcon: "",
    fullName: "",
    telephone: "",
    email: "",
    password: "",
    userRole: "",
    gamePosition: "",
    eachTeamPlayer: "",
    surfaceType: "",
    levelOfPlay: "",
    maxDistance: "",
    gameTime: "",
    frequencyOfPlay: "",
    sportType: "",
    nickName: "",
    findUs: "",
    ageRange: [],
    playArea: "",
    jointGroup: "",
    fbName: "",
    availableDays: [],
    availableHours: [],
    location: "",
    latitude: "",
    longitude: "",
    dob: "",
    city: "",
    country: "",
    fitness: "",
    payment: "",
    paymentType: "",
    publicEmail: "",
    iconSet: "",
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    allDate: [],
    loginCheck: "",
    profileUpdate: ""
}

export const themeReducer = (state: InitialState = initialState, action: any) => {
    switch (action.type) {

        case PROVIDER:
            return {
                ...state,
                provider: action.payload
            }
        case LOGIN_CHECK:
            return {
                ...state,
                loginCheck: action.payload
            }

        case PROFILE_UPDATE:
            return {
                ...state,
                profileUpdate: action.payload
            }

        case ACCESSTOKEN:
            return {
                ...state,
                accessToken: action.payload
            }

        case ALL_DATE:
            return {
                ...state,
                allDate: action.payload
            }

        case SATURDAY:
            return {
                ...state,
                saturday: action.payload
            }


        case FRIDAY:
            return {
                ...state,
                friday: action.payload
            }

        case THURSDAY:
            return {
                ...state,
                thursday: action.payload
            }


        case WEDNESDAY:
            return {
                ...state,
                wednesday: action.payload
            }


        case TUESDAY:
            return {
                ...state,
                tuesday: action.payload
            }

        case MONDAY:
            return {
                ...state,
                monday: action.payload
            }

        case SUNDAY:
            return {
                ...state,
                sunday: action.payload
            }

        case ICON:
            return {
                ...state,
                iconSet: action.payload
            }
        case CHANGE_GAME_TYPE:
            return {
                ...state,
                gameType: action.payload
            }
        case CHOOSE_TYPE:
            return {
                ...state,
                chooseType: action.payload
            }
        case CHANGE_SEARCH_OPTION:
            return {
                ...state,
                chooseOption: action.payload
            }
        case CHANGE_BACKTOFILTER_OPTION:
            return {
                ...state,
                chooseFilter: action.payload
            }

        case CHANGE_LOGIN_MODE:
            return {
                ...state,
                loginMode: action.payload
            }

        case CHANGE_MAP_CARD:
            return {
                ...state,
                mapCard: action.payload
            }

        case CHANGE_USER_ICON:
            return {
                ...state,
                userIcon: action.payload
            }

        case FULL_NAME:
            return {
                ...state,
                fullName: action.payload
            }
        case TELEPHONE:
            return {
                ...state,
                telephone: action.payload
            }
        case EMAIL:
            return {
                ...state,
                email: action.payload
            }
        case PASSWORD:
            return {
                ...state,
                password: action.payload
            }
        case USER_ROLE:
            return {
                ...state,
                userRole: action.payload
            }
        case GAME_POSITION:
            return {
                ...state,
                gamePosition: action.payload
            }
        case EACH_TEAM:
            return {
                ...state,
                eachTeamPlayer: action.payload
            }
        case SURFACE_TYPE:
            return {
                ...state,
                surfaceType: action.payload
            }
        case LEVEL_OF_PLAY:
            return {
                ...state,
                levelOfPlay: action.payload
            }

        case MAX_DISTANCE:
            return {
                ...state,
                maxDistance: action.payload
            }

        case GAME_TIME:
            return {
                ...state,
                gameTime: action.payload
            }

        case FREQUENCY_OF_PLAY:
            return {
                ...state,
                frequencyOfPlay: action.payload
            }

        case SPORT_TYPE:
            return {
                ...state,
                sportType: action.payload
            }

        case NICK_NAME:
            return {
                ...state,
                nickName: action.payload
            }

        case FIND_US:
            return {
                ...state,
                findUs: action.payload
            }

        case AGE_RANGE:
            return {
                ...state,
                ageRange: action.payload
            }


        case PLAY_AREA:
            return {
                ...state,
                playArea: action.payload
            }

        case JOINT_GROUP:
            return {
                ...state,
                jointGroup: action.payload
            }

        case FB_NAME:
            return {
                ...state,
                fbName: action.payload
            }

        case AVAILABLE_DAYS:
            return {
                ...state,
                availableDays: action.payload
            }

        case AVAILABLE_HOURS:
            return {
                ...state,
                availableHours: action.payload
            }

        case LOCATION:
            return {
                ...state,
                location: action.payload
            }

        case LATITUDE:
            return {
                ...state,
                latitude: action.payload
            }

        case LONGITUDE:
            return {
                ...state,
                longitude: action.payload
            }

        case DOB:
            return {
                ...state,
                dob: action.payload
            }


        case CITY:
            return {
                ...state,
                city: action.payload
            }

        case COUNTRY:
            return {
                ...state,
                country: action.payload
            }

        case FITNESS:
            return {
                ...state,
                fitness: action.payload
            }

        case PAYMENT:
            return {
                ...state,
                payment: action.payload
            }
        case PAYMENT_TYPE:
            return {
                ...state,
                paymentType: action.payload
            }
        case PUBLIC_EMAIL:
            return {
                ...state,
                publicEmail: action.payload
            }
        // clear data
        case CLEAR_DATA:
            return {
                provider: null,
                accessToken: "",
                gameType: "",
                chooseType: "",
                chooseOption: "url",
                chooseFilter: "",
                loginMode: "",
                mapCard: "",
                userIcon: "",
                fullName: "",
                telephone: "",
                email: "",
                password: "",
                userRole: "",
                gamePosition: "",
                eachTeamPlayer: "",
                surfaceType: "",
                levelOfPlay: "",
                maxDistance: "",
                gameTime: "",
                frequencyOfPlay: "",
                sportType: "",
                nickName: "",
                findUs: "",
                ageRange: "",
                playArea: "",
                jointGroup: "",
                fbName: "",
                availableDays: [],
                availableHours: [],
                location: "",
                latitude: "",
                longitude: "",
                dob: "",
                city: "",
                country: "",
                fitness: "",
                payment: "",
                paymentType: "",
                publicEmail: "",
                iconSet: "",
                saturday: [],
                sunday: [],
                monday: [],
                wednesday: [],
                thursday: [],
                friday: [],
                tuesday: [],
                allDate: [],
                loginCheck: "",
                profileUpdate: ""
            }
        // clear data
        default:
            return state;
    }
}

export default themeReducer