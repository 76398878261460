import React, { useState } from 'react';
import { PlayerIcons } from '../../../Models/Configs/PredefinedModels';
import './playerIconModal.css';
import Localize from '../../../Localization/Localize';

interface ChildProps {
    isPopupActive: boolean;
    onData: (value: string) => void;
}

function PlayerIconModal({ isPopupActive, onData }: ChildProps) {

    const [profileIcon, setProfileIcon] = useState<string | null>(null);

    const passProfileIcon = () => {
        onData(profileIcon || "");
    };

    return (
        <div className="playerIconModal" style={{ display: isPopupActive ? "block" : "none" }}>
            <div className="playerIconWrapper">
                <div className="icon-box">
                    {
                        PlayerIcons.map((playerIcon, index) => (
                            index > 0 && <div className={profileIcon === playerIcon.Name ? "icon-wrapper-active" : "icon-wrapper"}
                                onClick={() => {
                                    setProfileIcon(playerIcon.Name);
                                }
                                }>
                                <img src={playerIcon.icon} alt="" />
                            </div>
                        ))
                    }
                </div>
                <div className="button-box">
                    <button onClick={passProfileIcon} type="submit"><p>{Localize.Submit}</p></button>
                </div>
            </div>
        </div>
    )
}

export default PlayerIconModal;
