import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Dropdown, Modal } from 'react-bootstrap';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { levelOfPlay, frequencyOfPlay, maxDistance, gameTime, findUs, availableDays, availableHours } from '../../../store/theme/actions';
import themeReducer, { InitialState } from '../../../store/theme/themeReducer';
import SignUpButton from '../../StyleComponent/SignUpButton/SignUpButton';

interface FindUsProps {
    setFourthCollapse: any;
    fourthCollapse: any;
    fourthLine: () => void;
    fourthCollapseSet: () => void;
    termsAndCondition: boolean | undefined;
    setTermsAndCondition: any;
}
const FindUs = ({ fourthCollapseSet, setFourthCollapse, fourthCollapse, fourthLine, termsAndCondition, setTermsAndCondition }: FindUsProps) => {

    const themeState = useSelector((state: InitialState) => state);
    const dispatch = useDispatch();

    const [findUsValue, setFindUs] = useState("");

    const findUsHandler = (value: string) => {
        dispatch(findUs(value));
    }
    React.useEffect(() => {
        findUsHandler(findUsValue)
    }, [findUsValue]);

    let filterDayName: string[] = [], filterDayTime: string[] = [];


    const [otherField, setOtherField] = useState(false)

    const [otherValue, setOtherValue] = useState("")
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //format days and hours
    const filterDayTimeArray = () => {
        for (let i = 0; i < themeState.availableDays.length; i++) {
            if (themeState.availableHours[i] !== "") {
                filterDayName.push(themeState.availableDays[i]);
                filterDayTime.push(themeState.availableHours[i])
            }
        }
        dispatch(availableDays(filterDayName));
        dispatch(availableHours(filterDayTime));
    }

    useEffect(() => {
        if (filterDayName.length === 0 && filterDayTime.length === 0) {
            filterDayTimeArray();
        }
    }, [])

    const otherText = (event: any) => {
        setFindUs(event.target.value)
    }

    const findUsFunction = (value: string) => {
        if (value === "Facebook") {
            setFindUs("Facebook")
            setOtherField(false)
        }
        else if (value === "WhatsApp") {
            setFindUs("WhatsApp")
            setOtherField(false)
        }
        else if (value === "Instagram") {
            setFindUs("Instagram")
            setOtherField(false)
        }
        else if (value === "ReferFriend") {
            setFindUs("ReferFriend")
            setOtherField(false)
        }
        else if (value === "student") {
            setFindUs("Student Association")
            setOtherField(false)
        }
        else {
            setFindUs(otherValue)
            setOtherField(true)
        }
    }



    const [successActive, setSuccessActive] = useState(false)
    const [chooseItem, setChooseItem] = useState(false)
    const [termsError, setTermsError] = useState("")
    const checkTerms = () => {
        setSuccessActive(!successActive);
        setTermsAndCondition(!termsAndCondition);
    }


    useEffect(() => {

        if (termsAndCondition === false) {
            setTermsError("אנא אשר תנאי שימוש ומדיניות")
        } else {
            setTermsError("")
        };
    }, [termsAndCondition])


    return (
        <>
            <div className="gameDetails">
                <button className='collapseHeading'
                    onClick={() => fourthCollapseSet()}
                    aria-controls="example-collapse-text"
                    aria-expanded={fourthCollapse}
                >
                    <span>כיצד הגעת אלינו</span> <FontAwesomeIcon icon={faAnglesDown} />
                </button>
                <Collapse className='collapseBody' in={fourthCollapse}>
                    <div className="gameDetailsBox">
                        <div className="radiobtn">
                            <input type="radio" id="facebook" onClick={() => findUsFunction("Facebook")}
                                name="drone" value="facebook" />
                            <label htmlFor="facebook">קהילת ווטסאפ</label>
                        </div>

                        <div className="radiobtn">
                            <input type="radio" id="whatsapp" onClick={() => findUsFunction("WhatsApp")}
                                name="drone" value="whatsapp" />
                            <label htmlFor="whatsapp">פייסבוק</label>
                        </div>

                        <div className="radiobtn">
                            <input type="radio" id="instagram" onClick={() => findUsFunction("Instagram")}
                                name="drone" value="instagram" />
                            <label htmlFor="instagram">אינסטגרם</label>
                        </div>
                        <div className="radiobtn">
                            <input type="radio" id="friend" onClick={() => findUsFunction("ReferFriend")}
                                name="drone" value="friend" />
                            <label htmlFor="friend">חבר שלח לי</label>
                        </div>
                        <div className="radiobtn">
                            <input type="radio" id="student" onClick={() => findUsFunction("student")}
                                name="drone" value="student" />
                            <label htmlFor="student">דרך אגודת הסטודנטים</label>
                        </div>

                        <div className="radiobtn">
                            <input type="radio" id="other"
                                name="drone" value="other" />
                            <label htmlFor="other" onClick={() => findUsFunction("other")}>אחר</label>
                        </div>
                        {otherField ? <div className="otherText">
                            <textarea placeholder='נשמח שתפרט' name="other" id="other" onChange={otherText}></textarea>
                        </div> : <></>}

                        <div className="checkBox findUsTerms">
                            <div className="check">
                                <div className="roundCircle findUsCircle">
                                    <input type="checkbox" id='checkbox' onClick={checkTerms} />
                                    <label htmlFor="checkbox"></label>

                                </div>
                            </div>
                            <span className='modalNewText' onClick={handleShow}>מאשר/ת תנאי שימוש ומדיניות פרטיות של האתר</span>
                        </div>
                        {termsAndCondition === false ? <p className='errorMsgV_2'>{termsError}</p> : ''}

                        <SignUpButton buttonBorder='#FB730C' buttonBackground='#FB730C;'>
                            <div className="mainBtn">
                                {successActive ? <button onClick={fourthLine}>
                                    <p className='' >סיימת להירשם</p>
                                </button> : <button onClick={() => setTermsAndCondition(false)}>
                                    <p className='' >סיימת להירשם</p>
                                </button>}

                            </div>
                        </SignUpButton>
                        {/* modal */}
                        <Modal
                            show={show}
                            onHide={handleClose}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title className='modalTitle'>תנאי שימוש ומדיניות פרטיות</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='termsModalBox' style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                                <h1> <u>תנאי שימוש :</u></h1>
                                <h2>1.	כללי</h2>
                                <p>מערכת אתר tapnscore.com שייכת למיזם עצמאי (לא בבעלות חברה או עוסק מורשה), בשם Tap N Score,
                                    אשר מנוהל ע״י בר זילברשטיין, ופועל ברחוב גבעת התחמושת 37, חולון, ישראל.
                                    <br />
                                    אנחנו האחראים הבלעדיים למערכת האתר ולתוכן שלה.
                                </p>
                                <p>הגישה לאתר והשימוש בו כפופים לתנאים אלו ולמדיניות הפרטיות,
                                    אנו מייעצים לקרוא אותם בקפידה לפני הסכמתך לתנאים אלו.
                                    <br />
                                    סימון לאישור (v), בשורה שלפני סיום ההרשמה, מעיד על הסכמתך כמשתמש באתר לתנאי השימוש ומדיניות הפרטיות.
                                    <br />
                                    במידה ואינך מסכים עם תנאים אלו, הנך מתבקש שלא לעשות שימוש במערכת האתר.
                                </p>
                                <p>
                                    תנאי השימוש ומדיניות הפרטיות נכתבו בלשון זכר והם מתייחסים גם ללשון נקבה.
                                    <br />
                                    פנייה בלשון יחיד משמעה גם פנייה לרבים, ולהיפך.
                                </p>
                                <p>
                                    כל שינוי, עדכון או גרסה נוספת של האתר ייחשבו כחלק ממערכת האתר ובכפוף לתנאי השימוש ומדיניות הפרטיות,
                                    וללא צורך במתן התראה כלשהי למשתמש.

                                </p>
                                <h2>2.	שימוש באתר</h2>
                                <p>tapnscore.com הינו אתר אינטרנטי, המסייע לשחקני משחקי כדור (כדורגל, כדורסל, כדורעף, טניס וכיו״ב),
                                    להתחבר עם שחקנים וקבוצות למשחקים משותפים.
                                    <br />
                                    השימוש באתר tapnscore.com מותנה בהרשמה, אשר דורשת מתן פרטים אישיים, פרטי קשר, והעדפות משחק שונות.
                                    <br />
                                    פרטי ההרשמה משמשים אותנו להפיק פרמטרים ומסננים למנועי החיפוש, הכוללים תצוגת כרטיסים ומפה אינטראקטיבית,
                                    אשר באמצעותם נוכל למצוא עבורכם שותפים רלוונטיים למשחקי כדור משותפים.

                                </p>
                                <h1> <u>מסכים עיקריים באתר :</u></h1>
                                <p><u>מסך היציע</u> – המסך שבו תוכלו למצוא שותפים למשחקים עפ״י מסננים הרלוונטיים למשחקי כדור.
                                    <br />
                                    כרטיס שחקן / קבוצה – תוצאות החיפוש, הן בתצוגת כרטיסים והן בתצוגת מפה, מגיעים בתצורת כרטיסי מידע.
                                    <br />
                                    אם הכרטיס רלוונטי עבורכם, תוכלו ללחוץ על כפתור + בצדו הימני למעלה של הכרטיס, ולקבל את הפרטים המלאים של השחקן / קבוצה, וכן גם אייקונים ליצירת קשר (ווטסאפ, טלפון, אימייל וכדומה).
                                    <br />
                                    <u>מסך ההיסטוריה</u> – כל לחיצה על כפתור + בכרטיסי המשחק במסך היציע, או לחיצה על כרטיס המשחק שלכם,
                                    מייצרים שורת מידע במסך ההיסטוריה.
                                    <br />
                                    <u>מסך הפרופיל</u> – בעמוד זה יוצגו כל פרטיכם, כפי שמילאתם בעמוד ההרשמה, אשר ניתנים לעדכון ושינוי בכל עת.
                                </p>
                                <h1><u>הערות נוספות :</u></h1>
                                <p>האתר משתמש בשירותי מיקום על מנת לחשב מרחק בין משתמשים (שחקן / קבוצה), אשר מתפקד כמסנן לחיפוש שותפים.
                                    <br />
                                    עלות ההשתתפות במשחקים של קבוצות נכתבו ע״י בעלי הקבוצות בלבד, ואין להם כל קשר למערכת האתר.
                                    <br />
                                    ניתן להסתיר את הפרופיל שלכם ממנועי החיפוש ע״י לחיצה על כפתור ׳הסתר פרופיל׳ במסך הפרופיל שלכם.
                                    <br />
                                    ניתן להירשם גם כשחקן וגם כקבוצה ע״י לחיצה על כפתור ׳החלף פרופיל׳ במסך הפרופיל שלכם.
                                </p>
                                <h2>3.	התחייבות המשתמש והגבלת אחריות</h2>
                                <p>בעת הרשמתך לאתר, הנך מתחייב כי:
                                    <ul>
                                        <li>פרטיך, אשר מילאת בהרשמה לאתר, הינם נכונים ומדויקים, ולא נעשה שימוש תחת זהות בדויה או תחת אדם אחר.</li>
                                        <li>אתה מסכים לשימוש במידע שהעברת בהרשמה, אשר נשמר במאגר המידע שבבעלותנו, למשך זמן סביר למטרות האתר והמיזם שלשמן הן נאספו, לפי שיקול הדעת הבלעדי של מערכת האתר.</li>
                                        <li>אתה מאשר כי ידוע לך כי אין חובה על פי דין למסור מידע אישי, ומסירתו תלויה בהסכמתך בלבד, וכפופה למדיניות הפרטיות של מערכת האתר.</li>
                                        <li>אתה מאשר כי המיזם לא ייקח אחריות לשום נזק (ישיר /עקיף) אשר ייגרם כתוצאה מהשענות על מידע המופיע באתר.</li>
                                        <li>לא תציג באתר מסרים ותכנים פרסומיים.</li>
                                        <li>לא תשתמש במסרים אשר יכולים להיות פוגעניים, מסיתים, או מעודדי גזענות או אלימות כנגד אדם או קבוצה.</li>
                                        <li>לא תשתמש בשמות או בסימנים מסחריים.</li>
                                        <li>לא תייצר או תפיץ וירוסים, תוכנות-עוינות, או כל אמצעי אשר עלול לפגוע ולהפריע לשימוש במערכת המחשב, שרתים, חומרה או תוכנה של המיזם או של מערכת האתר שבבעלותו של המיזם.</li>
                                        <li>לא תשתמש בבוט או תוכנה אחרת על מנת לדלות מידע ממערכת האתר בכל דרך שהיא.</li>
                                        <li>לא תייצר קישורים לאתרים חיצוניים ותפרסם אותם בפרטי ההרשמה.</li>
                                        <li>אתה מאשר כי השימוש באתר הינו על אחריותך הבלעדית והמלאה.</li>
                                        <li>אתה מאשר כי המיזם לא ייקח אחריות (ישירה / עקיפה), כספית או אחריות, לכל תוצאה או תרחיש שנבע מהשימוש באתר או בתכנים בו. לא תהא לך כל טענה, דרישה או עילת תביעה כלפי המיזם או מערכת האתר, בקשר לשימוש באתר, תקלות, השלכות השימוש בו וכיו״ב. הסכמתך לתנאים אלו מהווים ויתור על כל טענה כלפי המיזם.</li>
                                        <li>אתה מאשר כי כל מפגש או חיבור שנוצר בעקבות השימוש באתר אינו באחריות המיזם.</li>
                                        <li>אתה מאשר כי המיזם לא ייקח אחריות ליצירת קשרים או מפגשים על רקע רומנטי או מיני בעקבות השימוש באתר.</li>
                                        <li>אתה מאשר כי המיזם לא ייקח אחריות בכל הנוגע לתשלומים בגין השתתפות במשחקי הכדור כפי שפורסמו באתר.</li>
                                    </ul></p>
                                <p>מערכת האתר רשאית לחסום בכל עת ומכל סיבה, וללא מתן התראה מראש או נימוק, כל משתמש מהאתר, בין אם על ידי חסימת כתובת ה-IP, כתובת ה-MAC של המכשיר, או אפילו בהתאם למדינת המוצא, על פי שיקול המערכת,
                                    ובמיוחד מקרים שבו המשתמש הפר את תנאי השימוש, מסר פרטי הרשמה שגויים, ביצע מעשה בלתי חוקי או שיש בו לפגוע בתקינות האתר, הציג מסרים פוגעניים, פרסומיים או מסחריים.</p>
                                <p>כל זכויות האתר, לרבות קניין רוחני, תוכן האתר, עיצוב האתר, קוד האתר, קבצי מדיה, אייקונים, טקסטים וגרפיקות, וכן כל שינוי או חידוש הקשורים למערכת האתר, מהווים משוא לזכויות קינן רוחני של המיזם בלבד.</p>
                                <p>המיזם מעניק לך רישיון שימוש מוגבל, שאינו בלעדי ואינו ניתן להעברה, לעשות שימוש אישי לצרכים המתוארים בתנאי השימוש בלבד ובכפוף לתנאי השימוש ומדיניות הפרטיות.</p>
                                <p>אם המשתמש ישלח הצעות או המלצות בקשר למערכת האתר, המיזם רשאי לעשות בהם שימוש כעולה על רוחו, מבלי שיהיה חייב לשלם או לפצות את המשתמש בכל דרך שהיא.</p>
                                <h2>4. קישורים ופרסומים </h2>
                                <p>האתר עשוי לכלול קישורים לעמודים, אפליקציות, קבוצות ווטסאפ, ואתרים שונים באינטרנט אשר מנוהלים או מופעלים ע״י המיזם או מי מטעמו.</p>
                                <p>האתר עשוי לכלול מידע מסחרי שנמסר ע״י מפרסמים שונים. הפרסום אינו מהווה המלצה או עידוד לרכוש את השירותים או המוצרים המוצעים בפרסומות. המיזם לא יישא בכל אחריות שהיא לתוכן המסחרי או לתוצאת השימוש בשירות או במוצרים המוצעים.</p>
                                <p>ליצירת קשר בכל עניין יש לפנות למייל info@tapnscore.com , או דרך השארת הודעה במסך צור קשר.</p>
                                <h2>5.	אזור שיפוט</h2>
                                <p>הדין החל על השימוש באתר הוא הדין הישראלי בלבד.
                                    <br />
                                    הסמכות המקומית בכל הקשור לתנאי השימוש ומדיניות הפרטיות, ולכך הנובע מהם נתונים לבתי המשפט המוסמכים של מחוז תל-אביב.
                                </p>
                                <p>הסכמתך לתנאים אלו מהווים הסכמה שלבתי המשפט הישראליים במחוז תל-אביב תהיה הסמכות הייחודית לדון בכל הנוגע למערכת האתר ולשימוש בה.</p>
                                <p>אני מאשר שקראתי את תנאי השימוש ואת מדיניות הפרטיות, ואני מבין את תנאי השימוש באתר ומסכים להם.
                                    <br />
                                    השימוש שלי במערכת האתר מהווה הסכמה לתנאי השימוש ואת התחייבותי המלאה לעמוד בהם.
                                </p>
                                <h1> <u>מדיניות פרטיות :</u></h1>
                                <h2>1.	כללי</h2>
                                <p>מדיניות פרטיות זו מפרטת כיצד מערכת אתר tapnscore.com ומיזם Tap N Score (האחראי הבלעדי למערכת האתר), משתמשים ומגנים על כל פרטי המידע שהמשתמש מסר למערכת האתר בעת ההרשמה ושימוש באתר.
                                    <br />
                                    מערכת האתר מחויבת לשמור על הפרטיות של המשתמשים, כל פריט מידע שנבקש הינו בהתאם להצהרת פרטיות זו.
                                    <br />
                                    מערכת האתר עשויה לשנות מדיניות זו מעת לעת, ועליך האחריות לבדוק זאת ולוודא שאתה מרוצה מכל שינוי.
                                </p>
                                <p>מדיניות זו מהווה חלק בלתי נפרד מתנאי השימוש באתר.
                                    <br />
                                    תנאי השימוש ומדינות הפרטיות נכתבו בלשון זכר לשם הנוחות בלבד והם מתייחסים גם ללשון נקבה.
                                    <br />
                                    פנייה בלשון יחיד משמעה גם פנייה לרבים, ולהיפך.
                                </p>
                                <p>קרא היטב מדיניות זו.
                                    <br />
                                    אם אינך מסכים עם התנאים, זכור שיש לך הזכות המלאה להימנע ממסירת מידע ומשימוש באתר.
                                    <br />
                                    מדיניות זו תיכנס לתקוף מתאריך 1 לאפריל, 2023.
                                </p>
                                <h2>2.	איסוף מידע</h2>
                                <p>השימוש במערכת האתר מותנה בהרשמה, לצורך הרישום תידרש למסור את הפרטים הבאים:
                                    <ul>
                                        <li>פרטי הרשמה – שם משתמש, סיסמה, אימייל, או כניסה דרך מדיה חברתית (גוגל, פייסבוק וכולי).</li>
                                        <li>פרטים מזהים - שם מלא, מספר טלפון, אימייל (רשות), שם בפייסבוק (רשות), תאריך לידה (רק שחקן),
                                            מיקום (של המגרש או של אזור המגורים) ומרחק מקסימלי (רק שחקן).
                                        </li>
                                        <li>פרטים אודות המשחק - סוג משתמש (שחקן או קבוצה), סוג משחק (כדורגל, כדורסל, כדורעף, טניס),
                                            רמת משחק, רמת כושר, תדירות וזמן משחק, העדפות משחק שונות (סוג משטח, כמות שחקנים, קביעות).
                                        </li>
                                        <li>פרטים אודות זמינות וזמני משחק.</li>
                                    </ul>
                                </p>
                                <p>כל השדות לעיל הינם בגדר רישום חובה (למעט רישום אימייל לפרופיל ושם בפייסבוק שהינם רשות), והינם תנאי הכרחי לשימוש במערכת האתר. יש למלא פרטים נכונים ומדויקים, ואסור להשתמש בזהות בדויה או בפרטי אדם אחר.</p>
                                <p>משתמש יכול להירשם גם כשחקן וגם כקבוצה ע״י לחיצה על כפתור ׳החלף פרופיל׳ במסך הפרופיל שבאתר.</p>
                                <h2>3.	שימוש במידע</h2>
                                <p>המידע שנאסף ע״י המערכת משמש אותנו לתפעול השוטף של המערכת, לזיהוי משתמשים בעת כניסות חוזרות, לשפר ולהעשיר את השירותים והתכנים במערכת האתר, לאפשר לך להתאים את השירות להעדפותיך, לצורך ניתוח ובקרה,
                                    וכדי לאכוף את תנאי השימוש של מערכת האתר.
                                </p>
                                <p>המידע שנאסף מוכנס למאגר מידע שבבעלות המיזם, ומשמש אותנו להפקת מסננים ופרמטרים למציאת שותפים רלוונטיים למשחקי כדור משותפים באמצעות מנועי החיפוש שבמסך היציע.</p>
                                <p>בעת כניסה למסך היציע תוכל לבחור אופן תצוגה ומסנני חיפוש שחשובים לך בשותף למשחק הכדור.
                                    <br />
                                    באמצעות המידע שמסרת בעת ההרשמה, או בעת העדכון במסך הפרופיל, המערכת תוכל לייצר עבורך רשימת שותפים פוטנציאליים ורלוונטיים למשחקים ותציג זאת באמצעות כרטיסי משחק (שחקן / קבוצה).
                                    <br />
                                    לחיצה על כפתור + בכרטיס המשחק, ייפתח כרטיס מורחב אשר יציג את הפרטים המלאים של השחקן / קבוצה,
                                    וכן גם אייקונים ליצירת קשר דרך ווטסאפ, טלפון, אימייל, וכו׳.
                                </p>
                                <h2>4.	נראות המידע</h2>
                                <p>המידע אשר מסרת למערכת האתר יוצג במלאו למשתמשים אחרים באתר, בעת לחיצה על כפתור + בכרטיס המשחק שלך.
                                    <br />
                                    אישורך לתנאי השימוש ומדיניות פרטיות זו מהווים אישור גורף לשיתוף המידע לכלל משתמשי האתר, אשר חפצים להתחבר עימך למשחק כדור משותף.
                                    <br />
                                    פרטי הקשר שלך (טלפון, אימייל וכו׳), אינם חשופים פיזית למשתמשים, ומוצגים כאייקונים אשר יישלחו את המשתמש לאתר חיצוני על מנת ליצור עימך קשר למשחק משותף.
                                    <br />
                                    כל לחיצה על כפתור + בכרטיס המשחק שלך, יוצר שורת מידע אודות המשתמש שלחץ, במסך ההיסטוריה, על מנת ליצור שקיפות בתהליך החיבור למשחקים משותפים, וכדי שתוכל ליצור עימו קשר בעצמך.
                                </p>
                                <h2>5.	שימוש של מערכת האתר במידע</h2>
                                <p>אתה מאשר כי המיזם, אשר מפעיל את מערכת האתר, רשאי לאסוף מידע אודותיך ועל אופן השימוש שלך במערכת האתר, כמו, זמן שימוש בממוצע, כמות כניסות, היסטוריית פעולות ולחיצות במערכת האתר וכדומה.
                                    <br />
                                    ההסכמה לתנאי השימוש ומדיניות הפרטיות מהווה הסכמה מלאה לפעולות אלו.
                                </p>
                                <h2>6.	רשתות חברתיות</h2>
                                <p>מערכת האתר מכילה כפתור לשיתוף קישור האתר ברשתות חברתיות.
                                    <br />
                                    מדיניות פרטיות זו אינה חלה על פעילותן של רשתות חברתיות אלו, והמיזם אינו לוקח אחריות על כך.
                                </p>
                                <p>המידע שנאסף יכול לשמש אותנו לטובת תכני פרסום האתר ברשתות חברתיות, אישור מדיניות זו מהווה הסכמה בנושא זה.
                                    <br />
                                    דע כי פרטי יצירת הקשר שלך אינם משותפים ונחשבים חסויים גם למשתמשים באתר וגם לשימושים ברשתות חברתיות.
                                </p>
                                <h2>7.	הסתרת הפרופיל</h2>
                                <p>כפתור ׳הסתר פרופיל׳ שבמסך הפרופיל יכול לשמש אותך להסתיר את כרטיס המשחק שלך ממנועי החיפוש שבמסך היציע, לתקופת זמן שתבחר. </p>
                                <h2>8.	Cookies</h2>
                                <p>האתר עשוי להשתמש ב –Cookies, תוכנות צוברות מידע.
                                    <br />
                                    נעשה בהן שימוש לצורך תפעול שוטף של האתר, לשמירת פרטי התחברות של משתמשים, ולשימור חווית משתמש איכותית.
                                </p>
                                <h2>9.	 אבטחת מידע</h2>
                                <p>מערכת האתר שמה את נושא אבטחת המידע של המשתמשים שלה בחשיבות עליונה,
                                    אך אין אנו מבטיחים באופן מוחלט שמידע אישי לא ייחשף או ייגנב ממאגר המידע, אם זה ייפרץ.
                                    <br />
                                    בעת אישורך לתנאי השימוש ומדיניות פרטיות זו, אתה מודע לסכנות ומסכים לנושא זה.
                                </p>
                                <h2>10.	יצירת קשר למערכת האתר</h2>
                                <p>תוכל לפנות למערכת האתר בכל עניין שקשור למדיניות הפרטיות: מידע שנאסף, פרטי יצירת קשר, השבתת חשבון ועוד,
                                    דרך המייל info@tapnscore.com , או דרך השארת הודעה במסך צור קשר.
                                </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className='closeModal' onClick={handleClose}>
                                    Close
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </Collapse>
            </div>
        </>
    )
}

export default FindUs