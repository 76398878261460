import styled from 'styled-components';
type Props = {
  navBackground: string,
  sidebar: string,
  textColor: string,
  hoverText: string
}
export default styled.div<Props>`
    background: ${(props) => props.navBackground};
    opacity: 0.9;
    box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.67);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    align-items: center;
     .dropdownbutton {
      min-width: 80px !important;
      background: ${(props) => props.sidebar};
      box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.67);
      text-align: center;
      top: -6px !important;
      padding-top: 95px;
      z-index: 999;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
     .navlink{
      text-decoration: none;
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px!important;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.05em;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      align-items: center;
      padding: 3px 31px;
      border-radius: 30px;
      margin: 15px 5px;
      color: ${(props) => props.textColor};
    }
    .navlink:hover {
      background-color:${(props) => props.textColor};
      color: ${(props) => props.hoverText};
    }
    .dropdown:hover button{
      background-color:${(props) => props.textColor};
      color: ${(props) => props.hoverText};
    }
    
    .activeNavbar{
      background-color:${(props) => props.textColor};
      color: #fff;
    }
    @media screen and (max-width: 780px) {
      background: ${(props) => props.sidebar};
      opacity:1;
      padding: 20px 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      .activeNavbarIcon{
        background: ${(props) => props.textColor};
        border-radius:10px;
      }
      
    }
`;