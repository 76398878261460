import styled from 'styled-components';


type Props = {
    border: string,
    textColor: string,
    background: string
}


export default styled.div<Props>`
border: ${(props) => props.border};
background: #E4E4E4;
opacity: 1;
border-radius: 6px;
padding:5px 10px;
width:100%;
margin-top: 8px;
min-height: 40px;
 .label-title {
    font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
text-align: right;
color: ${(props) => props.textColor};
}
@media screen and (max-width: 1580px) {
    padding: 5px 10px;
    .label-title {
    font-size: 14px;
    line-height: 12px;
margin-bottom:1px;
    }
}
`;