import { Lang } from "../Enums/Lang";
import Localize from "./Localize";

const defaultLang: string = 'he';

export const initializeLocalization = () => {
    let lang = localStorage.getItem('lang');
    Localize.setLanguage(lang || defaultLang);
};

export const getCurrentLanguage = () => {
    let lang = localStorage.getItem('lang');
    if (!lang || lang === '' || !Object.values(Lang).includes(lang as Lang)) {
        lang = Lang.HEBREW;
    }
    return lang;
}