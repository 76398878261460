import styled from 'styled-components';


type Props = {
  opacityBackground: string,
  heading: string,
  textBorder: string,
  background: string
}
// export default styled.div`
// display: flex;
// flex-direction: ${(props) => props.direction};
// flex: ${(props) => props.flex};
// `;
export default styled.div<Props>`
margin-top:10px;
background: ${(props) => props.background};
opacity: 0.9;
border-radius: 0px 0px 32px 32px;
position:relative;
width:100%;
min-height: calc(68vh - 80px);
 .title {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    font-size: 41px;
    line-height: 105%;
    text-align: center;
    color: ${(props) => props.heading};
    -webkit-text-stroke: 1px ${(props) => props.textBorder};
}
.filterOption{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.filterBtnList{
  width: 100%;
}
  .filterBtnList button:nth-child(2){
      margin-left:1%;
      margin-right:1%;
    }
  .filterBtnList button:nth-child(3){
      margin-left:1%;
      margin-right:1%;
    }
  .filterBtnList button:nth-child(6){
      margin-left:1%;
      margin-right:1%;
    }
  .filterBtnList button:nth-child(7){
      margin-left: 1%;
      margin-right:1%;
    }

.opacityAdd{
  content: '';
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.opacityBackground};
  opacity: 0.2;
margin-top: 0px;
border-radius: 0px 0px 32px 32px;
position: relative;
width:100%;
height:100%;
padding: 20px 35px;
 pointer-events: none;
}
.opacityRemove{
  padding: 20px 50px;
}
.opacityAddTwo{
  content: '';
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.opacityBackground};
  opacity: 0.2;
border-radius: 0px 0px 32px 32px;
position: relative;
width:100%;
height:100%;
padding: 0px;
 pointer-events: none;
}
.opacityRemoveTwo{
  padding: 0px;
}
.chooseAdd{
  opacity: 0.35;
 pointer-events: none;
}
.chooseRemove{
  opacity: 0.9;
}
@media screen and (max-width: 1580px) {
  margin-top: 5px;
  min-height: calc(68vh - 60px);
  display: grid;
align-content: center;
  .opacityRemove{
    padding: 8px 50px;
    margin-top: 0px;
  }
  .opacityAdd{
    padding: 8px 50px;
    margin-top: 0px;
  }
  .filterOption{
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 780px) {
  opacity: 0.95;
  margin-bottom: 15px;
  .filterOption{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  .opacityRemove{
    padding: 20px 10px;
  }
  .opacityAdd{
    padding: 20px 10px;
  }
  .filterBtnList button:nth-child(odd){
    margin-left:1%;
     margin-right:0;
  }
.filterBtnList button:nth-child(even){
     margin-left:0;
    margin-right:1%;
  }


}
@media screen and ( max-height: 800px ) {
  min-height: calc(65vh - 60px);
}
`;