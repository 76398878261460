import React, { useState, useEffect } from "react";
import CustomContainer from "./customContainer";
import { Col, Row } from "react-bootstrap";
import facebook from "../../../assets/svgs/facebook_new.svg";
import tiktok from "../../../assets/svgs/tiktok_new.svg";
import insta from "../../../assets/svgs/instagram_new.svg";
import phone from "../../../assets/svgs/phone_new.svg";
import whatsapp from "../../../assets/svgs/whatsapp_new.svg";
import gmail from "../../../assets/svgs/email_new.svg";
import letsProgress from "../../../assets/images/logo/letsProgress.png";
import { useMediaQuery } from 'react-responsive';

const socialIcons = [
  {
    title: "Gmail",
    url: "mailto:info@tapnscore.com",
    icon: gmail,
    mobile: false
  },
  {
    title: "WhatsApp",
    url: "https://wa.me/+972533034698",
    icon: whatsapp,
    mobile: true
  },
  {
    title: "Phone",
    url: "tel:+972523228205",
    icon: phone,
    mobile: false
  },
  {
    title: "Insta",
    url: "https://instagram.com/tapnscore?igshid=MmIzYWVlNDQ5Yg==",
    icon: insta,
    mobile: true
  },
  {
    title: "Tiktok",
    url: "http://www.tiktok.com/@tapnscore",
    icon: tiktok,
    mobile: true
  },

  {
    title: "Facebook",
    url: "https://www.facebook.com/TapNScore",
    icon: facebook,
    mobile: true
  },
];
export default function Footer() {
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the breakpoint for mobile devices
  const [isSafari, setIsSafari] = useState<boolean>();

  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setIsSafari(isSafari)
  }, []);

  return (
    <div style={{ minHeight: isMobile ? isSafari ? '22vh' : '22vh' : isSafari ? '40vh' : '35vh', display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: '1 0 auto', marginTop: "11%" }}>
        <CustomContainer>
          <a
            style={{ color: "inherit", textDecoration: "none" }}
            href="https://www.lets-progress.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className='text-center d-flex justify-content-center'>
              <img src={letsProgress} width={70} height={35} alt="Lets Progress Logo" /> האתר עוצב ופותח ע״י <label style={{ marginRight: "0.5%", cursor: "pointer" }}><b>  Lets Progress </b></label>
            </p>
          </a>
        </CustomContainer>
      </div>

      <div
        className='fixed-bottom'
        style={{
          background: "linear-gradient(269.97deg, #FDCC2B 0.03%, #FC9D1B 105.02%)",
        }}
      >
        <CustomContainer>
          {/* <Row className={`${isMobile ? '' : 'justify-content-center'}`}> */}
          <Row className={`justify-content-center`} style={{
            gap: isMobile ? "20px" : ""
          }}>
            {socialIcons.map((item, key) => (
              (!isMobile || (isMobile && item.mobile)) ? (
                <Col
                  lg
                  sm={isMobile ? 2 : 4} // Adjust the column size for mobile devices
                  xs={isMobile ? 2 : 6} // Adjust the column size for mobile devices
                  key={key}
                  className={`d-flex justify-content-center`}
                >
                  <a href={item.url} target='_blank' rel='noopener noreferrer' className='px-1'>
                    {isMobile ? (
                      <img
                        src={item.icon}
                        style={{ width: '70px', height: '70px' }} // Adjust the image size for mobile devices
                        alt={item.title}
                      />
                    ) : (
                      <img src={item.icon} style={{ width: '80px', height: '80px' }} alt={item.title} />
                    )}
                  </a>
                </Col>
              ) : null
            ))}

          </Row>
        </CustomContainer>
      </div>
    </div>
  );
}