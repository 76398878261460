import styled from 'styled-components';
type Props = {
    backgroundImage: string,
}

export default styled.div<Props>`
background-image: url("${(props) => props.backgroundImage}");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
display:flex;
align-items:center;
    min-height:100vh;
    position:relative;
}

`;