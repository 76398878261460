// default
import default00 from '../../assets/images/signUpIcon/DEFAULT-00.png';

// basket
import basket01 from '../../assets/images/signUpIcon/BASKET-01.png';
import basket02 from '../../assets/images/signUpIcon/BASKET-02.png';
import basket03 from '../../assets/images/signUpIcon/BASKET-03.png';
import basket04 from '../../assets/images/signUpIcon/BASKET-04.png';
import basket05 from '../../assets/images/signUpIcon/BASKET-05.png';
import basket06 from '../../assets/images/signUpIcon/BASKET-06.png';
import basket07 from '../../assets/images/signUpIcon/BASKET-07.png';
import basket08 from '../../assets/images/signUpIcon/BASKET-08.png';
import basket09 from '../../assets/images/signUpIcon/BASKET-09.png';
import basket10 from '../../assets/images/signUpIcon/BASKET-10.png';
// football
import footBall01 from '../../assets/images/signUpIcon/FOOTBALL-01.png';
import footBall02 from '../../assets/images/signUpIcon/FOOTBALL-02.png';
import footBall03 from '../../assets/images/signUpIcon/FOOTBALL-03.png';
import footBall04 from '../../assets/images/signUpIcon/FOOTBALL-04.png';
import footBall05 from '../../assets/images/signUpIcon/FOOTBALL-05.png';
import footBall06 from '../../assets/images/signUpIcon/FOOTBALL-06.png';
import footBall07 from '../../assets/images/signUpIcon/FOOTBALL-07.png';
import footBall08 from '../../assets/images/signUpIcon/FOOTBALL-08.png';
import footBall09 from '../../assets/images/signUpIcon/FOOTBALL-09.png';
import footBall10 from '../../assets/images/signUpIcon/FOOTBALL-10.png';
// tennis
import tennis01 from '../../assets/images/signUpIcon/TENNIS-01.png';
import tennis02 from '../../assets/images/signUpIcon/TENNIS-02.png';
import tennis03 from '../../assets/images/signUpIcon/TENNIS-03.png';
import tennis04 from '../../assets/images/signUpIcon/TENNIS-04.png';
import tennis05 from '../../assets/images/signUpIcon/TENNIS-05.png';
import tennis06 from '../../assets/images/signUpIcon/TENNIS-06.png';
import tennis07 from '../../assets/images/signUpIcon/TENNIS-07.png';
import tennis08 from '../../assets/images/signUpIcon/TENNIS-08.png';
import tennis09 from '../../assets/images/signUpIcon/TENNIS-09.png';
import tennis10 from '../../assets/images/signUpIcon/TENNIS-10.png';
// volley
import volley01 from '../../assets/images/signUpIcon/VOLLEY-01.png';
import volley02 from '../../assets/images/signUpIcon/VOLLEY-02.png';
import volley03 from '../../assets/images/signUpIcon/VOLLEY-03.png';
import volley04 from '../../assets/images/signUpIcon/VOLLEY-04.png';
import volley05 from '../../assets/images/signUpIcon/VOLLEY-05.png';
import volley06 from '../../assets/images/signUpIcon/VOLLEY-06.png';
import volley07 from '../../assets/images/signUpIcon/VOLLEY-07.png';
import volley08 from '../../assets/images/signUpIcon/VOLLEY-08.png';
import volley09 from '../../assets/images/signUpIcon/VOLLEY-09.png';
import volley10 from '../../assets/images/signUpIcon/VOLLEY-10.png';

export interface IPlayerIcon {
    Name: string;
    icon: any;
}

export const PlayerIcons: IPlayerIcon[] = [
    { "Name": "DEFAULT-00", "icon": default00 },

    { "Name": "BASKET-01", "icon": basket01 },
    { "Name": "BASKET-02", "icon": basket02 },
    { "Name": "BASKET-03", "icon": basket03 },
    { "Name": "BASKET-04", "icon": basket04 },
    { "Name": "BASKET-05", "icon": basket05 },
    { "Name": "BASKET-06", "icon": basket06 },
    { "Name": "BASKET-07", "icon": basket07 },
    { "Name": "BASKET-08", "icon": basket08 },
    { "Name": "BASKET-09", "icon": basket09 },
    { "Name": "BASKET-10", "icon": basket10 },

    { "Name": "FOOTBALL-01", "icon": footBall01 },
    { "Name": "FOOTBALL-02", "icon": footBall02 },
    { "Name": "FOOTBALL-03", "icon": footBall03 },
    { "Name": "FOOTBALL-04", "icon": footBall04 },
    { "Name": "FOOTBALL-05", "icon": footBall05 },
    { "Name": "FOOTBALL-06", "icon": footBall06 },
    { "Name": "FOOTBALL-07", "icon": footBall07 },
    { "Name": "FOOTBALL-08", "icon": footBall08 },
    { "Name": "FOOTBALL-09", "icon": footBall09 },
    { "Name": "FOOTBALL-10", "icon": footBall10 },

    { "Name": "TENNIS-01", "icon": tennis01 },
    { "Name": "TENNIS-02", "icon": tennis02 },
    { "Name": "TENNIS-03", "icon": tennis03 },
    { "Name": "TENNIS-04", "icon": tennis04 },
    { "Name": "TENNIS-05", "icon": tennis05 },
    { "Name": "TENNIS-06", "icon": tennis06 },
    { "Name": "TENNIS-07", "icon": tennis07 },
    { "Name": "TENNIS-08", "icon": tennis08 },
    { "Name": "TENNIS-09", "icon": tennis09 },
    { "Name": "TENNIS-10", "icon": tennis10 },

    { "Name": "VOLLEY-01", "icon": volley01 },
    { "Name": "VOLLEY-02", "icon": volley02 },
    { "Name": "VOLLEY-03", "icon": volley03 },
    { "Name": "VOLLEY-04", "icon": volley04 },
    { "Name": "VOLLEY-05", "icon": volley05 },
    { "Name": "VOLLEY-06", "icon": volley06 },
    { "Name": "VOLLEY-07", "icon": volley07 },
    { "Name": "VOLLEY-08", "icon": volley08 },
    { "Name": "VOLLEY-09", "icon": volley09 },
    { "Name": "VOLLEY-10", "icon": volley10 },
];