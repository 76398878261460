import React, { useEffect } from 'react'
import PlayerCard from '../../StyleComponent/PlayerCard/PlayerCard'
// google icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGoogle, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faLocationDot, faClock, faArrowRight, faPhone, faEnvelopeOpenText, faCircleXmark, faChevronDown, faClose } from '@fortawesome/free-solid-svg-icons';

// hebrew language
import hebrew from '../../../assets/raw-text/english-hebrew.json'
import searchPage from '../../../assets/raw-text/search.json'
import commonHebrew from '../../../assets/raw-text/common.json';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { changeChooseType, changeSearchOption, changeBackToFilterOption } from '../../../store/theme/actions';
import { InitialState } from '../../../store/theme/themeReducer';

// image
import medal from '../../../assets/images/card/medal.png';
import single from '../../../assets/images/icon/single.png'
import group from '../../../assets/images/icon/group.png';
import bluePlus from '../../../assets/images/card/plusBlue.png';
import silverPlus from '../../../assets/images/card/plusSilver.png';
import headingBlue from '../../../assets/images/card/blue.png';
import headingSilver from '../../../assets/images/card/silvar.png';
import professional from '../../../assets/images/card/professional.png';
import amateur from '../../../assets/images/card/amateur.png';
import advancing from '../../../assets/images/card/advancing.png';
import medium from '../../../assets/images/card/medium.png';

// default 
import default00 from '../../../assets/images/signUpIcon/DEFAULT-00.png';

// basket
import basket01 from '../../../assets/images/signUpIcon/BASKET-01.png';
import basket02 from '../../../assets/images/signUpIcon/BASKET-02.png';
import basket03 from '../../../assets/images/signUpIcon/BASKET-03.png';
import basket04 from '../../../assets/images/signUpIcon/BASKET-04.png';
import basket05 from '../../../assets/images/signUpIcon/BASKET-05.png';
import basket06 from '../../../assets/images/signUpIcon/BASKET-06.png';
import basket07 from '../../../assets/images/signUpIcon/BASKET-07.png';
import basket08 from '../../../assets/images/signUpIcon/BASKET-08.png';
import basket09 from '../../../assets/images/signUpIcon/BASKET-09.png';
import basket10 from '../../../assets/images/signUpIcon/BASKET-10.png';
// football
import footBall01 from '../../../assets/images/signUpIcon/FOOTBALL-01.png';
import footBall02 from '../../../assets/images/signUpIcon/FOOTBALL-02.png';
import footBall03 from '../../../assets/images/signUpIcon/FOOTBALL-03.png';
import footBall04 from '../../../assets/images/signUpIcon/FOOTBALL-04.png';
import footBall05 from '../../../assets/images/signUpIcon/FOOTBALL-05.png';
import footBall06 from '../../../assets/images/signUpIcon/FOOTBALL-06.png';
import footBall07 from '../../../assets/images/signUpIcon/FOOTBALL-07.png';
import footBall08 from '../../../assets/images/signUpIcon/FOOTBALL-08.png';
import footBall09 from '../../../assets/images/signUpIcon/FOOTBALL-09.png';
import footBall10 from '../../../assets/images/signUpIcon/FOOTBALL-10.png';
// tennis
import tennis01 from '../../../assets/images/signUpIcon/TENNIS-01.png';
import tennis02 from '../../../assets/images/signUpIcon/TENNIS-02.png';
import tennis03 from '../../../assets/images/signUpIcon/TENNIS-03.png';
import tennis04 from '../../../assets/images/signUpIcon/TENNIS-04.png';
import tennis05 from '../../../assets/images/signUpIcon/TENNIS-05.png';
import tennis06 from '../../../assets/images/signUpIcon/TENNIS-06.png';
import tennis07 from '../../../assets/images/signUpIcon/TENNIS-07.png';
import tennis08 from '../../../assets/images/signUpIcon/TENNIS-08.png';
import tennis09 from '../../../assets/images/signUpIcon/TENNIS-09.png';
import tennis10 from '../../../assets/images/signUpIcon/TENNIS-10.png';
// volley
import volley01 from '../../../assets/images/signUpIcon/VOLLEY-01.png';
import volley02 from '../../../assets/images/signUpIcon/VOLLEY-02.png';
import volley03 from '../../../assets/images/signUpIcon/VOLLEY-03.png';
import volley04 from '../../../assets/images/signUpIcon/VOLLEY-04.png';
import volley05 from '../../../assets/images/signUpIcon/VOLLEY-05.png';
import volley06 from '../../../assets/images/signUpIcon/VOLLEY-06.png';
import volley07 from '../../../assets/images/signUpIcon/VOLLEY-07.png';
import volley08 from '../../../assets/images/signUpIcon/VOLLEY-08.png';
import volley09 from '../../../assets/images/signUpIcon/VOLLEY-09.png';
import volley10 from '../../../assets/images/signUpIcon/VOLLEY-10.png';


export const convertToHebrew = (key: string) => {
    let stringObj = JSON.stringify(commonHebrew)
    let mainObj = JSON.parse(stringObj)
    return mainObj[key];
}


interface SearchProps {
    data: any;
    userDetails: (value: any) => void;
}
const Card = ({ data, userDetails }: SearchProps) => {

    const [availableDays, setAvailableDays] = React.useState<string[]>([]);

    // redux
    const themeState = useSelector((state: InitialState) => state);
    // english to hebrew



    const imagePath = "/static/media/FOOTBALL-03.72c0afaa.png"


    const iconList: any = {
        "DEFAULT-00": default00,

        "BASKET-01": basket01,
        "BASKET-02": basket02,

        "BASKET-03": basket03,
        "BASKET-04": basket04,
        "BASKET-05": basket05,
        "BASKET-06": basket06,
        "BASKET-07": basket07,
        "BASKET-08": basket08,
        "BASKET-09": basket09,
        "BASKET-10": basket10,

        "FOOTBALL-01": footBall01,
        "FOOTBALL-02": footBall02,
        "FOOTBALL-03": footBall03,
        "FOOTBALL-04": footBall04,
        "FOOTBALL-05": footBall05,
        "FOOTBALL-06": footBall06,
        "FOOTBALL-07": footBall07,
        "FOOTBALL-08": footBall08,
        "FOOTBALL-09": footBall09,
        "FOOTBALL-10": footBall10,


        "TENNIS-01": tennis01,
        "TENNIS-02": tennis02,
        "TENNIS-03": tennis03,
        "TENNIS-04": tennis04,
        "TENNIS-05": tennis05,
        "TENNIS-06": tennis06,
        "TENNIS-07": tennis07,
        "TENNIS-08": tennis08,
        "TENNIS-09": tennis09,
        "TENNIS-10": tennis10,


        "VOLLEY-01": volley01,
        "VOLLEY-02": volley02,
        "VOLLEY-03": volley03,
        "VOLLEY-04": volley04,
        "VOLLEY-05": volley05,
        "VOLLEY-06": volley06,
        "VOLLEY-07": volley07,
        "VOLLEY-08": volley08,
        "VOLLEY-09": volley09,
        "VOLLEY-10": volley10
    }


    function isEnglish(text: string) {
        return /^[a-zA-Z]+$/.test(text);
    }
    function isHebrew(text: string) {
        return /[\u0590-\u05FF]/.test(text);
    }

    return (
        <>
            <PlayerCard className='d-flex justify-content-center' userText={data.levelOfPlay === 'PROFESSIONAL' ? 'linear-gradient(34.74deg, #0A2D8A 34.17%, #2A6AC7 58.39%, #0844A5 77.83%)' : "linear-gradient(83.28deg, #E2CF8A 35.84%, #D6B43F 44.34%, #E6D28D 57.75%, #E1BF45 65.68%)"} userInfo={data.levelOfPlay === 'PROFESSIONAL' ? '#fff' : "#100159"} headingImage={data.levelOfPlay === 'PROFESSIONAL' ? headingSilver : headingBlue} backgroundImage={data.levelOfPlay === 'INTERMEDIATE' ? medium : data.levelOfPlay === 'AMATEUR' ? amateur : data.levelOfPlay === 'PROFESSIONAL' ? professional : data.levelOfPlay === 'ADVANCED' ? advancing : ''} textColor='' >
                <div className="playerCard" onClick={() => userDetails(data.email)}>
                    {/* <button className='plusBtn' ><img src={data.levelOfPlay === 'PROFESSIONAL' ? silverPlus : bluePlus} alt="" /></button> */}
                    {/* <span className='removeCard' onClick={() => closeMultiple(i)}><FontAwesomeIcon icon={faClose} /></span> */}
                    <img className='medalImg' src={iconList[`${data.userImage}`]} alt="user" />
                    <div className="user-name">
                        {data.nickName && data.nickName.length ? isHebrew(data.nickName) ?
                            <h1>{data.nickName.length > 12 ? data.nickName.substring(0, 9) + '...' : data.nickName}</h1> :
                            <h1>{data.nickName.length > 12 ? '...' + data.nickName.substring(0, 9) : data.nickName}</h1>

                            :
                            <>{data.fullName && data.fullName.length ? isHebrew(data.fullName) ?
                                <h1>{data.fullName.length > 12 ? data.fullName.substring(0, 9) + '...' : data.fullName}</h1> :
                                <h1>{data.fullName.length > 12 ? '...' + data.fullName.substring(0, 9) : data.fullName}</h1>
                                : ""}</>
                        }
                    </div>
                    <button className='userBtn'>
                        <img src={data.userRole === 'PLAYER' ? single : data.userRole === 'LEADER' ? group : ""} alt="" />
                        {" "}
                        <label className='location'>{data.userRole === 'PLAYER' ? "שחקן" : "קבוצה"}</label>
                    </button>

                    {data.city && data.city.length ? <p className='location'><FontAwesomeIcon icon={faLocationDot} />{data.city}</p> : ""}

                    {data.availableDays.length === 7 ? <p className='description'>כל השבוע</p> : <>
                        {<p className='description'>
                            {data.availableDays.map((day: string, i: number) => <React.Fragment key={`${day}-${i}`}> {convertToHebrew(day)}  </React.Fragment>).slice(0, 3)}
                        </p>}
                        {<p className='descriptionOne'>
                            {data.availableDays.map((day: string, i: number) => <React.Fragment key={`${day}-${i}`}>{convertToHebrew(day)}  </React.Fragment>).slice(3, 6)}
                        </p>}
                    </>}



                </div>
            </PlayerCard>
        </>
    )
}

export default Card