import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Dropdown } from 'react-bootstrap';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { allDate, availableDays, availableHours, saturday, sunday, monday, wednesday, thursday, friday, tuesday } from '../../../store/theme/actions';
import themeReducer, { InitialState } from '../../../store/theme/themeReducer';
import SignUpButton from '../../StyleComponent/SignUpButton/SignUpButton';
import commonHebrew from '../../../assets/raw-text/common.json';
interface FeeDaysProps {
    setThirdCollapse: any;
    errorTime: any;
    thirdCollapse: any;
    thirdLine: () => void;
    thirdCollapseSet: () => void;
}
const DaysHours = ({ thirdCollapseSet, errorTime, setThirdCollapse, thirdCollapse, thirdLine }: FeeDaysProps) => {


    const themeState = useSelector((state: InitialState) => state);
    const dispatch = useDispatch();


    const [freeHours, setFreeHours] = useState("");

    const [freeDaysValue, setFreeDaysValue] = useState([]);
    const [freeHoursValue, setFreeHoursValue] = useState([]);

    const [saturdayValue, setSaturdayValue] = useState<any>(themeState.saturday && themeState.saturday ? themeState.saturday : [])
    const [sundayValue, setSundayValue] = useState<any>(themeState.sunday && themeState.sunday ? themeState.sunday : [])
    const [mondayValue, setMondayValue] = useState<any>(themeState.monday && themeState.monday ? themeState.monday : [])
    const [tuesdayValue, setTuesdayValue] = useState<any>(themeState.tuesday && themeState.tuesday ? themeState.tuesday : [])
    const [wednesdayValue, setWednesdayValue] = useState<any>(themeState.wednesday && themeState.wednesday ? themeState.wednesday : [])
    const [thursdayValue, setThursdayValue] = useState<any>(themeState.thursday && themeState.thursday ? themeState.thursday : [])
    const [fridayValue, setFridayValue] = useState<any>(themeState.friday && themeState.friday ? themeState.friday : [])
    const [allDateValue, setAllDateValue] = useState<any>(themeState.allDate && themeState.allDate ? themeState.allDate : [])

    // choose saturday
    const saturdayHandler = (value: any) => {
        dispatch(saturday(value));
    }

    React.useEffect(() => {
        saturdayHandler(saturdayValue)
    }, [saturdayValue]);

    // choose sunday
    const sundayHandler = (value: any) => {
        dispatch(sunday(value));
    }

    React.useEffect(() => {
        sundayHandler(sundayValue)
    }, [sundayValue]);

    // choose monday
    const mondayHandler = (value: any) => {
        dispatch(monday(value));
    }

    React.useEffect(() => {
        mondayHandler(mondayValue)
    }, [mondayValue]);

    // choose tuesday
    const tuesdayHandler = (value: any) => {
        dispatch(tuesday(value));
    }

    React.useEffect(() => {
        tuesdayHandler(tuesdayValue)
    }, [tuesdayValue]);

    // choose wednesday
    const wednesdayHandler = (value: any) => {
        dispatch(wednesday(value));
    }

    React.useEffect(() => {
        wednesdayHandler(wednesdayValue)
    }, [wednesdayValue]);

    // choose thursday
    const thursdayHandler = (value: any) => {
        dispatch(thursday(value));
    }

    React.useEffect(() => {
        thursdayHandler(thursdayValue)
    }, [thursdayValue]);

    // choose friday
    const fridayHandler = (value: any) => {
        dispatch(friday(value));
    }

    React.useEffect(() => {
        fridayHandler(fridayValue)
    }, [fridayValue]);

    // choose allDate
    const allDateHandler = (value: any) => {
        dispatch(allDate(value));
    }

    React.useEffect(() => {
        allDateHandler(allDateValue)
    }, [allDateValue]);





    const feeDaysHandler = (value: any) => {
        dispatch(availableDays(value));
    }
    React.useEffect(() => {
        feeDaysHandler(["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"])
    }, []);

    const feeHoursHandler = (value: any) => {
        dispatch(availableHours(value));
    }



    // player
    const PlayerHoursChoose = [
        { hoursSize: "MORNING", hoursName: "בוקר" },
        { hoursSize: "NOON", hoursName: "צהריים", },
        { hoursSize: "AFTERNOON", hoursName: "אחה\"צ" },
        { hoursSize: "EVENING", hoursName: "ערב" },
        { hoursSize: "NIGHT", hoursName: "לילה" },
        { hoursSize: "FLEXIBLE", hoursName: " גמיש" },
    ];
    // leader
    const LeaderHoursChoose = [
        { hoursSize: "MORNING", hoursName: "בוקר" },
        { hoursSize: "NOON", hoursName: "צהריים", },
        { hoursSize: "AFTERNOON", hoursName: "אחה\"צ" },
        { hoursSize: "EVENING", hoursName: "ערב" },
        { hoursSize: "NIGHT", hoursName: "לילה" },
        { hoursSize: "NOT_CONSTANT", hoursName: "לא קבוע" },
    ];


    const hoursChoose = themeState.userRole === "LEADER" ? LeaderHoursChoose : PlayerHoursChoose;

    let playerDayName = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "ALL_DATE"]
    let leaderDayName = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"]

 //   let dayName = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "ALL_DATE"]
     const dayName = themeState.userRole === "LEADER" ? leaderDayName : playerDayName;
    // let dayTime = []
    const [dayTime, setDayTime] = useState<any>([])

    let obj: any = {
        "SUNDAY": [],
        "MONDAY": [],
        "TUESDAY": [],
        "WEDNESDAY": [],
        "THURSDAY": [],
        "FRIDAY": [],
        "SATURDAY": [],
        "ALL_DATE": [],
    }

    // const daysName=

    const setDayData = (dayName: string, hours: string) => {

        let reduxData: any = [];

        const allDate: any = themeState.allDate;

        switch (dayName) {
            case "SUNDAY":
                reduxData = themeState.sunday
                break;
            case "MONDAY":
                reduxData = themeState.monday
                break;
            case "TUESDAY":
                reduxData = themeState.tuesday
                break;
            case "WEDNESDAY":
                reduxData = themeState.wednesday
                break;
            case "THURSDAY":
                reduxData = themeState.thursday
                break;
            case "FRIDAY":
                reduxData = themeState.friday
                break;
            case "SATURDAY":
                reduxData = themeState.saturday
                break;
            case "ALL_DATE":
                reduxData = themeState.allDate
                break;

        }


        //   let flag = false;
        if (dayName !== "ALL_DATE") {
            for (let i = 0; i < allDate.length; i++) {
                if (allDate[i] === hours) {
                    return;
                }
            }
        }

        const temp1 = new Set([...reduxData, hours]);
        let arr = Array.from(temp1);


        switch (dayName) {
            case "SUNDAY":
                setSundayValue([...arr])
                break;
            case "MONDAY":
                setMondayValue([...arr])
                break;
            case "TUESDAY":
                setTuesdayValue([...arr])
                break;
            case "WEDNESDAY":
                setWednesdayValue([...arr])
                break;
            case "THURSDAY":
                setThursdayValue([...arr])
                break;
            case "FRIDAY":
                setFridayValue([...arr])
                break;
            case "SATURDAY":
                setSaturdayValue([...arr])
                break;
            case "ALL_DATE":
                setAllDateValue([...arr])
                dayRemove("SUNDAY", hours);
                dayRemove("MONDAY", hours)
                dayRemove("TUESDAY", hours)
                dayRemove("WEDNESDAY", hours)
                dayRemove("THURSDAY", hours)
                dayRemove("FRIDAY", hours)
                dayRemove("SATURDAY", hours)

                break;


        }

        return arr;


    }


    const chooseHoursPosition = (freeDay: string, hourEng: any, hoursName: any) => {
        setDayData(freeDay, hourEng)
    }






    // each team
    const surfaceTeamFunction = (freeDay: string) => {
        return hoursChoose.map((data, i: number) => (
            <div key={`navbar + ${i}`}>
                {data.hoursName === freeHours ? null :
                    <div key={`navbar + ${i}`}>
                        <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => chooseHoursPosition(freeDay, data.hoursSize, data.hoursName)}>
                            <p>{data.hoursName}</p>
                        </Dropdown.Item>
                    </div>}
            </div>

        ))
    }


    // english to hebrew
    const convertToHebrew = (key: string) => {
        let stringObj = JSON.stringify(commonHebrew)
        let mainObj = JSON.parse(stringObj)
        return mainObj[key];
    }



    const dayRemove = (dayName: string, data: any) => {


        let deleteValue = ((oldValues: any) => {
            const newArr = oldValues.filter((item: any) => item !== data)
            return newArr
        })

        switch (dayName) {
            case "SUNDAY":
                setSundayValue(deleteValue)
                break;
            case "MONDAY":
                setMondayValue(deleteValue)
                break;
            case "TUESDAY":
                setTuesdayValue(deleteValue)
                break;
            case "WEDNESDAY":
                setWednesdayValue(deleteValue)
                break;
            case "THURSDAY":
                setThursdayValue(deleteValue)
                break;
            case "FRIDAY":
                setFridayValue(deleteValue)
                break;
            case "SATURDAY":
                setSaturdayValue(deleteValue)
                break;
            case "ALL_DATE":
                setAllDateValue(deleteValue)
                break;

        }
    }


    return (
        <>
            <div className="gameDetails">
                <button className='collapseHeading'
                    onClick={() => thirdCollapseSet()}
                    aria-controls="example-collapse-text"
                    aria-expanded={thirdCollapse}
                >
                    {themeState.userRole === "LEADER" ?
                        <><span>בחירת ימים ושעות משחק קבועים</span> <FontAwesomeIcon icon={faAnglesDown} /></>
                        : <><span>בחירת ימים ושעות פנויות למשחק באופן קבוע</span> <FontAwesomeIcon icon={faAnglesDown} />
                        </>}

                </button>
                <Collapse className='collapseBody' in={thirdCollapse}>
                    <div className="gameDetailsBox">
                        <div className="normalSignUpWithIcon">
                            {themeState.userRole === "LEADER" ? <input type="text" id="userName" placeholder="ימים ושעות משחק קבועים" readOnly /> : <input type="text" id="userName" placeholder="ימים ושעות משחק פנויים" readOnly />}
                          
                        </div>

                        <div className="freeDays">


                            {dayName.map((day, i) => (

                                <div className="feeHours">

                                    <Dropdown className='down'>
                                        <Dropdown.Toggle className='arrowBtn' split id="dropdown-custom-2">
                                            <span> {convertToHebrew(day)} </span><FontAwesomeIcon icon={faChevronDown} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="super-colors dropDownItem">
                                            {surfaceTeamFunction(day)}
                                        </Dropdown.Menu>
                                    </Dropdown>


                                    {day === "SUNDAY" ? <> {themeState.sunday.map((data: any) => <div className="chooseHours">{convertToHebrew(data)} <span className='closeArrow' onClick={() => dayRemove("SUNDAY", data)}>X</span> </div>)}</> : ""}
                                    {day === "MONDAY" ? <> {themeState.monday.map((data: any) => <div className="chooseHours">{convertToHebrew(data)}<span className='closeArrow' onClick={() => dayRemove("MONDAY", data)}>X</span></div>)}</> : ""}
                                    {day === "TUESDAY" ? <> {themeState.tuesday.map((data: any) => <div className="chooseHours">{convertToHebrew(data)}<span className='closeArrow' onClick={() => dayRemove("TUESDAY", data)}>X</span></div>)}</> : ""}
                                    {day === "WEDNESDAY" ? <> {themeState.wednesday.map((data: any) => <div className="chooseHours">{convertToHebrew(data)}<span className='closeArrow' onClick={() => dayRemove("WEDNESDAY", data)}>X</span></div>)}</> : ""}
                                    {day === "THURSDAY" ? <> {themeState.thursday.map((data: any) => <div className="chooseHours">{convertToHebrew(data)}<span className='closeArrow' onClick={() => dayRemove("THURSDAY", data)}>X</span></div>)}</> : ""}
                                    {day === "FRIDAY" ? <> {themeState.friday.map((data: any) => <div className="chooseHours">{convertToHebrew(data)}<span className='closeArrow' onClick={() => dayRemove("FRIDAY", data)}>X</span></div>)}</> : ""}
                                    {day === "SATURDAY" ? <> {themeState.saturday.map((data: any) => <div className="chooseHours">{convertToHebrew(data)}<span className='closeArrow' onClick={() => dayRemove("SATURDAY", data)}>X</span></div>)}</> : ""}
                                    {day === "ALL_DATE" ? <> {themeState.allDate.map((data: any) => <div className="chooseHours">{convertToHebrew(data)}<span className='closeArrow' onClick={() => dayRemove("ALL_DATE", data)}>X</span></div>)}</> : ""}
                                </div>
                            ))}

                        </div>
                        {errorTime && errorTime.length ? <p className='errorMsg'>{errorTime}</p> : ""}
                        <SignUpButton className='mt-2' buttonBorder='#FB730C' buttonBackground='#FB730C;'>
                            <div className="mainBtn">
                                <button type='submit' onClick={thirdLine} >
                                    <p>המשך</p>
                                </button>
                            </div>
                        </SignUpButton>
                    </div>
                </Collapse>
            </div>
        </>
    )
}

export default DaysHours
