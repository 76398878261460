import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../Layout/Layout'
import './NotAvailable.css'

const NotAvailable = () => {


    function groupArray(arr: { userId: number, lat: string, lag: string }[]): { key: string, lat: string, lag: string, data: { userId: number }[] }[] {
        const groups = arr.reduce((acc, curr) => {
            const { userId, lat, lag } = curr;
            const key = `${lat}_${lag}`;
            const group = acc[key] || { key, lat, lag, data: [] };
            group.data.push({ userId });
            acc[key] = group;
            return acc;
        }, {} as { [key: string]: { key: string, lat: string, lag: string, data: { userId: number }[] } });

        return Object.values(groups);
    }


    let arr = [
        { userId: 1, lat: "2.3", lag: "4.5" },
        { userId: 2, lat: "2.3", lag: "4.5" },
        { userId: 3, lat: "4.3", lag: "4.5" },
        { userId: 4, lat: "2.3", lag: "9.5" },
    ]


    return (
        <>
            <Layout>
                <div id="notfound">
                    <div className="notfound">
                        {/* <div className="notfound-404">
                            <h1>404</h1>
                        </div> */}

                        <h2>עמוד זה יעלה ממש בקרוב , עמוד היציע פעיל לשירותכם. </h2>
                        <p>
                            לשאלות נוספות מוזמנים לפנות למייל info@tapnscore.com <br />
                            או למספר הטלפון 053-3034698</p>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default NotAvailable