import React from 'react';

// image
import buttonImage from '../../../assets/images/button/button.png'
import disabledButtonImage from '../../../assets/images/button/disable-button.png'
import singlePlayer from '../../../assets/images/button/character_set.png'
import disabledSinglePlayer from '../../../assets/images/button/disable-character_set.png'
import groupPlayer from '../../../assets/images/button/group.png';
import disableGroupPlayer from '../../../assets/images/button/disable-group.png';

import { useDispatch, useSelector } from 'react-redux';
import { } from '../../../store/theme/actions';
import themeReducer, { InitialState } from '../../../store/theme/themeReducer';


interface SelectTypeProps {
    iconPath: any;
    userSignProfile: (sportType: any) => void;
}




const SelectUserType = ({ iconPath, userSignProfile }: SelectTypeProps) => {

    const themeState = useSelector((state: InitialState) => state);
    const conditionLeader = themeState.profileUpdate === "profileUpdate" && themeState.userRole === "LEADER";
    const conditionPlayer = themeState.profileUpdate === "profileUpdate" && themeState.userRole === "PLAYER";

    return (
        <>
            <div className="userTypeGame">
                <img className='userType' src={iconPath[themeState.iconSet]} alt="" />
                <h1>בחירת סוג משתמש</h1>
                <div className="playerLogo">
                    <div className={conditionLeader ? 'playerButton disableChoose' : 'playerButton'} onClick={() => userSignProfile("PLAYER")}>
                        <img className='characterBackground' src={conditionLeader ? disabledButtonImage : buttonImage} alt="" />
                        <img className='character' src={conditionLeader ? disabledSinglePlayer : singlePlayer} alt="" />
                        <p className='playerName'>שחקן</p>
                    </div>
                    <div className={conditionPlayer ? 'playerButton disableChoose' : 'playerButton'} onClick={() => userSignProfile("LEADER")}  >
                        <img className='characterBackground' src={conditionPlayer ? disabledButtonImage : buttonImage} alt="" />
                        <img className='groupCharacter' src={conditionPlayer ? disableGroupPlayer : groupPlayer} alt="" />
                        <p className='groupName'>בעל קבוצה</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectUserType