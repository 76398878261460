import styled from 'styled-components';
type Props = {
    text: string,
    background: string
}
export default styled.button<Props>`
color: ${(props) => props.text};
border:none;
border-radius: 25px;
background: ${(props) => props.background};
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-align: center;
padding:3px 12px;
margin:5px 8px;
position:relative;
.resultClose{
    position: absolute;
right: -12px;
top: -4px;
background: ${(props) => props.background};
font-size: 8px;
width: 12px;
height: 12px;
border-radius: 50%;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
}
@media screen and (max-width: 1580px) {
    padding: 1px 12px;
    margin: 1px 8px;
  }
  @media screen and (max-width: 780px) {
    font-size: 15px;
    line-height: 17px;
    padding: 3px 12px;
    margin: 6px 8px;
  }
  @media screen and (min-height: 1080px) {
    font-size: 17px;
line-height: 20px;
  }
`;