import React, { useEffect } from 'react';
import donationImageBlack from '../../../assets/images/donation/donation-black.png';
import donationImageWhite from '../../../assets/images/donation/donation-white.png';
import './KofiWidget.css';
import { useMediaQuery } from 'react-responsive';

const KofiWidget = ({blackImage=false}) => {
    const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the breakpoint for mobile devices

    return (
        <div className="button-container" style={isMobile ? {marginLeft : "10%"} : { justifyContent : 'center', alignItems : 'center'}}>
            <a href={'https://ko-fi.com/tapnscore'} target="_blank" rel="noopener noreferrer" style={{textDecoration : 'none'}}>
            <button className="custom-button" style={{color : blackImage ? 'black' : 'white'}}>
            <img width={isMobile ? 25 : 35} height={isMobile ? 25 : 35} src={blackImage ?  donationImageBlack: donationImageWhite} alt='donation-button'/>
                {isMobile ? 'עזרו לנו לגדול' : '' } </button>
            </a>
        </div>
    );
};

export default KofiWidget;
