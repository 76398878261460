import { FrequencyOfPlay } from "../../Enums/FrequencyOfPlay";
import { OptionType } from "../../Interfaces/OptionType";
import Localize from "../../Localization/Localize";

export function getFrequencyOfPlayOptions(): OptionType[] {
    return [
        { label: Localize[FrequencyOfPlay.ONCE_A_MONTH], value: FrequencyOfPlay.ONCE_A_MONTH },
        { label: Localize[FrequencyOfPlay["2-3_TIMES_A_MONTH"]], value: FrequencyOfPlay["2-3_TIMES_A_MONTH"] },
        { label: Localize[FrequencyOfPlay.ONCE_A_WEEK], value: FrequencyOfPlay.ONCE_A_WEEK },
        { label: Localize[FrequencyOfPlay["2-3_TIMES_A_WEEK"]], value: FrequencyOfPlay["2-3_TIMES_A_WEEK"] },
        { label: Localize[FrequencyOfPlay.ALMOST_EVERY_DAY], value: FrequencyOfPlay.ALMOST_EVERY_DAY },
    ];
}