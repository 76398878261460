

import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Container, Col, Row, Dropdown, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticate, allUser, userProfile, multiUserApi, getProfile, updateSearchAndprofileCheckBox } from '../../../api/auth';
import Layout from '../Layout/Layout';
import './Search.css';
// toast
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
// google icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGoogle, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faLocationDot, faClock, faArrowRight, faPhone, faEnvelopeOpenText, faCircleXmark, faChevronDown, faClose } from '@fortawesome/free-solid-svg-icons';

// enum
import { SortValue, CooseType, AgeRange, LevelOfPlay, SurfaceType, Days, Hours, TeamSize, MaxDistance, UserRole, Fitness, Tenure, ChosePlayerType } from './search.enum';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { changeChooseType, changeSearchOption, changeBackToFilterOption, changeMapCard } from '../../../store/theme/actions';
import themeReducer, { InitialState } from '../../../store/theme/themeReducer';
import SearchPage from '../../StyleComponent/SearchPage/SearchPage';

// style component
import ChooseType from '../../StyleComponent/ChooseType/ChooseType';
import PlayerButton from '../../StyleComponent/PlayerButton/PlayerButton';
import FilterBox from '../../StyleComponent/FilterBox/FilterBox';
import LevelPlay from '../../StyleComponent/LevelPlay/LevelPlay';
import FilterButton from '../../StyleComponent/FilterButton/FilterButton';
import LabelResult from '../../StyleComponent/LabelResult/LabelResult';
import LabelResultBox from '../../StyleComponent/LableResultBox/LabelResultBox';
import ResultBox from '../../StyleComponent/ResultBox/ResultBox';
import Button from '../../StyleComponent/Button/Button';
import ResetFilter from '../../StyleComponent/ResetFilter/ResetFilter';
import PopupWithCheckbox from '../../StyleComponent/PopupWithCheckbox/PopupWithCheckbox';

// search page images
import medal from '../../../assets/images/card/medal.png';
import imark from '../../../assets/images/card/imark.png';
import PlayerCard from '../../StyleComponent/PlayerCard/PlayerCard';
import professional from '../../../assets/images/card/professional.png';
import amateur from '../../../assets/images/card/amateur.png';
import advancing from '../../../assets/images/card/advancing.png';
import medium from '../../../assets/images/card/medium.png';
import headingBlue from '../../../assets/images/card/blue.png';
import headingSilver from '../../../assets/images/card/silvar.png';
import bluePlus from '../../../assets/images/card/plusBlue.png';
import silverPlus from '../../../assets/images/card/plusSilver.png';
import multiUser from '../../../assets/images/icon/multiUser.png';
import singleUser from '../../../assets/images/icon/singleuser.png';
// import iButton from '../../../assets/images/icon/info.png';
import iButton from '../../../assets/images/icon/iButton.png'
import single from '../../../assets/images/icon/single.png'
import group from '../../../assets/images/icon/group.png';
import basketBallIcon from '../../../assets/images/icon/basketball.png'
import footballIcon from '../../../assets/images/icon/football.png'
import volleyIcon from '../../../assets/images/icon/volleyball.png'
import tennisIcon from '../../../assets/images/icon/tennis.png'
// background image
import tennisBackground from '../../../assets/images/bg-image/tennis.jpg';
import basketBackground from '../../../assets/images/bg-image/basket.png';
import footballBackground from '../../../assets/images/bg-image/football.png';
import volleyBackground from '../../../assets/images/bg-image/volley.png';
import Pagination from '../../StyleComponent/Pagination/Pagination';

// hebrew language
import hebrew from '../../../assets/raw-text/english-hebrew.json'
import searchPage from '../../../assets/raw-text/search.json'
import commonHebrew from '../../../assets/raw-text/common.json';

import groupPlus from '../../../assets/images/icon/groupPlus.png'
import singlePlus from '../../../assets/images/icon/singlePlus.png'
// map
import GoogleMapReact from 'google-map-react';
import { GoogleMap, LoadScript, useLoadScript, MarkerF, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import { MAP } from '../../../config';

// child component
import Profile from './Profile';
import Card from './Card';
import InfoDropdown from './InfoDropdown';
import CardButton from './CardButton';
import MapButton from './MapButton';
import useScript from './useScript';
import MultiClose from '../../StyleComponent/MultiClose/MultiClose'

import { UserRole as UserRoleEnum } from '../../../Enums/UserRole';

// default 
import default00 from '../../../assets/images/signUpIcon/DEFAULT-00.png';

// basket
import basket01 from '../../../assets/images/signUpIcon/BASKET-01.png';
import basket02 from '../../../assets/images/signUpIcon/BASKET-02.png';
import basket03 from '../../../assets/images/signUpIcon/BASKET-03.png';
import basket04 from '../../../assets/images/signUpIcon/BASKET-04.png';
import basket05 from '../../../assets/images/signUpIcon/BASKET-05.png';
import basket06 from '../../../assets/images/signUpIcon/BASKET-06.png';
import basket07 from '../../../assets/images/signUpIcon/BASKET-07.png';
import basket08 from '../../../assets/images/signUpIcon/BASKET-08.png';
import basket09 from '../../../assets/images/signUpIcon/BASKET-09.png';
import basket10 from '../../../assets/images/signUpIcon/BASKET-10.png';
// football
import footBall01 from '../../../assets/images/signUpIcon/FOOTBALL-01.png';
import footBall02 from '../../../assets/images/signUpIcon/FOOTBALL-02.png';
import footBall03 from '../../../assets/images/signUpIcon/FOOTBALL-03.png';
import footBall04 from '../../../assets/images/signUpIcon/FOOTBALL-04.png';
import footBall05 from '../../../assets/images/signUpIcon/FOOTBALL-05.png';
import footBall06 from '../../../assets/images/signUpIcon/FOOTBALL-06.png';
import footBall07 from '../../../assets/images/signUpIcon/FOOTBALL-07.png';
import footBall08 from '../../../assets/images/signUpIcon/FOOTBALL-08.png';
import footBall09 from '../../../assets/images/signUpIcon/FOOTBALL-09.png';
import footBall10 from '../../../assets/images/signUpIcon/FOOTBALL-10.png';
// tennis
import tennis01 from '../../../assets/images/signUpIcon/TENNIS-01.png';
import tennis02 from '../../../assets/images/signUpIcon/TENNIS-02.png';
import tennis03 from '../../../assets/images/signUpIcon/TENNIS-03.png';
import tennis04 from '../../../assets/images/signUpIcon/TENNIS-04.png';
import tennis05 from '../../../assets/images/signUpIcon/TENNIS-05.png';
import tennis06 from '../../../assets/images/signUpIcon/TENNIS-06.png';
import tennis07 from '../../../assets/images/signUpIcon/TENNIS-07.png';
import tennis08 from '../../../assets/images/signUpIcon/TENNIS-08.png';
import tennis09 from '../../../assets/images/signUpIcon/TENNIS-09.png';
import tennis10 from '../../../assets/images/signUpIcon/TENNIS-10.png';
// volley
import volley01 from '../../../assets/images/signUpIcon/VOLLEY-01.png';
import volley02 from '../../../assets/images/signUpIcon/VOLLEY-02.png';
import volley03 from '../../../assets/images/signUpIcon/VOLLEY-03.png';
import volley04 from '../../../assets/images/signUpIcon/VOLLEY-04.png';
import volley05 from '../../../assets/images/signUpIcon/VOLLEY-05.png';
import volley06 from '../../../assets/images/signUpIcon/VOLLEY-06.png';
import volley07 from '../../../assets/images/signUpIcon/VOLLEY-07.png';
import volley08 from '../../../assets/images/signUpIcon/VOLLEY-08.png';
import volley09 from '../../../assets/images/signUpIcon/VOLLEY-09.png';
import volley10 from '../../../assets/images/signUpIcon/VOLLEY-10.png';
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';




interface CustomObjectType {
    sportType?: any;
    ageRange?: any;
    levelOfPlay?: any;
    surfaceType?: any;
    availableDays?: any;
    availableHours?: any;
    maxDistance?: string;
    userRole?: any;
    fitness?: any;
    tenure?: any;
}

const Search = () => {


    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const navigate = useNavigate();
    const [showPlayer, setShowPlayer] = useState(false);
    const [choosePlayer, setChoosePlayer] = useState(false);
    const [styleOpacity, setStyleOpacity] = useState("opacity");
    const [userAll, setUserAll] = useState<any>([]);
    const [noResults, setNoResults] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<any>([]);
    const [profileDetails, setProfileDetails] = useState(false);
    const [sortingValue, setSortingValue] = useState('מיין לפי');
    const [sortingPath, setSortingPath] = useState("");
    const [chooseActive, setChooseActive] = useState("");
    const [customActive, setCustomActive] = useState("");
    const [backFilter, setBackFilter] = useState("");
    const [mapFilter, setMapFilter] = useState("");
    const [customSearch, setCustomSearch] = useState({ values: Object.values(CooseType), keys: Object.keys(CooseType) });
    const [customPlayerSearch, setCustomPlayerSearch] = useState({ values: Object.values(ChosePlayerType), keys: Object.keys(ChosePlayerType) });
    const [active, setActive] = useState(null);
    const [levelActive, setLevelActive] = React.useState<string[]>([]);
    const [chooseType, setChooseType] = useState(null);
    const [finalArray, setFinalArray] = React.useState<string[]>([]);
    const [defaultVisible, setDefaultVisible] = React.useState(false);
    const [customVisible, setCustomVisible] = React.useState(false);
    // enum state
    const [levelOFPlays, setLevelOFPlays] = useState(Object.keys(LevelOfPlay));
    const [availableDaysEnum, setAvailabeDays] = useState(Object.keys(Days));
    const [availableHours, setAvailableHours] = useState(Object.keys(Hours));
    const [maxDistance, setMaxDistance] = useState(Object.keys(MaxDistance));
    const [userRole, setUserRole] = useState(Object.keys(UserRole));
    const [fitnessValue, setFitnessValue] = useState(Object.keys(Fitness));
    const [tenureValue, setTenureValue] = useState(Object.keys(Tenure));
    // pagination state
    const [page, setPage] = useState(1);
    const [runPage, setRunPage] = useState(true)
    const [prev, setPrev] = useState("");
    const [next, setNext] = useState("");

    const [defaultPage, setDefaultPage] = useState(1);
    const [defaultRun, setDefaultRun] = useState(false)
    const [defaultPrev, setDefaultPrev] = useState("");
    const [defaultNext, setDefaultNext] = useState("");
    const [defaultNumber, setDefaultNumber] = useState("");

    const [mapPage, setMapPage] = useState(1);
    const [mapPrev, setMapPrev] = useState(false);
    const [mapNext, setMapNext] = useState(false);
    const [multiNumber, setMultiNumber] = useState(0);

    const [limit, setLimit] = useState<number>(8);

    const [paginationNumber, setPaginationNumber] = useState("");
    // custom search
    const [ageRanges, setAgeRanges] = React.useState<string[]>([]);
    const [surface, setSurface] = React.useState<string[]>([]);
    const [arrLevelOfPlay, setLevelOfPlay] = React.useState<string[]>([]);
    const [hebrewLevelOfPlay, setHebLevelOfPlay] = React.useState<string[]>([]);
    const [availableDays, setAvailableDays] = React.useState<string[]>([]);
    const [availableHour, setAvailableHour] = React.useState<string[]>([]);
    const [maximumDistance, setMaximumDistance] = React.useState<string>("");
    const [maximumObject, setMaximumObject] = React.useState();
    const [userType, setUserType] = React.useState<string[]>([]);

    const [headingLevel, setHeadingLevel] = useState("");

    const [showAge, setShowAge] = React.useState<string[]>([]);
    const [showLevel, setShowLevel] = React.useState<string[]>([]);
    const [showDay, setShowDay] = React.useState<string[]>([]);
    const [showHour, setShowHour] = React.useState<string[]>([]);
    const [showDistance, setShowDistance] = React.useState<string[]>([]);
    const [showUser, setShowUser] = React.useState<string[]>([]);

    const [showFitness, setFitness] = React.useState<string[]>([]);
    const [fitnessLevel, setFitnessLevel] = React.useState<string[]>([]);


    const [showTenure, setTenure] = React.useState<string[]>([]);
    const [tenureLevel, setTenureLevel] = React.useState<string[]>([]);
    const [customPagination, setCustomPagination] = useState(false);
    const [defaultPagination, setDefaultPagination] = useState(false)
    // map card
    const [widthMap, setWidthMap] = React.useState(false);
    const [singleMapCard, setSingleMapCard] = React.useState(false);
    const [doubleMapCard, setDoubleMapCard] = React.useState(false);
    const [mapLocationSearch, setMapLocationSearch] = React.useState(false);
    const [mapUser, setMapUser] = useState<any>([]);
    const [groupArrayValue, setGroupArray] = useState<any>([])
    const [mapSelectedIndex, setMapSelectedIndex] = useState(-3);
    const [activeMarker, setActiveMarker] = useState(null);
    const [mapBox, SetMapBox] = useState(true)
    const [mapGroup, setMapGroup] = useState<any>([]);
    // disable button
    const [disableAdd, setDisableAdd] = React.useState<string[]>([]);

    const [allSearch, setAllSearch] = React.useState<string[]>([]);
    const [levelData, setLevelData] = useState("");
    // max disable
    const [maxData, setMaxData] = React.useState<string[]>([]);
    const [disableMax, setDisableMax] = React.useState<string[]>([]);
    const [disableShow, setDisableShow] = React.useState("");
    const [maxDisplay, setMaxDisplay] = React.useState(false);

    const [mapLocationString, setMapLocationString] = React.useState("");
    const [markLocation, setMarkLocation] = useState<any>([]);
    const [showMap, setShowMap] = useState(false)
    // user profile
    const { user } = isAuthenticate();
    const [disableButton, setDisableButton] = useState(false)
    // modal
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const modalClose = () => setShow(false);
    const mapModal = () => setShow(true);
    const [userProfileData, setUserProfileData] = React.useState<any>([]);
    const [playerAgeRange, setPlayerAgeRange] = React.useState<string>("");
    const [newLevel, setNewLevel] = React.useState<string[]>([]);
    const [mapState, setMapState] = React.useState<any>([])
    const [centerMark, setCenterMark] = React.useState<any>([])
    const [newData, setNewData] = useState<any>([]);
    const [mapDisable, setMapDisable] = useState(false)
    // redux
    const themeState = useSelector((state: InitialState) => state);
    const dispatch = useDispatch();
    const defaultPosition = { lat: 31.4117, lng: 35.0818 }

    const iconList: any = {
        "DEFAULT-00": default00,

        "BASKET-01": basket01,
        "BASKET-02": basket02,

        "BASKET-03": basket03,
        "BASKET-04": basket04,
        "BASKET-05": basket05,
        "BASKET-06": basket06,
        "BASKET-07": basket07,
        "BASKET-08": basket08,
        "BASKET-09": basket09,
        "BASKET-10": basket10,

        "FOOTBALL-01": footBall01,
        "FOOTBALL-02": footBall02,
        "FOOTBALL-03": footBall03,
        "FOOTBALL-04": footBall04,
        "FOOTBALL-05": footBall05,
        "FOOTBALL-06": footBall06,
        "FOOTBALL-07": footBall07,
        "FOOTBALL-08": footBall08,
        "FOOTBALL-09": footBall09,
        "FOOTBALL-10": footBall10,


        "TENNIS-01": tennis01,
        "TENNIS-02": tennis02,
        "TENNIS-03": tennis03,
        "TENNIS-04": tennis04,
        "TENNIS-05": tennis05,
        "TENNIS-06": tennis06,
        "TENNIS-07": tennis07,
        "TENNIS-08": tennis08,
        "TENNIS-09": tennis09,
        "TENNIS-10": tennis10,


        "VOLLEY-01": volley01,
        "VOLLEY-02": volley02,
        "VOLLEY-03": volley03,
        "VOLLEY-04": volley04,
        "VOLLEY-05": volley05,
        "VOLLEY-06": volley06,
        "VOLLEY-07": volley07,
        "VOLLEY-08": volley08,
        "VOLLEY-09": volley09,
        "VOLLEY-10": volley10
    }



    // sorting
    const sorting = [
        { sortName: "איפוס למיון", sortPath: "" },
        { sortName: " גיל", sortPath: 'ageRange' },
        { sortName: "רמת משחק", sortPath: 'levelOfPlay' },
        { sortName: "מרחק מקסימלי בק״מ", sortPath: 'maxDistance' },

    ];

    // custom search value
    // let customObject: any = {
    //     "sportType": `${themeState.gameType}`,
    //     "ageRange": [],
    //     "levelOfPlay": [],
    //     "surfaceType": [],
    //     "availableDays": [],
    //     "availableHours": [],
    //     // "maxDistance": `${maximumDistance}`,
    //     "userRole": [],
    //     "fitness": [],
    //     "joinedGroup": []
    // }

    let customObject: any = {
        "user": { sportType: [themeState.gameType] },
        "age": {},
        "daysTime": {},
        "distance": {}
    }



    // choose search type
    const gameTypeHandler = (value: string) => {
        dispatch(changeChooseType(value));
    }

    React.useEffect(() => {
        gameTypeHandler(chooseActive)
    }, [chooseActive])

    // choose search option
    const gameChooseHandler = (value: string) => {
        dispatch(changeSearchOption(value));
    }

    React.useEffect(() => {
        gameChooseHandler(customActive)
    }, [customActive]);

    // back to filter redux
    const gameFilterHandler = (value: string) => {
        dispatch(changeBackToFilterOption(value));
    }

    React.useEffect(() => {
        gameFilterHandler(backFilter)
    }, [backFilter]);
    // map card filter redux
    const mapFilterHandler = (value: string) => {
        dispatch(changeMapCard(value));
    }

    React.useEffect(() => {
        mapFilterHandler(mapFilter)
    }, [mapFilter]);

    // opacity blur
    const filterOpacity = () => {

        if (windowSize.current[1] < 720) {
            setLimit(8)
        }
        else if (windowSize.current[1] > 720 && windowSize.current[1] < 950) {
            setLimit(8)
        }
        else if (windowSize.current[1] > 950 && windowSize.current[1] < 1250) {
            setLimit(12)
        }
        else if (windowSize.current[1] > 1250 && windowSize.current[1] < 1450) {
            setLimit(16)
        }


        setChooseActive("cards");
        setMapLocationSearch(false);

        if (!searchPopupFirstTime) {
            setSearchPopupShow(true);
            setSearchPopupFirstTime(true);
        }

    };


    let userObject = {
        "userRole": ['PLAYER', 'LEADER'],
        "sportType": [`${themeState.gameType}`],
        "availableDays": userProfileData.availableDays,
        "availableHours": userProfileData.availableHours,
        "maxDistance": userProfileData.maxDistance,
        "levelOfPlay": [userProfileData.levelOfPlay]

    }

    // user profile



    const userProfileSave = () => {
        userProfile(user.email).then((data) => {
            if (data.statusCode !== 200) {
                navigate("/login")
            }
            else {
                setUserProfileData(data.user)

                setMapState(data.user)

                if (data.user.userRole === "PLAYER") {
                    if (data.user.age >= 14 && data.user.age <= 17) {
                        setPlayerAgeRange("14-17")
                    } else if (data.user.age >= 18 && data.user.age <= 29) {
                        setPlayerAgeRange("18-29")
                    } else if (data.user.age >= 30 && data.user.age <= 49) {
                        setPlayerAgeRange("30-49")
                    } else {
                        setPlayerAgeRange("50+")
                    }
                }

                else {
                    setPlayerAgeRange(data.user.ageRange)
                }
            }

            // }
        });
    }

    const [searchPopupShow, setSearchPopupShow] = useState<boolean>(false);
    const [isSearchChecked, setIsSearchChecked] = useState<boolean>(false);
    const [searchPopupFirstTime, setSearchPopupFirstTime] = useState<boolean>(false);
    const [outsideClickDetectionEnabled, setOutsideClickDetectionEnabled] = useState<boolean>(false);
    const customPopupWithCheckbox = useRef<HTMLDivElement>(null);

    const [profilePopupShow, setProfilePopupShow] = useState<boolean>(false);
    const [isProfileChecked, setIsProfileChecked] = useState<boolean>(false);
    const [profilePopupFirstTime, setProfilePopupFirstTime] = useState<boolean>(false);
    const [outsideClickDetectionEnabledForProfile, setOutsideClickDetectionEnabledForProfile] = useState<boolean>(false);
    const customPopupWithCheckboxForProfile = useRef<HTMLDivElement>(null);
    const customPopupNoResult = useRef<HTMLDivElement>(null);
    const [outsideClickDetectionEnabledForNoResult, setOutsideClickDetectionEnabledForNoResult] = useState<boolean>(false);

    const [profileCheckPopup, setProfileCheckPopup] = useState<boolean>(false);
    useEffect(() => {
        const handleClick = (event: any) => {
            if (customPopupWithCheckbox.current && !customPopupWithCheckbox.current.contains(event.target)) {
                if (outsideClickDetectionEnabled) {
                    setSearchPopupShow(false);
                    if (isSearchChecked) {
                        updateSearchAndprofileCheckBox("searchCheckBox", isSearchChecked).then((data) => {
                            if (data.statusCode === StatusCodes.OK) {

                            } else {
                                console.log(data.message);
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                    setOutsideClickDetectionEnabled(false);
                }
                if (searchPopupShow) {
                    setOutsideClickDetectionEnabled(true);
                }
            }
        };
        document.body.addEventListener('click', handleClick);
        return () => {
            document.body.removeEventListener('click', handleClick);
        };
    }, [searchPopupShow, outsideClickDetectionEnabled, searchPopupShow, isSearchChecked]);

    useEffect(() => {
        const handleClickForProfilePopup = (event: any) => {
            if (customPopupWithCheckboxForProfile.current && !customPopupWithCheckboxForProfile.current.contains(event.target)) {
                if (outsideClickDetectionEnabledForProfile) {
                    setProfilePopupShow(false);
                    if (isProfileChecked) {
                        updateSearchAndprofileCheckBox("profileCheckBox", isProfileChecked).then((data) => {
                            if (data.statusCode === StatusCodes.OK) {

                            } else {
                                console.log(data.message);
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                    setOutsideClickDetectionEnabledForProfile(false);
                }
                if (profilePopupShow) {
                    setOutsideClickDetectionEnabledForProfile(true);
                }
            }
        };
        document.body.addEventListener('click', handleClickForProfilePopup);
        return () => {
            document.body.removeEventListener('click', handleClickForProfilePopup);
        };
    }, [profilePopupShow, outsideClickDetectionEnabledForProfile, profilePopupShow, isProfileChecked]);

    useEffect(() => {
        const handleClickForNoResultPopup = (event: any) => {
            if (customPopupNoResult.current && !customPopupNoResult.current.contains(event.target)) {
                if (outsideClickDetectionEnabledForNoResult) {
                    setNoResults(false);
                    setOutsideClickDetectionEnabledForNoResult(false);
                }
            }
        };
        document.body.addEventListener('click', handleClickForNoResultPopup);
        return () => {
            document.body.removeEventListener('click', handleClickForNoResultPopup);
        };
    }, [noResults, outsideClickDetectionEnabledForNoResult]);

    useEffect(() => {
        userProfileSave();
        getProfile().then((data) => {
            if (data && data.data && data.data.user) {
                if (data.data.user.searchCheckBox) {
                    setSearchPopupFirstTime(true);
                }
                if (data.data.user.profileCheckBox) {
                    setProfilePopupFirstTime(true);
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);


    useEffect(() => {
        let current = themeState.gameType;

        if (current === themeState.gameType) {
            resetFilter()
            backToFilter()
            setCustomActive("")
            setChooseActive("")
            setUserType([])
        }
        else {
            console.log('fail');

        }
    }, [themeState.gameType]);

    // back to filter
    const backToFilter = () => {
        if (windowSize.current[0] < 800) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        // resetFilter()
        setUserAll([])
        setPage(1)
        setMapFilter("")

        setCustomPagination(false)
        setDefaultPagination(false)
        setDoubleMapCard(false)
        setWidthMap(false)
        setSingleMapCard(false)
        setDisableButton(false)
        setMapDisable(false)
        //  setGroupArray([])
        setCustomActive("customSearch");
        setShowMap(false)

        if (themeState.chooseOption === "defaultSearch") {
            setCustomActive("defaultSearchRemove")
            resetFilter()
            setBackFilter("");
            setDefaultVisible(false);
            setProfileDetails(false);
            setMapLocationSearch(false);
            setActive(null)
        }
        else {

            setCustomActive("customSearch")
            setBackFilter("");
            setDefaultVisible(false);
            setProfileDetails(false);
            setMapLocationSearch(false);
            setGroupArray([])

        }
    }


    // default all user
    const defaultFilter = () => {
        resetFilter();
        if (windowSize.current[0] < 800) {
            window.scrollTo({
                top: 1050,
                behavior: 'smooth',
            });
            setTimeout(() => {
                window.scrollTo({
                    top: 1050,
                    behavior: 'smooth',
                });
            }, 100);
        }

        customObject['user'].userRole = Object.values(UserRoleEnum);

        allUser(sortingPath, customObject, limit, defaultPage).then((data) => {
            if (data.statusCode != 200) {
                setNoResults(true);
                setOutsideClickDetectionEnabledForNoResult(true);
            }
            else {
                setNoResults(false);
                if (themeState.chooseType === 'map') {
                    setMapLocationSearch(true)
                    setDefaultVisible(false)
                    // other
                    setShowPlayer(!showPlayer);
                    setCustomActive("defaultSearch");
                    setStyleOpacity("opacity")
                    setDisableButton(true);
                    setMapDisable(false);
                    SetMapBox(true)
                }
                else {
                    setDefaultVisible(true)
                    setMapLocationSearch(false)
                    // other
                    setShowPlayer(!showPlayer);
                    setCustomActive("defaultSearch");
                    setStyleOpacity("opacity");
                    setDisableButton(false);
                    setMapDisable(true);
                }

                setDefaultPagination(true)
                let pageNumber = Math.ceil(data.total / limit);
                setDefaultNumber(pageNumber.toString())
                if (data.prev === true) {
                    setDefaultPrev('btn prevActive')
                }
                else {
                    setDefaultPrev('btn prevDisable')
                }
                if (data.next === true) {
                    setDefaultNext('btn prevActive')
                }
                else {
                    setDefaultNext('btn prevDisable')
                }

                const users = data.users.map((u: any) => {
                    const uniqueDays = [...new Set(u.dayTime.map((item: any) => item.availableDay))];
                    return { ...u, availableDays: uniqueDays }
                });

                setUserAll(users)

                let newMap = [userProfileData, ...users]

                setMapState(newMap)
                setNewData(newMap)
                setCenterMark(newMap[0])
                setGroupArray(groupArray(newMap))

                if (!profilePopupFirstTime && data.users.length > 0) {
                    setProfilePopupShow(true);
                    setProfilePopupFirstTime(true);
                }
            }

        });

    };



    useEffect(() => {
        if (Object.keys(userObject).length > 0 && defaultPagination) {
            defaultFilter()
        }
    }, [defaultRun]);



    const loadUserInfo = (email: any) => {

        userProfile(email).then((data) => {

            if (data.statusCode != 200) {
                // console.log(data.message);
            }
            else {
                let tempObj: any = {}

                const uniqueDays: string[] = [];

                data.user.dayTime.forEach((item: any) => {
                    if (!uniqueDays.includes(item.availableDay)) {
                        uniqueDays.push(item.availableDay);
                    }
                });


                tempObj = { ...data.user, availableDays: uniqueDays }




                setUserInfo(tempObj)
                setMapUser(tempObj)
                setMarkLocation(tempObj)
            }
        });
    }


    // User profile show
    const userDetails = (email: string) => {
        setUserInfo([])
        setProfileDetails(!profileDetails);
        setDefaultVisible(false);
        SetMapBox(false)
        setDoubleMapCard(false)

        loadUserInfo(email)

    }

    // close profile button
    const closeProfile = () => {
        setProfileDetails(!profileDetails);

        if (themeState.chooseType === "cards") {
            setDefaultVisible(true)
            setMapLocationSearch(false)
        }
        if (themeState.chooseType === "map") {
            setDefaultVisible(false)

            if (themeState.mapCard === "singleCard") {
                setMapLocationSearch(true)
                SetMapBox(true)
                setSingleMapCard(false)
            }
            else if (themeState.mapCard === "multiCard") {
                setMapLocationSearch(true)
                SetMapBox(true)
                setSingleMapCard(true)
                setDoubleMapCard(true)
            }
            // multi

            // setShowPlayer(!showPlayer);
        }
    }


    // sorting dropdown
    const sortingDropdown = () => {
        return sorting.map((data, i) => (
            data.sortName === sortingValue ? <></> :
                <Dropdown.Item key={"sort" + i} onClick={() => sortingDropdownValue(data.sortName, data.sortPath)}>{data.sortName}</Dropdown.Item>
        ))
    }


    // search value
    const showSearchValue = (data: any, i: number) => {
        if (customSearch.keys[i] === 'MAXIMUM_DISTANCE') {
            setMaxDisplay(true)
        }
        else {
            setMaxDisplay(false)
        }

        setActive(data);
        // level data show
        setLevelData(customSearch.keys[i]);
        // setHeadingLevel(customSearch.keys[i])
        setHeadingLevel(customSearch.values[i])
        setNewLevel([...newLevel, data]);

        if (customSearch.keys[i] === 'AGE') {
            setAllSearch(Object.values(AgeRange))
        }
        else if (customSearch.keys[i] === 'LEVEL_OF_PLAY') {
            setAllSearch(Object.values(LevelOfPlay))
        }
        else if (customSearch.keys[i] === 'AVAILABLE_DAYS') {
            setAllSearch(Object.values(Days))
        }
        else if (customSearch.keys[i] === 'AVIALABLES_HOURS') {
            setAllSearch(Object.values(Hours))
        }
        else if (customSearch.keys[i] === 'MAXIMUM_DISTANCE') {
            setAllSearch(Object.values(MaxDistance));
            setMaxData(Object.values(MaxDistance))
        }
        else if (customSearch.keys[i] === 'USER_TYPE') {
            setAllSearch(Object.values(UserRole))
        }
        else if (customSearch.keys[i] === 'FITNESS') {
            setAllSearch(Object.values(Fitness))
        }
        else if (customSearch.keys[i] === 'TENURE') {
            setAllSearch(Object.values(Tenure))
        }
    }





    const finalSearch = (data: string, i: number) => {
        setDisableAdd([...disableAdd, data]);
        //  setDisableMax([data])

        setLevelActive([...levelActive, data]);

        if (levelData === 'AGE') {
            setAgeRanges([...ageRanges, data])

        }

        if (levelData === 'LEVEL_OF_PLAY') {
            setHebLevelOfPlay([...hebrewLevelOfPlay, levelOFPlays[i]])
            setShowLevel([...showLevel, data])
        }

        if (levelData === 'AVAILABLE_DAYS') {
            setAvailableDays([...availableDays, availableDaysEnum[i]])
            setShowDay([...showDay, data])
        }

        if (levelData === 'AVIALABLES_HOURS') {
            setAvailableHour([...availableHour, availableHours[i]])
            setShowHour([...showHour, data]);
        }

        if (levelData === 'MAXIMUM_DISTANCE') {



            if (maximumDistance.length === 0) {
                setShowDistance([data]);
                setDisableMax([data])

                setMaximumDistance(maxDistance[i])
            }



        }
        if (levelData === 'USER_TYPE') {
            setUserType([...userType, userRole[i]])
            setShowUser([...showUser, data])
        }

        if (levelData === 'FITNESS') {
            setFitnessLevel([...fitnessLevel, fitnessValue[i]])
            setFitness([...showFitness, data])
        }

        if (levelData === 'TENURE') {
            setTenureLevel([...tenureLevel, tenureValue[i]])
            setTenure([...showTenure, data])
        }


        setFinalArray([...finalArray, data])

    }


    // multiple user function
    function groupArray(arr: { userId: number, userRole: string, email: string, latitude: string, longitude: string }[]): { key: string, latitude: string, longitude: string, data: { email: string }[] }[] {
        const groups = arr.reduce((acc, curr) => {
            const { userId, email, userRole, latitude, longitude } = curr;
            const key = (email !== user.email) ? `${latitude}_${longitude}` : `CURRENT_${latitude}_${longitude}`;
            // const key = `${latitude}_${longitude}`;
            const group = acc[key] || { key, userRole, latitude, longitude, data: [] };
            group.data.push({ email, userId });
            acc[key] = group;
            return acc;
        }, {} as { [key: string]: { key: string, userRole: string, latitude: string, longitude: string, data: { email: string, userId: number }[] } });
        return Object.values(groups);
    }




    // custom search
    const customSearchFunction = () => {
        if (windowSize.current[0] < 800) {
            window.scrollTo({
                top: 1000,
                behavior: 'smooth',
            });
            setTimeout(() => {
                window.scrollTo({
                    top: 1000,
                    behavior: 'smooth',
                });
            }, 100);
        }

        customObject['age'].ageRange = ageRanges;
        customObject['user'].levelOfPlay = hebrewLevelOfPlay;
        customObject['daysTime'].availableDay = availableDays;
        customObject['daysTime'].availableHour = availableHour;
        customObject['user'].userRole = userType.length === 0 ? Object.values(UserRoleEnum) : userType;
        customObject['user'].fitness = fitnessLevel;
        customObject['user'].joinedGroup = tenureLevel;


        if (maximumDistance.length > 0) {
            customObject['distance'].maxDistance = maximumDistance;
        }

        allUser(sortingPath, customObject, limit, page).then((data: any) => {
            if (data.statusCode != 200) {
                setNoResults(true);
                setOutsideClickDetectionEnabledForNoResult(true);
                setError(data.message);
            }
            else {
                setNoResults(false);
                if (themeState.chooseType === 'map') {
                    SetMapBox(true)
                }
                setCustomPagination(true)
                let pageNumber = Math.ceil(data.total / limit);
                setPaginationNumber(pageNumber.toString())
                if (data.prev === true) {
                    setPrev('btn prevActive')
                }
                else {
                    setPrev('btn prevDisable')
                }
                if (data.next === true) {
                    setNext('btn prevActive')
                }
                else {
                    setNext('btn prevDisable')
                }
                let newMap = [userProfileData, ...data.users]
                setMapState(newMap)
                setNewData(newMap)
                setCenterMark(newMap[0])
                setGroupArray(groupArray(newMap))

                const users = data.users.map((u: any) => {
                    const uniqueDays = [...new Set(u.dayTime.map((item: any) => item.availableDay))];
                    return { ...u, availableDays: uniqueDays }
                });
                setUserAll(users)

                if (!profilePopupFirstTime && data.users.length > 0) {
                    setProfilePopupShow(true);
                    setProfilePopupFirstTime(true);
                }
            }

        });

        setBackFilter("searchChoose");
        setCustomActive("backSearch");

        setMapLocationSearch(true);

        if (themeState.chooseType === 'map') {
            setMapLocationSearch(true)
            setDefaultVisible(false)
            setDisableButton(true)
        }
        else {
            setDefaultVisible(true)
            setMapLocationSearch(false)
            setMapDisable(true)
        }
    }

    useEffect(() => {
        if (ageRanges.length + showLevel.length + showDay.length + showHour.length + showDistance.length + showUser.length + showFitness.length + showTenure.length > 0) {
            customSearchFunction()
            setMapLocationSearch(false)
        }
    }, [runPage]);




    const removeSearch = (index: number, data: any, name: string) => {

        let deleteValue = ((oldValues: any) => {
            const newArr = oldValues.filter((item: any) => item !== data)

            return newArr
        })

        let deleteMax = ((oldValues: any) => {
            return oldValues.filter((_: any, i: number) => i !== index)
        })


        setDisableAdd(deleteValue)

        setDisableMax([])

        if (name === 'ageRanges') {
            setAgeRanges(deleteValue)
        }
        else if (name === 'levelOf') {
            setShowLevel(deleteValue)
            setHebLevelOfPlay(deleteMax)
        }
        else if (name === 'availableDays') {
            setShowDay(deleteValue)
            setAvailableDays(deleteMax)
        }
        else if (name === 'availableHour') {
            setShowHour(deleteValue)
            setAvailableHour(deleteMax)
        }
        else if (name === 'maximumDistance') {
            setMaximumDistance("")
            setShowDistance(deleteValue)

        }


        else if (name === 'userType') {
            setShowUser(deleteValue)
            setUserType(deleteMax)
        }
        else if (name === 'fitness') {
            setFitness(deleteValue)
            setFitnessLevel(deleteMax)
        }
        else if (name === 'tenure') {
            setTenure(deleteValue)
            setTenureLevel(deleteMax)
        }

    }



    const mapLocation = () => {
        setChooseActive("map");
        setLimit(0);

        if (!searchPopupFirstTime) {
            setSearchPopupShow(true);
            setSearchPopupFirstTime(true);
        }
    }

    const customFilter = (event: any) => {
        setDefaultVisible(false)
        setCustomActive(event.target.id);
    }


    //    sorting api 
    const sortingDropdownValue = (sortName: any, sortPath: any) => {

        const sortBy = sortPath;

        customObject['age'].ageRange = ageRanges;
        customObject['user'].levelOfPlay = hebrewLevelOfPlay;
        customObject['daysTime'].availableDay = availableDays;
        customObject['daysTime'].availableHour = availableHour;
        customObject['user'].userRole = userType.length === 0 ? Object.values(UserRoleEnum) : userType;
        customObject['user'].fitness = fitnessLevel;
        customObject['user'].joinedGroup = tenureLevel;

        if (maximumDistance.length > 0) {
            customObject['distance'].maxDistance = maximumDistance;
        }

        setSortingValue(sortName);
        setSortingPath(sortPath);
        setDefaultPage(1)
        setPage(1)
        if (themeState.chooseOption === "defaultSearch") {
            allUser(sortPath, customObject, limit, 1).then((data) => {
                if (data.statusCode != 200) {
                    setNoResults(true);
                    setOutsideClickDetectionEnabledForNoResult(true);
                    setError(data.message);
                }
                else {
                    setNoResults(false);
                    const users = data.users.map((u: any) => {
                        const uniqueDays = [...new Set(u.dayTime.map((item: any) => item.availableDay))];
                        return { ...u, availableDays: uniqueDays }
                    });
                    setUserAll(users)

                    if (data.prev === true) {
                        setDefaultPrev('btn prevActive')
                    }
                    else {
                        setDefaultPrev('btn prevDisable')
                    }
                    if (data.next === true) {
                        setDefaultNext('btn prevActive')
                    }
                    else {
                        setDefaultNext('btn prevDisable')
                    }
                }

            });
        }
        else {

            allUser(sortPath, customObject, limit, 1).then((data) => {

                if (data.statusCode != 200) {
                    setNoResults(true);
                    setOutsideClickDetectionEnabledForNoResult(true);
                    setError(data.message);
                }
                else {
                    setNoResults(false);
                    const users = data.users.map((u: any) => {
                        const uniqueDays = [...new Set(u.dayTime.map((item: any) => item.availableDay))];
                        return { ...u, availableDays: uniqueDays }
                    });
                    setUserAll(users)

                    if (data.prev === true) {
                        setPrev('btn prevActive')
                    }
                    else {
                        setPrev('btn prevDisable')
                    }
                    if (data.next === true) {
                        setNext('btn prevActive')
                    }
                    else {
                        setNext('btn prevDisable')
                    }
                }
            });

        }

    }


    // reset search filter
    const resetFilter = () => {
        setAgeRanges([])
        setAllSearch([]);
        setShowLevel([]);
        setHebLevelOfPlay([]);
        setShowDay([]);
        setAvailableDays([])
        setShowHour([]);
        setAvailableHour([])
        setShowDistance([]);
        setShowUser([]);
        setTenure([]);
        setTenureLevel([])
        setFitness([]);
        setFitnessLevel([])
        setHeadingLevel("")
        setDisableAdd([])
        setActive(null)
        setGroupArray([])
        // setDisableShow("")
        setShowDistance([]);
        setMaxData([])
        setDisableMax([])
        setMaximumDistance("")
        setUserType([])

    }


    // english to hebrew
    const convertToHebrew = (key: string) => {
        let stringObj = JSON.stringify(commonHebrew)
        let mainObj = JSON.parse(stringObj)
        return mainObj[key];
    }


    // map card gallery
    const singleMap = () => {
        setSingleMapCard(false)
        setWidthMap(true)
        setDoubleMapCard(false)
    }

    const mapCardClose = () => {
        setShowMap(true)
        setSingleMapCard(false)
        setWidthMap(false)
        setMapSelectedIndex(-3)

    }

    const mapMultiClose = () => {
        setShowMap(true)
        setMapSelectedIndex(-3)
        setSingleMapCard(false);
        setWidthMap(true);
        // setGroupArray([]);
        setSingleMapCard(false)
        setWidthMap(false);
        setDoubleMapCard(false)
    }


    const doubleMap = () => {
        // setDoubleMapCard(true)
        setSingleMapCard(true);
        setWidthMap(false)
    }

    useEffect(() => {
        if (mapGroup && mapGroup.length) {
            checkMultiMapLocation();
        }
    }, [mapGroup])


    const handleActiveMarker = (email: any) => {

        if (email.length === 1) {
            loadUserInfo(email[0].email)
            singleMap();
            setMapFilter("singleCard")
            setShowMap(true)
        }
        else {
            doubleMap()
            setMapFilter("multiCard")
            setDoubleMapCard(true)
            setShowMap(true)
            setMultiNumber(Math.ceil(email.length / 3))

            let mapArr = []
            for (let i = 0; i < email.length; i++) {
                mapArr.push(email[i].email)
            }

            //state array - setMapGroup
            const take = 3, page = mapPage;
            const pageNo = page || 1;
            const skip = (pageNo - 1) * take;
            let next: boolean = false, prev: boolean = false;
            let newUser = [];
            setMapPage(pageNo)

            for (let i = 0; i < mapArr.length; i++) {
                next = true;
                if (i >= skip && i < skip + take) {
                    newUser.push(mapArr[i])
                }
                if (i === mapArr.length - 1) next = false;
                if (newUser.length === 3) break;
            }

            prev = skip ? true : false
            setMapNext(next);
            setMapPrev(prev);
            multiUserApi({ "email": newUser }).then((data) => {
                let tempObj: any = []
                for (let i = 0; i < data.users.length; i++) {
                    const uniqueDays: string[] = [];
                    data.users[i].dayTime.forEach((item: any) => {
                        if (!uniqueDays.includes(item.availableDay)) {
                            uniqueDays.push(item.availableDay);
                        }
                    });
                    tempObj.push({ ...data.users[i], availableDays: uniqueDays })
                }




                setMapGroup(tempObj)
                setMarkLocation(tempObj[0])
            })


        }

        if (email === activeMarker) {
            return;
        }
        setActiveMarker(email);
    };


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${MAP}`,
    });

    const options = {
        visible: true,
        // radius: 3000,
        zIndex: 1
    }

    //set map index update

    useEffect(() => {
        if (mapSelectedIndex > 0 && groupArrayValue && groupArrayValue.length) {
            handleActiveMarker(groupArrayValue[mapSelectedIndex].data);
        }
    }, [mapSelectedIndex])

    useEffect(() => {

        if (groupArrayValue && groupArrayValue.length) {
            handleActiveMarker(groupArrayValue[mapSelectedIndex].data);
        }
    }, [mapPage])


    //error tooltip

    const showError = () => {
        return (
            <>
                <ToastContainer position="bottom-end" className="p-3">
                    <Toast onClose={() => setError(false)} show={error} delay={5000} autohide>
                        <div className="bg-danger text-white">
                            <Toast.Body>{error}</Toast.Body>
                        </div>
                    </Toast>
                </ToastContainer>
            </>
        );
    };


    const setUpdateMapPaginationValue = (value: number) => {
        setMapSelectedIndex(1);
        setMapPage(1);
        setMapSelectedIndex(value)
    }

    //check multi location 
    const checkMultiMapLocation = () => {

        let tempMapLocation = "";
        let flag = false;
        for (let i = 0; i < mapGroup.length; i++) {
            if (i === 0) {
                tempMapLocation = mapGroup[i].location;
            } else {
                if (tempMapLocation !== mapGroup[i].location) {
                    flag = true;
                    break;
                }
            }
        }

        if (flag === true) {
            setMapLocationString("")
        } else {
            setMapLocationString(tempMapLocation)
        }


    }

    return (
        <>
            {showError()}
            <Layout>

                <SearchPage className='searchDiv' backgroundImage={themeState.gameType === 'FOOTBALL' ? footballBackground : themeState.gameType === 'TENNIS' ? tennisBackground : themeState.gameType === 'VOLLEYBALL' ? volleyBackground : themeState.gameType === 'BASKETBALL' ? basketBackground : footballBackground}>

                    <Container fluid>
                        <Row>
                            <Col md={5}>
                                <div className="filterBoxSide">

                                    <ChooseType textBorder={themeState.gameType === 'FOOTBALL' ? '#fff' : '#000'} heading={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} paragraphText={themeState.gameType === 'FOOTBALL' ? '#fff' : '#000'} background={themeState.gameType === 'FOOTBALL' ? '#000000cc' : '#ffffffcc'}>
                                        {/* iButton information */}
                                        <InfoDropdown />
                                        <h1 className="title">{searchPage["Welcome_title"]}</h1>
                                        <p className="dob">כאן תמצאו את <b>השותפים האידיאליים</b> למשחקים משותפים</p>
                                        <p className="viewBtn">{searchPage["Select_view"]}</p>
                                        {/* card search */}
                                        <CardButton disableButton={disableButton} chooseActive={chooseActive} filterOpacity={filterOpacity} />
                                        {/* map search */}
                                        <MapButton mapDisable={mapDisable} chooseActive={chooseActive} mapLocation={mapLocation} />

                                    </ChooseType>
                                    <FilterBox opacityBackground={themeState.gameType === 'FOOTBALL' ? '#000' : '#fff'} textBorder={themeState.gameType === 'FOOTBALL' ? '#fff' : '#000'} heading={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} background={themeState.gameType === 'FOOTBALL' ? '#000000cc' : '#ffffff'}>

                                        <div className={themeState.chooseType === 'cards' ? 'opacityRemove' : themeState.chooseType === '' ? 'opacityAdd' : themeState.chooseType === 'map' ? 'opacityRemove' : ''}>
                                            <div className={themeState.chooseOption === 'defaultSearch' ? 'opacityAddTwo' : themeState.chooseOption === 'defaultSearchRemove' ? '' : ''}>

                                                <div className={themeState.chooseFilter === 'searchChoose' ? 'opacityAddTwo' : ''}>

                                                    <h1 className="title">מסננים</h1>
                                                    {/* chooseFilter searchChoose */}
                                                    <div className="filterOption">
                                                        {/* default search */}
                                                        <Button buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                                                            <div className="mainBtn">
                                                                <button className={customActive === "defaultSearch" ? "active" : undefined} id={"defaultSearch"} onClick={defaultFilter}>
                                                                    <p className={customActive === "defaultSearch" ? "active" : undefined} id={"defaultSearch"}>{searchPage["All_the_results"]}</p>
                                                                </button>
                                                            </div>
                                                        </Button>
                                                        {/* custom search */}
                                                        <Button buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                                                            <div className="mainBtn">
                                                                <button className={customActive === "customSearch" ? "active" : undefined} id={"customSearch"} onClick={customFilter}>
                                                                    <p className={customActive === "customSearch" ? "active" : undefined} id={"customSearch"}>{searchPage["Custom_search"]}</p>
                                                                </button>
                                                            </div>
                                                        </Button>

                                                        {searchPopupShow && <div className="click-disable-overlay"></div>}
                                                        {/* <button onClick={() => {
                                                            setSearchPopupShow(true);
                                                            console.log(searchPopupShow);
                                                        }}>XX</button> */}
                                                        {searchPopupShow && <div ref={customPopupWithCheckbox} className="custom-popover">
                                                            <PopupWithCheckbox
                                                                bodyText={<>יש לבחור כעת את  <b>המסננים</b>  הרלוונטיים<br /> ולאחר מכן ללחוץ <b>חפש</b></>}
                                                                checkboxText={<>לא להציג הודעה זאת שוב</>}
                                                                buttonText={<>אשר</>}
                                                                isChecked={isSearchChecked}
                                                                closePopup={() => {
                                                                    setSearchPopupShow(false);
                                                                    if (isSearchChecked) {
                                                                        updateSearchAndprofileCheckBox("searchCheckBox", isSearchChecked).then((data) => {
                                                                            if (data.statusCode === StatusCodes.OK) {

                                                                            } else {
                                                                                console.log(data.message);
                                                                            }
                                                                        }).catch((error) => {
                                                                            console.log(error);
                                                                        });
                                                                    }
                                                                    setOutsideClickDetectionEnabled(false);
                                                                }}
                                                                onCheckboxClick={(isChecked) => { setIsSearchChecked(isChecked) }}
                                                            ></PopupWithCheckbox>
                                                        </div>}
                                                    </div>
                                                    <div className={themeState.chooseOption === 'customSearch' ? 'searchChoose chooseRemove' : themeState.chooseOption === 'backSearch' ? 'searchChoose chooseRemove' : 'searchChoose chooseAdd'}>
                                                        {/* custom search list */}

                                                        <div className="filterBtnList">
                                                            <div>   {userProfileData.userRole === 'PLAYER' ?
                                                                <>{customPlayerSearch.values.map((data: string, i: number) =>
                                                                    <FilterButton key={'search' + i} textColor={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} background='#E4E4E4' onClick={() => showSearchValue(data, i)} className={`${active == data && "active"}`}>{data}</FilterButton>
                                                                )}</> :
                                                                <>{customSearch.values.map((data: string, i: number) =>
                                                                    <FilterButton key={'searchValue' + i} textColor={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} background='#E4E4E4' onClick={() => showSearchValue(data, i)} className={`${active == data && "active"}`}>{data}</FilterButton>
                                                                )}</>}</div>


                                                        </div>
                                                        {/* level play */}

                                                        <LevelPlay className='levelPlay' textColor={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} border='none' background='red'>
                                                            <h1 className="label-title">{headingLevel}</h1>
                                                            {maxDisplay ? <>
                                                                {maxData.map((data: any, i: number) =>
                                                                    <PlayerButton key={'level' + i} textColor={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} background='#fff' onClick={() => finalSearch(data, i)} className={`${disableMax.indexOf(data) !== -1 && "active"}`}>{data}</PlayerButton>
                                                                )}
                                                            </> : <>
                                                                {allSearch.map((data: any, i: number) =>
                                                                    <PlayerButton key={'levelData' + i} textColor={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} background='#fff' onClick={() => finalSearch(data, i)} className={`${disableAdd.indexOf(data) !== -1 && "active"}`}>{data}</PlayerButton>
                                                                )} </>}


                                                            {/* */}
                                                        </LevelPlay>

                                                        {/* all list custom search list */}

                                                        <LabelResultBox border='none' backgroundColor={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(180deg, #81F881 0%, #08AF2B 100%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(180deg, #11C7FF 0%, #048C9D 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(360deg, #024FD2 -40.49%, #9268F6 57.09%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(180deg, #FFC73D 0%, #FC7C11 100%);' : 'linear-gradient(180deg, #81F881 0%, #08AF2B 100%);'}>
                                                            {ageRanges.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'ageRanges')}>X</span></LabelResult>)}
                                                            {showLevel.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'levelOf')}>X</span></LabelResult>)}
                                                            {showDay.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'availableDays')}>X</span></LabelResult>)}
                                                            {showHour.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'availableHour')}>X</span></LabelResult>)}
                                                            {showDistance.map((data: any, index: any) =>
                                                                <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>
                                                                    {data}<span className='resultClose' onClick={() => removeSearch(index, data, 'maximumDistance')}>X</span>
                                                                </LabelResult>)}
                                                            {showUser.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'userType')}>X</span></LabelResult>)}
                                                            {showFitness.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'fitness')}>X</span></LabelResult>)}
                                                            {showTenure.map((data: any, index: any) => <LabelResult key={'levelResult' + index} text='#fff' background={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#00AA25'}>{data}<span className='resultClose' onClick={() => removeSearch(index, data, 'tenure')}>X</span></LabelResult>)}
                                                        </LabelResultBox>

                                                        {/* reset filter */}
                                                        <div className='resetBtn'>
                                                            <ResetFilter textColor={themeState.gameType === 'FOOTBALL' ? '#fff' : '#000'} onClick={resetFilter}>{searchPage["Reset_Filter"]}</ResetFilter>
                                                        </div>
                                                        {/* custom search */}
                                                        <Button buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                                                            <div className="mainBtn">
                                                                <button className={ageRanges.length + showLevel.length + showDay.length + showHour.length + showDistance.length + showUser.length + showFitness.length + showTenure.length === 0 ? 'white disActive' : 'white runActive'} onClick={customSearchFunction}>
                                                                    <p>{searchPage["Search"]}</p>
                                                                    <FontAwesomeIcon icon={faArrowLeft} />
                                                                </button>
                                                            </div>
                                                        </Button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </FilterBox>
                                </div>
                            </Col>

                            <Col md={7} className='result-part'>

                                <ResultBox opacityBackground={themeState.gameType === 'FOOTBALL' ? '#000' : '#fff'} border='20' textBorder={themeState.gameType === 'FOOTBALL' ? '#fff' : '#000'} heading={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} background={themeState.gameType === 'FOOTBALL' ? '#00000080' : '#ffffff80'}>
                                    <div className={themeState.chooseOption === 'defaultSearch' ? 'resultbox blurRemove' : themeState.chooseOption === 'backSearch' ? 'resultbox blurRemove' : themeState.chooseOption === 'mapSearch' ? 'resultbox blurRemove' : 'resultbox blurAdd'}>

                                        <div className={noResults ? 'resultTopLine disabled' : "resultTopLine"}>
                                            <h1 className='result-title'>{searchPage["Result"]}</h1>
                                            {/* reset filter */}
                                            <Button buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                                                <div className="mainBtn backToFilter">
                                                    <button className="white" onClick={() => backToFilter()}>
                                                        <p>{searchPage["Back_to_Filter"]}</p>
                                                        <FontAwesomeIcon icon={faArrowRight} />
                                                    </button>
                                                </div>
                                            </Button>
                                        </div>



                                        {/* single user profile info userInfo */}
                                        {profileDetails ? <>
                                            <Profile userInfo={userInfo} closeProfile={closeProfile} />
                                        </> : <></>}

                                        {defaultVisible && <>
                                            {/* sorting dropdown list */}
                                            <div className={noResults ? 'sortOption disabled' : "sortOption"}>
                                                <Dropdown>
                                                    <Dropdown.Toggle className='sortClass' id="dropdown-basic">
                                                        <Button buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                                                            <div className="mainBtn">
                                                                <button className="white">
                                                                    <p>{sortingValue}</p>
                                                                    <FontAwesomeIcon icon={faChevronDown} />
                                                                </button>
                                                            </div>
                                                        </Button>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='sortingDrop'>
                                                        {sortingDropdown()}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                            {/* user result lists */}
                                            <div className="profileTable">
                                                <Row style={{ justifyContent: noResults ? 'center' : '' }}>

                                                    {noResults && (<div ref={customPopupNoResult} className="popup-wrapper">
                                                        <PopupWithCheckbox
                                                            bodyText={<>לא מצאנו תוצאות חיפוש,  <br /><b>הגדילו את טווח החיפוש<br /> </b> ע״י שימוש במסננים מועטים בכפתור <b>מותאם אישית</b></>}
                                                            checkboxText={null}
                                                            buttonText={<>{searchPage["Back_to_Filter"]}</>}
                                                            isChecked={null}
                                                            closePopup={() => {
                                                                backToFilter();
                                                                setNoResults(false);
                                                                setOutsideClickDetectionEnabledForNoResult(false);
                                                            }}
                                                            onCheckboxClick={null}
                                                        ></PopupWithCheckbox>
                                                    </div>)
                                                    }
                                                    {/* {noResults && (
                                                        <label className='no-result'>
                                                            אין תוצאות חיפוש, נסה להגדיל את טווח החיפוש דרך הוספת מסננים בכפתור ׳מותאם אישית׳
                                                        </label>
                                                    )} */}
                                                    {/* user card */}
                                                    {userAll.map((data: any, index: number) =>
                                                        <Col xs={6} md={3} key={'card' + index}>
                                                            <Card data={data} userDetails={userDetails} />

                                                            {index === 0 && profilePopupShow && <div ref={customPopupWithCheckboxForProfile} className="custom-popover-profile">
                                                                <PopupWithCheckbox
                                                                    bodyText={<>לחיצה על <b>הכרטיס</b>  תציג את הפרטים המלאים <br /> ואמצעי קשר</>}
                                                                    checkboxText={<>לא להציג הודעה זאת שוב</>}
                                                                    buttonText={<>אשר</>}
                                                                    isChecked={isProfileChecked}
                                                                    closePopup={() => {
                                                                        setProfilePopupShow(false);
                                                                        if (isProfileChecked) {
                                                                            updateSearchAndprofileCheckBox("profileCheckBox", isProfileChecked).then((data) => {
                                                                                if (data.statusCode === StatusCodes.OK) {

                                                                                } else {
                                                                                    console.log(data.message);
                                                                                }
                                                                            }).catch((error) => {
                                                                                console.log(error);
                                                                            });
                                                                        }
                                                                        setOutsideClickDetectionEnabledForProfile(false);
                                                                    }}
                                                                    onCheckboxClick={(isChecked) => { setIsProfileChecked(isChecked) }}
                                                                ></PopupWithCheckbox>
                                                            </div>}
                                                        </Col>
                                                    )}
                                                </Row>

                                            </div>
                                            {/* pagination list */}
                                            {customPagination ? <>
                                                <Pagination textColor={themeState.gameType === 'FOOTBALL' ? '#fff' : '#000'} disableTextColor={themeState.gameType === 'FOOTBALL' ? '#ffffff67' : '#00000067'}>
                                                    <div className='paginationLine'>
                                                        <button className={prev} onClick={() => { setPage(page - 1); setRunPage((pre) => !pre) }}>{searchPage["prev"]}</button>
                                                        {userAll.length === 0 ? <></> : <p>{page}/{paginationNumber}</p>}
                                                        <button className={next} onClick={() => { setPage(page + 1); setRunPage((pre) => !pre) }}>{searchPage["next"]}</button>
                                                    </div>
                                                </Pagination>
                                            </> : <></>}
                                            {defaultPagination ? <>

                                                <Pagination textColor={themeState.gameType === 'FOOTBALL' ? '#fff' : '#000'} disableTextColor={themeState.gameType === 'FOOTBALL' ? '#ffffff67' : '#00000067'}>
                                                    <div className='paginationLine'>
                                                        <button className={defaultPrev} onClick={() => { setDefaultPage(defaultPage - 1); setDefaultRun((pre) => !pre) }}>{searchPage["prev"]}</button>
                                                        {userAll.length === 0 ? <></> : <p>{defaultPage}/{defaultNumber}</p>}
                                                        <button className={defaultNext} onClick={() => { setDefaultPage(defaultPage + 1); setDefaultRun((pre) => !pre) }}>{searchPage["next"]}</button>
                                                    </div>
                                                </Pagination>
                                            </> : <></>}

                                        </>}


                                        {/* map location */}

                                        {mapLocationSearch ? <>
                                            <div className="mapView">
                                                <div className={mapBox ? 'mapSide' : "mapBox"}>
                                                    <div className={widthMap ? 'singleCard' : 'singleCardLong'}>

                                                        <div className="cardCenter">
                                                            <PlayerCard className='d-flex justify-content-center' userText={mapUser.levelOfPlay === 'PROFESSIONAL' ? 'linear-gradient(34.74deg, #0A2D8A 34.17%, #2A6AC7 58.39%, #0844A5 77.83%)' : "linear-gradient(83.28deg, #E2CF8A 35.84%, #D6B43F 44.34%, #E6D28D 57.75%, #E1BF45 65.68%)"} userInfo={mapUser.levelOfPlay === 'PROFESSIONAL' ? '#fff' : "#100159"} headingImage={mapUser.levelOfPlay === 'PROFESSIONAL' ? headingSilver : headingBlue} backgroundImage={mapUser.levelOfPlay === 'INTERMEDIATE' ? medium : mapUser.levelOfPlay === 'AMATEUR' ? amateur : mapUser.levelOfPlay === 'PROFESSIONAL' ? professional : mapUser.levelOfPlay === 'ADVANCED' ? advancing : ''} textColor={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'}>
                                                                <div className="playerCard">
                                                                    <span className='removeCard' onClick={mapCardClose}><FontAwesomeIcon icon={faClose} /></span>
                                                                    <div className="cardbody" onClick={() => userDetails(mapUser.email)}>
                                                                        {/* <button className='plusBtn'><img src={mapUser.levelOfPlay === 'PROFESSIONAL' ? silverPlus : bluePlus} alt="" /></button> */}
                                                                        <img className='medalImg' src={iconList[`${mapUser.userImage}`]} alt="" />
                                                                        {/* <button className='userBtn'><img src={mapUser.userRole === 'PLAYER' ? single : mapUser.userRole === 'LEADER' ? group : ""} alt="" /></button> */}
                                                                        <div className="user-name">

                                                                            {mapUser.nickName && mapUser.nickName.length ?
                                                                                <h1>{mapUser.nickName.length > 10 ? mapUser.nickName.substring(0, 8) + '..' : mapUser.nickName}</h1> :
                                                                                <>{mapUser.fullName && mapUser.fullName.length ? <h1>{mapUser.fullName.length > 10 ? mapUser.fullName.substring(0, 8) + '..' : mapUser.fullName}</h1> : ""}</>
                                                                            }
                                                                        </div>
                                                                        <button className='userBtn'>
                                                                            <img src={mapUser.userRole === 'PLAYER' ? single : mapUser.userRole === 'LEADER' ? group : ""} alt="" />
                                                                            {" "}
                                                                            <label className='location'>{mapUser.userRole === 'PLAYER' ? "שחקן" : "קבוצה"}</label>
                                                                        </button>
                                                                        {mapUser.city && mapUser.city.length ? <p className='location'><FontAwesomeIcon icon={faLocationDot} />{mapUser.city}</p> : ""}

                                                                        {mapUser.availableDays && mapUser.availableDays.length === 7 ? <p className='description'>כל השבוע</p> : <>
                                                                            {<p className='description'>
                                                                                {mapUser.availableDays && mapUser.availableDays.length ? mapUser.availableDays.map((day: string, index: number) => <span key={'days' + index}> {convertToHebrew(day)}  </span>).slice(0, 3) : ''}
                                                                            </p>}
                                                                            {<p className='descriptionOne'>

                                                                                {mapUser.availableDays && mapUser.availableDays.length ? mapUser.availableDays.map((day: string, index: number) => <span key={'days' + index}> {convertToHebrew(day)}  </span>).slice(3, 6) : ''}
                                                                            </p>}
                                                                        </>}
                                                                    </div>

                                                                </div>
                                                            </PlayerCard>
                                                        </div>
                                                    </div>
                                                    <div className={singleMapCard ? 'mapFrameNone' : 'mapFrameBlock'} style={{ justifyContent: noResults ? 'center' : '', display: noResults ? 'flex' : '' }}>


                                                        {noResults && (<div ref={customPopupNoResult} className="popup-wrapper">
                                                            <PopupWithCheckbox
                                                                bodyText={<>לא מצאנו תוצאות חיפוש,  <br /><b>הגדילו את טווח החיפוש<br /> </b> ע״י שימוש במסננים מועטים בכפתור <b>מותאם אישית</b></>}
                                                                checkboxText={null}
                                                                buttonText={<>{searchPage["Back_to_Filter"]}</>}
                                                                isChecked={null}
                                                                closePopup={() => {
                                                                    backToFilter();
                                                                    setNoResults(false);
                                                                    setOutsideClickDetectionEnabledForNoResult(false);
                                                                }}
                                                                onCheckboxClick={null}
                                                            ></PopupWithCheckbox>
                                                        </div>)
                                                        }
                                                        {isLoaded && !noResults &&
                                                            <GoogleMap
                                                                id="marker-example"
                                                                center={showMap ? { lat: parseFloat(markLocation.latitude), lng: parseFloat(markLocation.longitude) } : defaultPosition}
                                                                zoom={8}
                                                                onClick={() => setActiveMarker(null)}
                                                                options={{
                                                                    draggable: true,
                                                                    keyboardShortcuts: true,
                                                                    zoomControl: true,
                                                                    mapTypeId: 'roadmap',
                                                                    //   mapTypeId: 'satellite'
                                                                }}
                                                            >
                                                                {groupArrayValue && groupArrayValue.map((u: any, index: number) => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                index > 0 ?
                                                                                    <MarkerF
                                                                                        key={u.key}
                                                                                        //  options={options}
                                                                                        icon={u.data.length === 1 && u.userRole === 'PLAYER' ? single :
                                                                                            u.data.length === 1 && u.userRole === 'LEADER' ? group :
                                                                                                u.data.length > 0 && u.userRole === 'PLAYER' ? singlePlus :
                                                                                                    u.data.length > 0 && u.userRole === 'LEADER' ? groupPlus :
                                                                                                        u.data.length > 0 ? singlePlus :
                                                                                                            ''}

                                                                                        position={{ lat: parseFloat(u.latitude), lng: parseFloat(u.longitude) }}
                                                                                        onClick={() => setUpdateMapPaginationValue(index)}
                                                                                    >
                                                                                    </MarkerF>
                                                                                    : <></>
                                                                            }
                                                                            {
                                                                                index === 0 ?
                                                                                    <MarkerF
                                                                                        key={u.key}
                                                                                        icon={themeState.gameType === 'BASKETBALL' ? basketBallIcon : themeState.gameType === 'FOOTBALL' ? footballIcon : themeState.gameType === 'TENNIS' ? tennisIcon : themeState.gameType === 'VOLLEYBALL' ? volleyIcon : ""}
                                                                                        //   options={options}
                                                                                        position={{ lat: parseFloat(u.latitude), lng: parseFloat(u.longitude) }}
                                                                                    // visible={true}

                                                                                    >
                                                                                    </MarkerF>
                                                                                    : <></>
                                                                            }

                                                                        </>)
                                                                }

                                                                )}
                                                            </GoogleMap>
                                                        }



                                                    </div>

                                                </div>

                                                <div className={doubleMapCard ? 'multipleMapBlock' : 'multipleMapNone'}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="mapInfo">

                                                                <MultiClose backgroundColor={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'}>
                                                                    <span className='removeMulti' onClick={mapMultiClose}><FontAwesomeIcon icon={faClose} /></span>
                                                                </MultiClose>

                                                            </div>
                                                        </Col>
                                                        {mapGroup.map((data: any, i: number) =>
                                                            <Col xs={6} md={4} key={'card' + i}>
                                                                <Card data={data} userDetails={userDetails} />
                                                            </Col>
                                                        )}

                                                        {/* multi user api pagination */}
                                                        <Pagination textColor={themeState.gameType === 'FOOTBALL' ? '#fff' : '#000'} disableTextColor={themeState.gameType === 'FOOTBALL' ? '#ffffff67' : '#00000067'}>
                                                            <div className='paginationLine'>
                                                                <button className={mapPrev ? "btn prevActive" : "btn prevDisable"} onClick={() => setMapPage(mapPage - 1)}>{searchPage["prev"]}</button>
                                                                <p>{mapPage}/{multiNumber}</p>
                                                                <button className={mapNext ? "btn prevActive" : "btn prevDisable"} onClick={() => setMapPage(mapPage + 1)}>{searchPage["next"]}</button>
                                                            </div>
                                                        </Pagination>

                                                    </Row>
                                                </div>

                                            </div>
                                        </> : ''}

                                    </div>
                                </ResultBox>
                            </Col>

                        </Row>
                    </Container>

                </SearchPage>
            </Layout >
        </>


    )

}

export default Search