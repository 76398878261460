import { ThirdPartyApp } from '../../Interfaces/LoginRequeset'
import { ACCESSTOKEN, PROVIDER, CHANGE_GAME_TYPE, CHOOSE_TYPE, CHANGE_SEARCH_OPTION,CHANGE_BACKTOFILTER_OPTION,
    CHANGE_LOGIN_MODE,CHANGE_MAP_CARD,CHANGE_USER_ICON,
    FULL_NAME,TELEPHONE,EMAIL,PASSWORD,USER_ROLE,GAME_POSITION,SURFACE_TYPE,EACH_TEAM,
    LEVEL_OF_PLAY,MAX_DISTANCE,GAME_TIME,FREQUENCY_OF_PLAY,SPORT_TYPE,NICK_NAME,FIND_US,AGE_RANGE,
    PLAY_AREA,JOINT_GROUP,FB_NAME,AVAILABLE_DAYS,AVAILABLE_HOURS,LOCATION,LATITUDE,LONGITUDE,DOB,CITY,COUNTRY,
    FITNESS,PAYMENT,PAYMENT_TYPE,CLEAR_DATA,PUBLIC_EMAIL,ICON,SUNDAY,MONDAY,TUESDAY,WEDNESDAY,THURSDAY,FRIDAY,SATURDAY,ALL_DATE,LOGIN_CHECK,
    PROFILE_UPDATE
 } from './actionTypes'

 export const profileUpdate = (value: string) => {
    return {
        type: PROFILE_UPDATE,
        payload: value
    }
}
export const provider = (value: ThirdPartyApp) => {
    return {
        type: PROVIDER,
        payload: value
    }
}

export const loginCheck = (value: string) => {
    return {
        type: LOGIN_CHECK,
        payload: value
    }
}

export const accessToken = (value: string) => {
    return {
        type: ACCESSTOKEN,
        payload: value
    }
}

 export const allDate = (value: any) => {
    return {
        type: ALL_DATE,
        payload: value
    }
}

 export const saturday = (value: any) => {
    return {
        type: SATURDAY,
        payload: value
    }
}

 export const friday = (value: any) => {
    return {
        type: FRIDAY,
        payload: value
    }
}

 export const thursday = (value: any) => {
    return {
        type: THURSDAY,
        payload: value
    }
}

 export const wednesday = (value: any) => {
    return {
        type: WEDNESDAY,
        payload: value
    }
}


 export const tuesday = (value: any) => {
    return {
        type: TUESDAY,
        payload: value
    }
}

 export const monday = (value: any) => {
    return {
        type: MONDAY,
        payload: value
    }
}
 export const sunday = (value: any) => {
    return {
        type: SUNDAY,
        payload: value
    }
}

 export const iconSet = (value: string) => {
    return {
        type: ICON,
        payload: value
    }
}
export const changeGameType = (value: string) => {
    return {
        type: CHANGE_GAME_TYPE,
        payload: value
    }
}

export const changeChooseType = (value: string) => {
    return {
        type: CHOOSE_TYPE,
        payload: value
    }
}
export const changeSearchOption = (value: string) => {
    return {
        type: CHANGE_SEARCH_OPTION,
        payload: value
    }
}

export const changeBackToFilterOption = (value: string) => {
    return {
        type: CHANGE_BACKTOFILTER_OPTION,
        payload: value
    }
}
export const changeLoginMode = (value: string) => {
    return {
        type: CHANGE_LOGIN_MODE,
        payload: value
    }
}
export const changeMapCard = (value: string) => {
    return {
        type: CHANGE_MAP_CARD,
        payload: value
    }
}
export const changeUserIcon = (value: string) => {
    return {
        type: CHANGE_USER_ICON,
        payload: value
    }
}
export const fullName = (value: string) => {
    return {
        type: FULL_NAME,
        payload: value
    }
}
export const telephone = (value: string) => {
    return {
        type: TELEPHONE,
        payload: value
    }
}
export const email = (value: string) => {
    return {
        type: EMAIL,
        payload: value
    }
}
export const password = (value: string) => {
    return {
        type: PASSWORD,
        payload: value
    }
}
export const userRole = (value: string) => {
    return {
        type: USER_ROLE,
        payload: value
    }
}
export const gamePosition = (value: string) => {
    return {
        type: GAME_POSITION,
        payload: value
    }
}
export const eachTeamPlayer = (value: string) => {
    return {
        type: EACH_TEAM,
        payload: value
    }
}
export const surfaceType = (value: string) => {
    return {
        type: SURFACE_TYPE,
        payload: value
    }
}
export const levelOfPlay = (value: string) => {
    return {
        type: LEVEL_OF_PLAY,
        payload: value
    }
}
export const maxDistance = (value: string) => {
    return {
        type: MAX_DISTANCE,
        payload: value
    }
}
export const gameTime = (value: string) => {
    return {
        type: GAME_TIME,
        payload: value
    }
}

export const frequencyOfPlay = (value: string) => {
    return {
        type: FREQUENCY_OF_PLAY,
        payload: value
    }
}
export const sportType = (value: string) => {
    return {
        type: SPORT_TYPE,
        payload: value
    }
}
export const nickName = (value: string) => {
    return {
        type: NICK_NAME,
        payload: value
    }
}
export const findUs = (value: string) => {
    return {
        type: FIND_US,
        payload: value
    }
}
export const ageRange = (value: any) => {
    return {
        type: AGE_RANGE,
        payload: value
    }
}
export const playArea = (value: string) => {
    return {
        type: PLAY_AREA,
        payload: value
    }
}
export const jointGroup = (value: string) => {
    return {
        type: JOINT_GROUP,
        payload: value
    }
}
export const fbName = (value: string) => {
    return {
        type: FB_NAME,
        payload: value
    }
}

export const availableDays = (value: any) => {
    return {
        type: AVAILABLE_DAYS,
        payload: value
    }
}

export const availableHours = (value: any) => {
    return {
        type: AVAILABLE_HOURS,
        payload: value
    }
}

export const location = (value: any) => {
    return {
        type: LOCATION,
        payload: value
    }
}
export const latitude = (value: any) => {
    return {
        type: LATITUDE,
        payload: value
    }
}
export const longitude = (value: any) => {
    return {
        type: LONGITUDE,
        payload: value
    }
}
export const dob = (value: any) => {
    return {
        type: DOB,
        payload: value
    }
}
export const city = (value: any) => {
    return {
        type: CITY,
        payload: value
    }
}
export const country = (value: any) => {
    return {
        type: COUNTRY,
        payload: value
    }
}
export const fitness = (value: any) => {
    return {
        type: FITNESS,
        payload: value
    }
}
export const payment = (value: any) => {
    return {
        type: PAYMENT,
        payload: value
    }
}
export const paymentType = (value: any) => {
    return {
        type: PAYMENT_TYPE,
        payload: value
    }
}

export const clearData = () => {
    return {
        type: CLEAR_DATA,
        payload: undefined
    }
}
export const publicEmail = (value:any) => {
    return {
        type: PUBLIC_EMAIL,
        payload: value
    }
}