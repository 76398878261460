import { JoinGroup } from "../../Enums/JoinGroup";
import { OptionType } from "../../Interfaces/OptionType";
import Localize from "../../Localization/Localize";

export function getJoinGroupOptions(): OptionType[] {
    return [
        { label: Localize[JoinGroup.YES], value: JoinGroup.YES },
        { label: Localize[JoinGroup.NO], value: JoinGroup.NO },
        { label: Localize[JoinGroup["DOESN'T MATTER"]], value: JoinGroup["DOESN'T MATTER"] },
    ];
}