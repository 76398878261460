import { SportType } from "../../Enums/SportType";
import { SurfaceType } from "../../Enums/SurfaceType";
import { UserRole } from "../../Enums/UserRole";
import { OptionType } from "../../Interfaces/OptionType";
import Localize from "../../Localization/Localize";

function surfaceTypeOptionsForFootball(userRole: UserRole) {
    return [
        { label: Localize[SurfaceType.ASPHALT], value: SurfaceType.ASPHALT },
        { label: Localize[SurfaceType.SYNTHETIC_GRASS], value: SurfaceType.SYNTHETIC_GRASS },
        { label: Localize[SurfaceType.REAL_GRASS], value: SurfaceType.REAL_GRASS },
        { label: Localize[SurfaceType.HALL], value: SurfaceType.HALL },
        { label: Localize[userRole === UserRole.PLAYER ? SurfaceType["DOESN'T MATTER"] : SurfaceType.NOT_CONSTANT], 
            value: userRole === UserRole.PLAYER ? SurfaceType["DOESN'T MATTER"] : SurfaceType.NOT_CONSTANT },
    ] as OptionType[];
}

function surfaceTypeOptionsForBasketball(userRole: UserRole) {
    return [
        { label: Localize[SurfaceType.HALL], value: SurfaceType.HALL },
        { label: Localize[SurfaceType.OUTSIDE_PITCHES], value: SurfaceType.OUTSIDE_PITCHES },
        { label: Localize[userRole === UserRole.PLAYER ? SurfaceType["DOESN'T MATTER"] : SurfaceType.NOT_CONSTANT], 
            value: userRole === UserRole.PLAYER ? SurfaceType["DOESN'T MATTER"] : SurfaceType.NOT_CONSTANT },
    ] as OptionType[];
}

function surfaceTypeOptionsForVolleyball(userRole: UserRole) {
    return [
        { label: Localize[SurfaceType.HALL], value: SurfaceType.HALL },
        { label: Localize[SurfaceType.BEACH], value: SurfaceType.BEACH },
        { label: Localize[userRole === UserRole.PLAYER ? SurfaceType["DOESN'T MATTER"] : SurfaceType.NOT_CONSTANT], 
            value: userRole === UserRole.PLAYER ? SurfaceType["DOESN'T MATTER"] : SurfaceType.NOT_CONSTANT },
    ] as OptionType[];
}

function surfaceTypeOptionsForTennis(userRole: UserRole) {
    return [
        { label: Localize[SurfaceType.ASPHALT], value: SurfaceType.ASPHALT },
        { label: Localize[SurfaceType.REAL_GRASS], value: SurfaceType.REAL_GRASS },
        { label: Localize[SurfaceType.CLAY], value: SurfaceType.CLAY },
        { label: Localize[userRole === UserRole.PLAYER ? SurfaceType["DOESN'T MATTER"] : SurfaceType.NOT_CONSTANT], 
            value: userRole === UserRole.PLAYER ? SurfaceType["DOESN'T MATTER"] : SurfaceType.NOT_CONSTANT },
    ] as OptionType[];
}

function surfaceTypeOptionsForOthers(userRole: UserRole) {
    return [
        { label: Localize[SurfaceType.ASPHALT], value: SurfaceType.ASPHALT },
        { label: Localize[SurfaceType.SYNTHETIC_GRASS], value: SurfaceType.SYNTHETIC_GRASS },
        { label: Localize[SurfaceType.REAL_GRASS], value: SurfaceType.REAL_GRASS },
        { label: Localize[SurfaceType.BEACH], value: SurfaceType.BEACH },
        { label: Localize[SurfaceType.CLAY], value: SurfaceType.CLAY },
        { label: Localize[SurfaceType.HALL], value: SurfaceType.HALL },
        { label: Localize[SurfaceType.OUTSIDE_PITCHES], value: SurfaceType.OUTSIDE_PITCHES },
        { label: Localize[userRole === UserRole.PLAYER ? SurfaceType["DOESN'T MATTER"] : SurfaceType.NOT_CONSTANT], 
            value: userRole === UserRole.PLAYER ? SurfaceType["DOESN'T MATTER"] : SurfaceType.NOT_CONSTANT },
    ] as OptionType[];
}

export function getSurfaceOptions(userRole: UserRole, sportType: SportType | null): OptionType[] {
    switch (sportType) {
        case SportType.FOOTBALL:
            return surfaceTypeOptionsForFootball(userRole);
        case SportType.BASKETBALL:
            return surfaceTypeOptionsForBasketball(userRole);
        case SportType.VOLLEYBALL:
            return surfaceTypeOptionsForVolleyball(userRole)
        case SportType.TENNIS:
            return surfaceTypeOptionsForTennis(userRole);
        case SportType.OTHER:
            return surfaceTypeOptionsForOthers(userRole);
        default:
            return [
                { label: Localize[SurfaceType.ASPHALT], value: SurfaceType.ASPHALT },
                { label: Localize[SurfaceType.SYNTHETIC_GRASS], value: SurfaceType.SYNTHETIC_GRASS },
                { label: Localize[SurfaceType.REAL_GRASS], value: SurfaceType.REAL_GRASS },
                { label: Localize[SurfaceType.BEACH], value: SurfaceType.BEACH },
                { label: Localize[SurfaceType.CLAY], value: SurfaceType.CLAY },
                { label: Localize[SurfaceType.HALL], value: SurfaceType.HALL },
                { label: Localize[SurfaceType.OUTSIDE_PITCHES], value: SurfaceType.OUTSIDE_PITCHES },
                { label: Localize[SurfaceType["DOESN'T MATTER"]], value: SurfaceType["DOESN'T MATTER"] },
                { label: Localize[SurfaceType.NOT_CONSTANT], value: SurfaceType.NOT_CONSTANT },
            ];
    }
}