import React from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import { isAuthenticate } from './auth';

const PrivateRoute = () => {
    let auth = isAuthenticate().statusCode
    return(
        auth === 200 ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoute