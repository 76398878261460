import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap';
import './Header.css'
import { Link, useNavigate, NavLink, useLocation } from 'react-router-dom';
import { isAuthenticate, jwtValidation, userProfile } from '../../../api/auth';
// toast
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
// icon
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//static images
import footballLogo from '../../../assets/images/logo/logoFour.png'
import basketLogo from '../../../assets/images/logo/logoOne.png'
import tennisLogo from '../../../assets/images/logo/logoTwo.png'
import volleyLogo from '../../../assets/images/logo/logoThree.png'
import tennis from '../../../assets/images/category/tennis.png'
import basket from '../../../assets/images/category/basket.png'
import volley from '../../../assets/images/category/volley.png'
import football from '../../../assets/images/category/football.png';
import loginLight from '../../../assets/images/logo/loginLight.png'
import loginDark from '../../../assets/images/logo/loginDark.png';
import shareIcon from '../../../assets/images/icon/shareIcon.png';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { changeGameType, clearData, profileUpdate } from '../../../store/theme/actions';
import { InitialState } from '../../../store/theme/themeReducer'
import { faBars, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBar from '../../StyleComponent/Header/NavBar';
import { analyticsValue } from '../../../Analytics';
import { useMediaQuery } from 'react-responsive';
import KofiWidget from '../KoFiWidget/KofiWidget';


const Header = ({ aboutUs = false }) => {
  // user
  const { user } = isAuthenticate();
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the breakpoint for mobile devices
  const location = useLocation();

  //redux
  const themeState = useSelector((state: InitialState) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState("");
  const [copyMsg, setCopyMsg] = useState(false);
  const [scrollColor, setScrollColor] = useState(false)

  // game category type
  const [category, setCategory] = useState(themeState.gameType && themeState.gameType ? themeState.gameType : themeState.gameType);

  const [reduxCategory, setReduxCategory] = useState(themeState.gameType.length === 0 ? user ? user.sportType : themeState.gameType : themeState.gameType);

  // user data load

  // FOOTBALL BASKETBALL  VOLLEYBALL  TENNIS

  const categoryChoose = [
    { gameName: "FOOTBALL", gameURL: football },
    { gameName: "VOLLEYBALL", gameURL: volley },
    { gameName: "TENNIS", gameURL: tennis },
    { gameName: "BASKETBALL", gameURL: basket },
  ];

  const chooseCategory = (gameName: any, gameURL: any) => {
    setCategory(gameURL);
    setReduxCategory(gameName);
  }
  const gameTypeHandler = (value: string) => {
    dispatch(changeGameType(value));
  }


  useEffect(() => {


    if (themeState.gameType.length === 0) {
      if (user ? user.sportType === 'BASKETBALL' : null) {
        setCategory(basket);
      }
      else if (user ? user.sportType === 'FOOTBALL' : null) {
        setCategory(football);
      }
      else if (user ? user.sportType === 'TENNIS' : null) {
        setCategory(tennis);
      }
      else if (user ? user.sportType === 'VOLLEYBALL' : null) {
        setCategory(volley);
      }
    } else {
      if (themeState.gameType ? themeState.gameType === 'BASKETBALL' : null) {
        setCategory(basket);
      }
      else if (themeState.gameType ? themeState.gameType === 'FOOTBALL' : null) {
        setCategory(football);
      }
      else if (themeState.gameType ? themeState.gameType === 'TENNIS' : null) {
        setCategory(tennis);
      }
      else if (themeState.gameType ? themeState.gameType === 'VOLLEYBALL' : null) {
        setCategory(volley);
      }
    }

  }, [themeState.gameType])




  React.useEffect(() => {
    gameTypeHandler(reduxCategory)
  }, [reduxCategory])


  // dropdown game type
  const renderDropDownMenu = () => {
    return categoryChoose.map((data, i: number) => (
      <div key={`navbar + ${i}`}>
        {data.gameURL === category ? null :
          <div key={`navbar + ${i}`}>
            <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => chooseCategory(data.gameName, data.gameURL)}>
              <img src={data.gameURL} />
            </Dropdown.Item>
          </div>}
      </div>

    ))
  }
  const logout = () => {
    localStorage.removeItem("jwt");
    dispatch(clearData());
    // navigate('/login')
  }



  const changeNavbarColor = () => {
    if (window.scrollY >= 60) {

      setScrollColor(true)
    }
    else {
      setScrollColor(false)
    }
  };

  window.addEventListener('scroll', changeNavbarColor);

  // share icon copy text

  const textToCopy = "אני כבר רשום לאתר טאפ אנ סקור - שמחבר בין שחקנים וקבוצות של משחקי כדור ⚽🏀🎾\n לחצו כדי להצטרף:  https://tapnscore.com";
  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }
  function handleCopyClick() {
    copyToClipboard(textToCopy);
    setCopyMsg(true)
  }
  analyticsValue()
  const showMsg = () => {
    return (
      <>
        <ToastContainer position="top-end" className="p-3">
          <Toast onClose={() => setCopyMsg(false)} show={copyMsg} delay={3000} autohide>
            <div className="bg-primary text-white">
              <Toast.Body>הודעת שיתוף הועתק לקליפבורד</Toast.Body>
            </div>
          </Toast>
        </ToastContainer>
      </>
    );
  };

  // validation
  useEffect(() => {
    jwtValidation().then((data: any) => {
      if (data.statusCode !== 200) {
        localStorage.removeItem("jwt");
        //  dispatch(clearData());
      }
    })
  }, [])

  return (
    <>
      {showMsg()}
      <Navbar collapseOnSelect expand="lg" className={scrollColor ? 'navbar fixed-top scrollBackground' : 'navbar fixed-top'} id="header-element" >
        <Container fluid>

          {!isAuthenticate() && (
            <NavLink to="/" className='logo'>
              {aboutUs || location.pathname === '/signup' ?
                <img src={loginLight} />
                : <img src={themeState.gameType === 'FOOTBALL' ? footballLogo : themeState.gameType === 'TENNIS' ? tennisLogo : themeState.gameType === 'VOLLEYBALL' ? volleyLogo : themeState.gameType === 'BASKETBALL' ? basketLogo : themeState.loginMode === '' ? loginDark : themeState.loginMode === 'light' ? loginLight : ""} />
              }
            </NavLink>
          )}
          {isAuthenticate() && (
            <Navbar.Brand className='logo'>
              <img src={themeState.gameType === 'FOOTBALL' ? footballLogo : themeState.gameType === 'TENNIS' ? tennisLogo : themeState.gameType === 'VOLLEYBALL' ? volleyLogo : themeState.gameType === 'BASKETBALL' ? basketLogo : themeState.loginMode === '' ? loginDark : themeState.loginMode === 'light' ? loginLight : ""} />
            </Navbar.Brand>
          )}

          <Navbar.Toggle aria-controls="basic-navbar-nav" className={`${aboutUs && !isAuthenticate() && !scrollColor ? "toggleBtnBlack" : "toggleBtn"}`} style={{ borderColor: aboutUs && !isAuthenticate() && !scrollColor ? "black" : "white" }}>   <FontAwesomeIcon icon={faBars} /> </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">


            <NavBar className="navbar-nav me-auto headerBtn mobileDropdown" textColor={aboutUs && !isAuthenticate() ? '#FB730C;' : themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : themeState.loginMode === 'light' ? '#FB730C;' : '#fff'} sidebar={themeState.gameType === 'FOOTBALL' ? '#000' : '#fff'} navBackground={aboutUs ? 'rgba(255, 255, 255, 0.9)' : themeState.gameType === 'TENNIS' ? 'rgba(255, 255, 255, 0.9);' : themeState.gameType === 'BASKETBALL' ? 'rgba(255, 255, 255, 0.9);' : themeState.gameType === 'VOLLEYBALL' ? 'rgba(255, 255, 255, 0.9);' : themeState.gameType === 'FOOTBALL' ? 'rgba(0, 0, 0, 0.8);' : themeState.loginMode === 'light' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.9)'} hoverText={themeState.loginMode === 'light' ? '#fff' : '#000'}>

              {!isAuthenticate() && (
                <>
                  <Dropdown className="navlink mobileButton">
                    <Dropdown.Toggle className={themeState.loginMode === 'light' ? 'loginDropdown lightDropdown' : 'loginDropdown darkDropdown'} id="dropdown-basic">
                      <FontAwesomeIcon icon={faUserAlt} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={themeState.loginMode === 'light' ? 'lightDrop' : 'darkDrop'}>
                      <Dropdown.Item>

                        <NavLink to="/login">התחברות</NavLink>
                      </Dropdown.Item>
                      <Dropdown.Item >
                        <NavLink to="/signup">הרשמה</NavLink>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="mobileDrop">

                    <NavLink className={({ isActive }) => isActive ? 'navlink activeNavbar' : 'navlink'} to="/login">
                      התחברות
                    </NavLink>
                    <NavLink className={({ isActive }) => isActive ? 'navlink activeNavbar' : 'navlink'} to="/signup">
                      הרשמה
                    </NavLink>
                  </div>

                  <NavLink className={({ isActive }) => isActive ? 'navlink activeNavbar mobDrop' : 'navlink mobDrop'} to="/about_us">
                    מידע
                  </NavLink>
                </>
              )}

              {isAuthenticate() && (
                <>
                  <NavLink className={({ isActive }) => isActive ? 'navlink activeNavbar' : 'navlink'} to="/search">
                    יציע
                  </NavLink>

                  <NavLink className={({ isActive }) => isActive ? 'navlink activeNavbar' : 'navlink'} to="/log_history">
                    היסטוריה
                  </NavLink>

                  <NavLink className={({ isActive }) => isActive ? 'navlink activeNavbar' : 'navlink'} to="/profile">
                    פרופיל
                  </NavLink>

                  <NavLink className={({ isActive }) => isActive ? 'navlink activeNavbar' : 'navlink'} to="/about_us">
                    מידע
                  </NavLink>

                  <NavLink className={({ isActive }) => isActive ? 'navlink activeNavbar' : 'navlink'} to="/login" onClick={logout}>
                    התנתקות
                  </NavLink>

                </>
              )}


              <Nav.Link className='mobDrop shareNavbar pcShare'>
                <img onClick={handleCopyClick} src={shareIcon} alt="" />
              </Nav.Link>


              {isAuthenticate() && (
                <>
                  <div className="iconDropdown">
                    <Dropdown>
                      <Dropdown.Toggle className='dropdownitem' id="dropdown-basic">
                        <img src={`${category}`} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='dropdownbutton'>
                        {renderDropDownMenu()}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  {/* donation button in mobile and logged */}
                  {isAuthenticate() && isMobile ? <KofiWidget blackImage={themeState.gameType !== 'FOOTBALL'}  /> : null}

                  <div className='navbarSelect'>
                    <Nav.Link href='#' className={themeState.gameType === 'FOOTBALL' ? 'activeNavbarIcon' : ''} onClick={() => chooseCategory("FOOTBALL", football)}><img src={football} /></Nav.Link>
                    <Nav.Link href='#' className={themeState.gameType === 'VOLLEYBALL' ? 'activeNavbarIcon' : ''} onClick={() => chooseCategory("VOLLEYBALL", volley)}><img src={volley} /></Nav.Link>
                    <Nav.Link href='#' className={themeState.gameType === 'TENNIS' ? 'activeNavbarIcon' : ''} onClick={() => chooseCategory("TENNIS", tennis)}><img src={tennis} /></Nav.Link>
                    <Nav.Link href='#' className={themeState.gameType === 'BASKETBALL' ? 'activeNavbarIcon' : ''} onClick={() => chooseCategory("BASKETBALL", basket)}><img src={basket} /></Nav.Link>
                  </div>
                </>
              )}

              {/* share button */}
              <Nav.Link className='mobDrop shareNavbar mobileShare'>
                <img onClick={handleCopyClick} src={shareIcon} alt="" />
              </Nav.Link>

              {/* donation button in desktop */}
              {isMobile ? null : <KofiWidget blackImage={(isAuthenticate() && themeState.gameType !== 'FOOTBALL') || aboutUs || themeState.loginMode === 'light' } />}

              {/* donation button in mobile and not logged */}
              {!isAuthenticate() && isMobile ? <KofiWidget blackImage={true}  /> : null}


            </NavBar>

          </Navbar.Collapse>
        </Container>
      </Navbar >
    </>
  )
}






export default Header