import { HideProfileOption } from "../../Enums/HideProfileOption";

export const getDayFromHideProfileOption = (hideProfileOption: HideProfileOption) => {
    switch (hideProfileOption) {
        case HideProfileOption["3_Days"]:
            return 3;
        case HideProfileOption["1_Week"]:
            return 7;
        case HideProfileOption["for_always"]:
            return 999;
        default:
            return 0;
    }
}