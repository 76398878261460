import { GamePosition } from "../../Enums/GamePosition";
import { OptionType } from "../../Interfaces/OptionType";
import Localize from "../../Localization/Localize";

export function getGamePositionOptions(): OptionType[] {
    return [
        { label: Localize[GamePosition.GOAL_KEEPER], value: GamePosition.GOAL_KEEPER },
        { label: Localize[GamePosition.FIELDER], value: GamePosition.FIELDER },
        { label: Localize[GamePosition["DOESN'T MATTER"]], value: GamePosition["DOESN'T MATTER"] },
    ];
}