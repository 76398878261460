import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

// Initialize Google Analytics
ReactGA.initialize("G-3P041R628B");

// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId: "GTM-KBKTZHM",
};
TagManager.initialize(tagManagerArgs);

export const analyticsValue = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

