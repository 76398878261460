import styled from 'styled-components';

type Props = {
  border: string,
  backgroundColor: string
}

export default styled.div<Props>`
border: ${(props) => props.border};
margin-top:10px;
background: ${(props) => props.backgroundColor};
border-radius: 6px;
opacity: 1;
padding: 2px 17px;
width:100%;
min-height:40px;
position:relative;
@media screen and (max-width: 1580px) {
  min-height: 30px;
  margin-top:5px;
}

`;