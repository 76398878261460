import { AverageGameTime } from "../../Enums/AverageGameTime";
import { OptionType } from "../../Interfaces/OptionType";
import Localize from "../../Localization/Localize";

export function getAvgPlayingTimeOptions(): OptionType[] {
    return [
        { label: Localize[AverageGameTime.TO_AN_HOUR], value: AverageGameTime.TO_AN_HOUR },
        { label: Localize[AverageGameTime.BETWEEN_ONE_AND_TWO_HOURS], value: AverageGameTime.BETWEEN_ONE_AND_TWO_HOURS },
        { label: Localize[AverageGameTime.OVER_TWO_HOURS], value: AverageGameTime.OVER_TWO_HOURS },
    ];
}