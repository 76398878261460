import { LevelOfPlay } from "../../Enums/LevelOfPlay";
import { OptionType } from "../../Interfaces/OptionType";
import Localize from "../../Localization/Localize";

export function getLevelOfPlayOptions(): OptionType[] {
    return [
        { label: Localize[LevelOfPlay.AMATEUR], value: LevelOfPlay.AMATEUR },
        { label: Localize[LevelOfPlay.INTERMEDIATE], value: LevelOfPlay.INTERMEDIATE },
        { label: Localize[LevelOfPlay.ADVANCED], value: LevelOfPlay.ADVANCED },
        { label: Localize[LevelOfPlay.PROFESSIONAL], value: LevelOfPlay.PROFESSIONAL },
    ];
}