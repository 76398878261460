import React from 'react';
import './customCheckbox.css';
import { OptionType } from '../../../Interfaces/OptionType';

export interface CheckboxGroup {
    options: OptionType[];
    selectedValues: string[];
    maxWidth: number;
    onChange: (selected: string[]) => void;
}

const CustomCheckbox: React.FC<CheckboxGroup> = ({options, selectedValues, maxWidth, onChange}) => {

    const handleChange = (value: string) => {
        const newSelectedValues = [...selectedValues];
        const index = newSelectedValues.indexOf(value);
        if (index === -1) {
            newSelectedValues.push(value);
        } else {
            newSelectedValues.splice(index, 1);
        }
        onChange(newSelectedValues);
    };

    return (
        <div className="custom-chcekbox-wrapper" style={{ maxWidth: maxWidth === 0 ? "" : `${maxWidth}px` }}>
            {options.map(({ label, value }) => (
                <label className="checkbox-container" key={value}>
                    <input
                        type="checkbox"
                        checked={selectedValues.includes(value)}
                        onChange={() => handleChange(value)}
                    />
                    {label}
                    <span className="checkmark"></span>
                </label>
            ))}
        </div>
    );
};

export default CustomCheckbox;