import React from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { changeChooseType, changeSearchOption, changeBackToFilterOption } from '../../../store/theme/actions';
import { InitialState } from '../../../store/theme/themeReducer';
import Button from '../../StyleComponent/Button/Button';

// hebrew language
import searchPage from '../../../assets/raw-text/search.json'


interface CardProps {
    disableButton: any;
    chooseActive:any
    filterOpacity: () => void;
}
const CardButton = ({disableButton,chooseActive,filterOpacity}:CardProps) => {
    
    // redux
    const themeState = useSelector((state: InitialState) => state);
    return (
        <>
            <Button buttonBorder={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FFC93D' : '#00AA25'} buttonBackground={themeState.gameType === 'FOOTBALL' ? 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);' : themeState.gameType === 'TENNIS' ? 'linear-gradient(90deg, #0FC5FF 15.11%, #007F87 100%);' : themeState.gameType === 'VOLLEYBALL' ? 'linear-gradient(90deg, #004DD0 8.86%, #AA6CFA 100%);' : themeState.gameType === 'BASKETBALL' ? 'linear-gradient(90deg, #FFC93D 0%, #FB730C 100%);' : 'linear-gradient(90deg, #84FA84 13.86%, #00AA25 84.48%);'}>
                <div className="mainBtn">
                    {disableButton ? <>
                        <button className={chooseActive === "cards" ? "active" : 'disableActive'} onClick={filterOpacity}>
                            <p className={chooseActive === "cards" ? "active" : ''} id={"cards"} >{searchPage["Card"]}</p>
                        </button>
                    </> : <>
                        <button className={chooseActive === "cards" ? "active" : ''} onClick={filterOpacity}>
                            <p className={chooseActive === "cards" ? "active" : ''} id={"cards"} >{searchPage["Card"]}</p>
                        </button>
                    </>}

                </div>
            </Button>
        </>
    )
}

export default CardButton