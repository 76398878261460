import { AgeRange } from "../../Enums/AgeRange";
import { OptionType } from "../../Interfaces/OptionType";
import Localize from "../../Localization/Localize";

export function getAgeRangeOptions(): OptionType[] {
    return [
        { label: Localize[AgeRange.TANAGER], value: AgeRange.TANAGER },
        { label: Localize[AgeRange.TWENTIES], value: AgeRange.TWENTIES },
        { label: Localize[AgeRange.THIRTIES], value: AgeRange.THIRTIES },
        { label: Localize[AgeRange.FIFTIES], value: AgeRange.FIFTIES },
    ];
}