import hebrew from '../../../assets/raw-text/common.json'


export enum CooseType {
    USER_TYPE = "סוג משתמש",
    AVAILABLE_DAYS = "ימי משחק",
    AVIALABLES_HOURS = "שעות משחק",
    AGE = "טווח גילאים",
    LEVEL_OF_PLAY = "רמת המשחק",
    FITNESS = "דרגת כושר",
    TENURE = "קביעות",
    MAXIMUM_DISTANCE = "מרחק מקסימלי בק״מ"

}
export enum ChosePlayerType {
    USER_TYPE = "סוג משתמש",
    AVAILABLE_DAYS = "ימי משחק",
    AVIALABLES_HOURS = "שעות משחק",
    AGE = "גיל",
    LEVEL_OF_PLAY = "רמת המשחק",
    FITNESS = "דרגת כושר",
    TENURE = "קביעות",
    MAXIMUM_DISTANCE = "מרחק מקסימלי בק״מ"

}
export enum UserRole {
    PLAYER = 'שחקן',
    LEADER = 'קבוצה'
}
export enum AgeRange {
    TANAGER = '14-17',
    TWENTIES = '18-29',
    THIRTIES = '30-49',
    FIFTIES = '50+'
}
export enum MaxDistance {
    'UP_TO_5KM' = 'עד 5 ק״מ',
    'UP_TO_10KM' = 'עד 10 ק״מ',
    'UP_TO_20KM' = 'עד 20 ק״מ',
    'OVER_20KM' = 'מעל 20 ק״מ'
}

export enum LevelOfPlay {
    AMATEUR = 'חובבנית',
    INTERMEDIATE = 'בינונית',
    ADVANCED = 'מתקדמת',
    PROFESSIONAL = 'מקצועית'
}
export enum SurfaceType {
    ASPHALT = 'אספלט',
    SYNTHETIC_GRASS = 'דשא סינטטי',
    REAL_GRASS = 'דשא אמיתי',
    HALL = 'אולם',
    OUTSIDE_PITCHES = 'OUTSIDE_PITCHES',
    "DOESN'T MATTER" = "לא משנה"
}

export enum Days {
    SUNDAY = 'יום א',
    MONDAY = 'יום ב',
    TUESDAY = 'יום ג',
    WEDNESDAY = 'יום ד',
    THURSDAY = 'יום ה',
    FRIDAY = ' יום ו',
    SATURDAY = 'יום שבת',
}

export enum Fitness {
    LESS = 'פחותה',
    OK = 'סבירה',
    GOOD = 'טובה',
    VERY_GOOD = 'טובה מאוד',
    EXCELLENT = 'מצוינת'
}
export enum Tenure {
    YES = 'כן',
    NO = 'לא',
    //  "DOESN'T MATTER" = "לא משנה"
}

// export enum Fitness {
//     LESS = 'LESS',
//     REASONABLE = 'REASONABLE',
//     GOOD = 'GOOD',
//     VERY_GOOD = 'VERY_GOOD',
//     EXCELLENT = 'EXCELLENT',
// }

export enum Hours {
    MORNING = 'בוקר',
    AFTERNOON = 'אחה"צ',
    NOON = 'צהוריים',
    EVENING = 'ערב',
    NIGHT = ' לילה',
    // FLEXIBLE = " גמיש"
}
export enum TeamSize {
    S_1_2 = '1-2',
    S_3_4 = '3-4',
    S_4_5 = '4-5',
    S_5 = '5',
    S_6_7 = '6-7',
    S_8_10 = '8-10',
    S_11 = '11',
    "DOESN'T MATTER" = "לא משנה"
}
export enum SortValue {
    AGE_RANGE = 'ageRange',
    LEVEL_OF_PLAY = 'levelOfPlay',
    FREQUENCY_OF_PLAY = 'frequencyOfPlay',
    MAX_DISTANCE = 'maxDistance'
}

export enum FrequencyOfPlay {
    ONCE_A_MONTH = 'פעם בחודש',
    "2-3_TIMES_A_MONTH" = '2-3 פעמים בחודש',
    ONCE_A_WEEK = 'פעם בשבוע',
    "2-3_TIMES_A_WEEK" = '2-3 פעמים בשבוע',
    ALMOST_EVERY_DAY = 'כמעט כל יום'
}
