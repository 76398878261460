import { UserRole } from '../Enums/UserRole';
import { IDayIndexTimeArray } from '../Interfaces/DayIndexTimeArray';
import { IProfileUpdateRequest } from '../Interfaces/ProfileUpdateRequest';
import { post, get, del, put } from './base-api';

//get user profile info
export const getProfile = () => {
    return get(`/users/profile`);
};

//get user toggle profile hide/show info
export const toggleProfileStatus = (day: number | null = null) => {
    return get(`/users/toggle-profile-status${day ? ('?day='+day) : ''}`);
};

// TODO Add functionnality after refactor user / profile
//remove profile user
export const removeProfile = () => {
    return del(`/users/profile/del`);
};

//change profile picture
export const changeProfilePicture = (profileIconName: string) => {
    return put('/users/update-profile', {"userImage": profileIconName});
};

//update profile
export const updateProfile = (profileUpdateRequest: IProfileUpdateRequest) => {
    return put('/users/update-profile', profileUpdateRequest);
};

//update profile
export const updateAdditionalContact = (anotherContactDetails: string) => {
    return put('/users/update-profile', {anotherContactDetails: anotherContactDetails});
};

//update datetime
export const updateDateTIme = (dateTime: IDayIndexTimeArray[]) => {
    return put('/users/update/day-time', {"dayTime": dateTime});
};

//switch role: PLAYER | LEADER
export const changeUserRole = (email: string, role: UserRole) => {
    return post('/users/role-switch', { 
        "email": email,
        "role": role
    });
};
