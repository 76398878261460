import React from 'react';
import ChooseType from '../../StyleComponent/ChooseType/ChooseType';
import { useDispatch, useSelector } from 'react-redux';
import { changeChooseType, changeSearchOption, changeBackToFilterOption, changeMapCard } from '../../../store/theme/actions';
import themeReducer, { InitialState } from '../../../store/theme/themeReducer';
import InfoDropdown from '../Search/InfoDropdown';
import iButton from '../../../assets/images/icon/iButton.png';

interface TemplateProps {
    onClickInfoModalButton: () => void
}

const CustomInfo = ({ onClickInfoModalButton }: TemplateProps) => {
    const themeState = useSelector((state: InitialState) => state);
    return (
        <>
            <div className="info-button-wrapper">
                <img className='iButton mt-1 me-1 cursor-pointer' onClick={onClickInfoModalButton} src={iButton} alt="" style={{ width: '40px' }} />
            </div>
            <ChooseType textBorder={themeState.gameType === 'FOOTBALL' ? '#fff' : '#000'} heading={themeState.gameType === 'FOOTBALL' ? '#00AA25' : themeState.gameType === 'TENNIS' ? '#007F87' : themeState.gameType === 'VOLLEYBALL' ? '#004DD0' : themeState.gameType === 'BASKETBALL' ? '#FB730C' : '#FB730C'} paragraphText={themeState.gameType === 'FOOTBALL' ? '#fff' : '#000'} background={themeState.gameType === 'FOOTBALL' ? '#ffffffcc' : '#ffffffcc'}>

                <div className="info-content">
                    <h1 className="title"
                        style={{
                            color: themeState.gameType === 'BASKETBALL' ? '#FB730C' :
                                themeState.gameType === 'FOOTBALL' ? '#00AA25' :
                                    themeState.gameType === 'TENNIS' ? '#12BFEE' :
                                        themeState.gameType === 'VOLLEYBALL' ? '#9069F5' : '#FB730C'
                        }} >ברוך הבא להיסטורית החיבורים!</h1>
                    <p className="dob">כאן תמצאו את פרטי המשתמשים אשר <b>חיפשתם במסך יציע.</b><br />משתמשים אשר חיפשו אתכם מופיעים גם כן.
                    </p>
                </div>
            </ChooseType>
        </>
    )
}

export default CustomInfo