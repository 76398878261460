import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Localize from '../../../Localization/Localize';
import './modalInfos.css'
import iButton from '../../../assets/images/icon/iButton.png';

function ModalInfos() {

    const [show, setShow] = useState(false);
    const handleModal = () => setShow(!show);

    return (
        <>
            <div className="info-button-wrapper" style={{ marginLeft: "2%" }}>
                <img className='iButton mt-1 me-1 cursor-pointer' onClick={handleModal} src={iButton} alt="" style={{ width: '40px' }} />
            </div>
            {show && <div className="info-holder-wrapper" style={{ marginTop: "8%" }}>
                <p><label className="info-normal-text">ברוך הבא לאתר ׳<b>טאפ אנ סקור</b>׳, <br /> <b>האתר שמחבר בין שחקנים וקבוצות של משחקי כדור.</b></label></p>
                <p className="info-normal-text">
                    באמצעות אתר זה תוכלו למצוא את השותפים האידיאליים למשחקים משותפים,
                    <br /> ליצור רשימת אנשי קשר מועדפים,
                    <br />ולחסוך זמן יקר כדי למצוא שחקנים משלימים / קבוצה מתאימה.
                </p>
                {/* <ol style={{ paddingRight: "1.1rem" }}>
                    <li className="info-normal-text">ניתן להציג את כל החיבורים בכפתור <b>כל התוצאות</b><br /> או לסנן את החיבורים שיצרת ע״י לחיצה על כפתור <b>הוספת מסננים.</b></li>
                    <li className="info-normal-text">לחצו על כפתור <b>חפש</b> כדי לקבל את תוצאות החיבורים שיצרתם בעמוד <b>יציע.</b></li>
                    <li className="info-normal-text">ניתן למיין את התוצאות בכפתור <b>מיין לפי</b>.</li>
                    <li className="info-normal-text">ניתן לחפש חיבורים לפי מילת חיפוש <b>בשורת החיפוש</b> שמעל הטבלה.</li>
                    <li className="info-normal-text">ניתן לחזור ולשנות את המסננים בכפתור <b>חזרה למסננים.</b></li>
                </ol>
                <label className="info-normal-text mb-2"><b>מידע חשוב:</b></label>
                <ul style={{ paddingRight: "1.1rem" }}>
                    <li><b>פרטים מלאים</b> - על מנת לצפות בפרטי המשתמש המלאים, <br /> יש ללחוץ על <b>כפתור +</b> וכרטיס המשחק המורחב ייפתח, <br />כולל אופציות ליצירת קשר.</li>
                </ul> */}
            </div>}
        </>
    )
}

export default ModalInfos