import React, { useState } from 'react';
import whatsapp from "../../../assets/svgs/WhatsApp-icon.svg";
import './index.css';
import { useMediaQuery } from 'react-responsive';


export default function WhatsAppButton({ login = false, light = false }) {

  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the breakpoint for mobile devices
  const [bubbleOpened, setBubbleOpened] = useState(false)


  const handleIconMobileClick = (event: any) => {
    window.open("https://wa.me/+972533034698", '_blank');
  }

  const buttonStyle: React.CSSProperties = {
    position: 'fixed',
    left: login ? 'auto' : '20px',
    right: login ? (isMobile ? '20px' : '33px') : 'auto',
    bottom: login ? '10px' : '20px',
    width: isMobile ? '40px' : '60px',
    height: isMobile ? '40px' : '60px',
    borderRadius: '50%',
    backgroundColor: '#25D366',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
    cursor: 'pointer'
  };

  const iconStyle: React.CSSProperties = {
    width: '30px',
    height: '30px',
    fill: 'white',
  };

  const bubbleStyle: React.CSSProperties = {
    bottom: isMobile ? (login ? '16px' : '28px') : (login ? '20px' : '35px'),
    transform: 'translateX(-100%)', // Centers the bubble horizontally
    width: isMobile ? '20%' : '7%',
    backgroundColor: light ? 'rgba(0, 0, 0, 0.7)' : '#D3D3D3',
    color: light ? 'white' : 'black',
    padding: '3px',
    borderRadius: '8px',
    fontSize: isMobile ? '14px' : '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    left: login ? 'auto' : isMobile ? '37%' : '13%',
    right: login ? isMobile ? '-2%' : '0' : 'auto',
    zIndex: 999,
  };

  return (
    <div>
      <div style={buttonStyle} onClick={handleIconMobileClick}>
        <img src={whatsapp} alt="WhatsApp Logo" style={iconStyle} />
      </div>
      <div style={bubbleStyle}>
        <label>דברו איתנו</label>
      </div>

    </div>
  );

}