import React, { useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import CustomContainer from "./customContainer";
import Select from "react-select";
import { sendContactUsForm } from '../../../api/auth';
import { toast } from 'react-toastify';
import { defaultToastConfig } from '../../../Models/Configs/ToasterDefaultConfig';
import { useMediaQuery } from 'react-responsive';

//you can customize labels as per your requirements
const subjectOptions = [
  {
    label: "השימוש באתר",
    value: "value1",
  },
  {
    label: "מתן פרטים / הרשמה לאתר",
    value: "value2",
  },
  {
    label: "יצירת קשר עם משתמשים",
    value: "value3",
  },
  {
    label: "חווית משתמש",
    value: "value4",
  },
  {
    label: "תנאי שימוש ומדיניות פרטיות",
    value: "value5",
  },
  {
    label: "מסכי האתר",
    value: "value6",
  },
  {
    label: "הצעות לשיפור וייעול",
    value: "value7",
  },
  {
    label: "אחר",
    value: "value8",
  },
];

const defaultFormState = {
  fullname: "",
  email: "",
  subject: "",
  message: "",
}

const defaultErrorState = {
  fullname: { error: false, message: "" },
  email: { error: false, message: "" },
  subject: { error: false, message: "" },
  message: { error: false, message: "" },
}

export default function FormElement() {
  const [data, setData] = useState(defaultFormState);
  const [error, setError] = useState<any>(defaultErrorState);
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the breakpoint for mobile devices

  const onChange = (e: any, type: string) => {
    const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    switch (type) {
      case 'fullname':
        setData((d) => {
          return {
            ...d,
            fullname: e.target.value,
          };
        })
        break;
      case 'email':
        setData((d) => {
          return {
            ...d,
            email: e.target.value,
          };
        })
        break;
      case 'subject':
        setData((d) => {
          return {
            ...d,
            subject: e.label,
          };
        })
        break;
      case 'message':
        const wordCount = e.target.value.trim().split(/\s+/).length;
        if (wordCount <= 101) {
          setData((d: any) => ({
            ...d,
            message: e.target.value,
          }));
        } else {
          setError((d: any) => ({
            ...d,
            message: { error: true, message: "More than 100 words" },
          }));
        }
        break;
    }
  }

  //Change error messages here
  const validate = () => {
    const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    let errorState = { ...error };

    if (data.fullname === "") {
      errorState = {
        ...errorState,
        fullname: { error: true, message: "שם מלא לא יכול להיות ריק" },
      }
    } else {
      errorState = {
        ...errorState,
        fullname: { error: false, message: "" },
      }
    }
    if (data.email === "") {
      errorState = {
        ...errorState,
        email: { error: true, message: "האימייל לא יכול להיות ריק" },
      }
    } else if (!regex.test(data.email)) {
      errorState = {
        ...errorState,
        email: { error: true, message: "כתובת האימייל אינה תקינה" },
      }
    } else {
      errorState = {
        ...errorState,
        email: { error: false, message: "" },
      }
    }
    if (data.subject === "") {
      errorState = {
        ...errorState,
        subject: { error: true, message: "נושא לא יכול להיות ריק" },
      }
    } else {
      errorState = {
        ...errorState,
        subject: { error: false, message: "" },
      }
    }
    const wordCount = data.message.trim().split(/\s+/).length;
    if (data.message === "") {
      errorState = {
        ...errorState,
        message: { error: true, message: "הודעה לא יכולה להיות ריקה" },
      }
    } else if (wordCount > 100) {
      errorState = {
        ...errorState,
        message: { error: true, message: "מספר המילים עובר 100 מילים" },
      }
    } else {
      errorState = {
        ...errorState,
        message: { error: false, message: "" },
      }
    }

    setError(errorState);
    return !Object.values(errorState).some((error: any) => error.error);

  };

  const submitHandler = async () => {
    const isValid = validate();
    if (isValid) {
      const res = await sendContactUsForm(data);
      if (!res.error) {
        setData(defaultFormState);
        toast.success('ההודעה נשלחה בהצלחה', defaultToastConfig(3000));
      } else toast.error('ההודעה נכשלה בשליחה', defaultToastConfig(3000));
    }
  };

  const labelStyles = {
    teztShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
    color: "var(--blue)",
    fontSize: isMobile ? "20px" : "26px",
    fontWeight: 500,
  };
  const inputStyles = {
    border: "2px solid #100159",
    borderRadius: "44px",
    minHeight: isMobile ? "30px" : "50px",
    fontSize: "15px",
  };
  return (
    <div style={{ marginTop: isMobile ? "20px" : "30px" }}>
      <CustomContainer>
        <h1
          className='text-center'
          style={{
            fontSize: isMobile ? "20px" : "30px",
            color: "var(--blue)",
            fontWeight: 700,
            lineHeight: "33px",
          }}
        >
          בואו נדבר
        </h1>
        <h3
          className='text-center'
          style={{
            fontSize: isMobile ? "15px" : "20px",
            color: "var(--blue)",
            fontWeight: 400,
            fontStyle: "italic",
          }}
        >
          (נשמח לסייע לכל שאלה, הצעה וטענה)
        </h3>
        <h1
          className='text-center mt-2'
          style={{ fontSize: isMobile ? "20px" : "30px", color: "var(--blue)", fontWeight: 700 }}
        >
          השאירו פרטים ונחזור אליך בהקדם
        </h1>
        <Row className='align-items-end mt-2'>
          <Col md={6} xs={12}>
            <Form.Group className='mb-4' controlId='exampleForm.ControlInput1'>
              <Form.Label style={labelStyles}>שם מלא</Form.Label>
              <Form.Control
                type='text'
                style={inputStyles}
                value={data.fullname}
                onChange={(e) => onChange(e, 'fullname')}
              />
              {error && error.fullname && error.fullname.error && (
                <Row className='justify-content-center'>
                  <p className='d-block py-1 text-danger' style={{ textAlign: "center" }}>
                    {error.fullname.message}
                  </p>
                </Row>
              )}
            </Form.Group>
            <Form.Group className='mb-4' controlId='exampleForm.ControlInput2'>
              <Form.Label style={labelStyles}>אימייל</Form.Label>
              <Form.Control
                type='text'
                style={inputStyles}
                value={data.email}
                onChange={(e) => onChange(e, 'email')}
              />
              {error && error.email && error.email.error && (
                <Row className='justify-content-center'>
                  <p className='d-block py-1 text-danger' style={{ textAlign: "center" }}>
                    {error.email.message}
                  </p>
                </Row>
              )}
            </Form.Group>
            <Form.Group className='' controlId='exampleForm.ControlInput3'>
              <Form.Label style={labelStyles}>נושא ההודעה</Form.Label>
              <Select
                options={subjectOptions}
                placeholder=''
                isSearchable={false}
                onChange={(e) => onChange(e, 'subject')}
                styles={{
                  menu: (styles) => {
                    return {
                      ...styles,
                      borderRadius: "44px",
                    };
                  },
                  option: (styles) => {
                    return {
                      ...styles,
                      paddingRight: "25px",
                      ":first-child": {
                        borderTopLeftRadius: "44px",
                        borderTopRightRadius: "44px",
                      },
                      ":last-child": {
                        borderBottomRightRadius: "44px",
                        borderBottomLeftRadius: "44px",
                      },
                    };
                  },
                  indicatorSeparator: () => ({ display: "none" }),
                  control: (styles) => {
                    return {
                      ...styles,
                      ...inputStyles,
                    };
                  },
                }}
              />
              {error && error.subject && error.subject.error && (
                <Row className='justify-content-center'>
                  <p className='d-block py-1 text-danger' style={{ textAlign: "center" }}>
                    {error.subject.message}
                  </p>
                </Row>
              )}
            </Form.Group>
          </Col>
          <Col md={6} xs={12}>
            <Form.Group className='mb-3 mt-3' controlId='exampleForm.ControlInput1'>
              <Form.Label className='text-center w-100' style={labelStyles}>
                תוכן ההודעה
              </Form.Label>
              <Form.Control
                type='text'
                as='textarea'
                rows={5}
                maxLength={1500}
                value={data.message}
                style={{
                  ...inputStyles,
                  resize: "none",
                }}
                onChange={(e) => onChange(e, 'message')}
              />
              <label
                className='d-block'
                style={{ textAlign: "left", paddingLeft: "20px" }}
              >
                עד 100 מילים
              </label>
              {error && error.message && error.message.error && (
                <Row className='justify-content-center'>
                  <p className='d-block py-1 text-danger' style={{ textAlign: "center" }}>
                    {error.message.message}
                  </p>
                </Row>
              )}
            </Form.Group>
            {/* {error && (
              <Row className='justify-content-center'>
                {Object.values(error).map((err: any, index: number) => (
                  <p
                    key={index}
                    className='d-block py-1 text-danger'
                    style={{ textAlign: "center" }}
                  >
                    {err.message}
                  </p>
                ))}
              </Row>
            )} */}
            <Row className='justify-content-center'>
              <div
                className='p-2'
                style={{
                  border: "2px solid var(--orange)",
                  borderRadius: "53px",
                  width: "fit-content",
                }}
              >
                <Button
                  size='lg'
                  className='border-0'
                  style={{
                    //fontFamily: "Rubik One",
                    borderRadius: "53px",
                    background:
                      "linear-gradient(90deg, #FB730C -6.97%, #FC9818 42.82%, #FDD72E 106.39%)",

                    boxShadow: "1px 2px 10px 4px rgba(0, 0, 0, 0.25)",
                    color: "#fff",
                    fontWeight: 700,
                    textShadow: "0px 4px 4px 0px #00000040 inset",

                    minWidth: "283px",
                  }}
                  onClick={submitHandler}
                >
                  שלח הודעה
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      </CustomContainer>
    </div>
  );
}
