import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import "./Login.css";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  authenticate,
  updatePassword,
  forgetPasswordApi,
  isAuthenticate,
  login,
  userProfile,
  verifyMail,
  verifyPassword,
  checkInstagramUsername,
} from "../../../api/auth";
import Layout from "../Layout/Layout";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faGoogle,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { useCookies } from "react-cookie";
import LoginPage from "../../StyleComponent/LoginPage/LoginPage";
import facebookImg from "../../../assets/images/icon/loginFACEBOOK.png";
import gmailImg from "../../../assets/images/icon/gmail.png";
import instagramImg from "../../../assets/images/icon/instagram.png";
// background image
import loginman from "../../../assets/images/bg-image/loginman.png";
import loginball from "../../../assets/images/bg-image/loginBall.png";
import loginIcon from "../../../assets/images/icon/loginIcon.png";
import errorImage from "../../../assets/images/icon/error.png";
import successImage from "../../../assets/images/icon/successImage.png";
// style component
import Button from "../../StyleComponent/Button/Button";
import WhatsAppButton from '../WhatsappWidget/index'
import { useMediaQuery } from 'react-responsive';

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  accessToken,
  changeLoginMode,
  email,
  fullName,
  loginCheck,
  provider,
} from "../../../store/theme/actions";
import { InitialState } from "../../../store/theme/themeReducer";
import {
  faCheck,
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
// langugae
import hebrew from "../../../assets/raw-text/search.json";
import { checkMail } from "./../../../api/auth";

//Google OAuth...
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialInstagram,
  IResolveParams,
} from "reactjs-social-login";

import {
  GoogleLoginButton,
  FacebookLoginButton,
  InstagramLoginButton,
} from "react-social-login-buttons";
import CustomGoogleLoginButton from "../../StyleComponent/ProvidersCustomButtons/CustomGoogleLoginButton";
import {
  ILoginRequeset,
  ThirdPartyApp,
} from "../../../Interfaces/LoginRequeset";
import CustomFacebookLoginButton from "../../StyleComponent/ProvidersCustomButtons/CustomFacebookLoginButton";
import {
  FACEBOOK_APP_ID,
  GOOGLE_CLIENT_ID,
  INSTAGRAM_CLIENT_ID,
  INSTAGRAM_CLIENT_SECRET,
  REDIRECT_URI,
} from "../../../config";
import iButton from '../../../assets/images/icon/iButton.png';
import ModalInfos from '../ModalInfos/ModalInfos';

const Login = () => {
  const youtubeEmbedVideo = "https://www.youtube.com/embed/WWCK8vjpRDo"
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [creatingSuccess, setCreatingSuccess] = useState(false);
  const [loginUser, setLoginUser] = useState<any>();
  // redux
  const themeState = useSelector((state: InitialState) => state);
  const dispatch = useDispatch();
  const { user } = isAuthenticate();
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the breakpoint for mobile devices

  const [loginMode, setLoginMode] = useState("");

  const [modalShow, setModalShow] = useState(false);
  // password reset
  const [inputEmail, setInputEmail] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [forgetEmailValue, setForgetEmail] = useState("");
  const [forgetPassError, setForgetPassError] = useState("");
  const [codePassError, setCodeError] = useState("");
  const [forgetPass, setForgetPass] = useState(false);
  const [forgetCode, setForgetCode] = useState("");
  const [passConfirm, setPassConfirm] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [errorUpdatePass, setErrorUpdatePass] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleType, setToggleType] = useState("password");
  const ModalHandleShow = () => {
    setModalShow(true);
  };
  const successHandleClose = () => {
    setModalShow(false);
    return navigate("/login");
  };

  const [modalErrorShow, setErrorModalShow] = useState(false);

  const errorHandleClose = () => {
    setErrorModalShow(false);
    return navigate("/login");
  };

  const ModalErrorHandleShow = () => {
    setErrorModalShow(true);
  };

  const gameTypeHandler = (value: string) => {
    dispatch(changeLoginMode(value));
  };

  React.useEffect(() => {
    gameTypeHandler(loginMode);
  }, [loginMode]);

  const { register, handleSubmit, setValue } = useForm();

  let onSubmit = (user: any) => {
    const { email, password } = user;
    invokeLogin({ email, password });
  };

  const invokeLogin = (user: ILoginRequeset) => {
    login(user).then((data) => {
      if (data.statusCode != 200) {
        setError(data.message);
      } else {
        setCreatingSuccess(data.message);
        authenticate(data, () => {
          setValue("email", "", { shouldValidate: false });
          setValue("password", "", { shouldValidate: false });
          return navigate("/search");
        });
      }
    });
  };

  //error tooltip
  const showError = () => {
    return (
      <>
        <div className='toastPosition'>
          <ToastContainer position='bottom-end' className='p-3'>
            <Toast
              onClose={() => setError(false)}
              show={error}
              delay={3000}
              autohide
            >
              <div className='errorNotificaton'>
                <Toast.Body>
                  <img src={errorImage} />
                  {error}
                </Toast.Body>
              </div>
            </Toast>
          </ToastContainer>
        </div>
      </>
    );
  };

  // another
  // success login
  const successLogin = () => {
    return (
      <>
        <div className='toastPosition'>
          <ToastContainer position='bottom-end' className='mt-1 errorToast'>
            <Toast
              onClose={() => setCreatingSuccess(false)}
              show={creatingSuccess}
              delay={5000}
              autohide
            >
              <div className='successNotificaton'>
                <Toast.Body>
                  <img src={successImage} />
                  {creatingSuccess}
                </Toast.Body>
              </div>
            </Toast>
          </ToastContainer>
        </div>
      </>
    );
  };

  const [searchParams, setSearchParams] = useSearchParams();
  //  searchParams.get("token")

  const token = searchParams.get("token");
  const switchAccount = searchParams.get("switch");

  useEffect(() => {
    if (themeState.loginCheck === "true") {
      setCreatingSuccess(true);
      setTimeout(() => {
        dispatch(loginCheck("false"));
      }, 3000);
    }
  }, []);

  if (token) {
    verifyMail(token).then((data) => {
      if (data.status === true) {
        ModalHandleShow();
      } else {
        setErrorModalShow(true);
      }
    });
  }

  const tryLoginWithGoogleOAuth = (userData: any) => {
    checkMail(userData.email)
      .then((res) => {
        if (res.status) {
          const user: ILoginRequeset = {
            email: userData.email,
            thirdPartyAppName: ThirdPartyApp.GOOGLE,
            thirdPartyToken: userData.access_token,
          };

          invokeLogin(user);
        } else {
          dispatch(email(userData.email));
          dispatch(fullName(userData.name));
          dispatch(accessToken(userData.access_token));
          dispatch(provider(ThirdPartyApp.GOOGLE));
          return navigate("/signup");
        }
      })
      .catch((err) => {
        console.log(
          "Error in tryLoginWithGoogleOAuth() while verifying email",
          err
        );
      });
  };

  const onLoginStart = useCallback(() => { }, []);

  const onLogoutSuccess = useCallback(() => { }, []);

  const tryLoginWithFacebookOAuth = (userData: any) => {
    checkMail(userData.email)
      .then((res) => {
        if (res.status) {
          const user: ILoginRequeset = {
            email: userData.email,
            thirdPartyAppName: ThirdPartyApp.FACEBOOK,
            thirdPartyToken: userData.access_token,
          };

          invokeLogin(user);
        } else {
          dispatch(email(userData.email));
          dispatch(fullName(userData.name));
          dispatch(accessToken(userData.access_token));
          dispatch(provider(ThirdPartyApp.FACEBOOK));
          return navigate("/signup");
        }
      })
      .catch((err) => {
        console.log(
          "Error in tryLoginWithFacebookOAuth() while verifying email",
          err
        );
      });
  };

  const tryLoginWithInstagramOAuth = (userData: any) => {
    checkInstagramUsername(userData.username)
      .then((res) => {
        if (res.status) {
          setError(res.message);
        } else {
          dispatch(email(""));
          dispatch(fullName(userData.username));
          dispatch(accessToken(userData.access_token));
          dispatch(provider(ThirdPartyApp.INSTAGRAM));
          return navigate("/signup");
        }
      })
      .catch((err) => {
        console.log(
          "Error in tryLoginWithInstagramOAuth() while verifying email",
          err
        );
      });
  };

  // forget password

  const forgetPasswordModal = () => {
    setPasswordModal(true);
    setInputEmail(true);
    setPassConfirm(false);
    setForgetPass(false);
  };

  // forget email confirm
  const forgetEmail = (e: any) => {
    setForgetEmail(e.target.value);
  };

  const forgetEmailSend = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      !emailPattern.test(forgetEmailValue) ||
      !forgetEmailValue.includes("@")
    ) {
      setForgetPassError("האימייל לא חוקי");
    } else {
      forgetPasswordApi(forgetEmailValue).then((data: any) => {
        if (data.statusCode !== 200) {
          setForgetPassError('אנא הכנס דוא"ל שכבר רשום במערכת');
        } else {
          setForgetPassError("");
          setInputEmail(false);
          setForgetPass(true);
        }
      });
    }
  };
  // forget email code

  const forgetPassHandle = (e: any) => {
    setForgetCode(e.target.value);
  };
  const forgetEmailCode = () => {
    const verifyObj = {
      email: forgetEmailValue,
      code: parseInt(forgetCode),
    };
    verifyPassword(verifyObj).then((data: any) => {
      if (data.statusCode !== 200) {
        setCodeError("הסיסמה לא תואמת");
      } else {
        setCodeError("");
        setForgetPass(false);
        setPassConfirm(true);
      }
    });
  };

  // new password

  const newPassword = (e: any) => {
    setNewPass(e.target.value);
  };
  //password validation
  const newPasswordValidation = () => {
    const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/); //
    const check = regex.test(newPass);
    if (check) {
      setErrorUpdatePass("");
    } else {
      setErrorUpdatePass("not_strong");
    }
  };
  const forgetPasswordClose = () => {
    const updatePass = {
      email: forgetEmailValue,
      password: newPass,
    };
    const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/); //
    const check = regex.test(newPass);

    if (check) {
      setErrorUpdatePass("");
    } else {
      setErrorUpdatePass("not_strong");
    }
    updatePassword(updatePass).then((data: any) => {
      if (data.statusCode !== 200) {
        setCodeError("הזן סיסמה נכונה");
      } else {
        setCodeError("");
        setPasswordModal(false);
        setPassConfirm(false);
      }
    });
  };
  const forgetPassClose = () => {
    setPasswordModal(false);
    setForgetEmail("");
    setCodeError("");
    setErrorUpdatePass("");
  };

  const LoginSuccess = () => {
    return (
      <>
        <div className='toastPosition'>
          <ToastContainer position='bottom-end' className='mt-1 errorToast'>
            <Toast
              onClose={() => setCreatingSuccess(false)}
              show={creatingSuccess}
              autohide
            >
              <div className='successNotificaton signUpNotify'>
                <Toast.Body>
                  <img src={successImage} />
                  <div>
                    <p>מייל לאישור החשבון נשלח למייל שהזנת,</p>
                    <p>אנא אשר את הרשמתך דרך המייל, תודה.</p>
                  </div>
                </Toast.Body>
              </div>
            </Toast>
          </ToastContainer>
        </div>
      </>
    );
  };

  // password show
  const showPass = () => {
    setTogglePassword(!togglePassword);
    setToggleType("password");
  };

  const hidePass = () => {
    setTogglePassword(!togglePassword);
    setToggleType("text");
  };

  return (
    <>
      {showError()}
      {successLogin()}
      {LoginSuccess()}
      <Layout>
        {/* <LoginPage backgroundImage={themeState.gameType === 'football' ? loginman : loginball}> */}
        <LoginPage
          backgroundImage={
            themeState.loginMode === "light" ? loginman : loginball
          }
        >
          <div className='toggleButton'>
            <label className='switch light'>
              <input type='checkbox' id='togBtn' />
              <div
                className={
                  themeState.loginMode === "light"
                    ? "slider round lightToggle"
                    : "slider round darkToggle"
                }
              >
                <span className='on' onClick={() => setLoginMode("")}>
                  בהיר
                </span>
                <span className='off' onClick={() => setLoginMode("light")}>
                  כהה
                </span>
              </div>
            </label>
          </div>
          <Container>
            <Row className='d-flex align-items-center'>
              <Col md={5} style={{ textAlign: 'center' }}>
                <form className='loginBox' onSubmit={handleSubmit(onSubmit)}>
                  <div style={{ display: 'flex' }}>
                    <ModalInfos />
                    <h1>ברוך שובך ל׳טאפ אנ סקור׳</h1>
                  </div>
                  <div className='loginIcon'>
                    <img src={loginIcon} alt='' />
                  </div>
                  <div className='SocialIcons'>
                    <p>כניסה אחרת</p>
                    <LoginSocialGoogle
                      isOnlyGetToken={false}
                      client_id={GOOGLE_CLIENT_ID || ""}
                      scope='openid profile email'
                      onResolve={({ provider, data }: IResolveParams) => {
                        tryLoginWithGoogleOAuth(data);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <div className='socialLink'>
                        {" "}
                        <img src={gmailImg} alt='' />
                      </div>
                    </LoginSocialGoogle>

                    <LoginSocialFacebook
                      isOnlyGetToken={false}
                      appId={FACEBOOK_APP_ID || ""}
                      onLoginStart={onLoginStart}
                      scope='user_profile user_media'
                      onResolve={({ provider, data }: IResolveParams) => {
                        tryLoginWithFacebookOAuth(data);
                      }}
                      onReject={(err) => {
                        console.log("err: ", err);
                      }}
                    >
                      <div className='socialLink'>
                        <img src={facebookImg} alt='' />
                      </div>
                    </LoginSocialFacebook>

                    <LoginSocialInstagram
                      isOnlyGetToken={false}
                      client_id={INSTAGRAM_CLIENT_ID || ""}
                      client_secret={INSTAGRAM_CLIENT_SECRET || ""}
                      redirect_uri={REDIRECT_URI || ""}
                      onLoginStart={onLoginStart}
                      onResolve={({ provider, data }: IResolveParams) => {
                        tryLoginWithInstagramOAuth(data);
                      }}
                      onReject={(err: any) => {
                        console.log(err);
                      }}
                    >
                      <div className='socialLink'>
                        <img src={instagramImg} alt='' />
                      </div>
                    </LoginSocialInstagram>
                  </div>
                  <div className='inputWithIcon'>
                    <input
                      type='email'
                      id='email'
                      placeholder='מייל'
                      {...register("email", { required: true })}
                    />
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <div className='inputWithIcon inputPassShow'>
                    <input
                      type={toggleType}
                      id='password'
                      placeholder='סיסמה'
                      {...register("password", { required: true })}
                    />
                    <FontAwesomeIcon icon={faLock} />
                    {togglePassword ? (
                      <span onClick={() => showPass()}>
                        <FontAwesomeIcon icon={faEye} />{" "}
                      </span>
                    ) : (
                      <span onClick={() => hidePass()}>
                        <FontAwesomeIcon icon={faEyeSlash} />
                      </span>
                    )}
                  </div>
                  <div className='fogetPassword'>
                    {/* <p>זכור אותי</p> */}
                    <div className='check'>
                      <div className='round'>
                        <input type='checkbox' id='checkbox' />
                        <label htmlFor='checkbox'>
                          <span>{hebrew["remember"]}</span>
                        </label>
                      </div>
                    </div>
                    <p onClick={forgetPasswordModal}>שכחת סיסמא?</p>
                  </div>
                  <Button
                    className='loginButton'
                    buttonBorder='#fff'
                    buttonBackground='#fff'
                  >
                    <div className='mainBtn'>
                      <button type='submit'>
                        <p>התחברות</p>
                      </button>
                    </div>
                  </Button>
                  <div
                    className={
                      themeState.loginMode === "light" ? "lightBtn" : "darkBtn"
                    }
                  >
                    <p>
                      {" "}
                      עדיין לא נרשמת?<Link to='/signup'>הירשם כאן</Link>
                    </p>
                  </div>
                </form>
                <iframe
                  // width="100%"
                  width={isMobile ? "100%" :  "100%"}
                  height="250"
                  style={{borderRadius: "10px", marginBottom: "10%"}}
                  src={youtubeEmbedVideo}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  >
                </iframe>
              </Col>
            </Row>
          </Container>

          {/* success modal */}
          <Modal
            show={modalShow}
            // onHide={successHandleClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            className='modal-confirm'
          >
            <Modal.Header>
              <div className='icon-box'>
                <FontAwesomeIcon icon={faCheck} />
              </div>
              <h4 className='modal-title w-100'>הרשמתך לאתר הושלמה!</h4>
            </Modal.Header>
            <Modal.Body>
              <div className='modal-body'>
                <p className='text-center'>
                  היכנס למשתמש, חפש שותפים למשחקים, והתחל לשחק{" "}
                </p>
                <p className='text-center'>⚽🏀🎾🏐</p>
              </div>
              <div className='modal-footer text-center'>
                <button
                  className='btn btn-success btn-block'
                  data-dismiss='modal'
                  onClick={successHandleClose}
                >
                  OK
                </button>
              </div>
            </Modal.Body>
          </Modal>

          {/* error modal */}
          <Modal
            show={modalErrorShow}
            //   onHide={successHandleClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            className='modal-confirm modal-fail'
          >
            <Modal.Header>
              <div className='icon-box'>
                <FontAwesomeIcon icon={faXmark} />
              </div>
              <h4 className='modal-title w-100'>שגיע !!</h4>
            </Modal.Header>
            <Modal.Body>
              <div className='modal-header'></div>
              <div className='modal-body'>
                <p className='text-center'>
                  טוקן שגוי, נא להרשם או לצור קשר עם התמיכה
                </p>
              </div>
              <div className='modal-footer text-center'>
                <button
                  className='btn btn-success btn-block'
                  data-dismiss='modal'
                  onClick={errorHandleClose}
                >
                  OK
                </button>
              </div>
            </Modal.Body>
          </Modal>
          {/* forget password modal */}
          <Modal
            show={passwordModal}
            onHide={forgetPassClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            className=''
          >
            <Modal.Header className='forgetClose' closeButton>
              <h4>שכחת את הסיסמא</h4>
            </Modal.Header>
            <Modal.Body>
              {inputEmail ? (
                <>
                  <div className='forgetInput'>
                    <p>אנא הזן את המייל שאיתו נרשמת לאתר</p>
                    <input
                      type='email'
                      placeholder='אימייל'
                      onChange={forgetEmail}
                    />
                    {forgetPassError && forgetPassError.length ? (
                      <span className='errMsg'>{forgetPassError}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className='forgetBtn text-center'>
                    <button className='btn' onClick={forgetEmailSend}>
                      אשר
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              {forgetPass ? (
                <>
                  <div className='forgetInput'>
                    <p>נא להזין את הקוד שמופיע במייל ששלחנו</p>
                    <input
                      type='number'
                      placeholder='קוד'
                      onChange={forgetPassHandle}
                    />
                    {codePassError && codePassError.length ? (
                      <span className='errMsg'>{codePassError}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className='forgetBtn text-center'>
                    <button className='btn' onClick={forgetEmailCode}>
                      אשר
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              {passConfirm ? (
                <>
                  <div className='twoPass forgetInput'>
                    <p>אנא הזן את הסיסמה החדשה שלך</p>
                    <input
                      type='password'
                      onBlur={newPasswordValidation}
                      className='mt-1'
                      onChange={newPassword}
                      placeholder='אשר סיסמה'
                    />
                    {errorUpdatePass && errorUpdatePass.length ? (
                      errorUpdatePass === "not_strong" ? (
                        <div className='multiError'>
                          <span>סיסמה לא חזקה מידי</span>
                          <span>סיסמה חזקה היא:</span>
                          <span>
                            לפחות אות גדולה באנגלית, אות קטנה באנגלית, לפחות
                            מספר אחד, ומינימום של 8 תווים
                          </span>
                        </div>
                      ) : (
                        <p className='errorMsg'>{errorUpdatePass}</p>
                      )
                    ) : (
                      ""
                    )}
                    <div className='forgetBtn text-center'>
                      <button className='btn' onClick={forgetPasswordClose}>
                        אשר
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Modal.Body>
          </Modal>
        </LoginPage>
        <WhatsAppButton login={true} light={loginMode === 'light'} />
      </Layout>
    </>
  );
};

export default Login;
